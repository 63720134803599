import {Comment} from '../Account/interface';
import {Offer2} from '../../Products';

export interface SingleCategory {
  Name: string | null;
  CategoryId: string | null;
}

export interface Offer {
  AvailabilityEndDate?: string;
  AvailabilityStartDate?: string;
  Category?: SingleCategory | null;
  SecondCategoryId?: SingleCategory | null;
  ThirdCategoryId?: SingleCategory | null;
  CoverFile?: string;
  Description?: string;
  Featured?: boolean;
  GrossValue?: number;
  Id: string;
  IsWinWin?: boolean;
  Name?: string;
  NegotiatedOffer?: boolean;
  ProducerId?: string;
  ProducerName?: string;
  ProductDemoFile?: string;
  ProductId?: string;
  ProductType?: number;
  RightsEnd?: number;
  Tags?: string;
  Currency?: null | string;
}

export enum PageTheme {
  PLATINUM = 'platinum',
  CLASSIC = 'classic',
  DARK = 'dark',
}

export enum PageStyle {
  BASIC = 'basic',
  MODERN = 'modern',
  READABLE = 'readable',
}

// export enum BlockType {
//   BASIC = 'basic',
//   BIOGRAPHY = 'biography',
//   GRAPHICS = 'graphics',
// }

export interface BlockSettings {
  id: string;
  blockWidth: number;
  blockHeight: number;
  fontColor: BlockColor;
  backgroundColor: BlockColor;
  backgroundAlpha: number;
  blockType: BlockType;
}

export enum BlockColor {
  WHITE = 'F8F8F8',
  LIGHT_GRAY = '919191',
  GRAY = '5D5D5D',
  DARK = '211D1D',
  DARK_BLUE = '17181B',
}

export interface Skin {
  Id: string;
  Name: string;
  PrimaryColorHex: string;
  SecondaryColorHex: string;
}

export interface BlockType {
  FieldName: string;
  FlagValue: number;
}

export interface BlockFile {
  Id: string;
  BlockId?: string;
  CreationDate?: string;
  FileName?: string;
  FilePath: string;
  Audio?: HTMLAudioElement;
}

export interface CardCategory {
  Description: string;
  Id: string;
  Name: string;
}

export interface WebAreaAndMobileArea {
  Height: number;
  StartX: number;
  StartY: number;
  Width: number;
}

export interface UrlType {
  UrlType: number;
  Url: string;
}

export interface Block {
  BackgroundColorHex: string;
  BlockOpacityIn0To100Ratio: number;
  FontColorHex: string;
  TitleColorHex: string;
  Format?: null | number;
  // Height: number;
  Id: string;
  // StartX: number;
  // StartY: number;
  Type: number;
  // Width: number;
  MobileArea: WebAreaAndMobileArea;
  WebArea: WebAreaAndMobileArea;
  FirstName?: string;
  FtpPhotoFilePath?: string;
  LastName?: string;
  PostalCode?: string;
  Name?: string;
  Role?: number;
  City?: string;
  Street?: string;
  Region?: string;
  NIP?: number | null;
  SecondaryRole?: number;
  ProducerCountry?: string;
  DisplayProducerNameAsPublicDisplayName?: boolean;
  Comments?: Comment[];
  SettlementType?: number;
  Text?: string;
  Title?: string;
  SubTitle?: string;
  TextOne?: string;
  TextTwo?: string;
  TextThree?: string;
  BlockFileIds?: string[];
  BlockFiles?: BlockFile[];
  OfferIds?: string[];
  Offers?: Offer[];
  Offer?: Offer;
  OfferId?: string;
  Url?: UrlType;
  UrlType?: number;
  HorizontalFormat?: number;
  VerticalFormat?: number;
  Interline?: number;
  RemoveCurrentBanner?: boolean;
  CurrentFileFtpFilePath?: string;
  Base64BannerString?: string;
  Urls?: UrlType[];
  MaskType?: number;
  SpotifyUrl?: string;
}

export interface Producer {
  Id: string;
  ChosenSkinId: string;
  ChosenSkin: Skin;
  BackgroundColorHex: string;
  Blocks: Block[];
  CardCategory: CardCategory;
  DraftMatchPublish?: boolean;
  ProducerLastName?: string;
  ProducerName?: string;
  ProducerFirstName?: string;
  ProducerPostalCode?: string;
  ProducerStreet?: string;
  ProducerProducerPhoto?: string;
  PublishedCardOutOfDate?: string;
  ProducerSecondaryRole?: number;
  ProducerRole?: number;
  ProducerSettlementType: number;
  AutoRenewalEnabled?: boolean;
  IsActive?: boolean;
}

export interface BusinessCardCategory {
  value: string;
  text: string;
}
