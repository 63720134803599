import React, {useState, useContext, useRef, useEffect} from 'react';
import styled, {css, keyframes} from 'styled-components';
import {Link, useNavigate} from 'react-router-dom';

import {getProductTypePolishName} from 'utils/getProductTypePolishName';
import {getOrderTypePolishName} from 'utils/getOrderTypePolishName';
import useAlert from 'hooks/useAlert';
import PopupButton from 'components/PopUp/PopupButton';
import winWinIcon from 'images/svg/win-win-icon.svg';
import addFileImage from 'images/add_file.png';
import api from '../../../../services/axiosConfig';
import {useCookies} from 'react-cookie';
import {InputView, SelectView} from 'pages/MyAccount/Settings/styles';
import {
  OrderStatus,
  OrderType,
  PaymentMethod,
  FileTypeEnum,
} from 'constants/transactionEnums';
import {WinWinSvg} from 'components/SvgComponents';
import {Order, PayOut, Transaction} from '../index';
import {ProducerDataContext} from 'contexts/ProducerDataContext';
import {UserDataContext} from 'contexts/UserDataContext';
import {
  WithdrawButton,
  WithdrawMenuHeaderWrapper,
  WithdrawMenuWrapper,
  WithdrawMenuHeader,
} from '../index';
import {
  TextareaWrapper,
  StyledTextarea,
} from 'pages/MyStudio/Conditions/EditCondition/styles';
import {TextareaLettersCount} from 'pages/MyStudio/Conditions/EditCondition';
import {LoadingCircle} from 'utils/loadingCircle';
import {
  ThreadMessageWrapper,
  ThreadMessageHeader,
  ThreadMessageHeaderNameAndImg,
  ThreadMessageHeaderIcons,
  ThreadMessageContent,
} from 'pages/MyStudio/Messages/newMessage/styles';
import {
  BlueHr,
  CloseComplaintWindowButton,
  SingleDocumentsTabWrapper,
  DeleteOrderButton,
  DetailArrowButton,
  SingleDocumentsTabHeaderWrapper,
  WithdrawNewButton,
  DetailsContent,
  DetailsHeader,
  MobileTransactionNameWithNumberWrapper,
  ButtonsWrapper,
  DocumentsTabWrapper,
  MainWrapper,
  StatusHistoryTabMain,
  MobileBlackArrowSvg,
  SingleSectionPriceWithWinWin,
  MobileMainWrapper,
  MobileSingleTransactionWrapper,
  MobileSingleTransitionWithStatusWrapper,
  MobileStyledStatus,
  MobileTransactionDetailsWrapper,
  MobileTransactionTypeWithDateWrapper,
  PaymentTabWrapper,
  PrimaryButton,
  SingleTransactionMain,
  SingleProductTabItem,
  ProductTab,
  ProductsTabWrapper,
  RedHr,
  RedStatusHistoryInfo,
  RedWarningWrapper,
  RedWarningWrapperStatus6b,
  SingleSection,
  SingleStatusDiv,
  SingleStatusWrapper,
  SingleStatusWrapperBlinkingBorderYellow,
  SingleTransactionWrapper,
  StatusHistoryBlueButton,
  StatusHistoryButton,
  StatusHistoryCyanButton,
  StatusHistoryDateInput,
  StatusHistoryGreenButton,
  StatusHistoryGreenButtonStatus13a,
  StatusHistoryGreenButtonStatus6a,
  StatusHistoryGreenButtonStatus6a3,
  StatusHistoryGreenButtonStatus7,
  StatusHistoryInfo,
  StatusHistoryInfoStatus10,
  StatusHistoryInfoStatus10a,
  StatusHistoryInfoStatus13a,
  StatusHistoryInfoStatus13b,
  StatusHistoryInfoStatus14,
  StatusHistoryInfoStatus6a,
  StatusHistoryInfoStatus6b,
  StatusHistoryInfoStatus6b2,
  StatusHistoryInfoStatus8,
  StatusHistoryInfoStatus8a,
  StatusHistoryInfoStatus9a,
  StatusHistoryInputWithButtonWrapper,
  StatusHistoryInputWrapper,
  StatusHistoryLightBlueButton,
  StatusHistoryOrangeButton,
  StatusHistoryOrangeButtonStatus2,
  StatusHistoryOrangeButtonStatus6,
  StatusHistoryRedButton,
  StatusHistoryRedButtonWithPopup,
  StatusHistoryRedButtonStatus13a,
  StatusHistoryRedButtonStatus6a,
  StatusHistoryRedButtonStatus7,
  StatusHistoryTabWrapper,
  NewSingleStatusWrapper,
  StatusHistoryYellowButton,
  StatusHistoryYellowButtonStatus14a,
  StyledHr,
  MessagesTabWrapper,
  StyledStatus,
  TransactionDetailsWrapper,
  UserDataTabWrapper,
  WinWinInfo,
  WithdrawMenuContent,
  YellowHr,
  YellowStatusHistoryInfo,
  YellowWarningWrapper,
  blinkAnimation,
  blinkAnimationFontColor,
} from './styles';

const defaultAvatar = (
  <svg
    id="Group_4321"
    data-name="Group 4321"
    xmlns="http://www.w3.org/2000/svg"
    width="193"
    height="193"
    viewBox="0 0 193 193"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_2509"
          data-name="Rectangle 2509"
          width="193"
          height="193"
          fill="#dce0e9"
        />
      </clipPath>
    </defs>
    <g id="Group_4320" data-name="Group 4320" clipPath="url(#clip-path)">
      <path
        id="Path_3684"
        data-name="Path 3684"
        d="M132.354,26.555a5.75,5.75,0,0,1-5.75-5.75V16.2a4.711,4.711,0,0,0-4.7-4.7h-4.6a5.75,5.75,0,0,1,0-11.5h4.6a16.222,16.222,0,0,1,16.2,16.2v4.6a5.75,5.75,0,0,1-5.75,5.75"
        transform="translate(54.058 0)"
        fill="#dce0e9"
      />
      <path
        id="Path_3685"
        data-name="Path 3685"
        d="M5.75,26.555A5.75,5.75,0,0,1,0,20.805V16.2A16.222,16.222,0,0,1,16.2,0h4.6a5.75,5.75,0,0,1,0,11.5H16.2a4.711,4.711,0,0,0-4.7,4.7v4.6a5.75,5.75,0,0,1-5.75,5.75"
        transform="translate(0)"
        fill="#dce0e9"
      />
      <path
        id="Path_3686"
        data-name="Path 3686"
        d="M20.805,138.105H16.2A16.224,16.224,0,0,1,0,121.9v-4.6a5.75,5.75,0,0,1,11.5,0v4.6a4.711,4.711,0,0,0,4.7,4.7h4.6a5.75,5.75,0,0,1,0,11.5"
        transform="translate(0 54.059)"
        fill="#dce0e9"
      />
      <path
        id="Path_3687"
        data-name="Path 3687"
        d="M132.354,26.555a5.75,5.75,0,0,1-5.75-5.75V16.2a4.711,4.711,0,0,0-4.7-4.7h-4.6a5.75,5.75,0,0,1,0-11.5h4.6a16.222,16.222,0,0,1,16.2,16.2v4.6a5.75,5.75,0,0,1-5.75,5.75"
        transform="translate(54.058 0)"
        fill="#dce0e9"
      />
      <path
        id="Path_3688"
        data-name="Path 3688"
        d="M122.464,138.668h-4.6a5.75,5.75,0,0,1,0-11.5h4.6a4.712,4.712,0,0,0,4.7-4.7v-4.6a5.749,5.749,0,1,1,11.5,0v4.6a16.222,16.222,0,0,1-16.2,16.2"
        transform="translate(54.332 54.332)"
        fill="#dce0e9"
      />
      <path
        id="Path_3689"
        data-name="Path 3689"
        d="M122.464,138.668h-4.6a5.75,5.75,0,0,1,0-11.5h4.6a4.712,4.712,0,0,0,4.7-4.7v-4.6a5.749,5.749,0,1,1,11.5,0v4.6a16.222,16.222,0,0,1-16.2,16.2"
        transform="translate(54.332 54.332)"
        fill="#dce0e9"
      />
      <path
        id="Path_3690"
        data-name="Path 3690"
        d="M5.75,26.555A5.75,5.75,0,0,1,0,20.805V16.2A16.222,16.222,0,0,1,16.2,0h4.6a5.75,5.75,0,0,1,0,11.5H16.2a4.711,4.711,0,0,0-4.7,4.7v4.6a5.75,5.75,0,0,1-5.75,5.75"
        transform="translate(0)"
        fill="#dce0e9"
      />
      <path
        id="Path_3691"
        data-name="Path 3691"
        d="M20.805,138.105H16.2A16.224,16.224,0,0,1,0,121.9v-4.6a5.75,5.75,0,0,1,11.5,0v4.6a4.711,4.711,0,0,0,4.7,4.7h4.6a5.75,5.75,0,0,1,0,11.5"
        transform="translate(0 54.059)"
        fill="#dce0e9"
      />
      <path
        id="Path_3692"
        data-name="Path 3692"
        d="M66.283,112.6A35.9,35.9,0,0,1,30.422,76.736a5.751,5.751,0,0,1,11.5,0A24.387,24.387,0,0,0,66.283,101.1a5.75,5.75,0,1,1,0,11.5"
        transform="translate(14.743 34.401)"
        fill="#dce0e9"
      />
      <path
        id="Path_3693"
        data-name="Path 3693"
        d="M83.567,100.785H66.595a5.75,5.75,0,1,1,0-11.5h11.05C76.03,62.043,63.63,39.187,63.5,38.944A5.75,5.75,0,1,1,73.574,33.4c.643,1.17,15.743,29.012,15.743,61.632a5.75,5.75,0,0,1-5.75,5.75"
        transform="translate(29.486 14.743)"
        fill="#dce0e9"
      />
      <path
        id="Path_3694"
        data-name="Path 3694"
        d="M66.283,112.6A35.9,35.9,0,0,1,30.422,76.736a5.751,5.751,0,0,1,11.5,0A24.387,24.387,0,0,0,66.283,101.1a5.75,5.75,0,1,1,0,11.5"
        transform="translate(14.743 34.401)"
        fill="#dce0e9"
      />
      <path
        id="Path_3695"
        data-name="Path 3695"
        d="M83.567,100.785H66.595a5.75,5.75,0,1,1,0-11.5h11.05C76.03,62.043,63.63,39.187,63.5,38.944A5.75,5.75,0,1,1,73.574,33.4c.643,1.17,15.743,29.012,15.743,61.632a5.75,5.75,0,0,1-5.75,5.75"
        transform="translate(29.486 14.743)"
        fill="#dce0e9"
      />
      <path
        id="Path_3696"
        data-name="Path 3696"
        d="M36.173,46.915a5.75,5.75,0,0,1-5.75-5.75V33.637a5.75,5.75,0,1,1,11.5,0v7.528a5.75,5.75,0,0,1-5.75,5.75"
        transform="translate(14.743 13.514)"
        fill="#dce0e9"
      />
      <path
        id="Path_3697"
        data-name="Path 3697"
        d="M36.173,46.915a5.75,5.75,0,0,1-5.75-5.75V33.637a5.75,5.75,0,1,1,11.5,0v7.528a5.75,5.75,0,0,1-5.75,5.75"
        transform="translate(14.743 13.514)"
        fill="#dce0e9"
      />
      <path
        id="Path_3698"
        data-name="Path 3698"
        d="M97.018,46.915a5.75,5.75,0,0,1-5.75-5.75V33.637a5.75,5.75,0,1,1,11.5,0v7.528a5.75,5.75,0,0,1-5.75,5.75"
        transform="translate(44.23 13.514)"
        fill="#dce0e9"
      />
      <path
        id="Path_3699"
        data-name="Path 3699"
        d="M97.018,46.915a5.75,5.75,0,0,1-5.75-5.75V33.637a5.75,5.75,0,1,1,11.5,0v7.528a5.75,5.75,0,0,1-5.75,5.75"
        transform="translate(44.23 13.514)"
        fill="#dce0e9"
      />
    </g>
  </svg>
);

export const StyledStatusWrapper = styled.div<{statusType?: number}>`
  width: 220px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin: 0 auto;

  & > h1 {
    letter-spacing: 0.1px;
    font-size: 11px;
    font-weight: 500;

    ${({statusType, theme}) => {
      switch (statusType) {
        case 0:
          return css`
            color: ${theme.colors.statusNameBlack};
          `;
        case 1:
          return css`
            color: #fb9614;
          `;
        case 2:
        case 3:
        case 4:
          return css`
            color: #fb9614;
          `;
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
          return css`
            color: #fb9614;
          `;
        case 10:
          return css`
            color: #ff0300;
          `;
        case 11:
        case 12:
        case 13:
        case 15:
        case 17:
        case 18:
          return css`
            color: #0091ff;
          `;
        case 14:
        case 16:
        case 19:
        case 20:
          return css`
            color: #ff0300;
          `;
        default:
          return css`
            color: #fb9614;
          `;
      }
    }}
  }
`;

export const StyledStatusLine = styled.div<{statusType?: number}>`
  width: 220px;
  border-bottom: 3px solid ${({theme}) => theme.colors.statusDefaultLine};
  position: relative;
  border-radius: 25px;

  & > div {
    position: absolute;
    left: 0px;
    border-radius: 25px;
    height: 3px;

    ${({statusType, theme}) => {
      switch (statusType) {
        case 0:
          return css`
            background: ${theme.colors.statusLineBlack};
            width: 30px;
          `;
        case 1:
          return css`
            background: #fb9614;
            width: 60px;
          `;
        case 2:
        case 3:
        case 4:
          return css`
            background: #fb9614;
            width: 110px;
          `;
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
          return css`
            background: #fb9614;
            width: 155px;
          `;
        case 10:
          return css`
            background: #ff0300;
            width: 155px;
          `;
        case 11:
        case 12:
        case 13:
        case 15:
        case 17:
        case 18:
          return css`
            background: #0091ff;
            width: 220px;
          `;
        case 14:
        case 16:
        case 19:
        case 20:
          return css`
            background: #ff0300;
            width: 220px;
          `;
        default:
          return css`
            background: #fb9614;
            width: 155px;
          `;
      }
    }}
  }
`;

interface Props {
  transaction: Transaction;
  isDetailsOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  setTransactions: React.Dispatch<React.SetStateAction<Transaction[]>>;
  index: number;
  setCurrentIndexSelected: React.Dispatch<React.SetStateAction<number | null>>;
  currentIndexSelected: number | null;
  mobileRef?: any;
  desktopRef?: any;
}

export const getFormattedDate = (dateToFormat: string = undefined) => {
  const date = dateToFormat ? new Date(dateToFormat) : new Date();
  const year = date.getFullYear();
  let month: string | number = date.getMonth() + 1;
  let day: string | number = date.getDate();

  if (day < 10) day = `0${day}`;
  if (month < 10) month = `0${month}`;

  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

export const getFormattedDateStatusHistory = (
  dateToFormat: string = undefined,
  hours?: number,
) => {
  const date = dateToFormat ? new Date(dateToFormat) : new Date();

  if (hours) {
    date.setHours(date.getHours() + hours);
  }

  const year = date.getFullYear();
  let month: string | number = date.getMonth() + 1;
  let day: string | number = date.getDate();

  if (day < 10) day = `0${day}`;
  if (month < 10) month = `0${month}`;

  const formattedDate = `${day}-${month}-${year}`;

  return formattedDate;
};

export const getFormattedDateTime = (dateToFormat: string = undefined) => {
  const date = dateToFormat ? new Date(dateToFormat) : new Date();
  const year = date.getFullYear();
  let month: string | number = date.getMonth() + 1;
  let day: string | number = date.getDate();
  let hour: string | number = date.getHours();
  let minute: string | number = date.getMinutes();

  if (day < 10) day = `0${day}`;
  if (month < 10) month = `0${month}`;
  if (hour < 10) hour = `0${hour}`;
  if (minute < 10) minute = `0${minute}`;

  const formattedDateTime = `${day}-${month}-${year}`;

  return formattedDateTime;
};

export const getStatusPolishName = (status: number): string => {
  switch (status) {
    case 0:
      return 'Zamówienie złożone';
    case 1:
      return 'Oczekuje na płatność';
    case 2:
    case 3:
    case 4:
    case 6:
      return 'W realizacji';
    case 5:
      return 'Wstrzymane';
    case 7:
      return 'W trakcie akceptacji';
    case 8:
    case 9:
      return 'Brak akceptacji';
    case 10:
      return 'W sporze';
    case 11:
    case 12:
      return 'Zrealizowane';
    case 13:
      return 'Oczekuje na premierę';
    case 14:
    case 15:
      return 'Zamknięte';
    case 16:
      return 'Anulowane';
    case 17:
      return 'Oczekuje na zwrot';
    case 18:
      return 'Zwrócone';
    default:
      return 'Status techniczny';
  }
};

enum TabMenu {
  DEFAULT = '',
  STATUS_HISTORY = 'status_history',
  PRODUCTS = 'products',
  USER_DATA = 'user_data',
  DOCUMENTS = 'documents',
  PAYMENT = 'payment',
  MESSAGES = 'messages',
}

interface StatusHistory {
  CreatedAt: string;
  Status: number;
}

interface Timeline {
  Date: string;
  Messages: string[];
  Title: string;
  Type: number;
}

interface OrderDetails extends Order {
  StatusHistory: StatusHistory[];
  Timeline: Timeline[];
}

function addTwoHours(time) {
  const polishTime = new Date(time);
  polishTime.setHours(polishTime.getHours() + 2);
  return polishTime;
}

const SingleTransaction: React.FC<Props> = ({
  transaction,
  isDetailsOpen,
  onOpen,
  onClose,
  setTransactions,
  index,
  currentIndexSelected,
  setCurrentIndexSelected,
  desktopRef,
  mobileRef,
}) => {
  // const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);
  const [tabMenu, setTabMenu] = useState<TabMenu>(
    transaction.Order?.DtoType === 2
      ? TabMenu.PRODUCTS
      : TabMenu.STATUS_HISTORY,
  );
  const [isPayP24Loading, setIsPayP24Loading] = useState<boolean>(false);
  const [isPayTransferLoading, setIsPayTransferLoading] =
    useState<boolean>(false);
  const [orderDetails, setOrderDetails] = useState<OrderDetails | undefined>();
  const [extensionDate, setExtensionDate] = useState<string>('');
  const [isOrderDetailsLoaded, setIsOrderDetailsLoaded] =
    useState<boolean>(false);
  const [complaint, setComplaint] = useState<string>('');
  const [orderMessage, setOrderMessage] = useState<string>('');
  const [customerComplaintComment, setCustomerComplaintComment] =
    useState<string>('');
  const [isCommentsWindowOpen, setIsCommentsWindowOpen] =
    useState<boolean>(false);
  const [isAddFileWindowOpen, setIsAddFileWindowOpen] =
    useState<boolean>(false);
  const [isComplaintWindowOpen, setIsComplaintWindowOpen] =
    useState<boolean>(false);
  const [isOrderMessagesWindowOpen, setIsOrderMessagesWindowOpen] =
    useState<boolean>(false);
  const [isExtensionRequestWindowOpen, setIsExtensionRequestWindowOpen] =
    useState<boolean>(false);
  const [isCustomerComplaintWindowOpen, setIsCustomerComplaintWindowOpen] =
    useState<boolean>(false);
  const [userData, setUserData] = useContext(UserDataContext);
  const [fileType, setFileType] = useState<number | string>(0);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const ref = useRef<HTMLDivElement>(null);
  const addFileInputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const {Alert} = useAlert();
  const navigate = useNavigate();

  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const {decodedToken} = useContext(ProducerDataContext);

  const handleFileChange = () => {
    const files = addFileInputRef.current?.files;
    if (files) {
      const selectedFilesArray: File[] = Array.from(files);
      setSelectedFiles(selectedFilesArray);

      const allowedExtensions = [
        '.png',
        '.jpg',
        '.mp4',
        '.pdf',
        '.ico',
        '.rar',
        '.rtf',
        '.srt',
        '.flac',
        '.wav',
        '.mp3',
        '.xlsx',
      ];

      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileExtension = file.name.split('.').pop()?.toLowerCase();
        return (
          fileExtension && !allowedExtensions.includes(`.${fileExtension}`)
        );
      });

      if (invalidFiles.length > 0) {
        addFileInputRef.current.value = '';
        return Alert('Niedopuszczalny format pliku.', 'Błąd');
      }

      if (selectedFilesArray.length > 0) {
        const firstFile = selectedFilesArray[0];
        const reader = new FileReader();
        reader.onloadend = () => {
          if (typeof reader.result === 'string') {
            const base64Data = reader.result.split(';base64,')[1];
            uploadOrderFile(transaction.Order?.Id, base64Data, fileType);
          }
        };
        reader.readAsDataURL(firstFile);
      }
    }
  };

  const handleExtensionDateChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setExtensionDate(e.target.value);

  const handleOrderMessageChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => setOrderMessage(e.target.value);

  const handleComplaintChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setComplaint(e.target.value);

  const handleCustomerComplaintCommentChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => setCustomerComplaintComment(e.target.value);

  const handleFileTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setFileType(+e.target.value);

  const handleIsCommentsWindowOpen = (value: boolean) =>
    setIsCommentsWindowOpen(value);

  const handleIsAddFileWindowOpen = (value: boolean) =>
    setIsAddFileWindowOpen(value);

  const handleIsComplaintWindowOpen = (value: boolean) =>
    setIsComplaintWindowOpen(value);

  const handleIsOrderMessagesWindowOpen = (value: boolean) =>
    setIsOrderMessagesWindowOpen(value);

  const handleIsExtensionRequestWindowOpenChange = (value: boolean) =>
    setIsExtensionRequestWindowOpen(value);

  const handleIsCustomerComplaintWindowOpen = (value: boolean) =>
    setIsCustomerComplaintWindowOpen(value);

  const getPayoutStatusPolishName = (status: string) => {
    switch (status) {
      case 'CREATED':
        return 'Przetwarzanie';
      case 'SUCCEEDED':
        return 'Zrealizowane';
      case 'FAILED':
        return 'Niepowodzenie';
      default:
        return 'Undefined';
    }
  };

  const getOrderDetails = async (id: string) => {
    try {
      const response = await api.get(
        `Orders/${id}
`,
        {
          headers: {Authorization: `Bearer ${cookies.token}`},
        },
      );
      setOrderDetails(response.data.Response);
      setIsOrderDetailsLoaded(true);
      if (containerRef.current) {
        setTimeout(() => {
          containerRef.current.scrollLeft = containerRef.current.scrollWidth;
        }, 250);
      }
    } catch (error) {
      console.error(error);
      Alert(error.response.data.Error.Message, 'Błąd');
    }
  };

  useEffect(() => {
    if (!isOrderDetailsLoaded && isDetailsOpen) {
      getOrderDetails(transaction?.Order?.Id);
    }
  }, []);

  const handleOpen = () => {
    onOpen();
    setCurrentIndexSelected(index);
    if (ref.current) {
      if (!currentIndexSelected || currentIndexSelected > index) {
        setTimeout(() => {
          ref.current.style.scrollMargin = '123px';
          ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }, 0);
      } else {
        setTimeout(() => {
          ref.current.style.scrollMargin = '402px';
          ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }, 0);
      }
    }
  };

  const uploadOrderFile = async (
    id: string,
    file: string,
    fileType: number | string,
  ) => {
    if (isPayP24Loading) return;
    setIsPayP24Loading(true);
    try {
      const body = {
        File: file,
        FileType: fileType,
        OrderId: id,
      };

      const response = await api.post(`ordersFiles`, body, {
        headers: {
          Authorization: `Bearer ${cookies.token}`,
        },
      });
      Alert('Plik został wysłany', 'Komunikat');
      getOrderDetails(response.data.Response.OrderId);
    } catch (error) {
      console.error(error);
      Alert(error.response.data.Error.Message, 'Błąd');
    } finally {
      setIsPayP24Loading(false);
      setIsAddFileWindowOpen(false);
    }
  };

  const payByP24 = async (id: string) => {
    if (isPayP24Loading) return;
    setIsPayP24Loading(true);
    try {
      const body = {
        OrderId: id,
        CardType: 'CB_VISA_MASTERCARD',
      };

      const response = await api.post(
        `MangopayTransaction/PayCardPayIn`,
        body,
        {
          headers: {
            Authorization: `Bearer ${cookies.token}`,
          },
          // const response = await api.post(`MangopayTransaction/PayP24PayIn`, body, {
          //   headers: {
          //     Authorization: `Bearer ${cookies.token}`,
          //   },
        },
      );
      // Alert('Zamówienie zostało stworzone.', 'Komunikat');
      window.location.href = response.data.Response.RedirectUrl;
    } catch (error) {
      if (
        error?.response?.data?.Error?.Message.includes(
          'Order status not match AwaitingPayment.',
        )
      ) {
        Alert(
          'Twoje zamówienie zostało opłacone. Nie możesz zapłacić ponownie',
          'Błąd',
        );
      } else if (
        error?.response?.data?.Error?.Message.includes(
          'There is already registered payment on this order.',
        )
      ) {
        Alert('Płatność jest wciąż przetwarzana.', 'Błąd');
      } else {
        Alert(error.response.data.Error.Message, 'Błąd');
      }
    } finally {
      setIsPayP24Loading(false);
    }
  };

  const payByMugo = async (id: string) => {
    if (isPayTransferLoading) return;
    setIsPayTransferLoading(true);
    try {
      const body = {
        OrderId: id,
      };

      const response = await api.post(`MangopayTransaction/PayTransfer`, body, {
        headers: {
          Authorization: `Bearer ${cookies.token}`,
        },
      });
      // Alert('Zamówienie zostało stworzone.', 'Komunikat');
      Alert('Przelew został wysłany.', 'Komunikat', 'Zamknij', true);
    } catch (error) {
      if (
        error?.response?.data?.Error?.Message.includes(
          'Order status not match AwaitingPayment.',
        )
      ) {
        Alert(
          'Twoje zamówienie zostało opłacone. Nie możesz zapłacić ponownie',
          'Błąd',
        );
      } else if (
        error?.response?.data?.Error?.Message.includes(
          'There is already registered payment on this order.',
        )
      ) {
        Alert('Płatność jest wciąż przetwarzana.', 'Błąd');
      } else {
        Alert(error.response.data.Error.Message, 'Błąd');
      }
    } finally {
      setIsPayTransferLoading(false);
    }
  };

  const payByBlik = async (id: string) => {
    if (isPayP24Loading) return;
    setIsPayP24Loading(true);
    try {
      const body = {
        OrderId: id,
      };

      const response = await api.post(
        `MangopayTransaction/PayBlikWebPayIn`,
        body,
        {
          headers: {
            Authorization: `Bearer ${cookies.token}`,
          },
        },
      );
      // Alert('Zamówienie zostało stworzone.', 'Komunikat');
      window.location.href = response.data.Response.RedirectUrl;
    } catch (error) {
      if (
        error?.response?.data?.Error?.Message.includes(
          'Order status not match AwaitingPayment.',
        )
      ) {
        Alert(
          'Twoje zamówienie zostało opłacone. Nie możesz zapłacić ponownie',
          'Błąd',
        );
      } else if (
        error?.response?.data?.Error?.Message.includes(
          'There is already registered payment on this order.',
        )
      ) {
        Alert('Płatność jest wciąż przetwarzana.', 'Błąd');
      } else {
        Alert(error.response.data.Error.Message, 'Błąd');
      }
    } finally {
      setIsPayP24Loading(false);
    }
  };

  const acceptSubmission = async (orderId: string) => {
    try {
      const response = await api.put(
        `orders/${orderId}/AcceptSubmission`,
        {},
        {
          headers: {Authorization: `Bearer ${cookies.token}`},
        },
      );
      setOrderDetails(response.data.Response);
      setTransactions((prevTransactions) =>
        prevTransactions.map((transaction) =>
          transaction.Order?.Id === response.data.Response.Id
            ? {...transaction, Order: response.data.Response}
            : transaction,
        ),
      );
      Alert('Zaakceptowałeś zamówienie.', 'Komunikat');
    } catch (error) {
      console.error(error);
      Alert(error.response.data.Error.Message, 'Błąd');
    }
  };

  const acceptExtension = async (orderId: string) => {
    try {
      const response = await api.put(
        `orders/${orderId}/AcceptExtension`,
        {},
        {
          headers: {Authorization: `Bearer ${cookies.token}`},
        },
      );
      setOrderDetails(response.data.Response);
      setTransactions((prevTransactions) =>
        prevTransactions.map((transaction) =>
          transaction.Order?.Id === response.data.Response.Id
            ? {...transaction, Order: response.data.Response}
            : transaction,
        ),
      );
      Alert('Zaakceptowałeś nowy termin realizacji zamówienia.', 'Komunikat');
    } catch (error) {
      console.error(error);
      Alert(error.response.data.Error.Message, 'Błąd');
    }
  };

  const cancelOrder = async (orderId: string) => {
    try {
      const response = await api.put(
        `orders/${orderId}/Cancel`,
        {},
        {
          headers: {Authorization: `Bearer ${cookies.token}`},
        },
      );
      setOrderDetails(response.data.Response);
      setTransactions((prevTransactions) =>
        prevTransactions.map((transaction) =>
          transaction.Order?.Id === response.data.Response.Id
            ? {...transaction, Order: response.data.Response}
            : transaction,
        ),
      );
      Alert('Zamówienie zostało anulowane.', 'Komunikat');
    } catch (error) {
      console.error(error);
      Alert(error.response.data.Error.Message, 'Błąd');
    }
  };

  const continueWork = async (orderId: string) => {
    try {
      const response = await api.put(
        `orders/${orderId}/ContinueWork`,
        {},
        {
          headers: {Authorization: `Bearer ${cookies.token}`},
        },
      );
      setOrderDetails(response.data.Response);
      setTransactions((prevTransactions) =>
        prevTransactions.map((transaction) =>
          transaction.Order?.Id === response.data.Response.Id
            ? {...transaction, Order: response.data.Response}
            : transaction,
        ),
      );
      Alert('Zaakceptowałeś stary termin realizacji zamówienia.', 'Komunikat');
    } catch (error) {
      console.error(error);
      Alert(error.response.data.Error.Message, 'Błąd');
    }
  };

  const submitConflict = async (orderId: string) => {
    try {
      const response = await api.put(
        `orders/${orderId}/SubmitConflict`,
        {},
        {
          headers: {Authorization: `Bearer ${cookies.token}`},
        },
      );
      setOrderDetails(response.data.Response);
      setTransactions((prevTransactions) =>
        prevTransactions.map((transaction) =>
          transaction.Order?.Id === response.data.Response.Id
            ? {...transaction, Order: response.data.Response}
            : transaction,
        ),
      );
      Alert(
        'Zgłosiłeś spór. Spór zostanie rozstrzygnięty przez zespół MUGO.',
        'Komunikat',
      );
    } catch (error) {
      console.error(error);
      Alert(error.response.data.Error.Message, 'Błąd');
    }
  };

  const submit = async (orderId: string) => {
    try {
      const response = await api.put(
        `orders/${orderId}/Submit`,
        {},
        {
          headers: {Authorization: `Bearer ${cookies.token}`},
        },
      );
      setOrderDetails(response.data.Response);
      setTransactions((prevTransactions) =>
        prevTransactions.map((transaction) =>
          transaction.Order?.Id === response.data.Response.Id
            ? {...transaction, Order: response.data.Response}
            : transaction,
        ),
      );
      Alert(
        'Zrealizowałeś zamówienie. Poczekaj, aż druga strona zaakceptuje lub odrzuci zamówienie.',
        'Komunikat',
      );
    } catch (error) {
      console.error(error);
      Alert(error.response.data.Error.Message, 'Błąd');
    }
  };

  const declineExtension = async (orderId: string) => {
    try {
      const response = await api.put(
        `orders/${orderId}/DeclineExtension`,
        {},
        {
          headers: {Authorization: `Bearer ${cookies.token}`},
        },
      );
      setOrderDetails(response.data.Response);
      setTransactions((prevTransactions) =>
        prevTransactions.map((transaction) =>
          transaction.Order?.Id === response.data.Response.Id
            ? {...transaction, Order: response.data.Response}
            : transaction,
        ),
      );
      Alert(
        'Odrzuciłeś nowy termin realizacji zamówienia. Poczekaj na odpowiedź sprzedawcy.',
        'Komunikat',
      );
    } catch (error) {
      console.error(error);
      Alert(error.response.data.Error.Message, 'Błąd');
    }
  };

  const sellerComplaint = async (orderId: string, comment: string) => {
    if (!comment) return;
    try {
      const body = {
        OrderId: orderId,
        Comment: comment,
      };
      const response = await api.put(
        `orders/${orderId}/SellerComplaint`,
        body,
        {
          headers: {Authorization: `Bearer ${cookies.token}`},
        },
      );
      handleIsComplaintWindowOpen(false);
      setOrderDetails(response.data.Response);
      setTransactions((prevTransactions) =>
        prevTransactions.map((transaction) =>
          transaction.Order?.Id === response.data.Response.Id
            ? {...transaction, Order: response.data.Response}
            : transaction,
        ),
      );
      Alert(
        'Wysłałeś wiadomość do klienta. Poczekaj na odpowiedź.',
        'Komunikat',
      );
    } catch (error) {
      console.error(error);
      Alert(error.response.data.Error.Message, 'Błąd');
    }
  };

  const sendOrderMessage = async (orderId: string, content: string) => {
    if (!content) return;
    try {
      const body = {
        OrderId: orderId,
        Content: content,
        ReceiverGuid:
          transaction.Order?.DtoType === 3
            ? transaction.Order?.CustomerId
            : transaction.Order?.SellerId,
        Thread: orderId,
        Topic: orderId,
      };
      const response = await api.post(`message/order`, body, {
        headers: {Authorization: `Bearer ${cookies.token}`},
      });
      handleIsOrderMessagesWindowOpen(false);
      setTransactions((prevTransactions) =>
        prevTransactions.map((transaction) =>
          transaction.Order?.Id ===
          response.data.Response.OrderInformation.OrderId
            ? {
                ...transaction,
                Order: {
                  ...transaction.Order,
                  Messages: [
                    ...transaction.Order.Messages,
                    response.data.Response,
                  ],
                },
              }
            : transaction,
        ),
      );
      setOrderMessage('');
      Alert('Wysłałeś wiadomość', 'Komunikat');
    } catch (error) {
      console.error(error);
      Alert(error.response.data.Error.Message, 'Błąd');
    }
  };

  const acceptSellerComplaint = async (orderId: string) => {
    try {
      const response = await api.put(
        `orders/${orderId}/AcceptSellerComplaint`,
        {},
        {
          headers: {Authorization: `Bearer ${cookies.token}`},
        },
      );
      setOrderDetails(response.data.Response);
      setTransactions((prevTransactions) =>
        prevTransactions.map((transaction) =>
          transaction.Order?.Id === response.data.Response.Id
            ? {...transaction, Order: response.data.Response}
            : transaction,
        ),
      );
      Alert('Zaakceptowałeś projekt bez wykonanych poprawek.', 'Komunikat');
    } catch (error) {
      console.error(error);
      Alert(error.response.data.Error.Message, 'Błąd');
    }
  };

  const acceptCustomerComplaint = async (orderId: string) => {
    try {
      const response = await api.put(
        `orders/${orderId}/AcceptCustomerComplaint`,
        {},
        {
          headers: {Authorization: `Bearer ${cookies.token}`},
        },
      );

      setTransactions((prevTransactions) =>
        prevTransactions.map((transaction) =>
          transaction.Order?.Id === response.data.Response.Id
            ? {...transaction, Order: response.data.Response}
            : transaction,
        ),
      );
      Alert('Zaakceptowałeś otrzymane poprawki.', 'Komunikat');
    } catch (error) {
      console.error(error);
      Alert(error.response.data.Error.Message, 'Błąd');
    }
  };

  const extensionRequest = async (orderId: string, extensionDate: string) => {
    if (!extensionDate) return;
    try {
      const body = {
        OrderId: orderId,
        Date: new Date(extensionDate),
      };
      const response = await api.put(
        `orders/${orderId}/ExtensionRequest`,
        body,
        {
          headers: {Authorization: `Bearer ${cookies.token}`},
        },
      );
      setOrderDetails(response.data.Response);
      setTransactions((prevTransactions) =>
        prevTransactions.map((transaction) =>
          transaction.Order?.Id === response.data.Response.Id
            ? {...transaction, Order: response.data.Response}
            : transaction,
        ),
      );
      handleIsExtensionRequestWindowOpenChange(false);
      Alert(
        'Wysłano prośbę o zmianę terminu realizacji zamówienia. Poczekaj na odpowiedź drugiej strony.',
        'Komunikat',
      );
    } catch (error) {
      console.error(error);
      Alert(error.response.data.Error.Message, 'Błąd');
    }
  };

  const customerComplaint = async (orderId: string, comment: string) => {
    if (!comment) return;
    try {
      const body = {
        OrderId: orderId,
        CustomerComment: comment,
      };
      const response = await api.put(
        `orders/${orderId}/CustomerComplaint`,
        body,
        {
          headers: {Authorization: `Bearer ${cookies.token}`},
        },
      );
      handleIsCustomerComplaintWindowOpen(false);
      setOrderDetails(response.data.Response);
      setTransactions((prevTransactions) =>
        prevTransactions.map((transaction) =>
          transaction.Order?.Id === response.data.Response.Id
            ? {...transaction, Order: response.data.Response}
            : transaction,
        ),
      );
      Alert(
        'Wysłałeś wiadomość do sprzedawcy. Poczekaj na odpowiedź.',
        'Komunikat',
      );
    } catch (error) {
      console.error(error);
      Alert(error.response.data.Error.Message, 'Błąd');
    }
  };

  const downloadFiles = (files: {FilePath: string; FileName: string}[]) => {
    files.forEach((file) => {
      const link = document.createElement('a');
      link.href = file.FilePath;
      link.target = '_blank';
      link.download = file.FileName;
      link.click();
    });
  };

  const deleteOrder = async (orderId: string) => {
    try {
      const response = await api.delete(
        `orders/RemoveAwaitingPaymentOrder/${orderId}`,
        {
          headers: {Authorization: `Bearer ${cookies.token}`},
        },
      );
      Alert('Zamówienie zostało usunięte', 'Komunikat', 'Zamknij', true);
    } catch (error) {
      console.error(error);
      Alert(error.response.data.Error.Message, 'Błąd');
    }
  };

  const renderStatusHistoryTabDetail = (status: number, orderType: number) => {
    switch (orderType) {
      case 3:
        switch (status) {
          case 0:
            return (
              <ButtonsWrapper>
                <StatusHistoryOrangeButtonStatus2
                  style={{position: 'unset'}}
                  isWinWin={transaction.Order?.IsWinWin}
                  onClick={() => handleIsAddFileWindowOpen(true)}
                >
                  Dodaj materiały
                </StatusHistoryOrangeButtonStatus2>
                <StatusHistoryBlueButton
                  isWinWin={transaction.Order?.IsWinWin}
                  onClick={() => handleIsOrderMessagesWindowOpen(true)}
                >
                  {' '}
                  {transaction.Order?.DtoType === 1
                    ? 'Wiadomość do sprzedawcy'
                    : 'Wiadomość do kupującego'}{' '}
                </StatusHistoryBlueButton>
                <StatusHistoryRedButtonWithPopup
                  isWinWin={transaction.Order?.IsWinWin}
                >
                  <PopupButton
                    buttons={['Powrót', 'Anuluj']}
                    title={'Uwaga'}
                    text={`Czy na pewno chcesz anulować zamówienie?\n\nAnulowanie zamówienia może oznaczać:\n\n- Utrata rezerwacji produktu\n- Możliwe opóźnienie zwrotu środków (jeśli dotyczy)\n- Brak możliwości przywrócenia zamówienia po jego anulowaniu`}
                    onClick={() => cancelOrder(transaction.Order?.Id)}
                  >
                    Anuluj
                  </PopupButton>
                </StatusHistoryRedButtonWithPopup>
              </ButtonsWrapper>
            );
          case 1:
            return (
              <ButtonsWrapper>
                <StatusHistoryOrangeButtonStatus2
                  style={{position: 'unset'}}
                  isWinWin={transaction.Order?.IsWinWin}
                  onClick={() => handleIsAddFileWindowOpen(true)}
                >
                  Dodaj materiały
                </StatusHistoryOrangeButtonStatus2>
                <StatusHistoryBlueButton
                  isWinWin={transaction.Order?.IsWinWin}
                  onClick={() => handleIsOrderMessagesWindowOpen(true)}
                >
                  {' '}
                  {transaction.Order?.DtoType === 1
                    ? 'Wiadomość do sprzedawcy'
                    : 'Wiadomość do kupującego'}{' '}
                </StatusHistoryBlueButton>
                <StatusHistoryRedButtonWithPopup
                  isWinWin={transaction.Order?.IsWinWin}
                >
                  <PopupButton
                    buttons={['Powrót', 'Anuluj']}
                    title={'Uwaga'}
                    text={`Czy na pewno chcesz anulować zamówienie?\n\nAnulowanie zamówienia może oznaczać:\n\n- Utrata rezerwacji produktu\n- Możliwe opóźnienie zwrotu środków (jeśli dotyczy)\n- Brak możliwości przywrócenia zamówienia po jego anulowaniu`}
                    onClick={() => cancelOrder(transaction.Order?.Id)}
                  >
                    Anuluj
                  </PopupButton>
                </StatusHistoryRedButtonWithPopup>
              </ButtonsWrapper>
            );
          case 2:
          case 3:
          case 4:
            return transaction.Order?.ExtendedRealisationAccepted === false ? (
              <>
                <RedWarningWrapperStatus6b
                  isWinWin={transaction.Order?.IsWinWin}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14.9"
                    height="14"
                    viewBox="0 0 14.9 14"
                  >
                    <path
                      id="Path_3741"
                      data-name="Path 3741"
                      d="M21.607,19.443,15.915,8.961a1.753,1.753,0,0,0-3.076,0l-5.7,10.481A1.752,1.752,0,0,0,8.685,22.05h11.38a1.753,1.753,0,0,0,1.542-2.59Zm-8.1-7.475a.876.876,0,1,1,1.753,0V15.99a.876.876,0,1,1-1.753,0Zm.9,7.892a1.006,1.006,0,1,1,1-1A1,1,0,0,1,14.4,19.859Z"
                      transform="translate(-6.918 -8.05)"
                      fill="#ff0300"
                    />
                  </svg>
                  <p>Odrzucenie nowego terminu</p>
                </RedWarningWrapperStatus6b>
                <StatusHistoryInfoStatus6b2
                  isWinWin={transaction.Order?.IsWinWin}
                >
                  Chcesz kontynuować według poprzedniego terminu? Kupujący
                  odrzucił Twoją prośbę o zmiannę termiu realizacji zamówienia.
                  W tej styuacji akceptujesz stary termin, czy anulujesz
                  zamówienie? Info o konsekwencjach wyboru.
                </StatusHistoryInfoStatus6b2>

                <ButtonsWrapper>
                  <StatusHistoryOrangeButtonStatus2
                    style={{position: 'unset'}}
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => handleIsAddFileWindowOpen(true)}
                  >
                    Dodaj materiały
                  </StatusHistoryOrangeButtonStatus2>
                  <StatusHistoryBlueButton
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => handleIsOrderMessagesWindowOpen(true)}
                  >
                    {' '}
                    {transaction.Order?.DtoType === 1
                      ? 'Wiadomość do sprzedawcy'
                      : 'Wiadomość do kupującego'}{' '}
                  </StatusHistoryBlueButton>
                  {/* <StatusHistoryRedButtonStatus6a
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => cancelOrder(transaction.Order?.Id)}
                  >
                    Odrzucam
                  </StatusHistoryRedButtonStatus6a> */}
                  <StatusHistoryGreenButtonStatus6a
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => continueWork(transaction.Order?.Id)}
                  >
                    Akceptuje
                  </StatusHistoryGreenButtonStatus6a>
                  <StatusHistoryRedButtonWithPopup
                    isWinWin={transaction.Order?.IsWinWin}
                  >
                    <PopupButton
                      buttons={['Powrót', 'Anuluj']}
                      title={'Uwaga'}
                      text={`Czy na pewno chcesz anulować zamówienie?\n\nAnulowanie zamówienia może oznaczać:\n\n- Utrata rezerwacji produktu\n- Możliwe opóźnienie zwrotu środków (jeśli dotyczy)\n- Brak możliwości przywrócenia zamówienia po jego anulowaniu`}
                      onClick={() => cancelOrder(transaction.Order?.Id)}
                    >
                      Anuluj
                    </PopupButton>
                  </StatusHistoryRedButtonWithPopup>
                </ButtonsWrapper>
              </>
            ) : (
              <>
                <ButtonsWrapper>
                  <StatusHistoryOrangeButtonStatus2
                    style={{position: 'unset'}}
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => handleIsAddFileWindowOpen(true)}
                  >
                    Dodaj materiały
                  </StatusHistoryOrangeButtonStatus2>
                  <StatusHistoryBlueButton
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => handleIsOrderMessagesWindowOpen(true)}
                  >
                    {' '}
                    {transaction.Order?.DtoType === 1
                      ? 'Wiadomość do sprzedawcy'
                      : 'Wiadomość do kupującego'}{' '}
                  </StatusHistoryBlueButton>
                  <StatusHistoryGreenButtonStatus6a3
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => submit(transaction.Order?.Id)}
                  >
                    Zrealizowane
                  </StatusHistoryGreenButtonStatus6a3>
                  <StatusHistoryButton
                    style={{width: '168px'}}
                    onClick={() =>
                      handleIsExtensionRequestWindowOpenChange(true)
                    }
                  >
                    Zmień termin realizacji
                  </StatusHistoryButton>
                  <StatusHistoryRedButtonWithPopup
                    isWinWin={transaction.Order?.IsWinWin}
                  >
                    <PopupButton
                      buttons={['Powrót', 'Anuluj']}
                      title={'Uwaga'}
                      text={`Czy na pewno chcesz anulować zamówienie?\n\nAnulowanie zamówienia może oznaczać:\n\n- Utrata rezerwacji produktu\n- Możliwe opóźnienie zwrotu środków (jeśli dotyczy)\n- Brak możliwości przywrócenia zamówienia po jego anulowaniu`}
                      onClick={() => cancelOrder(transaction.Order?.Id)}
                    >
                      Anuluj
                    </PopupButton>
                  </StatusHistoryRedButtonWithPopup>
                </ButtonsWrapper>

                {/* <StatusHistoryInfo isWinWin={transaction.Order?.IsWinWin}>
                  Wyślij prośbę o zmianę daty realizacji zamówienia, klient może
                  ale nie musi wyrażać zgody.
                </StatusHistoryInfo>
                <StatusHistoryInputWithButtonWrapper
                  isWinWin={transaction.Order?.IsWinWin}
                >
                  <StatusHistoryInputWrapper>
                    <p>Proponowany nowy termin realizacji</p>
                    <StatusHistoryDateInput>
                      <input
                        type="date"
                        onChange={handleExtensionDateChange}
                        value={extensionDate}
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6.439"
                        height="11"
                        viewBox="0 0 6.439 11"
                      >
                        <g
                          id="Arrow_Top_Bar_Accent"
                          data-name="Arrow – Top Bar / Accent"
                          transform="translate(6.439 11) rotate(180)"
                        >
                          <path
                            id="Arrow"
                            d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                            transform="translate(0 0)"
                            fill="#3986ef"
                          />
                        </g>
                      </svg>
                    </StatusHistoryDateInput>
                  </StatusHistoryInputWrapper>
                  <StatusHistoryButton
                    onClick={() =>
                      extensionRequest(transaction.Order?.Id, extensionDate)
                    }
                  >
                    Wyślij
                  </StatusHistoryButton>
                </StatusHistoryInputWithButtonWrapper> */}
              </>
            );
          case 6:
            return (
              <>
                <YellowStatusHistoryInfo isWinWin={transaction.Order?.IsWinWin}>
                  Wysłano prośbę o zmianę terminu realizacji.
                </YellowStatusHistoryInfo>
                <ButtonsWrapper>
                  <StatusHistoryOrangeButtonStatus2
                    style={{position: 'unset'}}
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => handleIsAddFileWindowOpen(true)}
                  >
                    Dodaj materiały
                  </StatusHistoryOrangeButtonStatus2>
                  <StatusHistoryBlueButton
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => handleIsOrderMessagesWindowOpen(true)}
                  >
                    {' '}
                    {transaction.Order?.DtoType === 1
                      ? 'Wiadomość do sprzedawcy'
                      : 'Wiadomość do kupującego'}{' '}
                  </StatusHistoryBlueButton>
                  <StatusHistoryRedButtonWithPopup
                    isWinWin={transaction.Order?.IsWinWin}
                  >
                    <PopupButton
                      buttons={['Powrót', 'Anuluj']}
                      title={'Uwaga'}
                      text={`Czy na pewno chcesz anulować zamówienie?\n\nAnulowanie zamówienia może oznaczać:\n\n- Utrata rezerwacji produktu\n- Możliwe opóźnienie zwrotu środków (jeśli dotyczy)\n- Brak możliwości przywrócenia zamówienia po jego anulowaniu`}
                      onClick={() => cancelOrder(transaction.Order?.Id)}
                    >
                      Anuluj
                    </PopupButton>
                  </StatusHistoryRedButtonWithPopup>
                </ButtonsWrapper>
              </>
            );
          case 7:
            return (
              <ButtonsWrapper>
                <StatusHistoryOrangeButtonStatus2
                  style={{position: 'unset'}}
                  isWinWin={transaction.Order?.IsWinWin}
                  onClick={() => handleIsAddFileWindowOpen(true)}
                >
                  Dodaj materiały
                </StatusHistoryOrangeButtonStatus2>
                <StatusHistoryBlueButton
                  isWinWin={transaction.Order?.IsWinWin}
                  onClick={() => handleIsOrderMessagesWindowOpen(true)}
                >
                  {' '}
                  {transaction.Order?.DtoType === 1
                    ? 'Wiadomość do sprzedawcy'
                    : 'Wiadomość do kupującego'}{' '}
                </StatusHistoryBlueButton>
                <StatusHistoryRedButtonWithPopup
                  isWinWin={transaction.Order?.IsWinWin}
                >
                  <PopupButton
                    buttons={['Powrót', 'Anuluj']}
                    title={'Uwaga'}
                    text={`Czy na pewno chcesz anulować zamówienie?\n\nAnulowanie zamówienia może oznaczać:\n\n- Utrata rezerwacji produktu\n- Możliwe opóźnienie zwrotu środków (jeśli dotyczy)\n- Brak możliwości przywrócenia zamówienia po jego anulowaniu`}
                    onClick={() => cancelOrder(transaction.Order?.Id)}
                  >
                    Anuluj
                  </PopupButton>
                </StatusHistoryRedButtonWithPopup>
              </ButtonsWrapper>
            );
          case 8:
            return (
              <>
                <StatusHistoryInfoStatus8
                  isWinWin={transaction.Order?.IsWinWin}
                >
                  Zdecyduj, czy akceptujesz wykonać otrzymane poprawki.
                </StatusHistoryInfoStatus8>

                <ButtonsWrapper>
                  <StatusHistoryOrangeButtonStatus2
                    style={{position: 'unset'}}
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => handleIsAddFileWindowOpen(true)}
                  >
                    Dodaj materiały
                  </StatusHistoryOrangeButtonStatus2>
                  <StatusHistoryBlueButton
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => handleIsOrderMessagesWindowOpen(true)}
                  >
                    {' '}
                    {transaction.Order?.DtoType === 1
                      ? 'Wiadomość do sprzedawcy'
                      : 'Wiadomość do kupującego'}{' '}
                  </StatusHistoryBlueButton>
                  <StatusHistoryGreenButton
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() =>
                      acceptCustomerComplaint(transaction.Order?.Id)
                    }
                  >
                    Akceptuje
                  </StatusHistoryGreenButton>
                  <StatusHistoryRedButton
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => handleIsComplaintWindowOpen(true)}
                  >
                    Odrzucam
                  </StatusHistoryRedButton>
                  <StatusHistoryOrangeButton
                    onClick={() => handleIsCommentsWindowOpen(true)}
                    isWinWin={transaction.Order?.IsWinWin}
                  >
                    Sprawdź uwagi
                  </StatusHistoryOrangeButton>
                  <StatusHistoryRedButtonWithPopup
                    isWinWin={transaction.Order?.IsWinWin}
                  >
                    <PopupButton
                      buttons={['Powrót', 'Anuluj']}
                      title={'Uwaga'}
                      text={`Czy na pewno chcesz anulować zamówienie?\n\nAnulowanie zamówienia może oznaczać:\n\n- Utrata rezerwacji produktu\n- Możliwe opóźnienie zwrotu środków (jeśli dotyczy)\n- Brak możliwości przywrócenia zamówienia po jego anulowaniu`}
                      onClick={() => cancelOrder(transaction.Order?.Id)}
                    >
                      Anuluj
                    </PopupButton>
                  </StatusHistoryRedButtonWithPopup>
                </ButtonsWrapper>
              </>
            );
          case 9:
            return (
              <ButtonsWrapper>
                <StatusHistoryOrangeButtonStatus2
                  style={{position: 'unset'}}
                  isWinWin={transaction.Order?.IsWinWin}
                  onClick={() => handleIsAddFileWindowOpen(true)}
                >
                  Dodaj materiały
                </StatusHistoryOrangeButtonStatus2>
                <StatusHistoryBlueButton
                  isWinWin={transaction.Order?.IsWinWin}
                  onClick={() => handleIsOrderMessagesWindowOpen(true)}
                >
                  {' '}
                  {transaction.Order?.DtoType === 1
                    ? 'Wiadomość do sprzedawcy'
                    : 'Wiadomość do kupującego'}{' '}
                </StatusHistoryBlueButton>
                <StatusHistoryRedButtonWithPopup
                  isWinWin={transaction.Order?.IsWinWin}
                >
                  <PopupButton
                    buttons={['Powrót', 'Anuluj']}
                    title={'Uwaga'}
                    text={`Czy na pewno chcesz anulować zamówienie?\n\nAnulowanie zamówienia może oznaczać:\n\n- Utrata rezerwacji produktu\n- Możliwe opóźnienie zwrotu środków (jeśli dotyczy)\n- Brak możliwości przywrócenia zamówienia po jego anulowaniu`}
                    onClick={() => cancelOrder(transaction.Order?.Id)}
                  >
                    Anuluj
                  </PopupButton>
                </StatusHistoryRedButtonWithPopup>
              </ButtonsWrapper>
            );
          case 10:
            return (
              <>
                <StatusHistoryInfoStatus10
                  isWinWin={transaction.Order?.IsWinWin}
                >
                  Aby otrzymać środki za swój produkt kontynuuj wykonanie lub
                  poczekaj na rozstrzygnięcie sporu.
                </StatusHistoryInfoStatus10>

                <ButtonsWrapper>
                  <StatusHistoryOrangeButtonStatus2
                    style={{position: 'unset'}}
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => handleIsAddFileWindowOpen(true)}
                  >
                    Dodaj materiały
                  </StatusHistoryOrangeButtonStatus2>
                  <StatusHistoryBlueButton
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => handleIsOrderMessagesWindowOpen(true)}
                  >
                    {' '}
                    {transaction.Order?.DtoType === 1
                      ? 'Wiadomość do sprzedawcy'
                      : 'Wiadomość do kupującego'}{' '}
                  </StatusHistoryBlueButton>
                  <StatusHistoryGreenButton
                    isWinWin={transaction.Order?.IsWinWin}
                  >
                    Akceptuje
                  </StatusHistoryGreenButton>
                  {/* <StatusHistoryRedButton
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => cancelOrder(transaction.Order?.Id)}
                  >
                    Odmawiam
                  </StatusHistoryRedButton> */}
                  <StatusHistoryOrangeButton
                    onClick={() => handleIsCommentsWindowOpen(true)}
                    isWinWin={transaction.Order?.IsWinWin}
                  >
                    Sprawdź uwagi
                  </StatusHistoryOrangeButton>

                  <StatusHistoryRedButtonWithPopup
                    isWinWin={transaction.Order?.IsWinWin}
                  >
                    <PopupButton
                      buttons={['Powrót', 'Anuluj']}
                      title={'Uwaga'}
                      text={`Czy na pewno chcesz anulować zamówienie?\n\nAnulowanie zamówienia może oznaczać:\n\n- Utrata rezerwacji produktu\n- Możliwe opóźnienie zwrotu środków (jeśli dotyczy)\n- Brak możliwości przywrócenia zamówienia po jego anulowaniu`}
                      onClick={() => cancelOrder(transaction.Order?.Id)}
                    >
                      Anuluj
                    </PopupButton>
                  </StatusHistoryRedButtonWithPopup>
                </ButtonsWrapper>
              </>
            );
          case 12:
            return (
              <ButtonsWrapper>
                <StatusHistoryOrangeButtonStatus2
                  style={{position: 'unset'}}
                  isWinWin={transaction.Order?.IsWinWin}
                  onClick={() => handleIsAddFileWindowOpen(true)}
                >
                  Dodaj materiały
                </StatusHistoryOrangeButtonStatus2>
                <StatusHistoryBlueButton
                  isWinWin={transaction.Order?.IsWinWin}
                  onClick={() => handleIsOrderMessagesWindowOpen(true)}
                >
                  {' '}
                  {transaction.Order?.DtoType === 1
                    ? 'Wiadomość do sprzedawcy'
                    : 'Wiadomość do kupującego'}{' '}
                </StatusHistoryBlueButton>
                <StatusHistoryRedButtonWithPopup
                  isWinWin={transaction.Order?.IsWinWin}
                >
                  <PopupButton
                    buttons={['Powrót', 'Anuluj']}
                    title={'Uwaga'}
                    text={`Czy na pewno chcesz anulować zamówienie?\n\nAnulowanie zamówienia może oznaczać:\n\n- Utrata rezerwacji produktu\n- Możliwe opóźnienie zwrotu środków (jeśli dotyczy)\n- Brak możliwości przywrócenia zamówienia po jego anulowaniu`}
                    onClick={() => cancelOrder(transaction.Order?.Id)}
                  >
                    Anuluj
                  </PopupButton>
                </StatusHistoryRedButtonWithPopup>
              </ButtonsWrapper>
            );
          case 11:
            return (
              <ButtonsWrapper>
                <StatusHistoryOrangeButtonStatus2
                  style={{position: 'unset'}}
                  isWinWin={transaction.Order?.IsWinWin}
                  onClick={() => handleIsAddFileWindowOpen(true)}
                >
                  Dodaj materiały
                </StatusHistoryOrangeButtonStatus2>
                <StatusHistoryBlueButton
                  isWinWin={transaction.Order?.IsWinWin}
                  onClick={() => handleIsOrderMessagesWindowOpen(true)}
                >
                  {' '}
                  {transaction.Order?.DtoType === 1
                    ? 'Wiadomość do sprzedawcy'
                    : 'Wiadomość do kupującego'}{' '}
                </StatusHistoryBlueButton>
              </ButtonsWrapper>
            );
          case 13:
            return (
              <>
                <ButtonsWrapper>
                  <StatusHistoryOrangeButtonStatus2
                    style={{position: 'unset'}}
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => handleIsAddFileWindowOpen(true)}
                  >
                    Dodaj materiały
                  </StatusHistoryOrangeButtonStatus2>
                  <StatusHistoryBlueButton
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => handleIsOrderMessagesWindowOpen(true)}
                  >
                    {' '}
                    {transaction.Order?.DtoType === 1
                      ? 'Wiadomość do sprzedawcy'
                      : 'Wiadomość do kupującego'}{' '}
                  </StatusHistoryBlueButton>
                  {transaction.Order?.IsWinWin &&
                    new Date(transaction.Order?.WinWinRealizationDate) <
                      new Date() && (
                      <StatusHistoryGreenButtonStatus13a
                        onClick={() => handleIsComplaintWindowOpen(true)}
                        isWinWin={transaction.Order?.IsWinWin}
                      >
                        <Link
                          style={{
                            width: '100%',
                            height: '100%',
                            fontWeight: '500',
                            textDecoration: 'none',
                            fontSize: '11px',
                            color: 'white',
                          }}
                          to={'/messages'}
                          // state={{
                          //   producerIdFromOffer: transaction.Order?.CustomerId,
                          //   producerNameFromOffer: `${transaction.Order?.BillingInfo.FirstName} ${transaction.Order?.BillingInfo.LastName}`,
                          // }}
                        >
                          Napisz wiadmość
                        </Link>
                      </StatusHistoryGreenButtonStatus13a>
                    )}
                  {/* {transaction.Order?.IsWinWin &&
                    new Date(transaction.Order?.WinWinRealizationDate) <
                      new Date() && (
                      <StatusHistoryRedButtonStatus13a
                        onClick={() => cancelOrder(transaction.Order?.Id)}
                        isWinWin={transaction.Order?.IsWinWin}
                      >
                        Wycofaj umowę
                      </StatusHistoryRedButtonStatus13a>
                    )} */}
                  <StatusHistoryRedButtonWithPopup
                    isWinWin={transaction.Order?.IsWinWin}
                  >
                    <PopupButton
                      buttons={['Powrót', 'Anuluj']}
                      title={'Uwaga'}
                      text={`Czy na pewno chcesz anulować zamówienie?\n\nAnulowanie zamówienia może oznaczać:\n\n- Utrata rezerwacji produktu\n- Możliwe opóźnienie zwrotu środków (jeśli dotyczy)\n- Brak możliwości przywrócenia zamówienia po jego anulowaniu`}
                      onClick={() => cancelOrder(transaction.Order?.Id)}
                    >
                      Anuluj
                    </PopupButton>
                  </StatusHistoryRedButtonWithPopup>
                </ButtonsWrapper>
                {transaction.Order?.IsWinWin &&
                  new Date(transaction.Order?.WinWinRealizationDate) <
                    new Date() && (
                    <StatusHistoryInfoStatus13b
                      isWinWin={transaction.Order?.IsWinWin}
                    >
                      Utwór nie został opublikowany w określonym czasie, zapytaj
                      o problem lub wycofaj się z umowy.
                    </StatusHistoryInfoStatus13b>
                  )}
              </>
            );
          case 14:
            return (
              <>
                {transaction.Order?.IsWinWin && (
                  <StatusHistoryInfoStatus14
                    isWinWin={transaction.Order?.IsWinWin}
                  >
                    Nastąpi przelogowanie do konta wydawcy w MUGO.PL
                  </StatusHistoryInfoStatus14>
                )}
                <ButtonsWrapper>
                  <StatusHistoryOrangeButtonStatus2
                    style={{position: 'unset'}}
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => handleIsAddFileWindowOpen(true)}
                  >
                    Dodaj materiały
                  </StatusHistoryOrangeButtonStatus2>
                  <StatusHistoryBlueButton
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => handleIsOrderMessagesWindowOpen(true)}
                  >
                    {' '}
                    {transaction.Order?.DtoType === 1
                      ? 'Wiadomość do sprzedawcy'
                      : 'Wiadomość do kupującego'}{' '}
                  </StatusHistoryBlueButton>
                  {transaction.Order?.IsWinWin && (
                    <StatusHistoryBlueButton
                      onClick={() =>
                        (window.location.href = 'https://www.mugo.pl/Products')
                      }
                      isWinWin={transaction.Order?.IsWinWin}
                    >
                      Idź do MUGO
                    </StatusHistoryBlueButton>
                  )}
                </ButtonsWrapper>
              </>
            );
          case 15:
            return (
              <>
                {transaction.Order?.IsWinWin && (
                  <StatusHistoryInfoStatus14
                    isWinWin={transaction.Order?.IsWinWin}
                  >
                    Nastąpi przelogowanie do konta wydawcy w MUGO.PL
                  </StatusHistoryInfoStatus14>
                )}
                <ButtonsWrapper>
                  <StatusHistoryOrangeButtonStatus2
                    style={{position: 'unset'}}
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => handleIsAddFileWindowOpen(true)}
                  >
                    Dodaj materiały
                  </StatusHistoryOrangeButtonStatus2>
                  <StatusHistoryBlueButton
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => handleIsOrderMessagesWindowOpen(true)}
                  >
                    {' '}
                    {transaction.Order?.DtoType === 1
                      ? 'Wiadomość do sprzedawcy'
                      : 'Wiadomość do kupującego'}{' '}
                  </StatusHistoryBlueButton>
                  {transaction.Order?.IsWinWin && (
                    <StatusHistoryBlueButton
                      onClick={() =>
                        (window.location.href = 'https://www.mugo.pl/Products')
                      }
                      isWinWin={transaction.Order?.IsWinWin}
                    >
                      Idź do MUGO
                    </StatusHistoryBlueButton>
                  )}
                  <StatusHistoryRedButtonWithPopup
                    isWinWin={transaction.Order?.IsWinWin}
                  >
                    <PopupButton
                      buttons={['Powrót', 'Anuluj']}
                      title={'Uwaga'}
                      text={`Czy na pewno chcesz anulować zamówienie?\n\nAnulowanie zamówienia może oznaczać:\n\n- Utrata rezerwacji produktu\n- Możliwe opóźnienie zwrotu środków (jeśli dotyczy)\n- Brak możliwości przywrócenia zamówienia po jego anulowaniu`}
                      onClick={() => cancelOrder(transaction.Order?.Id)}
                    >
                      Anuluj
                    </PopupButton>
                  </StatusHistoryRedButtonWithPopup>
                </ButtonsWrapper>
              </>
            );
          case 16:
            return (
              <>
                <ButtonsWrapper>
                  <StatusHistoryOrangeButtonStatus2
                    style={{position: 'unset'}}
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => handleIsAddFileWindowOpen(true)}
                  >
                    Dodaj materiały
                  </StatusHistoryOrangeButtonStatus2>
                  <StatusHistoryBlueButton
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => handleIsOrderMessagesWindowOpen(true)}
                  >
                    {' '}
                    {transaction.Order?.DtoType === 1
                      ? 'Wiadomość do sprzedawcy'
                      : 'Wiadomość do kupującego'}{' '}
                  </StatusHistoryBlueButton>
                </ButtonsWrapper>
                <RedStatusHistoryInfo isWinWin={transaction.Order?.IsWinWin}>
                  {transaction.Order?.DtoType === 3 &&
                  transaction.Order?.BillingInfo.NIP
                    ? transaction.Order?.BillingInfo.CompanyName
                    : transaction.Order?.DtoType === 3 &&
                      !transaction.Order?.BillingInfo.NIP
                    ? `${transaction.Order?.BillingInfo.FirstName}
                     ${transaction.Order?.BillingInfo.LastName}`
                    : transaction.Order?.DtoType === 1 &&
                      transaction.Order?.BillingInfo.NIP
                    ? transaction.Order?.SellerInfo.CompanyName
                    : `${transaction.Order?.SellerInfo.FirstName} ${transaction.Order?.SellerInfo.LastName}`}
                  <br />
                  wycofał oferowany produkt
                </RedStatusHistoryInfo>
              </>
            );
          default:
            return;
        }
      case 1:
        switch (status) {
          case 0:
            return (
              <ButtonsWrapper>
                <StatusHistoryOrangeButtonStatus2
                  style={{position: 'unset'}}
                  isWinWin={transaction.Order?.IsWinWin}
                  onClick={() => handleIsAddFileWindowOpen(true)}
                >
                  Dodaj materiały
                </StatusHistoryOrangeButtonStatus2>
                <StatusHistoryBlueButton
                  isWinWin={transaction.Order?.IsWinWin}
                  onClick={() => handleIsOrderMessagesWindowOpen(true)}
                >
                  {' '}
                  {transaction.Order?.DtoType === 1
                    ? 'Wiadomość do sprzedawcy'
                    : 'Wiadomość do kupującego'}{' '}
                </StatusHistoryBlueButton>
              </ButtonsWrapper>
            );
          case 1:
            return (
              <ButtonsWrapper>
                <StatusHistoryOrangeButtonStatus2
                  style={{position: 'unset'}}
                  isWinWin={transaction.Order?.IsWinWin}
                  onClick={() => handleIsAddFileWindowOpen(true)}
                >
                  Dodaj materiały
                </StatusHistoryOrangeButtonStatus2>
                <StatusHistoryBlueButton
                  isWinWin={transaction.Order?.IsWinWin}
                  onClick={() => handleIsOrderMessagesWindowOpen(true)}
                >
                  {' '}
                  {transaction.Order?.DtoType === 1
                    ? 'Wiadomość do sprzedawcy'
                    : 'Wiadomość do kupującego'}{' '}
                </StatusHistoryBlueButton>
                <StatusHistoryLightBlueButton
                  isWinWin={transaction.Order?.IsWinWin}
                  onClick={() => {
                    if (transaction.Order?.PaymentMethod === 2) {
                      payByMugo(transaction.Order?.Id);
                    } else if (transaction.Order?.PaymentMethod === 3) {
                      payByBlik(transaction.Order?.Id);
                    } else {
                      payByP24(transaction.Order?.Id);
                    }
                  }}
                >
                  Wykonaj przelew
                </StatusHistoryLightBlueButton>
              </ButtonsWrapper>
            );
          case 2:
          case 3:
          case 4:
            return (
              <ButtonsWrapper>
                <StatusHistoryOrangeButtonStatus2
                  style={{position: 'unset'}}
                  isWinWin={transaction.Order?.IsWinWin}
                  onClick={() => handleIsAddFileWindowOpen(true)}
                >
                  Dodaj materiały
                </StatusHistoryOrangeButtonStatus2>
                <StatusHistoryBlueButton
                  isWinWin={transaction.Order?.IsWinWin}
                  onClick={() => handleIsOrderMessagesWindowOpen(true)}
                >
                  {' '}
                  {transaction.Order?.DtoType === 1
                    ? 'Wiadomość do sprzedawcy'
                    : 'Wiadomość do kupującego'}{' '}
                </StatusHistoryBlueButton>
              </ButtonsWrapper>
            );
          case 6:
            return (
              <>
                <YellowWarningWrapper isWinWin={transaction.Order?.IsWinWin}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14.9"
                    height="14"
                    viewBox="0 0 14.9 14"
                  >
                    <path
                      id="Path_3741"
                      data-name="Path 3741"
                      d="M21.607,19.443,15.915,8.961a1.753,1.753,0,0,0-3.076,0l-5.7,10.481A1.752,1.752,0,0,0,8.685,22.05h11.38a1.753,1.753,0,0,0,1.542-2.59Zm-8.1-7.475a.876.876,0,1,1,1.753,0V15.99a.876.876,0,1,1-1.753,0Zm.9,7.892a1.006,1.006,0,1,1,1-1A1,1,0,0,1,14.4,19.859Z"
                      transform="translate(-6.918 -8.05)"
                      fill="#fb9614"
                    />
                  </svg>
                  <p>Prośba o zmianę terminu</p>
                </YellowWarningWrapper>
                <ButtonsWrapper>
                  <StatusHistoryOrangeButtonStatus2
                    style={{position: 'unset'}}
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => handleIsAddFileWindowOpen(true)}
                  >
                    Dodaj materiały
                  </StatusHistoryOrangeButtonStatus2>
                  <StatusHistoryBlueButton
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => handleIsOrderMessagesWindowOpen(true)}
                  >
                    {' '}
                    {transaction.Order?.DtoType === 1
                      ? 'Wiadomość do sprzedawcy'
                      : 'Wiadomość do kupującego'}{' '}
                  </StatusHistoryBlueButton>
                  <StatusHistoryRedButtonStatus6a
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => declineExtension(transaction.Order?.Id)}
                  >
                    Odrzucam
                  </StatusHistoryRedButtonStatus6a>
                  <StatusHistoryGreenButtonStatus6a
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => acceptExtension(transaction.Order?.Id)}
                  >
                    Akceptuje
                  </StatusHistoryGreenButtonStatus6a>
                </ButtonsWrapper>
                <StatusHistoryInfoStatus6a
                  isWinWin={transaction.Order?.IsWinWin}
                >
                  Sprzedawca wysłał prośbę o zmianę terminu realizacji
                  zamówienia. Nowa proponowana data:{' '}
                  {transaction.Order?.ExtendedRealisationDate &&
                    getFormattedDateStatusHistory(
                      transaction.Order?.ExtendedRealisationDate,
                    )}
                </StatusHistoryInfoStatus6a>
              </>
            );
          case 7:
            return (
              <>
                <StatusHistoryInfoStatus8a
                  isWinWin={transaction.Order?.IsWinWin}
                >
                  Po naciśnięci „odrzucam” przejdziesz do okna wiadomości w celu
                  napisania poprawek.
                </StatusHistoryInfoStatus8a>
                <ButtonsWrapper>
                  <StatusHistoryOrangeButtonStatus2
                    style={{position: 'unset'}}
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => handleIsAddFileWindowOpen(true)}
                  >
                    Dodaj materiały
                  </StatusHistoryOrangeButtonStatus2>
                  <StatusHistoryBlueButton
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => handleIsOrderMessagesWindowOpen(true)}
                  >
                    {' '}
                    {transaction.Order?.DtoType === 1
                      ? 'Wiadomość do sprzedawcy'
                      : 'Wiadomość do kupującego'}{' '}
                  </StatusHistoryBlueButton>
                  <StatusHistoryRedButtonStatus7
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => handleIsCustomerComplaintWindowOpen(true)}
                  >
                    Odrzucam
                  </StatusHistoryRedButtonStatus7>
                  <StatusHistoryGreenButtonStatus7
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => acceptSubmission(transaction.Order?.Id)}
                  >
                    Akceptuje
                  </StatusHistoryGreenButtonStatus7>
                </ButtonsWrapper>
              </>
            );
          case 8:
            return (
              <>
                <StatusHistoryInfoStatus8a
                  isWinWin={transaction.Order?.IsWinWin}
                >
                  Zgłosiłeś swoje uwagi, poczekaj na odpowiedź sprzedawcy.
                </StatusHistoryInfoStatus8a>
                <ButtonsWrapper>
                  <StatusHistoryOrangeButtonStatus2
                    style={{position: 'unset'}}
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => handleIsAddFileWindowOpen(true)}
                  >
                    Dodaj materiały
                  </StatusHistoryOrangeButtonStatus2>
                  <StatusHistoryBlueButton
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => handleIsOrderMessagesWindowOpen(true)}
                  >
                    {' '}
                    {transaction.Order?.DtoType === 1
                      ? 'Wiadomość do sprzedawcy'
                      : 'Wiadomość do kupującego'}{' '}
                  </StatusHistoryBlueButton>
                </ButtonsWrapper>
                {/* <StatusHistoryGreenButton
                  isWinWin={transaction.Order?.IsWinWin}
                  onClick={() => acceptSubmission(transaction.Order?.Id)}
                >
                  Akceptuje
                </StatusHistoryGreenButton> */}
                {/* <StatusHistoryRedButton isWinWin={transaction.Order?.IsWinWin}
                onClick={() => customerComplaint(transaction.Order?.Id)}>
                  Odrzucam
                </StatusHistoryRedButton> */}
              </>
            );
          case 9:
            return (
              <>
                <StatusHistoryInfoStatus9a
                  isWinWin={transaction.Order?.IsWinWin}
                >
                  Możesz zaakceptować projekt mimo wprowadzonych poprawek albo
                  zgłosić spór, który zostanie <br />
                  roztrzygnięty przez doświadczone osoby z zespołu MUGO.
                </StatusHistoryInfoStatus9a>
                <ButtonsWrapper>
                  <StatusHistoryOrangeButtonStatus2
                    style={{position: 'unset'}}
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => handleIsAddFileWindowOpen(true)}
                  >
                    Dodaj materiały
                  </StatusHistoryOrangeButtonStatus2>
                  <StatusHistoryBlueButton
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => handleIsOrderMessagesWindowOpen(true)}
                  >
                    {' '}
                    {transaction.Order?.DtoType === 1
                      ? 'Wiadomość do sprzedawcy'
                      : 'Wiadomość do kupującego'}{' '}
                  </StatusHistoryBlueButton>
                  <StatusHistoryGreenButton
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => acceptSellerComplaint(transaction.Order?.Id)}
                  >
                    Akceptuje
                  </StatusHistoryGreenButton>
                  <StatusHistoryRedButton
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => submitConflict(transaction.Order?.Id)}
                  >
                    Zgłoś spór
                  </StatusHistoryRedButton>
                  <StatusHistoryOrangeButton
                    onClick={() => handleIsCommentsWindowOpen(true)}
                    isWinWin={transaction.Order?.IsWinWin}
                  >
                    Sprawdź uwagi
                  </StatusHistoryOrangeButton>
                </ButtonsWrapper>
              </>
            );
          case 10:
            return (
              <>
                <StatusHistoryInfoStatus10a
                  isWinWin={transaction.Order?.IsWinWin}
                >
                  Produkt otrzymasz po rozstrzygnięciu sporu przez nasz zaspół
                  MUGO. <br />
                  Jeżeli zależy Ci na czasie Akceptując projekt zaniechasz
                  rostrzygania sporu i otrzymasz produkt bez poprawek.
                </StatusHistoryInfoStatus10a>
                <ButtonsWrapper>
                  <StatusHistoryOrangeButtonStatus2
                    style={{position: 'unset'}}
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => handleIsAddFileWindowOpen(true)}
                  >
                    Dodaj materiały
                  </StatusHistoryOrangeButtonStatus2>
                  <StatusHistoryBlueButton
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => handleIsOrderMessagesWindowOpen(true)}
                  >
                    {' '}
                    {transaction.Order?.DtoType === 1
                      ? 'Wiadomość do sprzedawcy'
                      : 'Wiadomość do kupującego'}{' '}
                  </StatusHistoryBlueButton>
                  <StatusHistoryGreenButton
                    onClick={() => acceptSubmission(transaction.Order?.Id)}
                    isWinWin={transaction.Order?.IsWinWin}
                  >
                    Akceptuje
                  </StatusHistoryGreenButton>
                  {/* todo add endpoint */}
                  <StatusHistoryRedButton
                    isWinWin={transaction.Order?.IsWinWin}
                  >
                    Zgłoś spór
                  </StatusHistoryRedButton>
                  <StatusHistoryOrangeButton
                    onClick={() => handleIsCommentsWindowOpen(true)}
                    isWinWin={transaction.Order?.IsWinWin}
                  >
                    Sprawdź uwagi
                  </StatusHistoryOrangeButton>
                </ButtonsWrapper>
              </>
            );
          case 11:
          case 12:
            return (
              <ButtonsWrapper>
                <StatusHistoryOrangeButtonStatus2
                  style={{position: 'unset'}}
                  isWinWin={transaction.Order?.IsWinWin}
                  onClick={() => handleIsAddFileWindowOpen(true)}
                >
                  Dodaj materiały
                </StatusHistoryOrangeButtonStatus2>
                <StatusHistoryBlueButton
                  isWinWin={transaction.Order?.IsWinWin}
                  onClick={() => handleIsOrderMessagesWindowOpen(true)}
                >
                  {' '}
                  {transaction.Order?.DtoType === 1
                    ? 'Wiadomość do sprzedawcy'
                    : 'Wiadomość do kupującego'}{' '}
                </StatusHistoryBlueButton>
              </ButtonsWrapper>
            );
          case 13:
            return (
              <>
                {transaction.Order?.IsWinWin && (
                  <StatusHistoryInfoStatus13a
                    isWinWin={transaction.Order?.IsWinWin}
                  >
                    Przejdź do MUGO i wgraj utwór.
                  </StatusHistoryInfoStatus13a>
                )}
                <ButtonsWrapper>
                  <StatusHistoryOrangeButtonStatus2
                    style={{position: 'unset'}}
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => handleIsAddFileWindowOpen(true)}
                  >
                    Dodaj materiały
                  </StatusHistoryOrangeButtonStatus2>
                  <StatusHistoryBlueButton
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => handleIsOrderMessagesWindowOpen(true)}
                  >
                    {' '}
                    {transaction.Order?.DtoType === 1
                      ? 'Wiadomość do sprzedawcy'
                      : 'Wiadomość do kupującego'}{' '}
                  </StatusHistoryBlueButton>
                  {transaction.Order?.IsWinWin ? (
                    new Date(transaction.Order?.WinWinRealizationDate) <
                    new Date() ? (
                      <StatusHistoryCyanButton
                        isWinWin={transaction.Order?.IsWinWin}
                        onClick={() =>
                          (window.location.href =
                            'https://www.mugo.pl/MusicProduct/Create')
                        }
                      >
                        Wgraj utwór
                      </StatusHistoryCyanButton>
                    ) : (
                      <StatusHistoryYellowButton
                        isWinWin={transaction.Order?.IsWinWin}
                        onClick={() =>
                          (window.location.href =
                            'https://www.mugo.pl/MusicProduct/Create')
                        }
                      >
                        Wgraj utwór
                      </StatusHistoryYellowButton>
                    )
                  ) : null}
                </ButtonsWrapper>
              </>
            );
          case 14:
          case 15:
            return (
              <ButtonsWrapper>
                <StatusHistoryOrangeButtonStatus2
                  style={{position: 'unset'}}
                  isWinWin={transaction.Order?.IsWinWin}
                  onClick={() => handleIsAddFileWindowOpen(true)}
                >
                  Dodaj materiały
                </StatusHistoryOrangeButtonStatus2>
                <StatusHistoryBlueButton
                  isWinWin={transaction.Order?.IsWinWin}
                  onClick={() => handleIsOrderMessagesWindowOpen(true)}
                >
                  {' '}
                  {transaction.Order?.DtoType === 1
                    ? 'Wiadomość do sprzedawcy'
                    : 'Wiadomość do kupującego'}{' '}
                </StatusHistoryBlueButton>
                {transaction.Order?.IsWinWin && (
                  <StatusHistoryYellowButton
                    isWinWin={transaction.Order?.IsWinWin}
                  >
                    Zwróć
                  </StatusHistoryYellowButton>
                )}
              </ButtonsWrapper>
            );
          case 16:
            return (
              <>
                <RedStatusHistoryInfo isWinWin={transaction.Order?.IsWinWin}>
                  {transaction.Order?.DtoType === 3 &&
                  transaction.Order?.BillingInfo.NIP
                    ? transaction.Order?.BillingInfo.CompanyName
                    : transaction.Order?.DtoType === 3 &&
                      !transaction.Order?.BillingInfo.NIP
                    ? `${transaction.Order?.BillingInfo.FirstName}
                       ${transaction.Order?.BillingInfo.LastName}`
                    : transaction.Order?.DtoType === 1 &&
                      transaction.Order?.BillingInfo.NIP
                    ? transaction.Order?.SellerInfo.CompanyName
                    : `${transaction.Order?.SellerInfo.FirstName} ${transaction.Order?.SellerInfo.LastName}`}
                  <br />
                  wycofał oferowany produkt
                </RedStatusHistoryInfo>
                <ButtonsWrapper>
                  <StatusHistoryOrangeButtonStatus2
                    style={{position: 'unset'}}
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => handleIsAddFileWindowOpen(true)}
                  >
                    Dodaj materiały
                  </StatusHistoryOrangeButtonStatus2>
                  <StatusHistoryBlueButton
                    isWinWin={transaction.Order?.IsWinWin}
                    onClick={() => handleIsOrderMessagesWindowOpen(true)}
                  >
                    {' '}
                    {transaction.Order?.DtoType === 1
                      ? 'Wiadomość do sprzedawcy'
                      : 'Wiadomość do kupującego'}{' '}
                  </StatusHistoryBlueButton>
                </ButtonsWrapper>
              </>
            );
          default:
            return;
        }
      default:
        return;
    }
  };

  const renderStatusHistoryTabContent = (status: number, orderType: number) => {
    return (
      <StatusHistoryTabMain>
        <StatusHistoryTabWrapper ref={containerRef}>
          {orderDetails?.Timeline.map((timeline, i) => {
            const {Date, Messages, Title, Type} = timeline;

            return (
              <NewSingleStatusWrapper
                key={`${Title}-${i}-${Type}`}
                statusType={Type}
              >
                <p>{Title}</p>
                <SingleStatusDiv
                  style={Type === 5 ? {cursor: 'pointer'} : {}}
                  onClick={() => {
                    if (Type === 5) {
                      return setTabMenu(TabMenu.MESSAGES);
                    } else {
                      return;
                    }
                  }}
                >
                  {Type === 0 || Type === 5 ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                    >
                      <path
                        id="Icon_awesome-check-circle"
                        data-name="Icon awesome-check-circle"
                        d="M14.563,7.563a7,7,0,1,1-7-7A7,7,0,0,1,14.563,7.563Zm-7.81,3.706,5.194-5.194a.452.452,0,0,0,0-.639L11.308,4.8a.452.452,0,0,0-.639,0L6.433,9.034,4.456,7.056a.452.452,0,0,0-.639,0l-.639.639a.452.452,0,0,0,0,.639l2.935,2.935a.452.452,0,0,0,.639,0Z"
                        transform="translate(-0.563 -0.563)"
                        fill="#0091ff"
                      />
                    </svg>
                  ) : (
                    Type === 1 && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                      >
                        <path
                          id="Path_3749"
                          data-name="Path 3749"
                          d="M7,0a7,7,0,1,0,7,7A7.021,7.021,0,0,0,7,0Zm.787,11.375H6.037V9.625h1.75ZM9.625,7.087a2.2,2.2,0,0,1-1.05.613c-.525.35-.7.175-.7,1.05H6.125a2.629,2.629,0,0,1,1.75-2.625,1.41,1.41,0,0,0,.613-.35.462.462,0,0,0,.087-.612,1.551,1.551,0,0,0-1.488-.875A1.49,1.49,0,0,0,5.6,5.6L3.85,5.337A3.07,3.07,0,0,1,7,2.537a3.449,3.449,0,0,1,3.15,1.925A2.412,2.412,0,0,1,9.625,7.087Z"
                          fill="#0091ff"
                        />
                      </svg>
                    )
                  )}
                </SingleStatusDiv>
                <StyledHr
                  statusType={Type}
                  isWinWin={transaction.Order?.IsWinWin}
                />
                <h5>{getFormattedDateStatusHistory(Date, 0)}</h5>
              </NewSingleStatusWrapper>
            );
          })}
        </StatusHistoryTabWrapper>
        {renderStatusHistoryTabDetail(status, orderType)}
      </StatusHistoryTabMain>
    );
  };

  const renderTabMenuContent = (tabMenu: TabMenu, documentLength: number) => {
    switch (tabMenu) {
      case TabMenu.DEFAULT:
        return (
          <DetailsContent>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which
            </p>
          </DetailsContent>
        );
      case TabMenu.PRODUCTS:
        return (
          <ProductTab>
            {transaction.Order?.Items.map((item, index) => {
              const {
                AmountTotal,
                Brutto,
                Id,
                Name,
                Netto,
                OrderId,
                Quantity,
                Symbol,
                Tax,
                TaxTotal,
                ShortDescription,
                ProductType,
              } = item;

              return (
                <SingleProductTabItem key={Id}>
                  <ProductsTabWrapper>
                    <div>
                      <p>Nazwa</p>
                      <h5 title={Name ? Name : ''}>{Name && Name}</h5>
                    </div>
                    <div>
                      <p>Typ</p>
                      <h5>
                        {transaction.Order?.DtoType === 2
                          ? 'Plan'
                          : transaction.Order?.DtoType === 2
                          ? 'Opłata'
                          : getProductTypePolishName(ProductType)}
                      </h5>
                    </div>
                    <div>
                      <p>Netto</p>
                      <h5>{(Netto || Netto === 0) && Netto / 100}</h5>
                    </div>
                    <div>
                      <p>Podatek</p>
                      <h5>{(TaxTotal || TaxTotal === 0) && TaxTotal / 100}</h5>
                    </div>
                    <div>
                      <p>Brutto</p>
                      <h5>{(Brutto || Brutto === 0) && Brutto / 100}</h5>
                    </div>
                  </ProductsTabWrapper>
                  <p>{ShortDescription}</p>
                </SingleProductTabItem>
              );
            })}
          </ProductTab>
        );
      case TabMenu.USER_DATA:
        return (
          <UserDataTabWrapper>
            <div>
              <p>Nazwa</p>
              <h5
                title={
                  transaction.Order?.SellerInfo.NIP
                    ? transaction.Order?.SellerInfo.CompanyName
                    : `${transaction.Order?.SellerInfo.FirstName} ${transaction.Order?.SellerInfo.LastName}`
                }
              >
                {transaction.Order?.SellerInfo.NIP
                  ? transaction.Order?.SellerInfo.CompanyName
                  : `${transaction.Order?.SellerInfo.FirstName} ${transaction.Order?.SellerInfo.LastName}`}
              </h5>
            </div>
            <div>
              <p>Lokalizacja</p>
              <h5
                title={
                  transaction.Order?.SellerInfo.Region &&
                  transaction.Order?.SellerInfo.Region
                }
              >
                {transaction.Order?.SellerInfo.Region &&
                  transaction.Order?.SellerInfo.Region}
              </h5>
            </div>
            <div>
              <p>Profesje</p>
              <h5>
                {transaction.Order?.SellerInfo.Roles &&
                  transaction.Order?.SellerInfo.Roles.length > 0 &&
                  transaction.Order?.SellerInfo.Roles.map((role, i) => {
                    if (i === 0) {
                      return `${role}`;
                    } else {
                      return `, ${role}`;
                    }
                  })}
              </h5>
            </div>
          </UserDataTabWrapper>
        );
      case TabMenu.DOCUMENTS:
        return (
          <DocumentsTabWrapper documentLength={orderDetails?.Files?.length}>
            <StatusHistoryOrangeButtonStatus2
              style={{position: 'unset', minHeight: '19px'}}
              isWinWin={transaction.Order?.IsWinWin}
              onClick={() => handleIsAddFileWindowOpen(true)}
            >
              Dodaj materiały
            </StatusHistoryOrangeButtonStatus2>
            {orderDetails?.Files?.length > 0 && (
              <SingleDocumentsTabHeaderWrapper>
                <div>
                  <p>Typ</p>
                </div>
                <div>
                  <p>Nazwa</p>
                </div>
                <div>
                  <p>Data dodania</p>
                </div>
                <div></div>
              </SingleDocumentsTabHeaderWrapper>
            )}
            {orderDetails?.Files?.map((file, i) => {
              const {
                CreatedDate,
                FileName,
                FilePath,
                Id,
                LastDownloadDate,
                NumberOfDownloads,
                OrderId,
                FileType,
              } = file;

              return (
                <SingleDocumentsTabWrapper key={Id}>
                  <div>
                    <h5>{FileTypeEnum[FileType]}</h5>
                  </div>
                  <div>
                    <h5>{FileName}</h5>
                  </div>
                  <div>
                    <h5>{getFormattedDate(CreatedDate)}</h5>
                  </div>
                  <div>
                    <a href={FilePath} target="_blink" download={FileName}>
                      <StatusHistoryOrangeButtonStatus2>
                        Pobierz
                      </StatusHistoryOrangeButtonStatus2>
                    </a>
                  </div>
                </SingleDocumentsTabWrapper>
              );
            })}
            {/* {orderDetails?.Files?.length > 0 && (
                <div>
                  <PrimaryButton
                    onClick={() => downloadFiles(orderDetails.Files)}
                  >
                    Pobierz wszystkie
                  </PrimaryButton>
                </div>
              )} */}
          </DocumentsTabWrapper>
        );
      case TabMenu.STATUS_HISTORY:
        return renderStatusHistoryTabContent(
          transaction.Order?.Status,
          transaction.Order?.DtoType,
        );
      case TabMenu.PAYMENT:
        return (
          <PaymentTabWrapper
            style={
              transaction.Order?.DtoType === 1 &&
              transaction.Order?.Status === 1
                ? {gridTemplateColumns: 'repeat(5,1fr)'}
                : {gridTemplateColumns: 'repeat(4,1fr)'}
            }
          >
            <div>
              <p>Metoda płatności</p>
              <h5>
                {(transaction.Order?.PaymentMethod ||
                  transaction.Order?.PaymentMethod === 0) &&
                  PaymentMethod[transaction.Order?.PaymentMethod]}
              </h5>
            </div>
            <div>
              <p>Data wykonania płatności</p>
              <h5>
                {transaction.Order?.DatePaid
                  ? getFormattedDateTime(transaction.Order?.DatePaid)
                  : '-'}
              </h5>
            </div>
            <div>
              <p>Kwota</p>
              <h5>
                {(transaction.Order?.AmountToPay ||
                  transaction.Order?.AmountToPay === 0) &&
                  `${Number(transaction.Order?.AmountToPay / 100)
                    .toFixed(2)
                    .replace('.', ',')} zł`}
              </h5>
            </div>
            <div>
              <p>Status</p>
              <h5>
                {!transaction.Order?.DatePaid
                  ? 'Oczekuje na płatność'
                  : 'Zrealizowany'}
                {/* {transaction.Order?.IsWinWin &&
                new Date(transaction.Order?.WinWinRealizationDate) <
                  new Date() &&
                transaction.Order?.Status === 13
                  ? 'Brak publikacji'
                  : getStatusPolishName(transaction.Order?.Status)} */}
              </h5>
            </div>
            {transaction.Order?.DtoType === 1 && !transaction.Order?.DatePaid && (
              <div>
                <PrimaryButton
                  onClick={() => {
                    if (transaction.Order?.PaymentMethod === 2) {
                      payByMugo(transaction.Order?.Id);
                    } else if (transaction.Order?.PaymentMethod === 3) {
                      payByBlik(transaction.Order?.Id);
                    } else {
                      payByP24(transaction.Order?.Id);
                    }
                  }}
                >
                  Zapłać ponownie
                </PrimaryButton>
              </div>
            )}
          </PaymentTabWrapper>
        );
      case TabMenu.MESSAGES:
        return (
          <MessagesTabWrapper>
            {transaction?.Order?.Messages.map((message, index) => (
              <ThreadMessageWrapper
                isFirst={index === 0}
                key={message.Id}
                isReciever={message.SenderId === userData.UserId}
              >
                <ThreadMessageHeader>
                  <ThreadMessageHeaderNameAndImg>
                    {message.SenderPhotoFilePath !== null ? (
                      <img src={message.SenderPhotoFilePath} alt="" />
                    ) : (
                      defaultAvatar
                    )}
                    <p>{message.SenderName}</p>
                  </ThreadMessageHeaderNameAndImg>
                  <ThreadMessageHeaderIcons>
                    <p>
                      {new Intl.DateTimeFormat('default', {
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      })
                        .format(addTwoHours(message.SentDate))
                        .replace(',', '')}
                    </p>
                  </ThreadMessageHeaderIcons>
                </ThreadMessageHeader>
                <ThreadMessageContent>{message.Content}</ThreadMessageContent>
              </ThreadMessageWrapper>
            ))}
          </MessagesTabWrapper>
        );
      default:
        return <p>default</p>;
    }
  };

  // const handleIsDetailsOpenChange = (value: boolean) => setIsDetailsOpen(value);

  const getTransactionDetail = async () => {
    try {
      const response = await api.get(
        `MangopayTransaction/RedirectToTransactionEntity/${transaction.Order?.Id}
`,
        {
          headers: {Authorization: `Bearer ${cookies.token}`},
        },
      );
      // handleIsDetailsOpenChange(true);
      // console.log(response.data.Response, 'transaction detail response');
    } catch (error) {
      console.error(error);
    }
  };

  if (transaction.Type === 1 || transaction.Type === 3)
    return (
      <>
        <MainWrapper ref={ref}>
          {(isPayP24Loading || isPayTransferLoading) && <LoadingCircle />}
          {isComplaintWindowOpen && (
            <WithdrawMenuWrapper>
              <WithdrawMenuHeaderWrapper>
                <CloseComplaintWindowButton
                  onClick={() => handleIsComplaintWindowOpen(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="9"
                    viewBox="0 0 9 9"
                  >
                    <path
                      id="Path_4069"
                      data-name="Path 4069"
                      d="M32.273,8.038,28.6,4.515l3.633-3.508a.608.608,0,0,0,.043-.824.608.608,0,0,0-.823.043L27.941,3.86,24.417.182A.6.6,0,0,0,23.6.144a.6.6,0,0,0,.038.819l3.678,3.523L23.681,7.993a.555.555,0,1,0,.781.781L27.97,5.141l3.523,3.678a.554.554,0,1,0,.781-.781"
                      transform="translate(-23.455 0)"
                      fill="#fff"
                    />
                  </svg>
                </CloseComplaintWindowButton>
                <WithdrawMenuHeader>
                  <p>Wyślij wiadomość</p>
                </WithdrawMenuHeader>
              </WithdrawMenuHeaderWrapper>

              <TextareaWrapper
                style={{width: '94%', height: '84px'}}
                isEditing={true}
              >
                <h5>Wiadomość</h5>
                <StyledTextarea
                  maxLength={500}
                  readOnly={false}
                  placeholder="Uzupełnij"
                  value={complaint}
                  onChange={handleComplaintChange}
                />
                <TextareaLettersCount>
                  {complaint.length}/500
                </TextareaLettersCount>
              </TextareaWrapper>
              <button
                style={{marginTop: '4px'}}
                onClick={() =>
                  sellerComplaint(transaction.Order?.Id, complaint)
                }
              >
                Wyślij
              </button>
            </WithdrawMenuWrapper>
          )}
          {isOrderMessagesWindowOpen && (
            <WithdrawMenuWrapper>
              <WithdrawMenuHeaderWrapper>
                <CloseComplaintWindowButton
                  onClick={() => handleIsOrderMessagesWindowOpen(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="9"
                    viewBox="0 0 9 9"
                  >
                    <path
                      id="Path_4069"
                      data-name="Path 4069"
                      d="M32.273,8.038,28.6,4.515l3.633-3.508a.608.608,0,0,0,.043-.824.608.608,0,0,0-.823.043L27.941,3.86,24.417.182A.6.6,0,0,0,23.6.144a.6.6,0,0,0,.038.819l3.678,3.523L23.681,7.993a.555.555,0,1,0,.781.781L27.97,5.141l3.523,3.678a.554.554,0,1,0,.781-.781"
                      transform="translate(-23.455 0)"
                      fill="#fff"
                    />
                  </svg>
                </CloseComplaintWindowButton>
                <WithdrawMenuHeader>
                  <p>Wyślij wiadomość</p>
                </WithdrawMenuHeader>
              </WithdrawMenuHeaderWrapper>

              <TextareaWrapper
                style={{width: '94%', height: '84px'}}
                isEditing={true}
              >
                <h5>Wiadomość</h5>
                <StyledTextarea
                  maxLength={500}
                  readOnly={false}
                  placeholder="Uzupełnij"
                  value={orderMessage}
                  onChange={handleOrderMessageChange}
                />
                <TextareaLettersCount>
                  {orderMessage.length}/500
                </TextareaLettersCount>
              </TextareaWrapper>
              <button
                style={{marginTop: '4px'}}
                onClick={() =>
                  sendOrderMessage(transaction.Order?.Id, orderMessage)
                }
              >
                Wyślij
              </button>
            </WithdrawMenuWrapper>
          )}
          {isExtensionRequestWindowOpen && (
            <WithdrawMenuWrapper>
              <WithdrawMenuHeaderWrapper>
                <CloseComplaintWindowButton
                  onClick={() =>
                    handleIsExtensionRequestWindowOpenChange(false)
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="9"
                    viewBox="0 0 9 9"
                  >
                    <path
                      id="Path_4069"
                      data-name="Path 4069"
                      d="M32.273,8.038,28.6,4.515l3.633-3.508a.608.608,0,0,0,.043-.824.608.608,0,0,0-.823.043L27.941,3.86,24.417.182A.6.6,0,0,0,23.6.144a.6.6,0,0,0,.038.819l3.678,3.523L23.681,7.993a.555.555,0,1,0,.781.781L27.97,5.141l3.523,3.678a.554.554,0,1,0,.781-.781"
                      transform="translate(-23.455 0)"
                      fill="#fff"
                    />
                  </svg>
                </CloseComplaintWindowButton>
                <WithdrawMenuHeader>
                  <p>Zmiana terminu realizacji</p>
                </WithdrawMenuHeader>
              </WithdrawMenuHeaderWrapper>
              <StatusHistoryInfo isWinWin={transaction.Order?.IsWinWin}>
                Wyślij prośbę o zmianę daty realizacji zamówienia, klient może
                ale nie musi wyrażać zgody.
              </StatusHistoryInfo>
              <StatusHistoryInputWrapper>
                <p>Proponowany nowy termin realizacji</p>
                <StatusHistoryDateInput>
                  <input
                    type="date"
                    onChange={handleExtensionDateChange}
                    value={extensionDate}
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6.439"
                    height="11"
                    viewBox="0 0 6.439 11"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(6.439 11) rotate(180)"
                    >
                      <path
                        id="Arrow"
                        d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                        transform="translate(0 0)"
                        fill="#3986ef"
                      />
                    </g>
                  </svg>
                </StatusHistoryDateInput>
              </StatusHistoryInputWrapper>
              <StatusHistoryButton
                style={{height: 'unset'}}
                onClick={() =>
                  extensionRequest(transaction.Order?.Id, extensionDate)
                }
              >
                Wyślij
              </StatusHistoryButton>
            </WithdrawMenuWrapper>
          )}
          {isAddFileWindowOpen && (
            <WithdrawMenuWrapper>
              <WithdrawMenuHeaderWrapper>
                <CloseComplaintWindowButton
                  onClick={() => handleIsAddFileWindowOpen(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="9"
                    viewBox="0 0 9 9"
                  >
                    <path
                      id="Path_4069"
                      data-name="Path 4069"
                      d="M32.273,8.038,28.6,4.515l3.633-3.508a.608.608,0,0,0,.043-.824.608.608,0,0,0-.823.043L27.941,3.86,24.417.182A.6.6,0,0,0,23.6.144a.6.6,0,0,0,.038.819l3.678,3.523L23.681,7.993a.555.555,0,1,0,.781.781L27.97,5.141l3.523,3.678a.554.554,0,1,0,.781-.781"
                      transform="translate(-23.455 0)"
                      fill="#fff"
                    />
                  </svg>
                </CloseComplaintWindowButton>
                <WithdrawMenuHeader>
                  <p>Dodaj materiały</p>
                </WithdrawMenuHeader>
              </WithdrawMenuHeaderWrapper>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {' '}
              </div>
              <SelectView isEditing={true} style={{width: '80%'}}>
                <p>Typ pliku</p>
                <select value={fileType} onChange={handleFileTypeChange}>
                  <option value="0">Faktura</option>
                  <option value="1">Umowa</option>
                  <option value="2">Korekta</option>
                  <option value="3">Produkt</option>
                  <option value="4">Pit</option>
                </select>
              </SelectView>
              <input
                type="file"
                hidden
                ref={addFileInputRef}
                onChange={handleFileChange}
                value={undefined}
                accept=".png, .jpg, .mp4, .pdf, .ico, .rar, .rtf, .srt, .flac, .wav, .mp3, .xlsx"
              />
              <WithdrawNewButton
                onClick={() => addFileInputRef.current.click()}
              >
                DODAJ ZAŁĄCZNIK
              </WithdrawNewButton>
            </WithdrawMenuWrapper>
          )}
          {isCommentsWindowOpen && (
            <WithdrawMenuWrapper style={{minHeight: '200px', height: 'unset'}}>
              <WithdrawMenuHeaderWrapper>
                <CloseComplaintWindowButton
                  onClick={() => handleIsCommentsWindowOpen(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="9"
                    viewBox="0 0 9 9"
                  >
                    <path
                      id="Path_4069"
                      data-name="Path 4069"
                      d="M32.273,8.038,28.6,4.515l3.633-3.508a.608.608,0,0,0,.043-.824.608.608,0,0,0-.823.043L27.941,3.86,24.417.182A.6.6,0,0,0,23.6.144a.6.6,0,0,0,.038.819l3.678,3.523L23.681,7.993a.555.555,0,1,0,.781.781L27.97,5.141l3.523,3.678a.554.554,0,1,0,.781-.781"
                      transform="translate(-23.455 0)"
                      fill="#fff"
                    />
                  </svg>
                </CloseComplaintWindowButton>
                <WithdrawMenuHeader>
                  <p>Uwagi</p>
                </WithdrawMenuHeader>
              </WithdrawMenuHeaderWrapper>
              <WithdrawMenuContent>
                {transaction.Order?.OrderComment?.CustommerComment && (
                  <p>Wiadomość sprzedawcy:</p>
                )}
                {transaction.Order?.OrderComment?.CustommerComment && (
                  <p>{transaction.Order?.OrderComment?.CustommerComment}</p>
                )}
                {transaction.Order?.OrderComment?.SellerResponse && (
                  <p>Wiadomość kupującego:</p>
                )}
                {transaction.Order?.OrderComment?.SellerResponse && (
                  <p>{transaction.Order?.OrderComment?.SellerResponse}</p>
                )}
                {(!transaction.Order?.OrderComment?.CustommerComment ||
                  !transaction.Order?.OrderComment?.SellerResponse) && (
                  <p>Brak uwag</p>
                )}
              </WithdrawMenuContent>
            </WithdrawMenuWrapper>
          )}
          {isCustomerComplaintWindowOpen && (
            <WithdrawMenuWrapper>
              <WithdrawMenuHeaderWrapper>
                <CloseComplaintWindowButton
                  onClick={() => handleIsCustomerComplaintWindowOpen(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="9"
                    viewBox="0 0 9 9"
                  >
                    <path
                      id="Path_4069"
                      data-name="Path 4069"
                      d="M32.273,8.038,28.6,4.515l3.633-3.508a.608.608,0,0,0,.043-.824.608.608,0,0,0-.823.043L27.941,3.86,24.417.182A.6.6,0,0,0,23.6.144a.6.6,0,0,0,.038.819l3.678,3.523L23.681,7.993a.555.555,0,1,0,.781.781L27.97,5.141l3.523,3.678a.554.554,0,1,0,.781-.781"
                      transform="translate(-23.455 0)"
                      fill="#fff"
                    />
                  </svg>
                </CloseComplaintWindowButton>
                <WithdrawMenuHeader>
                  <p>Wyślij wiadomość</p>
                </WithdrawMenuHeader>
              </WithdrawMenuHeaderWrapper>

              <TextareaWrapper
                style={{width: '94%', height: '84px'}}
                isEditing={true}
              >
                <h5>Wiadomość</h5>
                <StyledTextarea
                  maxLength={500}
                  readOnly={false}
                  placeholder="Uzupełnij"
                  value={customerComplaintComment}
                  onChange={handleCustomerComplaintCommentChange}
                />
                <TextareaLettersCount>
                  {customerComplaintComment.length}/500
                </TextareaLettersCount>
              </TextareaWrapper>
              <button
                style={{marginTop: '4px'}}
                onClick={() =>
                  customerComplaint(
                    transaction.Order?.Id,
                    customerComplaintComment,
                  )
                }
              >
                Wyślij
              </button>
            </WithdrawMenuWrapper>
          )}
          <SingleTransactionMain isDetailsOpen={isDetailsOpen} ref={desktopRef}>
            <SingleTransactionWrapper>
              <SingleSection>
                <p>Typ transakcji</p>
                <h5>{getOrderTypePolishName(transaction.Order?.DtoType)}</h5>
              </SingleSection>
              <SingleSection>
                <p>Data wprowadzenia</p>
                {transaction.Order?.CreatedAt && (
                  <h5>{getFormattedDate(transaction.Order?.CreatedAt)}</h5>
                )}
              </SingleSection>
              <SingleSection>
                <p>Numer dokumentu</p>
                <h5
                  style={{padding: '0 5px', textAlign: 'center'}}
                  title={transaction.Order?.Number}
                >
                  {transaction.Order?.Number}
                </h5>
              </SingleSection>
              <SingleSection>
                <p>
                  {transaction.Order?.DtoType === 1 ? 'Sprzedawca' : 'Klient'}
                </p>
                {
                  // decodedToken.ProducerId === transaction.Order?.CustomerId
                  transaction.Order?.DtoType === 3 ? (
                    <h5
                      title={
                        transaction.Order?.BillingInfo.NIP
                          ? transaction.Order?.BillingInfo.CompanyName
                          : `${transaction.Order?.BillingInfo.FirstName} ${transaction.Order?.BillingInfo.LastName}`
                      }
                    >
                      {transaction.Order?.BillingInfo.NIP
                        ? transaction.Order?.BillingInfo.CompanyName
                        : `${transaction.Order?.BillingInfo.FirstName} ${transaction.Order?.BillingInfo.LastName}`}
                    </h5>
                  ) : (
                    <h5
                      title={
                        transaction.Order?.SellerInfo.NIP
                          ? transaction.Order?.SellerInfo.CompanyName
                          : `${transaction.Order?.SellerInfo.FirstName} ${transaction.Order?.SellerInfo.LastName}`
                      }
                    >
                      {transaction.Order?.SellerInfo.NIP
                        ? transaction.Order?.SellerInfo.CompanyName
                        : `${transaction.Order?.SellerInfo.FirstName} ${transaction.Order?.SellerInfo.LastName}`}
                    </h5>
                  )
                }
              </SingleSection>
              <SingleSection>
                <p>Wartość</p>
                <SingleSectionPriceWithWinWin>
                  {transaction.Order?.IsWinWin && (
                    <svg
                      id="Group_8095"
                      data-name="Group 8095"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="55.53"
                      height="14"
                      viewBox="0 0 55.53 14"
                    >
                      <g
                        id="Group_6972"
                        data-name="Group 6972"
                        clipPath="url(#clip-path)"
                      >
                        <path
                          id="Path_4111"
                          data-name="Path 4111"
                          d="M210.528,39.406l-.892,1.937c-.223.484-.454,1-.669,1.491h-.015c-.038-.477-.085-.984-.138-1.468l-.215-1.96h-1.161l-.922,1.968c-.223.477-.461,1-.661,1.46h-.015c-.031-.461-.077-.976-.123-1.453l-.192-1.975h-1.653l.7,5.565h1.637l.615-1.314c.223-.477.453-.984.669-1.476h.015c.038.484.084.961.138,1.453l.146,1.337h1.668l2.675-5.565Z"
                          transform="translate(-182.052 -35.189)"
                          fill="#fb9614"
                        />
                        <path
                          id="Path_4112"
                          data-name="Path 4112"
                          d="M274.3,39.406l-.984,5.565H274.9l.984-5.565Z"
                          transform="translate(-244.065 -35.189)"
                          fill="#fb9614"
                        />
                        <path
                          id="Path_4113"
                          data-name="Path 4113"
                          d="M297.445,39.406l-.277,1.583c-.1.569-.2,1.176-.277,1.676l-.015.008c-.269-.515-.553-1.038-.838-1.553l-.946-1.714h-1.476l-.984,5.565h1.522l.277-1.584c.1-.569.2-1.176.277-1.676l.015-.008c.269.515.554,1.038.838,1.553l.945,1.714h1.476l.984-5.565Z"
                          transform="translate(-261.314 -35.189)"
                          fill="#fb9614"
                        />
                        <path
                          id="Path_4114"
                          data-name="Path 4114"
                          d="M349.151,61.188h1.929l.208-1.168h-1.929Z"
                          transform="translate(-311.784 -53.596)"
                          fill="#fb9614"
                        />
                        <path
                          id="Path_4115"
                          data-name="Path 4115"
                          d="M377.569,39.406l-.892,1.937c-.223.484-.454,1-.669,1.491h-.015c-.038-.477-.085-.984-.138-1.468l-.215-1.96h-1.161l-.922,1.968c-.223.477-.461,1-.661,1.46h-.016c-.031-.461-.077-.976-.123-1.453l-.192-1.975h-1.653l.7,5.565h1.637l.615-1.314c.223-.477.453-.984.669-1.476h.016c.038.484.084.961.138,1.453l.146,1.337H376.5l2.675-5.565Z"
                          transform="translate(-331.215 -35.189)"
                          fill="#fb9614"
                        />
                        <path
                          id="Path_4116"
                          data-name="Path 4116"
                          d="M441.34,39.406l-.984,5.565h1.584l.984-5.565Z"
                          transform="translate(-393.228 -35.189)"
                          fill="#fb9614"
                        />
                        <path
                          id="Path_4117"
                          data-name="Path 4117"
                          d="M464.485,39.406l-.277,1.583c-.1.569-.2,1.176-.277,1.676l-.015.008c-.269-.515-.553-1.038-.838-1.553l-.946-1.714h-1.476l-.984,5.565h1.522l.277-1.584c.1-.569.2-1.176.277-1.676l.015-.008c.269.515.554,1.038.838,1.553l.945,1.714h1.476l.984-5.565Z"
                          transform="translate(-410.477 -35.189)"
                          fill="#fb9614"
                        />
                        <path
                          id="Path_4118"
                          data-name="Path 4118"
                          d="M16.552,2.18H15.467l-.822-.822H11.921l2.786,2.786.763-.039h1.082a.75.75,0,0,1,.749.749V9.137a.75.75,0,0,1-.748.749l-2.931,0-1.973,1.972a.75.75,0,0,1-1.06,0L7.562,8.829a.751.751,0,0,1-.172-.793l5.368-.171.269-.269a2.675,2.675,0,0,0,0-3.784L10,.785a2.676,2.676,0,0,0-3.784,0L4.809,2.192l-2.133,0A2.679,2.679,0,0,0,0,4.866V9.147a2.679,2.679,0,0,0,2.676,2.676h.919l.944.944H7.263L4.352,9.856l-.809.04H2.676a.75.75,0,0,1-.749-.749V4.866a.75.75,0,0,1,.748-.749l2.931,0L7.579,2.147a.749.749,0,0,1,1.059,0l3.027,3.027a.751.751,0,0,1,.172.793l-5.368.171L6.2,6.408a2.676,2.676,0,0,0,0,3.784l3.027,3.027a2.679,2.679,0,0,0,3.784,0l1.408-1.408,2.133,0a2.679,2.679,0,0,0,2.676-2.676V4.856A2.679,2.679,0,0,0,16.552,2.18"
                          transform="translate(0 -0.001)"
                          fill="#fb9614"
                        />
                      </g>
                    </svg>
                  )}
                  <h5>
                    {(Number(transaction.Order?.AmountTotal) / 100)
                      .toFixed(2)
                      .replace('.', ',')}{' '}
                    zł
                  </h5>
                </SingleSectionPriceWithWinWin>
              </SingleSection>
              {/* <SingleSection> */}
              {!isDetailsOpen ? (
                <DetailArrowButton
                  isActive={false}
                  onClick={() => {
                    if (!isOrderDetailsLoaded) {
                      getOrderDetails(transaction.Order?.Id);
                    }
                    handleOpen();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="6.439"
                    viewBox="0 0 11 6.439"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(0 6.439) rotate(-90)"
                    >
                      <path
                        id="Arrow"
                        d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                        transform="translate(0 0)"
                        fill="#0091ff"
                      />
                    </g>
                  </svg>
                </DetailArrowButton>
              ) : (
                <DetailArrowButton isActive={true} onClick={() => onClose()}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="6.439"
                    viewBox="0 0 11 6.439"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(0 6.439) rotate(-90)"
                    >
                      <path
                        id="Arrow"
                        d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                        transform="translate(0 0)"
                        fill="#0091ff"
                      />
                    </g>
                  </svg>
                </DetailArrowButton>
              )}
              {/* </SingleSection> */}
              <SingleSection>
                <StyledStatusWrapper statusType={transaction.Order?.Status}>
                  <h1
                    style={
                      transaction.Order?.IsWinWin &&
                      new Date(transaction.Order?.WinWinRealizationDate) <
                        new Date() &&
                      transaction.Order?.Status === 13
                        ? {
                            color: '#ff0300',
                          }
                        : {}
                    }
                  >
                    {transaction.Order?.IsWinWin &&
                    new Date(transaction.Order?.WinWinRealizationDate) <
                      new Date() &&
                    transaction.Order?.Status === 13
                      ? 'Brak publikacji'
                      : getStatusPolishName(transaction.Order?.Status)}
                  </h1>
                  <StyledStatusLine
                    style={
                      transaction.Order?.IsWinWin &&
                      new Date(transaction.Order?.WinWinRealizationDate) <
                        new Date() &&
                      transaction.Order?.Status === 13
                        ? {
                            background: '#ff0300',
                            width: '220px',
                          }
                        : {}
                    }
                    statusType={transaction.Order?.Status}
                  >
                    <div />
                  </StyledStatusLine>
                </StyledStatusWrapper>
                {/* <StyledStatus
                style={
                  transaction.Order?.IsWinWin &&
                  new Date(transaction.Order?.WinWinRealizationDate) <
                    new Date() &&
                  transaction.Order?.Status === 13
                    ? {
                        background: 'rgba(255, 4, 0, 0.7)',
                      }
                    : {}
                }
                statusType={transaction.Order?.Status}
              >
                {transaction.Order?.IsWinWin &&
                new Date(transaction.Order?.WinWinRealizationDate) <
                  new Date() &&
                transaction.Order?.Status === 13
                  ? 'Brak publikacji'
                  : getStatusPolishName(transaction.Order?.Status)}
              </StyledStatus> */}
              </SingleSection>
            </SingleTransactionWrapper>
          </SingleTransactionMain>
          <TransactionDetailsWrapper isDetailsOpen={isDetailsOpen}>
            <>
              {' '}
              <DetailsHeader>
                {transaction.Order?.DtoType !== 2 && (
                  <PrimaryButton
                    isTabButton={true}
                    isActive={tabMenu === TabMenu.STATUS_HISTORY}
                    onClick={() => setTabMenu(TabMenu.STATUS_HISTORY)}
                  >
                    Status
                  </PrimaryButton>
                )}
                <PrimaryButton
                  isTabButton={true}
                  isActive={tabMenu === TabMenu.PRODUCTS}
                  onClick={() => setTabMenu(TabMenu.PRODUCTS)}
                >
                  Produkty
                </PrimaryButton>
                <PrimaryButton
                  isTabButton={true}
                  isActive={tabMenu === TabMenu.USER_DATA}
                  onClick={() => setTabMenu(TabMenu.USER_DATA)}
                >
                  {transaction.Order?.DtoType === 1 ? 'Sprzedawca' : 'Klient'}
                </PrimaryButton>
                <PrimaryButton
                  isTabButton={true}
                  isActive={tabMenu === TabMenu.DOCUMENTS}
                  onClick={() => setTabMenu(TabMenu.DOCUMENTS)}
                >
                  Pliki
                </PrimaryButton>
                <PrimaryButton
                  isTabButton={true}
                  isActive={tabMenu === TabMenu.PAYMENT}
                  onClick={() => setTabMenu(TabMenu.PAYMENT)}
                >
                  Płatność
                </PrimaryButton>
                <PrimaryButton
                  isTabButton={true}
                  isActive={tabMenu === TabMenu.MESSAGES}
                  onClick={() => setTabMenu(TabMenu.MESSAGES)}
                >
                  Wiadomości
                </PrimaryButton>
              </DetailsHeader>
              {renderTabMenuContent(tabMenu, orderDetails?.Files?.length)}
            </>
          </TransactionDetailsWrapper>
        </MainWrapper>
        <MobileMainWrapper
          isDetailsOpen={isDetailsOpen}
          style={
            index === 0
              ? {
                  // marginTop: '135px'
                }
              : {}
          }
        >
          {(isPayP24Loading || isPayTransferLoading) && <LoadingCircle />}
          <Link
            ref={mobileRef}
            to={`/transactions/${transaction?.Order?.Id}`}
            style={{width: '100%', textDecoration: 'none'}}
            state={{
              transaction: transaction,
            }}
          >
            <MobileSingleTransitionWithStatusWrapper
              onClick={
                !isDetailsOpen
                  ? () => {
                      if (!isOrderDetailsLoaded) {
                        getOrderDetails(transaction.Order?.Id);
                      }
                      handleOpen();
                    }
                  : () => onClose()
              }
            >
              <MobileTransactionTypeWithDateWrapper
                style={{marginBottom: '2px'}}
              >
                <h5>{getOrderTypePolishName(transaction.Order?.DtoType)}</h5>
                {transaction.Order?.CreatedAt && (
                  <p>{getFormattedDate(transaction.Order?.CreatedAt)}</p>
                )}
              </MobileTransactionTypeWithDateWrapper>
              <MobileTransactionNameWithNumberWrapper>
                {
                  // decodedToken.ProducerId === transaction.Order?.CustomerId
                  transaction.Order?.DtoType === 3 ? (
                    <h1>
                      {transaction.Order?.BillingInfo.NIP
                        ? transaction.Order?.BillingInfo.CompanyName
                        : `${transaction.Order?.BillingInfo.FirstName} ${transaction.Order?.BillingInfo.LastName}`}
                    </h1>
                  ) : (
                    <h1>
                      {transaction.Order?.SellerInfo.NIP
                        ? transaction.Order?.SellerInfo.CompanyName
                        : `${transaction.Order?.SellerInfo.FirstName} ${transaction.Order?.SellerInfo.LastName}`}
                    </h1>
                  )
                }
                <p style={{marginTop: '4px', marginBottom: '1px'}}>
                  {transaction.Order?.Number}
                </p>
              </MobileTransactionNameWithNumberWrapper>

              <MobileTransactionTypeWithDateWrapper>
                <StyledStatusWrapper
                  statusType={transaction.Order?.Status}
                  style={{margin: 'unset'}}
                >
                  <h1
                    style={
                      transaction.Order?.IsWinWin &&
                      new Date(transaction.Order?.WinWinRealizationDate) <
                        new Date() &&
                      transaction.Order?.Status === 13
                        ? {
                            color: '#ff0300',
                          }
                        : {}
                    }
                  >
                    {transaction.Order?.IsWinWin &&
                    new Date(transaction.Order?.WinWinRealizationDate) <
                      new Date() &&
                    transaction.Order?.Status === 13
                      ? 'Brak publikacji'
                      : getStatusPolishName(transaction.Order?.Status)}
                  </h1>
                  <StyledStatusLine
                    style={
                      transaction.Order?.IsWinWin &&
                      new Date(transaction.Order?.WinWinRealizationDate) <
                        new Date() &&
                      transaction.Order?.Status === 13
                        ? {
                            background: '#ff0300',
                            width: '220px',
                          }
                        : {}
                    }
                    statusType={transaction.Order?.Status}
                  >
                    <div />
                  </StyledStatusLine>
                </StyledStatusWrapper>

                <MobileTransactionNameWithNumberWrapper
                  style={{width: 'unset'}}
                >
                  <WinWinSvg />
                  <h2>
                    {(Number(transaction.Order?.AmountTotal) / 100)
                      .toFixed(2)
                      .replace('.', ',')}{' '}
                    PLN
                  </h2>
                </MobileTransactionNameWithNumberWrapper>
              </MobileTransactionTypeWithDateWrapper>
            </MobileSingleTransitionWithStatusWrapper>
          </Link>
        </MobileMainWrapper>
      </>
    );
  else
    return (
      <>
        <MainWrapper ref={ref}>
          <SingleTransactionWrapper ref={desktopRef}>
            <SingleSection>
              <p>Typ transakcji</p>
              <h5>Wypłata</h5>
            </SingleSection>
            <SingleSection>
              <p>Data wprowadzenia</p>
              {transaction.CreationDate && (
                <h5>{getFormattedDate(transaction.CreationDate)}</h5>
              )}
            </SingleSection>
            <SingleSection>
              <p>Numer dokumentu</p>
              <h5 style={{padding: '0 5px', textAlign: 'center'}}>
                {transaction.PayOut?.Id}
              </h5>
            </SingleSection>
            <SingleSection>
              <p>Klient</p>
              <h5>MUGOPortfel</h5>
            </SingleSection>
            <SingleSection>
              <p>Wartość</p>
              <h5>
                {Number(transaction.PayOut?.DebitedFunds / 100)
                  .toFixed(2)
                  .replace('.', ',')}{' '}
                zł
              </h5>
              {transaction.Order?.IsWinWin && (
                <WinWinInfo>
                  <img src={winWinIcon} />
                  <p>WIN-WIN</p>
                </WinWinInfo>
              )}
            </SingleSection>
            {/* <SingleSection style={{visibility: 'hidden'}}>
              {!isDetailsOpen ? (
                <PrimaryButton
                  isActive={false}
                  // onClick={() => {
                  //   if (!isOrderDetailsLoaded) {
                  //     getOrderDetails(transaction.Order?.Id);
                  //   }
                  //   handleOpen();
                  // }}
                >
                  Szczegóły
                </PrimaryButton>
              ) : (
                <PrimaryButton
                // isActive={true} onClick={() => onClose()}
                >
                  Szczegóły
                </PrimaryButton>
              )}
            </SingleSection> */}
            {/* <SingleSection> */}
            {/* <StyledStatus
                style={
                  transaction.PayOut?.Status === 'CREATED'
                    ? {background: 'rgba(251, 155, 31,0.7)'}
                    : transaction.PayOut?.Status === 'SUCCEEDED'
                    ? {background: 'rgba(0, 102, 255, 0.7)'}
                    : {background: 'rgba(255, 4, 0, 0.7)'}
                }
              >
                {getPayoutStatusPolishName(transaction.PayOut?.Status)}
              </StyledStatus> */}
            <StyledStatusWrapper>
              <h1
                style={
                  transaction.PayOut?.Status === 'CREATED'
                    ? {color: '#fb9614'}
                    : transaction.PayOut?.Status === 'SUCCEEDED'
                    ? {color: '#0091ff'}
                    : {color: '#ff0300'}
                }
              >
                {getPayoutStatusPolishName(transaction.PayOut?.Status)}
              </h1>
              <StyledStatusLine>
                <div
                  style={
                    transaction.PayOut?.Status === 'CREATED'
                      ? {background: '#fb9614', width: '155px'}
                      : transaction.PayOut?.Status === 'SUCCEEDED'
                      ? {background: '#0091ff', width: '220px'}
                      : {background: '#ff0300', width: '220px'}
                  }
                />
              </StyledStatusLine>
            </StyledStatusWrapper>
            {/* </SingleSection> */}
          </SingleTransactionWrapper>
        </MainWrapper>
        <MobileMainWrapper
          ref={mobileRef}
          isDetailsOpen={isDetailsOpen}
          style={
            index === 0
              ? {
                  // marginTop: '135px'
                }
              : {}
          }
        >
          {(isPayP24Loading || isPayTransferLoading) && <LoadingCircle />}
          <MobileSingleTransitionWithStatusWrapper>
            <MobileTransactionTypeWithDateWrapper style={{marginBottom: '2px'}}>
              <h5>Wypłata</h5>
              {transaction.CreationDate && (
                <p>{getFormattedDate(transaction.CreationDate)}</p>
              )}
            </MobileTransactionTypeWithDateWrapper>
            <MobileTransactionNameWithNumberWrapper>
              <h1>MUGO Portfel</h1>
              <p style={{marginTop: '4px', marginBottom: '1px'}}>
                {transaction.PayOut?.Id}
              </p>
            </MobileTransactionNameWithNumberWrapper>

            <MobileTransactionTypeWithDateWrapper>
              <StyledStatusWrapper
                statusType={transaction.Order?.Status}
                style={{margin: 'unset'}}
              >
                <h1
                  style={
                    transaction.Order?.IsWinWin &&
                    new Date(transaction.Order?.WinWinRealizationDate) <
                      new Date() &&
                    transaction.Order?.Status === 13
                      ? {
                          color: '#ff0300',
                        }
                      : {}
                  }
                >
                  {transaction.Order?.IsWinWin &&
                  new Date(transaction.Order?.WinWinRealizationDate) <
                    new Date() &&
                  transaction.Order?.Status === 13
                    ? 'Brak publikacji'
                    : getStatusPolishName(transaction.Order?.Status)}
                </h1>
                <StyledStatusLine
                  style={
                    transaction.Order?.IsWinWin &&
                    new Date(transaction.Order?.WinWinRealizationDate) <
                      new Date() &&
                    transaction.Order?.Status === 13
                      ? {
                          background: '#ff0300',
                          width: '220px',
                        }
                      : {}
                  }
                  statusType={transaction.Order?.Status}
                >
                  <div />
                </StyledStatusLine>
              </StyledStatusWrapper>

              <MobileTransactionNameWithNumberWrapper style={{width: 'unset'}}>
                <WinWinSvg />
                <h2>
                  {(Number(transaction.PayOut?.DebitedFunds) / 100)
                    .toFixed(2)
                    .replace('.', ',')}{' '}
                  PLN
                </h2>
              </MobileTransactionNameWithNumberWrapper>
            </MobileTransactionTypeWithDateWrapper>
          </MobileSingleTransitionWithStatusWrapper>
        </MobileMainWrapper>
      </>
    );
};

export default SingleTransaction;
