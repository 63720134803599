import React, {useState, useEffect, useContext} from 'react';
import styled, {keyframes} from 'styled-components';
import api from 'services/axiosConfig';
import {useNavigate} from 'react-router-dom';
import {getProductTypePolishName} from 'utils/getProductTypePolishName';
import {ProducerDataContext} from 'contexts/ProducerDataContext';

import {
  Main,
  MainWrapper,
  PageHeaderContent,
  PageHeaderHeader,
  PageHeaderHeaderWrapper,
  PageHeaderWrapper,
  MobileSliderWrapper,
  FirstSectionWrapper,
  Content,
  SecondSectionWrapper,
  SectionWrapper,
  ThirdSectionWrapper,
  MobileTutorialButton,
  FooterNavbar,
  FooterBanner,
  FooterWrapper,
  SearchBarWrapper,
  SearchButton,
  FilterButtonSvg,
  SelectWrapper,
  StyledButton,
  Wrapper,
  MobileWrapper,
} from './styles';

import BottomBannerImage from './HomeImages/bottom_banner.png';
import {ProductTypeEnum} from 'constants/conditionEnums';
import {HeaderSlider} from './HeaderSlider';
import {PopularCategories} from './PopularCategories';
import {Articles} from './Articles';
import {WhiteWinWinSvg} from 'components/SvgComponents';

import Image1 from './HomeImages/image_1.png';
import MobileImage1 from './HomeImages/mobile_image_2_2.png';
import HomeFooterImage from './HomeImages/bottom_banner.png';
import MobileHomeFooterImage from './HomeImages/home_footer2.png';
import Image2 from './HomeImages/image_2_5.jpg';
import MobileImage2 from './HomeImages/mobile_image_2_5.jpg';
import Image3 from './HomeImages/image_3.png';
import {PopularProducts} from './PopularProducts';
import {BigArticle} from './BigArticle';
import {PopularTracks} from './PopularTracks';
import {Navigate} from 'react-router-dom';
import parallaxImage from 'pages/Home/HomeImages/home_footer.jpg';
import desktopTxt from 'pages/Home/HomeImages/1440desktoptxt.png';
import mobileTxt from 'pages/Home/HomeImages/420mobilkatxt.png';
import {Parallax} from 'react-parallax';

export interface RecommendedArticle {
  Id: string;
  Title: string;
  Description: string;
  PicturePath: string;
  UrlPath: string;
  Category: string;
}

export interface RecommendedTrack {
  OfferId: string;
  ProductId: string;
  ProductName: string;
  Name: string;
  Price: number;
  PicturePath: string;
  IsWinWin: boolean;
  Categories: string[];
  Attributes: null;
  SoundPath: string;
}

export interface RecommendedProducer {
  Id: string;
  ProducerName: string;
  Description: string;
  PicturePath: string;
  Location: string;
  Roles: string[];
}

export interface RecommendedProducts {
  Category: string | null;
  ProductType: number | null;
  Title: string;
  Products: Product[];
}

export interface Product {
  Categories: string[];
  IsWinWin: boolean;
  Name: string;
  OfferId: string;
  PicturePath: string;
  Position: number;
  Price: number;
  ProductId: string;
  ProductName: string;
}

export interface Article {
  Id: string;
  Title: string;
  Description: string;
  PicturePath: string;
  UrlPath: string;
  Category: string;
}

export interface RecommendedProducers {
  Producers: RecommendedProducer[];
  Article: Article;
}

export interface HomeRecommendations {
  RecommendedArticles: RecommendedArticle[];
  RecommendedTracks: RecommendedTrack[];
  RecommendedProducers: RecommendedProducers;
  RecommendedProducts: RecommendedProducts;
}

const Home = () => {
  const [homeRecommendations, setHomeRecommendations] =
    useState<HomeRecommendations | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState<boolean>(false);
  const [productType, setProductType] = useState<number | string>('');
  const {decodedToken} = useContext(ProducerDataContext);

  const navigate = useNavigate();

  const handleIsFilterMenuOpenChange = (value: boolean) =>
    setIsFilterMenuOpen(value);

  const handleSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchQuery(e.target.value);

  const handleProductTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === '') {
      setProductType('');
    } else {
      setProductType(+e.target.value);
    }
  };

  const getHomeRecommendations = async () => {
    try {
      const response = await api.get(`Home`);
      setHomeRecommendations(response.data.Response);
    } catch (error) {
      console.error(error);
    }
  };

  const search = () => {
    navigate(
      `/all?SearchString=${searchQuery}&ProducerName=&ProductType=${productType}&PrimaryCategory=&SecondaryCategory=&TertiaryCategory=&Vibe=&Tempo=&Tone=&MinGrossValue=&MaxGrossValue=&IsWinwin=no&IsFavourite=no&HasWaveFile=no`,
    );
  };

  useEffect(() => {
    getHomeRecommendations();
  }, []);

  useEffect(() => {
    document.title = `MUGO Studio`;
    document
      .querySelector("meta[property='og:title']")
      ?.setAttribute('content', `MUGO Studio`);
    document
      .querySelector("meta[property='og:description']")
      ?.setAttribute(
        'content',
        'MUGO Studio to przestrzeń dla twórców, freelancerów, artystów i ludzi poszukujących usług oraz produktów oferowanych przez te osoby. To platforma stworzona dla usługodawców i usługobiorców, gwarantująca bezpieczeństwo transakcji oraz stwarzająca możliwość współpracy na innowacyjnych warunkach.',
      );
  }, []);

  return (
    <>
      <MainWrapper>
        <BackgroundColorWrapper>
          <Main>
            <PageHeaderWrapper>
              <PageHeaderHeaderWrapper>
                <Wrapper isFilterMenuOpen={isFilterMenuOpen}>
                  <PageHeaderHeader>
                    <SearchBarWrapper>
                      <input
                        type="text"
                        value={searchQuery}
                        onChange={handleSearchQueryChange}
                        placeholder={'Wyszukaj usługę lub bit'}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            search();
                          }
                        }}
                      />
                      <SearchButton onClick={() => search()}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14.146"
                          height="14"
                          viewBox="0 0 14.146 14"
                        >
                          <path
                            id="Path_1507"
                            data-name="Path 1507"
                            d="M2601.826,547.617l-3.519-3.207a5.82,5.82,0,0,0-.448-7.709h0a5.816,5.816,0,1,0-.7,8.813l3.6,3.28a.8.8,0,1,0,1.072-1.177Zm-11.3-3.586a4.554,4.554,0,1,1,3.219,1.333A4.559,4.559,0,0,1,2590.529,544.03Z"
                            transform="translate(-2587.94 -535.001)"
                            fill="#fff"
                          />
                        </svg>
                      </SearchButton>
                    </SearchBarWrapper>
                    <FilterButtonSvg
                      value={isFilterMenuOpen}
                      onClick={() => {
                        handleIsFilterMenuOpenChange(!isFilterMenuOpen);
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="20.87"
                      height="15.047"
                      viewBox="0 0 20.87 15.047"
                    >
                      <g
                        id="Group_1312"
                        data-name="Group 1312"
                        transform="translate(-2473.175 -536.818)"
                      >
                        <path
                          id="Path_1510"
                          data-name="Path 1510"
                          d="M2480.2,539.042h13a.85.85,0,0,0,0-1.7h-13a.85.85,0,0,0,0,1.7Z"
                          fill="#797c93"
                        />
                        <path
                          id="Path_1511"
                          data-name="Path 1511"
                          d="M2493.2,543.517h-13a.85.85,0,0,0,0,1.7h13a.85.85,0,0,0,0-1.7Z"
                          fill="#797c93"
                        />
                        <path
                          id="Path_1512"
                          data-name="Path 1512"
                          d="M2493.2,549.4h-13a.85.85,0,0,0,0,1.7h13a.85.85,0,0,0,0-1.7Z"
                          fill="#797c93"
                        />
                        <circle
                          id="Ellipse_1"
                          data-name="Ellipse 1"
                          cx="1.5"
                          cy="1.5"
                          r="1.5"
                          transform="translate(2473.175 548.864)"
                          fill="#797c93"
                        />
                        <circle
                          id="Ellipse_2"
                          data-name="Ellipse 2"
                          cx="1.5"
                          cy="1.5"
                          r="1.5"
                          transform="translate(2473.175 542.853)"
                          fill="#797c93"
                        />
                        <circle
                          id="Ellipse_3"
                          data-name="Ellipse 3"
                          cx="1.5"
                          cy="1.5"
                          r="1.5"
                          transform="translate(2473.175 536.818)"
                          fill="#797c93"
                        />
                      </g>
                    </FilterButtonSvg>
                    {decodedToken ? (
                      <StyledButton onClick={() => navigate('/myproducts/add')}>
                        Wystaw ofertę
                      </StyledButton>
                    ) : (
                      <StyledButton
                        onClick={() => navigate('/identity/register')}
                      >
                        Zostań sprzedawcą!
                      </StyledButton>
                    )}
                  </PageHeaderHeader>

                  {isFilterMenuOpen && (
                    <SelectWrapper>
                      <p>Typ produktu</p>
                      <select
                        value={productType}
                        onChange={handleProductTypeChange}
                      >
                        <option hidden value={''}>
                          Wybierz
                        </option>
                        <option value={''}>Wszystko</option>
                        {ProductTypeEnum.map((productType) => {
                          const {FieldName, FlagValue} = productType;

                          return (
                            <option value={FlagValue} key={FlagValue}>
                              {getProductTypePolishName(FlagValue)}
                            </option>
                          );
                        })}
                      </select>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6.439"
                        height="11"
                        viewBox="0 0 6.439 11"
                      >
                        <g
                          id="Arrow_Top_Bar_Accent"
                          data-name="Arrow – Top Bar / Accent"
                          transform="translate(6.439 11) rotate(180)"
                        >
                          <path
                            id="Arrow"
                            d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                            transform="translate(0 0)"
                            fill="#0091ff"
                          />
                        </g>
                      </svg>
                    </SelectWrapper>
                  )}
                </Wrapper>
              </PageHeaderHeaderWrapper>
              <PageHeaderContent>
                <HeaderSlider />
              </PageHeaderContent>
            </PageHeaderWrapper>
            <Content>
              <MobileWrapper isFilterMenuOpen={isFilterMenuOpen}>
                <PageHeaderHeader>
                  <SearchBarWrapper>
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={handleSearchQueryChange}
                      placeholder={'Wyszukaj usługę lub bit'}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          search();
                        }
                      }}
                    />
                    <SearchButton onClick={() => search()}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14.146"
                        height="14"
                        viewBox="0 0 14.146 14"
                      >
                        <path
                          id="Path_1507"
                          data-name="Path 1507"
                          d="M2601.826,547.617l-3.519-3.207a5.82,5.82,0,0,0-.448-7.709h0a5.816,5.816,0,1,0-.7,8.813l3.6,3.28a.8.8,0,1,0,1.072-1.177Zm-11.3-3.586a4.554,4.554,0,1,1,3.219,1.333A4.559,4.559,0,0,1,2590.529,544.03Z"
                          transform="translate(-2587.94 -535.001)"
                          fill="#fff"
                        />
                      </svg>
                    </SearchButton>
                  </SearchBarWrapper>
                  <FilterButtonSvg
                    value={isFilterMenuOpen}
                    onClick={() => {
                      handleIsFilterMenuOpenChange(!isFilterMenuOpen);
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="20.87"
                    height="15.047"
                    viewBox="0 0 20.87 15.047"
                  >
                    <g
                      id="Group_1312"
                      data-name="Group 1312"
                      transform="translate(-2473.175 -536.818)"
                    >
                      <path
                        id="Path_1510"
                        data-name="Path 1510"
                        d="M2480.2,539.042h13a.85.85,0,0,0,0-1.7h-13a.85.85,0,0,0,0,1.7Z"
                        fill="#797c93"
                      />
                      <path
                        id="Path_1511"
                        data-name="Path 1511"
                        d="M2493.2,543.517h-13a.85.85,0,0,0,0,1.7h13a.85.85,0,0,0,0-1.7Z"
                        fill="#797c93"
                      />
                      <path
                        id="Path_1512"
                        data-name="Path 1512"
                        d="M2493.2,549.4h-13a.85.85,0,0,0,0,1.7h13a.85.85,0,0,0,0-1.7Z"
                        fill="#797c93"
                      />
                      <circle
                        id="Ellipse_1"
                        data-name="Ellipse 1"
                        cx="1.5"
                        cy="1.5"
                        r="1.5"
                        transform="translate(2473.175 548.864)"
                        fill="#797c93"
                      />
                      <circle
                        id="Ellipse_2"
                        data-name="Ellipse 2"
                        cx="1.5"
                        cy="1.5"
                        r="1.5"
                        transform="translate(2473.175 542.853)"
                        fill="#797c93"
                      />
                      <circle
                        id="Ellipse_3"
                        data-name="Ellipse 3"
                        cx="1.5"
                        cy="1.5"
                        r="1.5"
                        transform="translate(2473.175 536.818)"
                        fill="#797c93"
                      />
                    </g>
                  </FilterButtonSvg>
                </PageHeaderHeader>

                {isFilterMenuOpen && (
                  <SelectWrapper>
                    <p>Typ produktu</p>
                    <select
                      value={productType}
                      onChange={handleProductTypeChange}
                    >
                      <option hidden value={''}>
                        Wybierz
                      </option>
                      <option value={''}>Wszystko</option>
                      {ProductTypeEnum.map((productType) => {
                        const {FieldName, FlagValue} = productType;

                        return (
                          <option value={FlagValue} key={FlagValue}>
                            {getProductTypePolishName(FlagValue)}
                          </option>
                        );
                      })}
                    </select>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6.439"
                      height="11"
                      viewBox="0 0 6.439 11"
                    >
                      <g
                        id="Arrow_Top_Bar_Accent"
                        data-name="Arrow – Top Bar / Accent"
                        transform="translate(6.439 11) rotate(180)"
                      >
                        <path
                          id="Arrow"
                          d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                          transform="translate(0 0)"
                          fill="#0091ff"
                        />
                      </g>
                    </svg>
                  </SelectWrapper>
                )}
              </MobileWrapper>
              <MobileSliderWrapper>
                <HeaderSlider />
              </MobileSliderWrapper>
              <PopularCategories />
              <PopularProducts
                RecommendedProducts={homeRecommendations?.RecommendedProducts}
              />
              <FirstSectionWrapper>
                <h1>Nowe pomysły? MUGO STUDIO</h1>
                <a
                  // href="/faq/default"
                  href="https://www.youtube.com/live/_WwqUJR_taY"
                >
                  Zobacz więcej
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6.438"
                    height="11"
                    viewBox="0 0 6.438 11"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(0)"
                      opacity="0.92"
                    >
                      <path
                        id="Arrow"
                        d="M1.321.224l4.961,4.9a.523.523,0,0,1,0,.747l-4.961,4.9a.781.781,0,0,1-1.095,0,.759.759,0,0,1,0-1.082L4.47,5.5.226,1.306a.76.76,0,0,1,0-1.082.781.781,0,0,1,1.095,0"
                        transform="translate(0 0)"
                        fill="#0091ff"
                      />
                    </g>
                  </svg>
                </a>
                <img
                  onClick={() =>
                    (window.location.href = 'https://youtu.be/n1H3vsPQl9U')
                  }
                  src={Image1}
                />
                <img
                  onClick={() =>
                    (window.location.href = 'https://youtu.be/n1H3vsPQl9U')
                  }
                  src={Image1}
                />
              </FirstSectionWrapper>
              <BigArticle
                RecommendedProducers={
                  homeRecommendations?.RecommendedProducers.Producers
                }
                Article={homeRecommendations?.RecommendedProducers.Article}
              />
              <PopularTracks
                RecommendedTracks={homeRecommendations?.RecommendedTracks}
              />
              <Articles
                RecommendedArticles={homeRecommendations?.RecommendedArticles}
              />
              <a
                href={`/czytelnia/Poradnik/win-win-to-sie-oplaca`}
                style={{textDecoration: 'none'}}
              >
                <SecondSectionWrapper
                // onClick={() =>
                //   navigate('/czytelnia/Poradnik/win-win-to-sie-oplaca')
                // }
                >
                  <h1>Czym jest WIN-WIN? Poznaj korzyści!</h1>
                  <WhiteWinWinSvg />
                  <img src={Image2} />
                  <img src={MobileImage2} />
                </SecondSectionWrapper>
              </a>
              {/* <ThirdSectionWrapper>
            <div>
              <h1>Jak zacząć? Zobacz WORKFLOW</h1>
              <h1>
                Jak zacząć?
                <br /> Zobacz WORKFLOW
              </h1>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don t look even
                slightly believable. If you are
              </p>
              <div>
                Obejrzyj tutorial
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6.438"
                  height="11"
                  viewBox="0 0 6.438 11"
                >
                  <g
                    id="Arrow_Top_Bar_Accent"
                    data-name="Arrow – Top Bar / Accent"
                    transform="translate(0)"
                    opacity="0.92"
                  >
                    <path
                      id="Arrow"
                      d="M1.321.224l4.961,4.9a.523.523,0,0,1,0,.747l-4.961,4.9a.781.781,0,0,1-1.095,0,.759.759,0,0,1,0-1.082L4.47,5.5.226,1.306a.76.76,0,0,1,0-1.082.781.781,0,0,1,1.095,0"
                      transform="translate(0 0)"
                      fill="#17161d"
                    />
                  </g>
                </svg>
              </div>
            </div>
            <div>
              <img src={Image3} />
              <MobileTutorialButton>
                Obejrzyj tutorial
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6.438"
                  height="11"
                  viewBox="0 0 6.438 11"
                >
                  <g
                    id="Arrow_Top_Bar_Accent"
                    data-name="Arrow – Top Bar / Accent"
                    transform="translate(0)"
                    opacity="0.92"
                  >
                    <path
                      id="Arrow"
                      d="M1.321.224l4.961,4.9a.523.523,0,0,1,0,.747l-4.961,4.9a.781.781,0,0,1-1.095,0,.759.759,0,0,1,0-1.082L4.47,5.5.226,1.306a.76.76,0,0,1,0-1.082.781.781,0,0,1,1.095,0"
                      transform="translate(0 0)"
                      fill="#17161d"
                    />
                  </g>
                </svg>
              </MobileTutorialButton>
            </div>
          </ThirdSectionWrapper> */}
            </Content>
          </Main>
        </BackgroundColorWrapper>
        {/* <img src={HomeFooterImage} /> */}
        {/* <img src={MobileHomeFooterImage} /> */}
        <FooterWrapper>
          <FooterNavbar>
            <div>
              <p>© 2024 MUGO</p>
              <div>
                <a
                  target="__blank"
                  href="https://fmmtp.pl/dokumentymugo/Polityka%20prywatno%C5%9Bci.pdf"
                >
                  Polityka prywatności
                </a>
                <a
                  target="__blank"
                  href="https://fmmtp.pl/mugostudio/Regulamin%20Serwisu_Mugo_Studio_17.09.2024.pdf"
                >
                  Regulamin
                </a>
                <a
                  target="__blank"
                  href="https://fmmtp.pl/mugostudio/formularz_zgloszenia_tresci_nielegalnych_18_09_2024.docx"
                >
                  Zgłoś naruszenie
                </a>
                <a href="/faq/default">FAQ</a>
              </div>
            </div>
          </FooterNavbar>
          <FooterBanner href="https://mugo.pl/" />
        </FooterWrapper>
      </MainWrapper>
      <ParallaxContainer />
    </>
  );
};

export default Home;

export const ParallaxWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
`;

export const DesktopParallaxMain = styled.div`
  width: 100%;
  /* height: 100%; */

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const MobileParallaxMain = styled.div`
  width: 100%;
  height: 100%;
  display: none;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: unset;
  }
`;

export const BackgroundColorWrapper = styled.div`
  width: 100%;
  /* height: 100%; */
  height: 100%;
  background: ${({theme}) => theme.colors.bodyBg};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Test = styled.div`
  width: 100%;
  /* height: 100%; */
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: -5;
  user-select: all;
  pointer-events: all;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-bottom: 57px;
  }
`;

const ParallaxContainer = () => (
  <Test>
    <DesktopParallaxMain>
      <Parallax
        bgImage={require('pages/Home/HomeImages/1440desktopbg.png')}
        bgImageAlt="image"
      >
        <ParallaxWrapper>
          <img src={desktopTxt} />
        </ParallaxWrapper>
      </Parallax>
    </DesktopParallaxMain>
    <MobileParallaxMain>
      <Parallax
        bgImage={require('pages/Home/HomeImages/420mobilkabg.png')}
        bgImageAlt="image"
      >
        <ParallaxWrapper>
          <img src={mobileTxt} />
        </ParallaxWrapper>
      </Parallax>
    </MobileParallaxMain>
  </Test>
);
