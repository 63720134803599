import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import styled from 'styled-components';
import {useCookies} from 'react-cookie';
import {ProducerDataContext} from '../../../../contexts/ProducerDataContext';
import {StatisticsContext} from '../../../../contexts/StatisticsContext';
import {Navigate, useNavigate, useLocation} from 'react-router-dom';
import {Message, MessagesType, Status} from '../interfaces';
import api from '../../../../services/axiosConfig';
import _ from 'lodash';
import useAlert from 'hooks/useAlert';
import {UserDataContext} from 'contexts/UserDataContext';
import {LoadingCircle} from 'utils/loadingCircle';

import {
  CancelButton,
  Flex,
  HeaderWrapper,
  InputWrapper,
  LeftSection,
  BlackScreen,
  LeftSectionButton,
  LeftSectionButtonWrapper,
  LeftSectionMessageContentWrapper,
  LeftSectionMessageHeader,
  LeftSectionMessagesWrapper,
  LeftSectionMessageWrapper,
  LeftSectionSearchWrapper,
  Main,
  MainWrapper,
  RightSection,
  RightSectionAddressAndTitleWrapper,
  RightSectionAddressAndTitleWriteMode,
  RightSectionHeaderWrapper,
  RightSectionIconsWrapper,
  RightSectionMessageContent,
  RefreshButton,
  RightSectionSingleIcon,
  InputWithSearchResultsWrapper,
  SearchResultWrapper,
  SingleSearchResultWrapper,
  ContentTitle,
  MobileMain,
  MobileMainWrapper,
  MobileLeftSection,
  CloseIsLoginPageOpen,
  CloseIsLoginPageOpenWrapper,
  MenuMain,
  MenuWrapper,
  MobileIconsWrapper,
  SvgEmptyMsgWrapper,
  RightSectionThreadMessagesWrapper,
  ThreadMessageWrapper,
  ThreadMessageHeader,
  ThreadMessageHeaderNameAndImg,
  ThreadMessageHeaderIcons,
  ThreadMessageContent,
  ThreadResponsIconWrapper,
  ThreadResponseWrappers,
  ThreadResponseHeaderNameAndImg,
  ThreadResponseButtonsWrapper,
  ThreadResponseContent,
  ThreadMobileScrollWrapper,
  MobileTopicWrapper,
  SvgReplyAndRefreshWrapper,
} from '../newMessage/styles';
import {useIsBodyOverflowBlockedContext} from 'contexts/IsBodyOverflowBlockedContext';
import {AccountProducerContext} from 'contexts/AccountProducerContext';

const defaultAvatar = (
  <svg
    id="Group_4321"
    data-name="Group 4321"
    xmlns="http://www.w3.org/2000/svg"
    width="193"
    height="193"
    viewBox="0 0 193 193"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_2509"
          data-name="Rectangle 2509"
          width="193"
          height="193"
          fill="#dce0e9"
        />
      </clipPath>
    </defs>
    <g id="Group_4320" data-name="Group 4320" clipPath="url(#clip-path)">
      <path
        id="Path_3684"
        data-name="Path 3684"
        d="M132.354,26.555a5.75,5.75,0,0,1-5.75-5.75V16.2a4.711,4.711,0,0,0-4.7-4.7h-4.6a5.75,5.75,0,0,1,0-11.5h4.6a16.222,16.222,0,0,1,16.2,16.2v4.6a5.75,5.75,0,0,1-5.75,5.75"
        transform="translate(54.058 0)"
        fill="#dce0e9"
      />
      <path
        id="Path_3685"
        data-name="Path 3685"
        d="M5.75,26.555A5.75,5.75,0,0,1,0,20.805V16.2A16.222,16.222,0,0,1,16.2,0h4.6a5.75,5.75,0,0,1,0,11.5H16.2a4.711,4.711,0,0,0-4.7,4.7v4.6a5.75,5.75,0,0,1-5.75,5.75"
        transform="translate(0)"
        fill="#dce0e9"
      />
      <path
        id="Path_3686"
        data-name="Path 3686"
        d="M20.805,138.105H16.2A16.224,16.224,0,0,1,0,121.9v-4.6a5.75,5.75,0,0,1,11.5,0v4.6a4.711,4.711,0,0,0,4.7,4.7h4.6a5.75,5.75,0,0,1,0,11.5"
        transform="translate(0 54.059)"
        fill="#dce0e9"
      />
      <path
        id="Path_3687"
        data-name="Path 3687"
        d="M132.354,26.555a5.75,5.75,0,0,1-5.75-5.75V16.2a4.711,4.711,0,0,0-4.7-4.7h-4.6a5.75,5.75,0,0,1,0-11.5h4.6a16.222,16.222,0,0,1,16.2,16.2v4.6a5.75,5.75,0,0,1-5.75,5.75"
        transform="translate(54.058 0)"
        fill="#dce0e9"
      />
      <path
        id="Path_3688"
        data-name="Path 3688"
        d="M122.464,138.668h-4.6a5.75,5.75,0,0,1,0-11.5h4.6a4.712,4.712,0,0,0,4.7-4.7v-4.6a5.749,5.749,0,1,1,11.5,0v4.6a16.222,16.222,0,0,1-16.2,16.2"
        transform="translate(54.332 54.332)"
        fill="#dce0e9"
      />
      <path
        id="Path_3689"
        data-name="Path 3689"
        d="M122.464,138.668h-4.6a5.75,5.75,0,0,1,0-11.5h4.6a4.712,4.712,0,0,0,4.7-4.7v-4.6a5.749,5.749,0,1,1,11.5,0v4.6a16.222,16.222,0,0,1-16.2,16.2"
        transform="translate(54.332 54.332)"
        fill="#dce0e9"
      />
      <path
        id="Path_3690"
        data-name="Path 3690"
        d="M5.75,26.555A5.75,5.75,0,0,1,0,20.805V16.2A16.222,16.222,0,0,1,16.2,0h4.6a5.75,5.75,0,0,1,0,11.5H16.2a4.711,4.711,0,0,0-4.7,4.7v4.6a5.75,5.75,0,0,1-5.75,5.75"
        transform="translate(0)"
        fill="#dce0e9"
      />
      <path
        id="Path_3691"
        data-name="Path 3691"
        d="M20.805,138.105H16.2A16.224,16.224,0,0,1,0,121.9v-4.6a5.75,5.75,0,0,1,11.5,0v4.6a4.711,4.711,0,0,0,4.7,4.7h4.6a5.75,5.75,0,0,1,0,11.5"
        transform="translate(0 54.059)"
        fill="#dce0e9"
      />
      <path
        id="Path_3692"
        data-name="Path 3692"
        d="M66.283,112.6A35.9,35.9,0,0,1,30.422,76.736a5.751,5.751,0,0,1,11.5,0A24.387,24.387,0,0,0,66.283,101.1a5.75,5.75,0,1,1,0,11.5"
        transform="translate(14.743 34.401)"
        fill="#dce0e9"
      />
      <path
        id="Path_3693"
        data-name="Path 3693"
        d="M83.567,100.785H66.595a5.75,5.75,0,1,1,0-11.5h11.05C76.03,62.043,63.63,39.187,63.5,38.944A5.75,5.75,0,1,1,73.574,33.4c.643,1.17,15.743,29.012,15.743,61.632a5.75,5.75,0,0,1-5.75,5.75"
        transform="translate(29.486 14.743)"
        fill="#dce0e9"
      />
      <path
        id="Path_3694"
        data-name="Path 3694"
        d="M66.283,112.6A35.9,35.9,0,0,1,30.422,76.736a5.751,5.751,0,0,1,11.5,0A24.387,24.387,0,0,0,66.283,101.1a5.75,5.75,0,1,1,0,11.5"
        transform="translate(14.743 34.401)"
        fill="#dce0e9"
      />
      <path
        id="Path_3695"
        data-name="Path 3695"
        d="M83.567,100.785H66.595a5.75,5.75,0,1,1,0-11.5h11.05C76.03,62.043,63.63,39.187,63.5,38.944A5.75,5.75,0,1,1,73.574,33.4c.643,1.17,15.743,29.012,15.743,61.632a5.75,5.75,0,0,1-5.75,5.75"
        transform="translate(29.486 14.743)"
        fill="#dce0e9"
      />
      <path
        id="Path_3696"
        data-name="Path 3696"
        d="M36.173,46.915a5.75,5.75,0,0,1-5.75-5.75V33.637a5.75,5.75,0,1,1,11.5,0v7.528a5.75,5.75,0,0,1-5.75,5.75"
        transform="translate(14.743 13.514)"
        fill="#dce0e9"
      />
      <path
        id="Path_3697"
        data-name="Path 3697"
        d="M36.173,46.915a5.75,5.75,0,0,1-5.75-5.75V33.637a5.75,5.75,0,1,1,11.5,0v7.528a5.75,5.75,0,0,1-5.75,5.75"
        transform="translate(14.743 13.514)"
        fill="#dce0e9"
      />
      <path
        id="Path_3698"
        data-name="Path 3698"
        d="M97.018,46.915a5.75,5.75,0,0,1-5.75-5.75V33.637a5.75,5.75,0,1,1,11.5,0v7.528a5.75,5.75,0,0,1-5.75,5.75"
        transform="translate(44.23 13.514)"
        fill="#dce0e9"
      />
      <path
        id="Path_3699"
        data-name="Path 3699"
        d="M97.018,46.915a5.75,5.75,0,0,1-5.75-5.75V33.637a5.75,5.75,0,1,1,11.5,0v7.528a5.75,5.75,0,0,1-5.75,5.75"
        transform="translate(44.23 13.514)"
        fill="#dce0e9"
      />
    </g>
  </svg>
);

export const SearchBarWrapper = styled.div`
  // position: absolute;
  // top: 139px;
  // left: 135px;
  height: 28px;
  margin-left: 11px;
  width: 275px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 2.5px;
  background: ${({theme}) => theme.colors.bgWhite};
  border: ${({theme}) => `1px solid ${theme.colors.fontBlackOp50}`};
  border-radius: 9px;

  & > input {
    width: 324px;
    border: none;
    background: transparent;
    outline: none;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    top: 106px;
    left: 21px;
    width: 100%;
    margin-left: 7px;

    & > input {
      width: 204px;
    }
  }
`;

export const SearchButton = styled.div`
  width: 28px;
  height: 23px;
  border-radius: 7px;
  background: ${({theme}) => theme.colors.blueTheme};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 11px;
  letter-spacing: 0.05px;
  font-weight: 400;
  color: rgba(23, 22, 29, 0.5);

  &::placeholder {
    color: rgba(23, 22, 29, 0.5);
  }

  & > svg {
    margin-left: 2px;
  }
`;

export const AddButton = styled.button`
  width: 44px;
  height: 44px;
  position: absolute;
  right: 7px;
  border: ${({theme}) => `1px solid ${theme.colors.blueTheme}`};
  background: transparent;
  border-radius: 9px;
  cursor: pointer;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.625px;
  color: #352f40;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.15s;
  & > svg {
    & > g {
      & > path {
        stroke: ${({theme}) => theme.colors.blueTheme};
      }
    }
  }

  &:hover {
    background: rgba(248, 248, 248, 0.15);
  }
`;

export const CancelMessage = styled.button`
  width: 44px;
  height: 44px;
  position: absolute;
  left: 7px;
  border: none;
  background: transparent;
  border-radius: 9px;
  cursor: pointer;
  font-weight: 400;
  font-size: 11px;
  border: ${({theme}) => `1px solid ${theme.colors.blueTheme}`};
  letter-spacing: 0.625px;
  color: ${({theme}) => theme.colors.fontBlackOp100};
  transition: all 0.15s;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: rgba(248, 248, 248, 0.15);
  }
  & > svg {
    & > path {
      fill: ${({theme}) => theme.colors.blueTheme};
    }
  }
`;

interface KnowsUser {
  Name: string;
  UserId: string;
  PhotoFilePath: string;
}

interface ProducerData {
  CardId: string;
  ProducerFirstName: string;
  ProducerId: string;
  ProducerLastName: string;
  ProducerPhoto: string;
}

interface DataFromOffer {
  producerIdFromOffer: string;
  producerNameFromOffer: string;
  productNameFromOffer: string;
  productIdFromOffer: string;
}

interface LocationState {
  state: null | DataFromOffer;
}

export const SupportMessage: React.FC = () => {
  const [cookies, setCookie] = useCookies(['token']);

  const {decodedToken} = useContext(ProducerDataContext);
  const [statistics, setStatistics] = useContext(StatisticsContext);
  const [userData, setUserData] = useContext(UserDataContext);
  const [producerData, setProducerData] = useContext(AccountProducerContext);

  if (!decodedToken) {
    return <Navigate to="/identity/login" />;
  }

  // if (decodedToken.ProducerId === '00000000-0000-0000-0000-000000000000') {
  //   return <Navigate to="/producer" />;
  // }

  if (userData.FirstName === null) {
    window.location.href = '/account/settings';
  }

  const [productId, setProductId] = useState<string>('');

  const [isReceivedMessagesLoading, setIsReceivedMessagesLoading] =
    useState<boolean>(false);
  const [isSentMessagesLoading, setIsSentMessagesLoading] =
    useState<boolean>(false);
  const [isMessageSending, setIsMessageSending] = useState<boolean>(false);

  const [topic, setTopic] = useState<string>('');
  const [thread, setThread] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [target, setTarget] = useState<number | string>(0);
  const [receiver, setReceiver] = useState<string>('');
  const [receiverId, setReceiverId] = useState<string>('');

  useState<number>(0);

  const [selectedMessageDetails, setSelectedMessageDetails] = useState<Message>(
    {
      Thread: null,
      Content: '',
      Id: '',
      RecieverId: '',
      RecieverName: '',
      SenderId: '',
      SenderName: '',
      SenderStatus: {
        FieldName: '',
        FlagValue: 0,
      },
      RecieverStatus: {
        FieldName: '',
        FlagValue: 0,
      },
      Topic: '',
      IsCurrentUserSender: false,
    },
  );

  const [isFocused, toggleFocused] = useState<boolean>(false);
  const [isWriteMode, setIsWriteMode] = useState<boolean>(false);

  const [isThreadViewAActiveMobile, setIsThreadViewAActiveMobile] =
    useState(false);

  const [isMobileMessageViewOpen, setIsMobileMessageViewOpen] =
    useState<boolean>(false);
  const [isMobileNewMessageViewOpen, setIsMobileNewMessageViewOpen] =
    useState<boolean>(false);
  const [knowsUsers, setKnowsUsers] = useState<KnowsUser[]>([
    {Name: 'Damian', UserId: '1', PhotoFilePath: 'dad'},
    {Name: 'Aleksandara', UserId: '2', PhotoFilePath: 'dad'},
    {Name: 'Zosia', UserId: '3', PhotoFilePath: 'dad'},
  ]);
  const mobileMessageViewWindowRef = useRef<HTMLDivElement | null>(null);
  const mobileNewMessageViewWindowRef = useRef<HTMLDivElement | null>(null);
  const mobileThreadViewWindowRef = useRef<HTMLDivElement | null>(null);
  const {setIsBodyOverflowBlocked} = useIsBodyOverflowBlockedContext();

  const {Alert} = useAlert();

  const handleIsWriteMode = (active: boolean) => setIsWriteMode(active);

  const location = useLocation();
  const {state} = location as LocationState;

  const navigate = useNavigate();

  const threadRef = useRef(null);

  const clearFormData = () => {
    // setTarget('');
    setReceiver('');
    setReceiverId('');
    setTopic('');
    setContent('');
  };

  const clearSelectedMessage = () => {
    if (!selectedMessageDetails.Id) return;

    setSelectedMessageDetails({
      Thread: null,
      Content: '',
      Id: '',
      RecieverId: '',
      RecieverName: '',
      SenderId: '',
      SenderName: '',
      SenderStatus: {
        FieldName: '',
        FlagValue: 0,
      },
      RecieverStatus: {
        FieldName: '',
        FlagValue: 0,
      },
      Topic: '',
      IsCurrentUserSender: false,
    });
    setThread('');
  };

  const handleOnFocusChange = (value: boolean) => toggleFocused(value);

  const onBlur = (e: any) => {
    if (!e.currentTarget.contains(e.relatedTarget)) return toggleFocused(false);
    return toggleFocused(true);
  };

  const handleReceiverIdChange = (value: string) => setReceiverId(value);

  const handleTopicChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setTopic(e.target.value);

  const handleReceiverChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // console.log('producerIdFromOffer podczas zmiany oferty', state);
    if (state !== null) return;
    setReceiver(e.target.value);
    setReceiverId('');
  };

  const handleContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setContent(e.target.value);

  const getProducerData = async (producerId: string) => {
    // console.log('producer');
    if (producerData.Id.length > 1) return console.log('mam już producera');
    if (decodedToken.ProducerId === '00000000-0000-0000-0000-000000000000') {
      return;
    }

    try {
      const response = await api.get(`Producer/${producerId}`, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      setProducerData(response.data.Response);
    } catch (error) {
      console.log(error);
    }
  };

  const getStatistics = async () => {
    try {
      const response = await api.get(`Statistics`, {
        headers: {
          Authorization: `Bearer ${cookies.token}`,
        },
      });
      // console.log(response.data);
      setStatistics(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getKnowsUsers = async () => {
    try {
      const response = await api.get(`message/knownusers`, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });

      setKnowsUsers(
        response.data.Response.filter(
          (user) => user.UserId !== userData.UserId,
        ),
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getSupport = async () => {
    try {
      const response = await api.get(`Producer/support`, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      // console.log('get knowsUers', response.data.Response);
      // console.log(
      //   userData.UserId,
      //   'know user aftrer filter',
      //   response.data.Response.filter(
      //     (user) => user.UserId !== userData.UserId,
      //   ),
      // );
      // console.log(response.data.Response);
      if (response.data.Response.length > 0) {
        setReceiver(response.data.Response[0].Name);
        setReceiverId(response.data.Response[0].Id);
        setIsWriteMode(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sendMessage = async (isNewMessage: boolean) => {
    if (!topic || !content)
      return Alert('Uzupełnij wszystkie pola', 'Komunikat');
    if (target === 0 && !receiverId)
      return Alert(
        'Zaznacz twórcę, do którego chcesz wysłać wiadomość.',
        'Komunikat',
      );
    if (isMessageSending) return;
    setIsMessageSending(true);
    try {
      const body = {
        Thread: thread,
        Topic: topic,
        Content: content,
        ProductId: productId,
        ReceiverGuid: receiverId,
      };
      const response = await api.post(`Message`, body, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      window.history.replaceState({}, document.title);
      navigate('/messages');
      Alert('Nowa Wiadomość została wysłana', 'Komunikat', 'Zamknij');
    } catch (error) {
      console.error(error);
    } finally {
      setIsMessageSending(false);
    }
  };

  useEffect(() => {
    if (userData.UserId) {
      getKnowsUsers();
    }
  }, [userData]);

  useEffect(() => {
    if (producerData.Id.length < 1) {
      getProducerData(decodedToken.ProducerId);
    }
  }, [producerData.Id]);

  useEffect(() => {
    if (state) {
      const {
        productIdFromOffer,
        producerIdFromOffer,
        producerNameFromOffer,
        productNameFromOffer,
      } = state;
      if (producerIdFromOffer === 'support') {
        getSupport();
        return;
      }
      setReceiver(producerNameFromOffer);
      setReceiverId(producerIdFromOffer);
      setTopic(productNameFromOffer);
      setProductId(productIdFromOffer);
      setIsWriteMode(true);
    }
  }, []);

  useEffect(() => {
    const checkCloseButtonVisibility = () => {
      if (mobileMessageViewWindowRef.current) {
        if (isMobileMessageViewOpen) {
          mobileMessageViewWindowRef.current.style.height = `${window.visualViewport.height}px`;
        } else {
          mobileMessageViewWindowRef.current.style.height = `${0}px`;
        }
      }
    };

    checkCloseButtonVisibility();

    window.addEventListener('resize', checkCloseButtonVisibility);

    return () => {
      window.removeEventListener('resize', checkCloseButtonVisibility);
    };
  }, [isMobileMessageViewOpen]);

  useEffect(() => {
    const checkCloseButtonVisibility = () => {
      if (mobileNewMessageViewWindowRef.current) {
        if (isMobileNewMessageViewOpen) {
          mobileNewMessageViewWindowRef.current.style.height = `${window.visualViewport.height}px`;
        } else {
          mobileNewMessageViewWindowRef.current.style.height = `${0}px`;
        }
      }
    };

    checkCloseButtonVisibility();

    window.addEventListener('resize', checkCloseButtonVisibility);

    return () => {
      window.removeEventListener('resize', checkCloseButtonVisibility);
    };
  }, [isMobileNewMessageViewOpen]);

  useEffect(() => {
    const checkCloseButtonVisibility = () => {
      if (mobileThreadViewWindowRef.current) {
        if (isThreadViewAActiveMobile) {
          mobileThreadViewWindowRef.current.style.height = `${window.visualViewport.height}px`;
        } else {
          mobileThreadViewWindowRef.current.style.height = `${0}px`;
        }
      }
    };

    checkCloseButtonVisibility();

    window.addEventListener('resize', checkCloseButtonVisibility);

    return () => {
      window.removeEventListener('resize', checkCloseButtonVisibility);
    };
  }, [isThreadViewAActiveMobile]);

  useEffect(() => {
    document.title = `MUGO Studio - Support`;
    document
      .querySelector("meta[property='og:title']")
      ?.setAttribute('content', `MUGO Studio - Support`);
    document
      .querySelector("meta[property='og:description']")
      ?.setAttribute(
        'content',
        'MUGO Studio to przestrzeń dla twórców, freelancerów, artystów i ludzi poszukujących usług oraz produktów oferowanych przez te osoby. To platforma stworzona dla usługodawców i usługobiorców, gwarantująca bezpieczeństwo transakcji oraz stwarzająca możliwość współpracy na innowacyjnych warunkach.',
      );
  }, []);

  return (
    <>
      <MainWrapper>
        <Main>
          {(isReceivedMessagesLoading ||
            isSentMessagesLoading ||
            isMessageSending) && <LoadingCircle />}
          <HeaderWrapper>
            <CancelMessage onClick={() => navigate(-1)}>
              <svg
                id="Arrow_Top_Bar_Accent"
                data-name="Arrow – Top Bar / Accent"
                xmlns="http://www.w3.org/2000/svg"
                width="8.195"
                height="14"
                viewBox="0 0 8.195 14"
              >
                <path
                  id="Arrow"
                  d="M6.513,13.715.2,7.475a.666.666,0,0,1,0-.95L6.513.285a.994.994,0,0,1,1.394,0,.967.967,0,0,1,0,1.377L2.506,7l5.4,5.337a.967.967,0,0,1,0,1.377.994.994,0,0,1-1.394,0"
                  transform="translate(0 0)"
                  fill="#fff"
                />
              </svg>
            </CancelMessage>
            Nowa wiadomość
            <AddButton
              onClick={() => {
                sendMessage(true);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20.414"
                height="20.414"
                viewBox="0 0 20.414 20.414"
              >
                <g id="wyslij_wiadomosc" transform="translate(-2 -1.586)">
                  <path
                    id="Path_3733"
                    data-name="Path 3733"
                    d="M26.4,3l-9.9,9.9"
                    transform="translate(-5.4 0)"
                    fill="none"
                    stroke="#f8f8f8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    id="Path_3734"
                    data-name="Path 3734"
                    d="M21,3,14.7,21l-3.6-8.1L3,9.3Z"
                    transform="translate(0 0)"
                    fill="none"
                    stroke="#f8f8f8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </g>
              </svg>
            </AddButton>
          </HeaderWrapper>
          <Flex>
            <LeftSection isWriteMode={isWriteMode}></LeftSection>
            <RightSection isWriteMode={isWriteMode}>
              <>
                <RightSectionHeaderWrapper isWriteMode={isWriteMode}>
                  <RightSectionIconsWrapper>
                    <div>
                      {!isWriteMode && (
                        <RightSectionSingleIcon>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="19.429"
                            height="17"
                            viewBox="0 0 19.429 17"
                            onClick={() => {
                              handleIsWriteMode(true);
                            }}
                          >
                            <path
                              id="Icon_awesome-reply"
                              data-name="Icon awesome-reply"
                              d="M.315,8.239,6.994,2.472A.911.911,0,0,1,8.5,3.161V6.2c6.1.07,10.929,1.291,10.929,7.068a7.757,7.757,0,0,1-3.162,5.849.676.676,0,0,1-1.065-.707c1.721-5.5-.816-6.964-6.7-7.048V14.7a.911.911,0,0,1-1.506.689L.315,9.618a.911.911,0,0,1,0-1.379Z"
                              transform="translate(0 -2.25)"
                              fill="#3986EF"
                            />
                          </svg>
                        </RightSectionSingleIcon>
                      )}
                      {!isWriteMode ? (
                        <RightSectionSingleIcon>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16.3"
                            height="16.5"
                            viewBox="0 0 12.3 13.5"
                          >
                            <g
                              id="Icon_feather-trash"
                              data-name="Icon feather-trash"
                              transform="translate(0.75 0.75)"
                            >
                              <path
                                id="Path_3663"
                                data-name="Path 3663"
                                d="M4.5,9H15.3"
                                transform="translate(-4.5 -6.6)"
                                fill="none"
                                stroke="#3986EF"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                              <path
                                id="Path_3664"
                                data-name="Path 3664"
                                d="M15.9,5.4v8.4A1.2,1.2,0,0,1,14.7,15h-6a1.2,1.2,0,0,1-1.2-1.2V5.4m1.8,0V4.2A1.2,1.2,0,0,1,10.5,3h2.4a1.2,1.2,0,0,1,1.2,1.2V5.4"
                                transform="translate(-6.3 -3)"
                                fill="none"
                                stroke="#3986EF"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                            </g>
                          </svg>
                        </RightSectionSingleIcon>
                      ) : (
                        <RightSectionSingleIcon>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16.3"
                            height="16.5"
                            viewBox="0 0 12.3 13.5"
                            onClick={() => {
                              clearFormData();
                            }}
                          >
                            <g
                              id="Icon_feather-trash"
                              data-name="Icon feather-trash"
                              transform="translate(0.75 0.75)"
                            >
                              <path
                                id="Path_3663"
                                data-name="Path 3663"
                                d="M4.5,9H15.3"
                                transform="translate(-4.5 -6.6)"
                                fill="none"
                                stroke="#3986EF"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                              <path
                                id="Path_3664"
                                data-name="Path 3664"
                                d="M15.9,5.4v8.4A1.2,1.2,0,0,1,14.7,15h-6a1.2,1.2,0,0,1-1.2-1.2V5.4m1.8,0V4.2A1.2,1.2,0,0,1,10.5,3h2.4a1.2,1.2,0,0,1,1.2,1.2V5.4"
                                transform="translate(-6.3 -3)"
                                fill="none"
                                stroke="#3986EF"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                            </g>
                          </svg>
                        </RightSectionSingleIcon>
                      )}
                    </div>
                  </RightSectionIconsWrapper>
                  {!isWriteMode ? (
                    <RightSectionAddressAndTitleWrapper>
                      <p>Nadawca</p>
                      <span>{selectedMessageDetails.SenderName}</span>
                    </RightSectionAddressAndTitleWrapper>
                  ) : (
                    <InputWithSearchResultsWrapper
                      onBlur={onBlur}
                      style={{marginBottom: '10px'}}
                    >
                      <RightSectionAddressAndTitleWriteMode
                        style={
                          receiverId !== ''
                            ? {
                                borderColor: '#0091FF',
                                background: 'rgba(0,0,0,0.15)',
                              }
                            : null
                        }
                      >
                        <p>Odbiorca</p>
                        <input
                          type="text"
                          placeholder="Pole obowiązkowe"
                          value={receiver}
                          disabled={state !== null}
                          onFocus={() => handleOnFocusChange(true)}
                          onChange={handleReceiverChange}
                        />
                      </RightSectionAddressAndTitleWriteMode>
                      <SearchResultWrapper
                        isFocused={isFocused}
                        autoCompleted={receiver.length > 1}
                        tabIndex={0}
                      >
                        {knowsUsers.filter((user) =>
                          user.Name.toLowerCase().includes(
                            receiver.toLowerCase(),
                          ),
                        ).length === 0 &&
                          receiver.length > 1 && (
                            <p style={{color: '#707070'}}>Brak wyników</p>
                          )}

                        {knowsUsers
                          .filter((user) =>
                            user.Name.toLowerCase().includes(
                              receiver.toLowerCase(),
                            ),
                          )
                          .map((user) => {
                            const {UserId, Name, PhotoFilePath} = user;

                            return (
                              <SingleSearchResultWrapper
                                key={UserId}
                                onClick={() => {
                                  handleReceiverIdChange(UserId);
                                  setReceiver(`${Name} `);
                                  toggleFocused(false);
                                }}
                              >
                                <p>{`${Name} `}</p>
                              </SingleSearchResultWrapper>
                            );
                          })}
                      </SearchResultWrapper>
                    </InputWithSearchResultsWrapper>
                  )}
                  {!isWriteMode ? (
                    <RightSectionAddressAndTitleWrapper>
                      <p>Temat</p>
                      <span>{selectedMessageDetails.Topic}</span>
                    </RightSectionAddressAndTitleWrapper>
                  ) : (
                    <RightSectionAddressAndTitleWriteMode>
                      <p>Temat</p>
                      <input
                        type="text"
                        placeholder="Pole obowiązkowe"
                        value={topic}
                        onChange={handleTopicChange}
                      />
                    </RightSectionAddressAndTitleWriteMode>
                  )}
                </RightSectionHeaderWrapper>
                <RightSectionMessageContent isWriteMode={isWriteMode}>
                  {/* <ContentTitle>Treść</ContentTitle> */}
                  {!isWriteMode ? (
                    <p>{selectedMessageDetails.Content}</p>
                  ) : (
                    <textarea
                      placeholder="Wprowadź tekst..."
                      value={content}
                      onChange={handleContentChange}
                    />
                  )}
                </RightSectionMessageContent>{' '}
              </>
            </RightSection>
          </Flex>
        </Main>
      </MainWrapper>
    </>
  );
};
