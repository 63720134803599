import React, {useState, useEffect, useContext, useRef} from 'react';
import {useCookies} from 'react-cookie';
import {useNavigate, Navigate} from 'react-router-dom';
import useAlert from 'hooks/useAlert';

import openEye from 'images/svg/openEye.svg';
import closeEye from 'images/svg/closeEye.svg';

import defaultAvatar from 'images/profilowe z tłem.png';

import api from 'services/axiosConfig';
import {ProducerDataContext} from 'contexts/ProducerDataContext';
import {UserDataContext} from 'contexts/UserDataContext';
import {LanguageContext} from 'contexts/LanguageContext';

import {
  EmailandPhoneNumberButton,
  NewStyleCenterSection,
  NewStyleCenterSectionInputsWrappers,
  NewStyleCenterSectionTextandButtonWrapper,
  NewStyleCheckBox,
  NewStyleCheckBoxWrapper,
  NewStyleGrid,
  NewStyleHeaderWrapper,
  NewStyleImgButtonWrapper,
  NewStyleLeftSection,
  NewStyleLeftSectionDateWrapper,
  NewStyleLeftSectionImgButtonWrapper,
  NewStyleLeftSectionImgWrapper,
  NewStyleMain,
  NewStyleMainWrapper,
  NewStyleRightSection,
  NewStyleRightSectionCheckBoxWithText,
  NewStyleRightSectionHeader,
  InputView,
} from './styles';

import {ErrorStar} from '../../../components/ErrorStar';
import styled from 'styled-components';
import {PhoneCodeSelect} from '../../../components/Select/PhoneCodeSelect';
// import {light} from '../../../constants/theme';
import {light, dark} from '../../../constants/theme';
import {HoverTextForEdit} from '../../MyStudio/Account/styles';
import {ResolutionContext} from '../../../contexts/ResolutionContext';
import {EditBtnWrapper, EditSvgWrapper} from 'constants/globalElements';

export const AccountBigBlueBtn = styled.button`
  width: 312px;
  height: 44px;
  font-size: 15px;
  cursor: pointer;
  font-weight: 400;
  letter-spacing: 0.7px;
  border: none;
  color: white;
  border-radius: 15px;
  //background: #3986ef;
  background: #89bfff;
  transition: all 0.15s;
  &:hover {
    filter: brightness(1.1);
  }
  &:active {
    filter: brightness(0.9);
  }
  & > a {
    text-decoration: none;
    color: white;
  }
  @media (max-width: 1024px) {
    width: 358px;
    margin-bottom: 10px;
  }
  @media (max-width: 400px) {
    width: 311px;
  }
  @media (max-width: 340px) {
    width: 90%;
  }
`;

const IconShowPasswordWrapper = styled.div`
  position: absolute;
  top: 1px;
  right: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  & > svg {
    overflow: visible;
    width: 100%;
    height: 100%;
  }
  & > img {
    cursor: pointer;
  }
  @media (max-width: 1024px) {
    top: 2px;

    & > img {
      width: 19px;
      height: 19px;
    }
  }
`;

const dataTypeArr = [
  {text: '+48', value: 0},
  {text: '+49', value: 1},
  {text: '+50', value: 2},
  {text: '+59', value: 3},
];
const dataTypeObj = {
  0: {text: '+48', value: 0},
  1: {text: '+49', value: 1},
  2: {text: '+50', value: 2},
  3: {text: '+59', value: 3},
};

interface GetUserResponse {
  FirstName: string;
  LastName: string;
  ImagePath: string;
  Language: string;
  Email: string;
  PhoneNumber: string;
  PhoneNumberCode: string;
  UserId: string;
  UserName: string;
  StudioAllowNotifications: boolean;
  TwoStepVerificationEnabled: boolean;
  StudioAcceptedMarketing: boolean;
  StudioAcceptedPrivacyPolicy: boolean;
  StudioAcceptedRequlations: boolean;
}

interface Language {
  name: string;
  value: string;
}

interface UserPasswords {
  currentPassword: string;
  newPassword: string;
  replayPassword: string;
}

interface Props {
  handleThemeChange: (theme) => void;
}

const SettingsComponent: React.FC<Props> = ({handleThemeChange}) => {
  const [cookies, setCookie] = useCookies(['token']);
  const {Alert} = useAlert();

  const {decodedToken} = useContext(ProducerDataContext);
  const [userData, setUserData] = useContext(UserDataContext);
  const [language, setLanguage] = useContext(LanguageContext);
  const navigate = useNavigate();

  if (!decodedToken) {
    return <Navigate to="/identity/login" />;
  }

  const [userPasswords, setUserPasswords] = useState<UserPasswords>({
    currentPassword: '',
    newPassword: '',
    replayPassword: '',
  });

  const [isEditingUserData, setIsEditingUserData] = useState<boolean>(false);
  const [isHoveredForEditUserDataBtn, setIsHoveredForEditUserDataBtn] =
    useState<boolean>(false);

  const [currentUserData, setCurrentUserData] = useState<GetUserResponse>({
    FirstName: '',
    ImagePath: '',
    Language: '',
    LastName: '',
    Email: '',
    PhoneNumber: '',
    UserId: '',
    UserName: '',
    PhoneNumberCode: '',
    StudioAllowNotifications: false,
    TwoStepVerificationEnabled: false,
    StudioAcceptedMarketing: false,
    StudioAcceptedPrivacyPolicy: false,
    StudioAcceptedRequlations: false,
  });

  const [isMarketingRulesActive, setIsMarketingRulesActive] = useState(true);
  const [isDataProcessingActive, setIsDataProcessingActive] = useState(true);

  const [phoneNumberCode, setPhoneNumberCode] = useState({
    text: userData.PhoneNumberCode === null ? '+48' : userData.PhoneNumberCode,
    value: 0,
  });

  const [currentUserErrors, setCurrentUserErrors] = useState({
    FirstName: false,
    ImagePath: false,
    Language: false,
    LastName: false,
    Email: false,
    PhoneNumber: false,
    UserId: false,
    UserName: false,
    StudioAllowNotifications: false,
    ShowErrors: false,
  });
  const [enableTwoStep, setEnableTwoStep] = useState<boolean>(false);

  const [errorVerification, setErrorVerification] = useState({
    currentPassword: false,
    newPassword: false,
    replayPassword: false,
    showErrors: false,
  });

  const [images, setImages] = useState<string | null>(null);
  const [showUpdateButton, setShowUpdateButton] = useState({
    btnEmail: false,
    btnPhone: false,
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showReplayPassword, setShowReplayPassword] = useState(false);

  const [isAfterUpdate, setIsAfterUpdate] = useState(false);
  const [isRuleEditing, setIsRuleEditing] = useState<boolean>(false);
  const [isHoveredForEditRuleBtn, setIsHoveredForEditRuleBtn] =
    useState<boolean>(false);

  const [isGoogleUser, setIsGoogleUser] = useState(true);

  const [languages, setLanguages] = useState<Language[]>([
    {
      name: 'Polski (Polska)',
      value: 'pl-PL',
    },
    {
      name: 'English (United Kingdom)',
      value: 'en-GB',
    },
  ]);
  const {windowWidth} = useContext(ResolutionContext);

  const imgRef = useRef<HTMLInputElement>(document.createElement('input'));

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const getImageFile = async (e: any) => {
    if (e.target.value.length > 0) {
      const fileName = e.target.value;
      const dotIndex = fileName.lastIndexOf('.');
      const extFile = fileName
        .substr(dotIndex + 1, fileName.length)
        .toLowerCase();
      if (extFile === 'jpg' || extFile === 'jpeg' || extFile === 'png') {
        const base64 = await toBase64(e.target.files[0]);
        const body = base64.toString().split(',')[1];

        setCurrentUserData((prev) => ({
          ...prev,
          ImagePath: URL.createObjectURL(e.target.files[0]),
        }));
        setImages(body);
        Alert(`Dodano plik ${e.target.files[0].name}`, 'Komunikat');
      } else {
        Alert('Wybierz zdjęcie w formacie .jpg lub .png', 'Komunikat');
      }
    }
  };

  const toggleMarketingActive = () => {
    setCurrentUserData((prev) => ({
      ...prev,
      StudioAcceptedMarketing: !currentUserData.StudioAcceptedMarketing,
    }));
  };
  const toggleDataProcessingActive = () => {
    setCurrentUserData((prev) => ({
      ...prev,
      StudioAllowNotifications: !currentUserData.StudioAllowNotifications,
    }));
  };

  const handleStudioAllowNotifications = () => {
    setCurrentUserData((prev) => ({
      ...prev,
      StudioAllowNotifications: !currentUserData.StudioAllowNotifications,
    }));
  };

  const handleTwoStepAuth = () => {
    setCurrentUserData((prev) => ({
      ...prev,
      TwoStepVerificationEnabled: !currentUserData.TwoStepVerificationEnabled,
    }));
  };

  const checkUserType = (data) => {
    if (data.UserName.toLowerCase().indexOf('google') !== -1) {
      return true;
    }
    if (data.UserName.toLowerCase().indexOf('facebook') !== -1) {
      return true;
    }
    if (data.UserName.toLowerCase().indexOf('apple') !== -1) {
      return true;
    }
    return false;
  };

  const backToOldDataAfterCancelEdit = () => {
    setCurrentUserData((prevState) => ({
      ...prevState,
      FirstName: userData.FirstName === null ? '' : userData.FirstName,
      ImagePath: userData.ImagePath,
      Language: userData.Language,
      LastName: userData.LastName === null ? '' : userData.LastName,
      Email: userData.Email,
      PhoneNumber: userData.PhoneNumber === null ? '' : userData.PhoneNumber,
      PhoneNumberCode: userData.PhoneNumberCode,
      UserId: userData.UserId,
      UserName: userData.UserName,
      StudioAllowNotifications: userData.StudioAllowNotifications,
      TwoStepVerificationEnabled: userData.TwoStepVerificationEnabled,
    }));
    setPhoneNumberCode({
      text:
        userData.PhoneNumberCode === null ? '+48' : userData.PhoneNumberCode,
      value: 0,
    });
    setIsGoogleUser(checkUserType(userData));
  };

  useEffect(() => {
    setCurrentUserData((prevState) => ({
      ...prevState,
      FirstName: userData.FirstName === null ? '' : userData.FirstName,
      ImagePath: userData.ImagePath,
      Language: userData.Language,
      LastName: userData.LastName === null ? '' : userData.LastName,
      Email: userData.Email,
      PhoneNumber: userData.PhoneNumber === null ? '' : userData.PhoneNumber,
      PhoneNumberCode: userData.PhoneNumberCode,
      UserId: userData.UserId,
      UserName: userData.UserName,
      StudioAllowNotifications: userData.StudioAllowNotifications,
      TwoStepVerificationEnabled: userData.TwoStepVerificationEnabled,
      StudioAcceptedMarketing: userData.StudioAcceptedMarketing,
      StudioAcceptedPrivacyPolicy: userData.StudioAcceptedPrivacyPolicy,
    }));
    setPhoneNumberCode({
      text:
        userData.PhoneNumberCode === null ? '+48' : userData.PhoneNumberCode,
      value: 0,
    });
    setIsGoogleUser(checkUserType(userData));
  }, [userData]);

  const handleUserDataChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    setCurrentUserData((prev) => ({...prev, [e.target.id]: e.target.value}));
    setCurrentUserErrors((prev) => ({...prev, [e.target.id]: false}));
  };

  const handleLanguageChange = (lang: string) => {
    setLanguage(lang);
  };

  const handlePhoneNumberCode = (value) => {
    setPhoneNumberCode(dataTypeObj[value]);
  };

  const handleUserEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowUpdateButton((prevState) => ({...prevState, btnEmail: true}));
    setCurrentUserData((prevState) => ({...prevState, Email: e.target.value}));
    setCurrentUserErrors((prev) => ({...prev, [e.target.id]: false}));
  };

  const handleUserPhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowUpdateButton((prevState) => ({...prevState, btnPhone: true}));
    setCurrentUserData((prevState) => ({
      ...prevState,
      PhoneNumber: e.target.value
        .replace(/[^0-9]/g, '')
        .replace(/\s/g, '')
        .replace(/(\d{3})/g, '$1 ')
        .trim(),
    }));
    setCurrentUserErrors((prev) => ({...prev, [e.target.id]: false}));
  };

  useEffect(() => {
    if (currentUserData.Language === 'pl-PL') return handleLanguageChange('pl');
    if (currentUserData.Language === 'en-GB') return handleLanguageChange('en');
  }, [currentUserData]);

  console.log(currentUserData);

  useEffect(() => {
    if (currentUserData.FirstName === null) {
      setIsEditingUserData(true);
      return Alert('Proszę uzupełnić podstawowe dane', 'Komunikat');
    }
  }, []);

  const handleUserPasswordsChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setUserPasswords((prev) => ({...prev, [e.target.id]: e.target.value}));
    setErrorVerification((prev) => ({...prev, [e.target.id]: false}));
  };

  const validatePassword = () => {
    const {currentPassword, newPassword, replayPassword} = userPasswords;

    const validate = {
      currentPassword: false,
      newPassword: false,
      replayPassword: false,
    };

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (currentPassword.length === 0) {
      setErrorVerification((prev) => ({
        ...prev,
        currentPassword: true,
        showErrors: true,
      }));
      validate.currentPassword = true;
    }

    if (newPassword.length === 0) {
      setErrorVerification((prev) => ({
        ...prev,
        newPassword: true,
        showErrors: true,
      }));
      validate.newPassword = true;
    }

    if (!passwordRegex.test(newPassword)) {
      setErrorVerification((prev) => ({
        ...prev,
        newPassword: true,
        showErrors: true,
      }));
      validate.newPassword = true;
    }

    if (newPassword !== replayPassword) {
      setErrorVerification((prev) => ({
        ...prev,
        replayPassword: true,
        showErrors: true,
      }));
      validate.replayPassword = true;
    }

    if (currentPassword === newPassword) {
      setErrorVerification((prev) => ({
        ...prev,
        newPassword: true,
        showErrors: true,
      }));
      validate.newPassword = true;
    }

    console.log(validate, 'validacja haslo');

    if (
      !validate.replayPassword &&
      !validate.newPassword &&
      !validate.currentPassword
    ) {
      setErrorVerification({
        currentPassword: false,
        newPassword: false,
        replayPassword: false,
        showErrors: false,
      });
      return true;
    } else {
      setErrorVerification((prevState) => ({...prevState, showErrors: true}));
      return false;
    }
  };

  const changePassword = async () => {
    const {currentPassword, newPassword, replayPassword} = userPasswords;
    setIsAfterUpdate(true);

    if (!validatePassword()) return;

    try {
      const body = {
        Email: currentUserData.Email,
        CurrentPassword: currentPassword,
        NewPassword: newPassword,
      };

      const response = await api.put(`Account/UpdatePassword`, body, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      await updateUserData();
    } catch (error) {
      console.error(error);
      setErrorVerification((prev) => ({
        ...prev,
        currentPassword: true,
      }));
      return false;
      // Alert(error.response.data.Error.Message, 'Błąd');
    }
  };

  const validateData = () => {
    const onlyLettersWithPolishCharactersRegex =
      /^[A-Za-zA-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]+$/;

    const validateData = {
      FirstName: false,
      LastName: false,
      PhoneNumber: false,
    };

    if (currentUserData.FirstName.length === 0) {
      setCurrentUserErrors((prevState) => ({...prevState, FirstName: true}));
      validateData.FirstName = true;
    }
    if (currentUserData.FirstName === null) {
      setCurrentUserErrors((prevState) => ({...prevState, FirstName: true}));
      validateData.FirstName = true;
    }
    if (!onlyLettersWithPolishCharactersRegex.test(currentUserData.FirstName)) {
      setCurrentUserErrors((prevState) => ({...prevState, FirstName: true}));
      validateData.FirstName = true;
    }
    if (currentUserData.FirstName.length > 63) {
      setCurrentUserErrors((prevState) => ({...prevState, FirstName: true}));
      validateData.FirstName = true;
    }

    if (currentUserData.LastName.length === 0) {
      setCurrentUserErrors((prevState) => ({...prevState, LastName: true}));
      validateData.LastName = true;
    }
    if (currentUserData.LastName === null) {
      setCurrentUserErrors((prevState) => ({...prevState, LastName: true}));
      validateData.LastName = true;
    }
    if (!onlyLettersWithPolishCharactersRegex.test(currentUserData.LastName)) {
      setCurrentUserErrors((prevState) => ({...prevState, LastName: true}));
      validateData.LastName = true;
    }
    if (currentUserData.LastName.length > 63) {
      setCurrentUserErrors((prevState) => ({...prevState, LastName: true}));
      validateData.LastName = true;
    }

    if (currentUserData.PhoneNumber === null) {
      setCurrentUserErrors((prevState) => ({...prevState, PhoneNumber: true}));
      validateData.PhoneNumber = true;
    }

    if (
      !validateData.FirstName &&
      !validateData.LastName &&
      !validateData.PhoneNumber
    ) {
      setCurrentUserErrors({
        FirstName: false,
        ImagePath: false,
        Language: false,
        LastName: false,
        Email: false,
        PhoneNumber: false,
        UserId: false,
        UserName: false,
        StudioAllowNotifications: false,
        ShowErrors: false,
      });
      return true;
    } else {
      setCurrentUserErrors((prevState) => ({...prevState, ShowErrors: true}));
    }
  };

  const updateUserData = async () => {
    if (!validateData()) return Alert(`Błędne dane`, 'Komunikat');
    if (currentUserData.PhoneNumber.length > 0) {
      if (!validatePhoneNumber()) return Alert(`Błędne dane`, 'Komunikat');
    }
    try {
      const body = {
        FirstName: currentUserData.FirstName,
        LastName: currentUserData.LastName,
        Language:
          currentUserData.Language === null
            ? 'pl-PL'
            : currentUserData.Language,
        ImageShouldBeDeleted: currentUserData.ImagePath === null,
        Image: images,
        PhoneNumber: currentUserData.PhoneNumber,
        PhoneNumberCode: phoneNumberCode.text,
        StudioAllowNotifications: currentUserData.StudioAllowNotifications,
        TwoStepVerificationEnabled: currentUserData.TwoStepVerificationEnabled,
      };
      console.log(body);
      const response = await api.put(`Account/UpdateUser`, body, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      setIsEditingUserData(false);

      const productType = localStorage.getItem('productType');
      const productId = localStorage.getItem('productId');

      localStorage.removeItem('productId');
      localStorage.removeItem('productType');

      if (productType && productId) {
        Alert('Udało się zakutalizować dane', 'Komunikat', 'Zamknij');
        if (productType === '8') {
          navigate(`/tracks/main/${productId}`);
          navigate(0);
        } else {
          navigate(`/products/main/${productId}`);
          navigate(0);
        }
      } else {
        Alert('Udało się zakutalizować dane', 'Komunikat', 'Zamknij', true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateRules = async () => {
    try {
      const body = {
        StudioAcceptedMarketing: currentUserData.StudioAcceptedMarketing,
        StudioAcceptedPrivacyPolicy: true,
        StudioAcceptedRequlations: true,
        StudioAllowNotifications: currentUserData.StudioAllowNotifications,
      };

      const response = await api.put(`Account/UpdateAgreements`, body, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
    } catch (error) {
      console.error(error);
    } finally {
      handleEditingMode();
    }
  };

  const validateEmail = () => {
    const validate = {Email: false};
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (currentUserData.Email.length === 0) {
      setCurrentUserErrors((prevState) => ({...prevState, Email: true}));
      validate.Email = true;
    }
    if (!emailRegex.test(currentUserData.Email)) {
      setCurrentUserErrors((prevState) => ({...prevState, Email: true}));
      validate.Email = true;
    }
    if (!validate.Email) {
      setCurrentUserErrors({
        FirstName: false,
        ImagePath: false,
        Language: false,
        LastName: false,
        Email: false,
        PhoneNumber: false,
        UserId: false,
        UserName: false,
        StudioAllowNotifications: false,
        ShowErrors: false,
      });
      return true;
    } else {
      setCurrentUserErrors((prevState) => ({...prevState, ShowErrors: true}));
      return false;
    }
  };

  const updateUserEmail = async () => {
    if (!validateEmail()) return Alert(`Błędne dane`, 'Komunikat');
    try {
      const body = {NewEmail: currentUserData.Email};
      const response = await api.put('Account/UpdateEmailRequest', body, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      Alert('Wysłaliśmy link z potwierdzeniem na aktualny e-mail', 'Komunikat');
      setShowUpdateButton((prevState) => ({...prevState, btnEmail: false}));
    } catch (error) {
      console.error(error);
    }
  };

  const validatePhoneNumber = () => {
    const validate = {PhoneNumber: false};
    const onlyNumberRegex = /^\d+$/;
    if (currentUserData.PhoneNumber.length === 0) {
      setCurrentUserErrors((prevState) => ({...prevState, PhoneNumber: true}));
      validate.PhoneNumber = true;
    }
    if (
      !onlyNumberRegex.test(currentUserData.PhoneNumber.replace(/\s+/g, '')) ||
      currentUserData.PhoneNumber.length < 9 ||
      currentUserData.PhoneNumber.length > 12
    ) {
      setCurrentUserErrors((prevState) => ({...prevState, PhoneNumber: true}));
      validate.PhoneNumber = true;
    }
    if (!validate.PhoneNumber) {
      setCurrentUserErrors({
        FirstName: false,
        ImagePath: false,
        Language: false,
        LastName: false,
        Email: false,
        PhoneNumber: false,
        UserId: false,
        UserName: false,
        StudioAllowNotifications: false,
        ShowErrors: false,
      });
      return true;
    } else {
      setCurrentUserErrors((prevState) => ({...prevState, ShowErrors: true}));
      return false;
    }
  };

  const handleEditingMode = () => {
    setIsRuleEditing((prevState) => !prevState);
  };

  const updateUserPhoneNumber = async () => {
    if (!validatePhoneNumber()) return;
    try {
      const body = {NewPhoneNumber: currentUserData.PhoneNumber};
      const response = await api.put('Account/UpdatePhoneNumberRequest', body, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      Alert('Wysłaliśmy link z potwierdzeniem na aktualny e-mail', 'Komunikat');
      setShowUpdateButton((prevState) => ({...prevState, btnPhone: false}));
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {
  //   const currentTheme = JSON.parse(localStorage.getItem('current-theme'));
  //   if (currentTheme) {
  //     handleThemeChange(currentTheme);
  //   }
  // }, []);

  useEffect(() => {
    document.title = `MUGO Studio - Ustawienia`;
    document
      .querySelector("meta[property='og:title']")
      ?.setAttribute('content', `MUGO Studio - Ustawienia`);
    document
      .querySelector("meta[property='og:description']")
      ?.setAttribute(
        'content',
        'MUGO Studio to przestrzeń dla twórców, freelancerów, artystów i ludzi poszukujących usług oraz produktów oferowanych przez te osoby. To platforma stworzona dla usługodawców i usługobiorców, gwarantująca bezpieczeństwo transakcji oraz stwarzająca możliwość współpracy na innowacyjnych warunkach.',
      );
  }, []);

  return (
    <NewStyleMainWrapper>
      <NewStyleMain>
        <NewStyleHeaderWrapper>
          <h4>Ustawienia</h4>{' '}
        </NewStyleHeaderWrapper>
        <NewStyleGrid>
          <NewStyleLeftSection>
            {isHoveredForEditUserDataBtn && (
              <HoverTextForEdit>
                <p>{isEditingUserData ? 'Anuluj' : 'Edytuj'}</p>
              </HoverTextForEdit>
            )}
            <EditBtnWrapper
              onMouseEnter={() => setIsHoveredForEditUserDataBtn(true)}
              onMouseLeave={() => setIsHoveredForEditUserDataBtn(false)}
              isEditing={isEditingUserData}
            >
              <EditSvgWrapper
                isEditing={isEditingUserData}
                onClick={() => {
                  if (isEditingUserData) {
                    backToOldDataAfterCancelEdit();
                    setIsEditingUserData(false);
                  } else {
                    setIsEditingUserData(true);
                  }
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.27"
                  height="16.658"
                  viewBox="0 0 17.27 16.658"
                >
                  <g
                    id="Icon_feather-edit-3"
                    data-name="Icon feather-edit-3"
                    transform="translate(-3.75 -3.41)"
                  >
                    <path
                      id="Path_3712"
                      data-name="Path 3712"
                      d="M18,30h7.885"
                      transform="translate(-5.615 -10.682)"
                      fill="none"
                      stroke={
                        isEditingUserData
                          ? 'rgba(58,135,240,1)'
                          : 'rgba(7,2,15,0.7)'
                      }
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    />
                    <path
                      id="Path_3713"
                      data-name="Path 3713"
                      d="M16.327,4.862a1.858,1.858,0,0,1,2.628,2.628L8,18.442l-3.5.876.876-3.5Z"
                      fill="none"
                      stroke={
                        isEditingUserData
                          ? 'rgba(58,135,240,1)'
                          : 'rgba(7,2,15,0.7)'
                      }
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    />
                  </g>
                </svg>
              </EditSvgWrapper>
            </EditBtnWrapper>
            <NewStyleRightSectionHeader>
              <h4>Dane użytkownika</h4>
            </NewStyleRightSectionHeader>
            <NewStyleLeftSectionImgWrapper
              isNotPhoto={
                currentUserData.ImagePath === null ||
                currentUserData.ImagePath === ''
              }
              isEditing={isEditingUserData}
            >
              {currentUserData.ImagePath !== null ? (
                <img src={currentUserData.ImagePath} />
              ) : isEditingUserData ? null : (
                // <svg
                //   xmlns="http://www.w3.org/2000/svg"
                //   width="61.719"
                //   height="64"
                //   viewBox="0 0 61.719 64"
                // >
                //   <defs>
                //     <clipPath id="clip-path">
                //       <rect
                //         id="Rectangle_3689"
                //         data-name="Rectangle 3689"
                //         width="61.719"
                //         height="64"
                //         fill="rgba(23,22,29,0.12)"
                //       />
                //     </clipPath>
                //   </defs>
                //   <g
                //     id="Group_6791"
                //     data-name="Group 6791"
                //     transform="translate(0 0)"
                //   >
                //     <g
                //       id="Group_6790"
                //       data-name="Group 6790"
                //       transform="translate(0 0)"
                //       clipPath="url(#clip-path)"
                //     >
                //       <path
                //         id="Path_4071"
                //         data-name="Path 4071"
                //         d="M21.735,68.319A21.759,21.759,0,0,1,0,46.584a3.485,3.485,0,0,1,6.971,0A14.781,14.781,0,0,0,21.735,61.349a3.485,3.485,0,0,1,0,6.97"
                //         transform="translate(0 -4.318)"
                //         fill="rgba(23,22,29,0.12)"
                //       />
                //       <path
                //         id="Path_4072"
                //         data-name="Path 4072"
                //         d="M44.194,45.181H33.907a3.485,3.485,0,0,1,0-6.97h6.7C39.625,21.7,32.111,7.847,32.029,7.7a3.485,3.485,0,1,1,6.108-3.358c.391.709,9.541,17.584,9.541,37.354a3.484,3.484,0,0,1-3.484,3.485"
                //         transform="translate(-3.048 -0.254)"
                //         fill="rgba(23,22,29,0.12)"
                //       />
                //       <path
                //         id="Path_4073"
                //         data-name="Path 4073"
                //         d="M21.735,68.319A21.759,21.759,0,0,1,0,46.584a3.485,3.485,0,0,1,6.971,0A14.781,14.781,0,0,0,21.735,61.349a3.485,3.485,0,0,1,0,6.97"
                //         transform="translate(0 -4.318)"
                //         fill="rgba(23,22,29,0.12)"
                //       />
                //       <path
                //         id="Path_4074"
                //         data-name="Path 4074"
                //         d="M44.194,45.181H33.907a3.485,3.485,0,0,1,0-6.97h6.7C39.625,21.7,32.111,7.847,32.029,7.7a3.485,3.485,0,1,1,6.108-3.358c.391.709,9.541,17.584,9.541,37.354a3.484,3.484,0,0,1-3.484,3.485"
                //         transform="translate(-3.048 -0.254)"
                //         fill="rgba(23,22,29,0.12)"
                //       />
                //       <path
                //         id="Path_4075"
                //         data-name="Path 4075"
                //         d="M3.485,11.533A3.485,3.485,0,0,1,0,8.048V3.485a3.485,3.485,0,1,1,6.97,0V8.048a3.485,3.485,0,0,1-3.485,3.485"
                //         transform="translate(0 0)"
                //         fill="rgba(23,22,29,0.12)"
                //       />
                //       <path
                //         id="Path_4076"
                //         data-name="Path 4076"
                //         d="M3.485,11.533A3.485,3.485,0,0,1,0,8.048V3.485a3.485,3.485,0,1,1,6.97,0V8.048a3.485,3.485,0,0,1-3.485,3.485"
                //         transform="translate(0 0)"
                //         fill="rgba(23,22,29,0.12)"
                //       />
                //       <path
                //         id="Path_4077"
                //         data-name="Path 4077"
                //         d="M64.33,11.533a3.485,3.485,0,0,1-3.485-3.485V3.485a3.485,3.485,0,1,1,6.97,0V8.048a3.485,3.485,0,0,1-3.485,3.485"
                //         transform="translate(-6.097 0)"
                //         fill="rgba(23,22,29,0.12)"
                //       />
                //       <path
                //         id="Path_4078"
                //         data-name="Path 4078"
                //         d="M64.33,11.533a3.485,3.485,0,0,1-3.485-3.485V3.485a3.485,3.485,0,1,1,6.97,0V8.048a3.485,3.485,0,0,1-3.485,3.485"
                //         transform="translate(-6.097 0)"
                //         fill="rgba(23,22,29,0.12)"
                //       />
                //     </g>
                //   </g>
                // </svg>
                <img src={defaultAvatar} alt="Awatar domyślny" />
              )}
              {currentUserData.ImagePath === null && isEditingUserData ? (
                <>
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="61.719"
                    height="64"
                    viewBox="0 0 61.719 64"
                  >
                    <defs>
                      <clipPath id="clip-path">
                        <rect
                          id="Rectangle_3689"
                          data-name="Rectangle 3689"
                          width="61.719"
                          height="64"
                          fill="rgba(23,22,29,0.12)"
                        />
                      </clipPath>
                    </defs>
                    <g
                      id="Group_6791"
                      data-name="Group 6791"
                      transform="translate(0 0)"
                    >
                      <g
                        id="Group_6790"
                        data-name="Group 6790"
                        transform="translate(0 0)"
                        clipPath="url(#clip-path)"
                      >
                        <path
                          id="Path_4071"
                          data-name="Path 4071"
                          d="M21.735,68.319A21.759,21.759,0,0,1,0,46.584a3.485,3.485,0,0,1,6.971,0A14.781,14.781,0,0,0,21.735,61.349a3.485,3.485,0,0,1,0,6.97"
                          transform="translate(0 -4.318)"
                          fill="rgba(23,22,29,0.12)"
                        />
                        <path
                          id="Path_4072"
                          data-name="Path 4072"
                          d="M44.194,45.181H33.907a3.485,3.485,0,0,1,0-6.97h6.7C39.625,21.7,32.111,7.847,32.029,7.7a3.485,3.485,0,1,1,6.108-3.358c.391.709,9.541,17.584,9.541,37.354a3.484,3.484,0,0,1-3.484,3.485"
                          transform="translate(-3.048 -0.254)"
                          fill="rgba(23,22,29,0.12)"
                        />
                        <path
                          id="Path_4073"
                          data-name="Path 4073"
                          d="M21.735,68.319A21.759,21.759,0,0,1,0,46.584a3.485,3.485,0,0,1,6.971,0A14.781,14.781,0,0,0,21.735,61.349a3.485,3.485,0,0,1,0,6.97"
                          transform="translate(0 -4.318)"
                          fill="rgba(23,22,29,0.12)"
                        />
                        <path
                          id="Path_4074"
                          data-name="Path 4074"
                          d="M44.194,45.181H33.907a3.485,3.485,0,0,1,0-6.97h6.7C39.625,21.7,32.111,7.847,32.029,7.7a3.485,3.485,0,1,1,6.108-3.358c.391.709,9.541,17.584,9.541,37.354a3.484,3.484,0,0,1-3.484,3.485"
                          transform="translate(-3.048 -0.254)"
                          fill="rgba(23,22,29,0.12)"
                        />
                        <path
                          id="Path_4075"
                          data-name="Path 4075"
                          d="M3.485,11.533A3.485,3.485,0,0,1,0,8.048V3.485a3.485,3.485,0,1,1,6.97,0V8.048a3.485,3.485,0,0,1-3.485,3.485"
                          transform="translate(0 0)"
                          fill="rgba(23,22,29,0.12)"
                        />
                        <path
                          id="Path_4076"
                          data-name="Path 4076"
                          d="M3.485,11.533A3.485,3.485,0,0,1,0,8.048V3.485a3.485,3.485,0,1,1,6.97,0V8.048a3.485,3.485,0,0,1-3.485,3.485"
                          transform="translate(0 0)"
                          fill="rgba(23,22,29,0.12)"
                        />
                        <path
                          id="Path_4077"
                          data-name="Path 4077"
                          d="M64.33,11.533a3.485,3.485,0,0,1-3.485-3.485V3.485a3.485,3.485,0,1,1,6.97,0V8.048a3.485,3.485,0,0,1-3.485,3.485"
                          transform="translate(-6.097 0)"
                          fill="rgba(23,22,29,0.12)"
                        />
                        <path
                          id="Path_4078"
                          data-name="Path 4078"
                          d="M64.33,11.533a3.485,3.485,0,0,1-3.485-3.485V3.485a3.485,3.485,0,1,1,6.97,0V8.048a3.485,3.485,0,0,1-3.485,3.485"
                          transform="translate(-6.097 0)"
                          fill="rgba(23,22,29,0.12)"
                        />
                      </g>
                    </g>

                  </svg> */}
                  <img src={defaultAvatar} alt="Awatar domyślny" />
                  <div
                    style={{
                      position: 'absolute',
                      bottom: '0px',
                      right: '5px',
                    }}
                  >
                    {' '}
                    <svg
                      onClick={() => imgRef.current.click()}
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                    >
                      <g
                        id="Group_6685"
                        data-name="Group 6685"
                        transform="translate(-575.5 -906.5)"
                      >
                        <rect
                          id="Rectangle_2486"
                          data-name="Rectangle 2486"
                          width="19"
                          height="19"
                          rx="4"
                          transform="translate(575.5 906.5)"
                          fill="#0091ff"
                        />
                        <path
                          id="Path_3683"
                          data-name="Path 3683"
                          d="M186.263,107.114l-6.256.135.109-6.2a.682.682,0,1,0-1.356,0l.109,6.2-6.256-.135a.681.681,0,1,0,0,1.356l6.256-.135-.109,6.2a.682.682,0,1,0,1.356,0l-.109-6.2,6.256.135a.681.681,0,1,0,0-1.356Z"
                          transform="translate(405.631 808.208)"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                  </div>
                </>
              ) : null}
              <NewStyleLeftSectionImgButtonWrapper>
                <input
                  type="file"
                  onChange={getImageFile}
                  accept=".jpeq,.jpg,.png"
                  hidden
                  ref={imgRef}
                />
                {isEditingUserData && currentUserData.ImagePath !== null ? (
                  <NewStyleImgButtonWrapper
                    style={
                      windowWidth > 1024
                        ? {
                            background: '#ff0300',
                            width: '19px',
                            height: '19px',
                            borderRadius: '4px',
                            position: 'absolute',
                            bottom: '-105px',
                            right: '0px',
                          }
                        : {
                            background: '#ff0300',
                            width: '19px',
                            height: '19px',
                            borderRadius: '4px',
                            position: 'absolute',
                            bottom: '-85px',
                            right: '5px',
                          }
                    }
                    onClick={() => {
                      setCurrentUserData((prevState) => ({
                        ...prevState,
                        ImagePath: null,
                      }));
                      setImages(null);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10.9"
                      height="12"
                      viewBox="0 0 10.9 12"
                    >
                      <g
                        id="Icon_feather-trash"
                        data-name="Icon feather-trash"
                        transform="translate(0.5 0.5)"
                      >
                        <path
                          id="Path_3663"
                          data-name="Path 3663"
                          d="M4.5,9h9.9"
                          transform="translate(-4.5 -6.8)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                        <path
                          id="Path_3664"
                          data-name="Path 3664"
                          d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                          transform="translate(-6.4 -3)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                      </g>
                    </svg>
                  </NewStyleImgButtonWrapper>
                ) : null}
              </NewStyleLeftSectionImgButtonWrapper>
            </NewStyleLeftSectionImgWrapper>
            <NewStyleLeftSectionDateWrapper>
              <InputView isEditing={isEditingUserData} notEdit={true}>
                <p>Użytkownik</p>
                <input
                  type="text"
                  readOnly={true}
                  value={currentUserData.UserName}
                  id="UserName"
                />
              </InputView>
              <InputView
                isEditing={isEditingUserData}
                isError={
                  currentUserErrors.ShowErrors && currentUserErrors.FirstName
                }
              >
                <p>Imię</p>
                <input
                  readOnly={!isEditingUserData}
                  type="text"
                  placeholder=""
                  value={currentUserData.FirstName}
                  id="FirstName"
                  onChange={handleUserDataChange}
                />
                {currentUserErrors.FirstName ? (
                  <ErrorStar
                    text={'Imię nie może być puste , nie może zawierać cyfr'}
                  />
                ) : null}
              </InputView>
              <InputView
                isEditing={isEditingUserData}
                isError={
                  currentUserErrors.ShowErrors && currentUserErrors.LastName
                }
              >
                <p>Nazwisko</p>
                <input
                  type="text"
                  readOnly={!isEditingUserData}
                  placeholder=""
                  value={currentUserData.LastName}
                  id="LastName"
                  onChange={handleUserDataChange}
                />
                {currentUserErrors.LastName ? (
                  <ErrorStar
                    text={'Nazwisko nie może być puste, nie może zawierać cyfr'}
                  />
                ) : null}
              </InputView>
              <InputView
                isEditing={isEditingUserData}
                notEdit={isGoogleUser}
                isError={
                  currentUserErrors.ShowErrors && currentUserErrors.Email
                }
              >
                <p>E-mail</p>
                <input
                  type="email"
                  readOnly={isGoogleUser ? true : !isEditingUserData}
                  placeholder=""
                  value={currentUserData.Email}
                  id="Email"
                  onChange={handleUserEmail}
                />
                {currentUserErrors.Email ? (
                  <ErrorStar text={'Nieprawidłowy adres e-mail'} />
                ) : null}
              </InputView>
              {showUpdateButton.btnEmail ? (
                <EmailandPhoneNumberButton onClick={updateUserEmail}>
                  Zaktualizuj email
                </EmailandPhoneNumberButton>
              ) : null}
              <InputView
                isEditing={isEditingUserData}
                isError={
                  currentUserErrors.ShowErrors && currentUserErrors.PhoneNumber
                }
              >
                <p>Telefon</p>
                <input
                  type="text"
                  readOnly={!isEditingUserData}
                  placeholder=""
                  value={
                    currentUserData.PhoneNumber !== null
                      ? currentUserData.PhoneNumber.replace(/[^0-9]/g, '')
                          .replace(/\s/g, '')
                          .replace(/(\d{3})/g, '$1 ')
                          .trim()
                      : ''
                  }
                  id="PhoneNumber"
                  maxLength={11}
                  onChange={handleUserPhoneNumber}
                />
                {currentUserErrors.PhoneNumber ? (
                  <ErrorStar text={'Nieprawidłowy numer telefonu'} />
                ) : null}
                <PhoneCodeSelect
                  onChange={(v) => handlePhoneNumberCode(v)}
                  selectWidth={'50px'}
                  isEditing={isEditingUserData}
                  title={''}
                  values={dataTypeArr}
                  value={phoneNumberCode}
                />
              </InputView>
              <NewStyleRightSectionCheckBoxWithText>
                <NewStyleCheckBoxWrapper
                  style={isEditingUserData ? {borderBottom: 'none'} : null}
                >
                  <p
                    style={
                      isEditingUserData
                        ? {fontWeight: '400'}
                        : {marginLeft: '3px', fontWeight: '400', opacity: '0.5'}
                    }
                  >
                    Weryfikacja dwuetapowa
                  </p>
                  {isEditingUserData ? (
                    <NewStyleCheckBox
                      onClick={handleTwoStepAuth}
                      isActive={currentUserData.TwoStepVerificationEnabled}
                    >
                      <div></div>
                    </NewStyleCheckBox>
                  ) : (
                    <p style={{paddingRight: '8.5px', fontWeight: '500'}}>
                      {currentUserData.TwoStepVerificationEnabled
                        ? 'Tak'
                        : 'Nie'}
                    </p>
                  )}
                </NewStyleCheckBoxWrapper>
              </NewStyleRightSectionCheckBoxWithText>
              <AccountBigBlueBtn
                hidden={!isEditingUserData}
                disabled={!isEditingUserData}
                onClick={updateUserData}
                style={
                  windowWidth <= light.breakpointsJs.mobile
                    ? {
                        background: '#3986EF',
                        position: 'unset',
                        bottom: 'unset',
                        left: 'unset',
                        margin: '10px auto',
                      }
                    : {
                        position: 'absolute',
                        bottom: '11px',
                        left: '11px',
                        background: '#3986EF',
                      }
                }
              >
                Zapisz
              </AccountBigBlueBtn>
            </NewStyleLeftSectionDateWrapper>
          </NewStyleLeftSection>
          <NewStyleCenterSection>
            {isHoveredForEditRuleBtn && (
              <HoverTextForEdit>
                <p>{isRuleEditing ? 'Anuluj' : 'Edytuj'}</p>
              </HoverTextForEdit>
            )}
            <EditBtnWrapper
              onMouseEnter={() => setIsHoveredForEditRuleBtn(true)}
              onMouseLeave={() => setIsHoveredForEditRuleBtn(false)}
              isEditing={isRuleEditing}
            >
              <EditSvgWrapper
                isEditing={isRuleEditing}
                onClick={() => {
                  handleEditingMode();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.27"
                  height="16.658"
                  viewBox="0 0 17.27 16.658"
                >
                  <g
                    id="Icon_feather-edit-3"
                    data-name="Icon feather-edit-3"
                    transform="translate(-3.75 -3.41)"
                  >
                    <path
                      id="Path_3712"
                      data-name="Path 3712"
                      d="M18,30h7.885"
                      transform="translate(-5.615 -10.682)"
                      fill="none"
                      stroke={
                        isRuleEditing
                          ? 'rgba(58,135,240,1)'
                          : 'rgba(7,2,15,0.7)'
                      }
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    />
                    <path
                      id="Path_3713"
                      data-name="Path 3713"
                      d="M16.327,4.862a1.858,1.858,0,0,1,2.628,2.628L8,18.442l-3.5.876.876-3.5Z"
                      fill="none"
                      stroke={
                        isRuleEditing
                          ? 'rgba(58,135,240,1)'
                          : 'rgba(7,2,15,0.7)'
                      }
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    />
                  </g>
                </svg>
              </EditSvgWrapper>
            </EditBtnWrapper>
            <NewStyleRightSectionHeader>
              <h4>Regulamin</h4>
            </NewStyleRightSectionHeader>
            <NewStyleRightSectionCheckBoxWithText
              style={windowWidth <= 1024 ? {marginTop: '15px'} : null}
            >
              <NewStyleCheckBoxWrapper>
                <p>Regulamin i polityka prywatności</p>
                <p style={{paddingRight: '8.5px', fontWeight: 'normal'}}>Tak</p>
              </NewStyleCheckBoxWrapper>
              <span>
                Oświadczam, że zapoznałam/łem się z{' '}
                <a
                  target="blank"
                  href="https://fmmtp.pl/mugostudio/Regulamin%20Serwisu_Mugo_Studio_17.09.2024.pdf"
                >
                  Regulaminem
                </a>{' '}
                oraz{' '}
                <a
                  target="blank"
                  href="https://fmmtp.pl/dokumentymugo/Polityka%20prywatności.pdf"
                >
                  Polityką prywatnościi
                </a>{' '}
                akceptuję ich postanowienia.
              </span>
            </NewStyleRightSectionCheckBoxWithText>
            <NewStyleRightSectionCheckBoxWithText>
              <NewStyleCheckBoxWrapper>
                <p>Klauzula informacyjna</p>

                <p style={{paddingRight: '8.5px', fontWeight: 'normal'}}>Tak</p>
              </NewStyleCheckBoxWrapper>
              <span>
                Przeczytałam/łem i zrozumiałam/łem Klauzulę informacyjną dot.
                przetwarzania moich danych osobowych.
              </span>
            </NewStyleRightSectionCheckBoxWithText>

            <NewStyleRightSectionCheckBoxWithText>
              <NewStyleCheckBoxWrapper>
                <p>Uruchomienie</p>

                <p style={{paddingRight: '8.5px', fontWeight: 'normal'}}>Tak</p>
              </NewStyleCheckBoxWrapper>
              <span>
                Żądam uruchomienia usługi przed upływem terminu przysługującego
                mi prawa do odstąpienia od kontraktu bez podania przyczyn.
                Rozumiem, że zgłoszenie żądania powoduje utratę prawa do
                odstąpienia.
              </span>
            </NewStyleRightSectionCheckBoxWithText>

            <NewStyleRightSectionCheckBoxWithText>
              <NewStyleCheckBoxWrapper>
                <p>Zgody marketingowe</p>
                {isRuleEditing ? (
                  <NewStyleCheckBox
                    onClick={toggleMarketingActive}
                    isActive={currentUserData.StudioAcceptedMarketing}
                  >
                    <div></div>
                  </NewStyleCheckBox>
                ) : (
                  <p style={{paddingRight: '8.5px', fontWeight: 'normal'}}>
                    {currentUserData.StudioAcceptedMarketing ? 'Tak' : 'Nie'}
                  </p>
                )}
              </NewStyleCheckBoxWrapper>
              <span>
                Wyrażam zgodę na przekazywanie przez MyMusic sp. komandytowa
                Lupicki, Czysz z siedzibą w Wysogotowie, przy ul. Wierzbowej 21,
                62-081 Wysogotowo, treści marketingowych za pośrednictwem moich
                urządzeń telekomunikacyjnych, w szczególności takich jak laptop,
                telefon czy smartfon, zgodnie z art. 172 ust. 1 ustawy z dnia 16
                lipca 2004 r. Prawo telekomunikacyjne.
              </span>
            </NewStyleRightSectionCheckBoxWithText>

            <NewStyleRightSectionCheckBoxWithText>
              <NewStyleCheckBoxWrapper>
                <p>Przetwarzanie danych</p>
                {isRuleEditing ? (
                  <NewStyleCheckBox
                    onClick={toggleDataProcessingActive}
                    isActive={currentUserData.StudioAllowNotifications}
                  >
                    <div></div>
                  </NewStyleCheckBox>
                ) : (
                  <p style={{paddingRight: '8.5px', fontWeight: 'normal'}}>
                    {currentUserData.StudioAllowNotifications ? 'Tak' : 'Nie'}
                  </p>
                )}
              </NewStyleCheckBoxWrapper>
              <span>
                {/* Wyrażam zgodę na otrzymywanie informacji handlowej od MyMusic
                sp. Komandytowa Lupicki, Czysz z siedzibą w Wysogotowie, przy
                ul. Wierzbowej 31, 62-081 Wysogotowo, zgodnie z art. 10 ustawy z
                dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną. */}
                Wyrażam zgodę na przetwarzanie moich danych osobowych, w celu
                otrzymywania na podany adres e-mail Newslettera Serwisu
                internetowego mugo.pl tworzonego przez MyMusic sp. komandytowa
                Lupicki, Czysz, pod adresem https://mymusic.pl/ Rozumiem, że
                moja zgoda jest dobrowolna i nie muszę jej udzielać, przy czym w
                przypadku jej nieudzielenia bądź późniejszego jej wycofania, jak
                również przesłania wniosku o zmianę lub usunięcie moich danych,
                stracę możliwość uczestniczenia w w/w działaniach marketingowych
                MyMusic sp. komandytowa Lupicki, Czysz. Zostałam/em
                poinformowana/y o przysługującym mi prawie dostępu do treści
                moich danych oraz ich poprawiania.
              </span>
            </NewStyleRightSectionCheckBoxWithText>
            <AccountBigBlueBtn
              hidden={!isRuleEditing}
              disabled={!isRuleEditing}
              onClick={() => updateRules()}
              style={
                windowWidth <= light.breakpointsJs.mobile
                  ? {
                      background: '#3986EF',
                      position: 'unset',
                      bottom: 'unset',
                      left: 'unset',
                      margin: '10px auto',
                    }
                  : {
                      position: 'absolute',
                      bottom: '-10px',
                      left: '11px',
                      background: '#3986EF',
                    }
              }
            >
              Zapisz
            </AccountBigBlueBtn>
          </NewStyleCenterSection>
          <NewStyleRightSection>
            {!isGoogleUser ? (
              <NewStyleCenterSectionInputsWrappers>
                <InputView
                  isEditing={true}
                  isError={errorVerification.currentPassword}
                >
                  <p>Stare hasło</p>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    // readOnly={!isEditing}
                    // placeholder={isEditing ? '' : '********'}
                    value={userPasswords.currentPassword}
                    id="currentPassword"
                    onChange={handleUserPasswordsChange}
                    style={{paddingRight: '17.5px'}}
                  />
                  {errorVerification.currentPassword ? (
                    <ErrorStar text={'Wprowadź aktualne hasło'} />
                  ) : null}

                  <IconShowPasswordWrapper
                    onClick={() => setShowPassword((prevState) => !prevState)}
                  >
                    {showPassword ? (
                      <img src={closeEye} alt="hidePAssword" />
                    ) : (
                      <img src={openEye} alt="showPAssword" />
                    )}
                  </IconShowPasswordWrapper>
                </InputView>
                <InputView
                  // style={!isEditing ? {display: 'none'} : null}
                  isEditing={true}
                  isError={errorVerification.newPassword}
                >
                  <p>Nowe hasło</p>
                  <input
                    type={showNewPassword ? 'text' : 'password'}
                    // readOnly={!isEditing}
                    placeholder=""
                    value={userPasswords.newPassword}
                    id="newPassword"
                    onChange={handleUserPasswordsChange}
                    style={{paddingRight: '17.5px'}}
                  />
                  {errorVerification.newPassword ? (
                    <ErrorStar
                      text={
                        'Nowe hasło nie może być takie samo jak stare hasło. Hasło nie może zawierać spacji/przerwy. Nowe hasło musi zawierać minimum 8 znaków, 1 znak specjalny, 1 wielką i małą literę i 1 liczbę'
                      }
                    />
                  ) : null}

                  <IconShowPasswordWrapper
                    onClick={() =>
                      setShowNewPassword((prevState) => !prevState)
                    }
                  >
                    {showNewPassword ? (
                      <img src={closeEye} alt="hidePAssword" />
                    ) : (
                      <img src={openEye} alt="showPAssword" />
                    )}
                  </IconShowPasswordWrapper>
                </InputView>
                <InputView
                  // style={!isEditing ? {display: 'none'} : null}
                  isError={errorVerification.replayPassword}
                  isEditing={true}
                >
                  <p>Powtórz nowe hasło</p>
                  <input
                    type={showReplayPassword ? 'text' : 'password'}
                    // readOnly={!isEditing}
                    placeholder=""
                    value={userPasswords.replayPassword}
                    id="replayPassword"
                    onChange={handleUserPasswordsChange}
                    style={{paddingRight: '17.5px'}}
                  />
                  {errorVerification.replayPassword ? (
                    <ErrorStar text={'Oba hasła muszą być identyczne'} />
                  ) : null}

                  <IconShowPasswordWrapper
                    onClick={() =>
                      setShowReplayPassword((prevState) => !prevState)
                    }
                  >
                    {showReplayPassword ? (
                      <img src={closeEye} alt="hidePAssword" />
                    ) : (
                      <img src={openEye} alt="showPAssword" />
                    )}
                  </IconShowPasswordWrapper>
                </InputView>
                {/*{isEditing ? (*/}
                {/*  <StyledButtonSetting onClick={() => changePassword()}>*/}
                {/*    Aktualizuj hasło*/}
                {/*  </StyledButtonSetting>*/}
                {/*) : null}*/}
              </NewStyleCenterSectionInputsWrappers>
            ) : null}
            <NewStyleCenterSectionTextandButtonWrapper>
              {/*<img src={openEye} alt="showPAssword" />*/}
              {!isGoogleUser && (
                <AccountBigBlueBtn
                  onClick={changePassword}
                  style={
                    windowWidth <= light.breakpointsJs.mobile
                      ? {
                          background: light.colors.blueTheme,
                          position: 'unset',
                          bottom: 'unset',
                          left: 'unset',
                          margin: '20px auto',
                        }
                      : {
                          cursor: 'pointer',
                          background: light.colors.blueTheme,
                          opacity: 1,
                          marginTop: '20px',
                        }
                  }
                >
                  Zapisz nowe hasło
                </AccountBigBlueBtn>
              )}
              {/*<p style={{marginTop: '30px'}}>*/}
              {/*  There are many variations of passage of Lorem Ipsum available,*/}
              {/*  but the majority have suffered*/}
              {/*</p>*/}
              {/*<p>*/}
              {/*  alteration in some form, by injected humour, or randomised wirds*/}
              {/*  which*/}
              {/*</p>*/}
            </NewStyleCenterSectionTextandButtonWrapper>
          </NewStyleRightSection>
        </NewStyleGrid>
      </NewStyleMain>
    </NewStyleMainWrapper>
  );
};

export default SettingsComponent;
