import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from 'react';
import styled, {css} from 'styled-components';
import _ from 'lodash';
import qs from 'qs';
import {useCookies} from 'react-cookie';
import {countTruthyVariables} from 'utils/countTruthyVariables';
import useInfinityScroller from 'hooks/useInfinityScroller';
import {useLocation, useSearchParams} from 'react-router-dom';

import {ProductTypeEnum} from 'constants/conditionEnums';
import Selector from 'components/FilterSelector';
import api from 'services/axiosConfig';
import searchIcon from 'images/svg/search.svg';
import twoArrowsIcon from 'images/svg/two-arrows.svg';
import listIcon from 'images/svg/list.svg';
import arrowDown from 'images/svg/purple-select-arrow.svg';
import filterIcon from 'images/svg/filter-icon.svg';
import SingleDigitalProduct from './SingleDigitalProduct';
import {LoadingCircle} from 'utils/loadingCircle';
import {useMenuContext} from 'contexts/MenuContext';
import {useFilterMenuContext} from 'contexts/FilterMenuContext';
import {useSearchBarContext} from 'contexts/SearchBarContext';
import {UserDataContext} from 'contexts/UserDataContext';
import {useIsBodyOverflowBlockedContext} from 'contexts/IsBodyOverflowBlockedContext';
import {
  MobileInput,
  MobileInputTitle,
  MobileInputWrapper,
  MobileSelect,
  MobileSelectWrapper,
  CustomSelectArrow,
} from 'pages/Products/';
import {
  Grid,
  CheckboxWithTitleWrapper,
  CustomBlueCheckbox,
  CountFilterDisplay,
  FilterButtonSvg,
  SortButtonSvg,
  FilterWrapper,
  FilterInputWrapper,
  SingleFilterWrapper,
  FilterMenuWrapper,
  FilterInput,
  SelectWrapper,
  PageHeaderContent,
  PageHeaderWrapper,
} from 'pages/Products/index';
import {useAudio} from 'contexts/AudioContext';

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const Main = styled.div`
  width: 1020px;
  height: calc(100vh - 143px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  /* margin-top: 10px; */
  /* margin-bottom: 20px; */
  pointer-events: all;

  & > p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.92);
    line-height: 24px;
    margin-bottom: 10px;
  }

  @media (max-width: 1045px) {
    width: 360px;
  }
`;

export const MobileMainWrapper = styled.div`
  width: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background: #f8f8f8; */

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
  }
`;

export const NavButtonsWrapper = styled.div`
  width: 370px;
  /* height: 0px;
  position: fixed;
  top: 15px; */
  /* z-index: 2; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* padding-top: 10px; */

  @media (max-width: 400px) {
    width: 100%;
  }
`;

export const MobileSearchBarWrapper = styled.div`
  background: ${({theme}) => theme.colors.mobileSearchBarBg};
  width: 258px;
  border-radius: 14px;
  height: 39px;
  position: relative;
  padding: 0 13px;
  display: flex;
  gap: 5px;
  align-items: center;
`;

export const MobileSearchBar = styled.input<{isActive?: boolean}>`
  display: ${({isActive}) => (isActive ? 'unset' : 'none')};
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15px;
  font-weight: 400;
  color: ${({theme}) => theme.colors.mobileSearchBarTextColor};
  /* background: #dce0e9; */
  background: transparent;
  width: auto;

  border: none;
  /* position: absolute;
  right: 79px; */

  /* top: -3px; */
  position: relative;
  outline: none;

  &::placeholder {
    color: ${({theme}) => theme.colors.mobileSearchBarTextColor};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    /* width: calc(100% - 120px);
    right: 88px; */
  }
`;

export const FilterSvg = styled.svg<{isActive?: boolean}>`
  /* position: absolute;
  right: 56px; */

  & path {
    fill: ${({isActive, theme}) =>
      isActive ? '#3A87F0' : theme.colors.mobileSearchBarTextColor};
  }
  & circle {
    fill: ${({isActive, theme}) =>
      isActive ? '#3A87F0' : theme.colors.mobileSearchBarTextColor};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    right: 65px;
  }
`;
export const ArrowSvg = styled.svg<{isActive?: boolean}>`
  /* position: absolute;
  right: 56px; */

  & path {
    fill: ${({isActive, theme}) =>
      isActive ? '#3A87F0' : theme.colors.mobileSearchBarTextColor};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    right: 65px;
  }
`;

export const SearchSvg = styled.svg<{isActive?: boolean}>`
  /* position: absolute;
  left: 0px;
  margin-top: 1px; */

  & path {
    fill: ${({isActive, theme}) =>
      isActive ? '#3A87F0' : theme.colors.mobileSearchBarTextColor};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    right: 101px;
  }
`;

export const MobileMain = styled.div`
  width: 400px;
  min-height: calc(100vh - 105px);
  margin-bottom: 57px;
  /* min-height: 765px; */
  /* height: calc(100vh - 48px - 57px); */
  display: none;
  padding: 8px 0px;
  flex-direction: column;
  align-items: center;
  background: transparent;
  gap: 14px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: 100%;
    /* padding: unset; */
    padding: 8px;
    gap: 10px;
  }
`;

export const MenuMain = styled.div<{isMenuOpen: boolean; decodedToken?: any}>`
  width: 100%;
  background: white;
  border-top: ${({isMenuOpen}) => (isMenuOpen ? '4px solid #3A87F0' : 'none')};
  height: ${({isMenuOpen}) => (isMenuOpen ? '100vh' : '0px')};
  border-radius: 16px;
  position: fixed;
  overflow-y: auto;
  bottom: 0;
  transition: all 0.35s ease-out;
  z-index: 20;
  padding-bottom: ${({isMenuOpen}) => (isMenuOpen ? '42px' : '0px')};
`;

export const CloseIsLoginPageOpenWrapper = styled.div`
  width: 100%;
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(7, 2, 15, 0.3);
  position: relative;
  top: 0px;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: -0.05px;
  color: #352f40;
  margin-bottom: 20px;
`;

export const CloseIsLoginPageOpen = styled.div`
  position: absolute;
  height: 24px;
  width: 24px;
  font-size: 20px;
  color: black;
  font-weight: 900;
  /* top: 20px; */
  right: 23.5px;
`;

export const MenuWrapper = styled.div<{decodedToken?: any}>`
  width: 100%;
  padding: 0 32px;
  gap: 13px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const MobileCheckboxFilterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;

  & > p {
    font-weight: 500;
    font-size: 17px;
    letter-spacing: 0.15px;
    line-height: 16px;
    color: #07020f;
  }
`;

export const MobileCheckbox = styled.div<{
  isActive: boolean;
}>`
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: ${({isActive}) =>
    isActive ? '3px solid #0066FF' : '3px solid rgba(112,112,112,0.3)'};
  cursor: ${({isActive}) => (isActive ? 'default' : 'pointer')};

  & > svg {
    display: ${({isActive}) => (isActive ? 'unset' : 'none')};
    margin-left: 1px;
  }
`;

export const MobileInputFilterWrapper = styled.div`
  width: 100%;
  border-radius: 16px;
  position: relative;
  background: #dce0e9;
  height: 48px;
  gap: 5px;
  padding: 11px 25px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & > p {
    font-size: 17px;
    color: #352f40;
    font-weight: 500;
    letter-spacing: -0.0625px;
    margin: 0;
  }

  & > input {
    width: 100%;
    text-align: right;
    border: none;
    outline: none;
    background: transparent;
    font-size: 17px;
    color: #3a87f0;
    font-weight: 500;
    letter-spacing: -0.0625px;

    &::placeholder {
      color: rgba(7, 2, 15, 0.3);
    }
  }
`;

export const MobileSelectFilterWrapper = styled.div<{value?: any}>`
  width: 100%;
  border-radius: 16px;
  position: relative;
  background: #dce0e9;
  height: 48px;
  gap: 5px;
  padding: 11px 25px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & > select {
    width: 100%;
    font-size: 17px;
    background: transparent;
    border: none;
    outline: none;
    color: ${({value}) => (value ? '#3a87f0' : '#352f40')};
    font-weight: 500;
    letter-spacing: -0.0625px;
    margin: 0;
    -webkit-appearance: none;

    & > option {
      color: #352f40;
    }
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.1);
  padding: 10px;
`;

export const FilterButton = styled.div<{isFilterMenuOpen: boolean}>`
  width: 28px;
  height: 22px;
  border-radius: 7px;
  margin-right: 1px;
  background: ${({isFilterMenuOpen, theme}) =>
    isFilterMenuOpen ? '#DCE0E9' : theme.colors.blueTheme};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & > img {
    height: 15px;
    width: 15px;
  }
`;

export const ClearFiltersButton = styled.div`
  width: 19px;
  height: 19px;
  border-radius: 4px;
  background: #ff0300;
  position: absolute;
  right: 344px;
  top: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    right: 5px;
    top: unset;
  }
`;

export const SingleFilterHeaderWrapper = styled.div`
  height: 40px;
  width: 100%;
  border-bottom: 1px solid rgba(7, 2, 15, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  & > p {
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 0.15px;
    color: #07020f;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    border-bottom: none;

    & > p {
      font-size: 17px;
      color: #352f40;
      letter-spacing: -0.05px;
      line-height: 24px;
      font-weight: 400;
    }
  }
`;

export const SingleFilterContentWrapper = styled.div`
  height: calc(100% - 40px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
`;

export const PageHeaderHeaderWrapper = styled.div`
  width: 100%;
  height: 55px;
  border-radius: 24px 24px 0 0;
  background: #0091ff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageHeaderHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 9px;

  & > p {
    color: ${({theme}) => theme.colors.headerBlackFont};
    font-size: 15px;
    letter-spacing: 0.625px;
  }
`;

export const FilterButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ArrowWrapper = styled.div<{isActive?: boolean; length?: number}>`
  width: 84px;
  height: 21px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  transition: all 0.15s;
  border-radius: 7px;
  background: ${({isActive, length}) =>
    isActive || length > 0 ? 'rgba(137, 190, 255, 0.3)' : 'transparent'};

  & > svg {
    & path {
      fill: ${({theme}) => theme.colors.grayFontOp100};
    }
  }

  &:hover {
    background: rgba(137, 190, 255, 0.3);
  }

  & > p {
    position: absolute;
    background: #89bfff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 18px;
    width: 18px;
    right: 6px;
    letter-spacing: 0.15px;
    font-size: 14px;
    color: #07020f;
  }
`;

export interface Role {
  FieldName: string;
  FlagValue: number;
}

interface Block {
  BackgroundColorHex: string;
  BlockOpacityIn0To100Ratio: number;
  FontColorHex: string;
  Height: number;
  Id: string;
  IsRequiredOnCard: boolean;
  StartX: number;
  Type: number;
  Width: number;
}

interface ProducerCard {
  BackgroundColorHex: string;
  CardId: string;
  Country: string;
  ProducerFirstName: string;
  ProducerId: string;
  ProducerLastName: string;
  ProducerName: string;
  ProducerPhoto: string;
  Role: number;
  SecondaryRole: number;
  Street: string;
  BaseBlock: Block;
}

export interface Role {
  FieldName: string;
  FlagValue: number;
}

export interface ViewsInfo {
  LastViewDate: string;
  ViewsCount: number;
}

export interface ICategory {
  CategoryId: string;
  Name: string;
}

export interface AttributeValue {
  AttributeId: string;
  Id: string;
  Name: string;
  Position: number;
}

export interface Offer {
  VerifiedRole: boolean;
  VerifiedSecondaryRole: boolean;
  AttributeValues: AttributeValue[];
  Favourite: boolean | null;
  AvailabilityEndDate: string;
  AvailabilityStartDate: string;
  Category: ICategory | null;
  ProducerCoverFile?: string;
  SecondCategoryId: ICategory | null;
  ThirdCategoryId: ICategory | null;
  ProducerRole: number;
  ProducerSecondaryRole: number;
  ProducerPostalCode: string;
  ProducerRegion: string;
  CoverFile: string;
  Description: string;
  Featured: boolean;
  GrossValue: string;
  Id: string;
  ProducerFirstName: string;
  ProducerSecondName: string;
  IsWinWin: boolean;
  Name: string;
  NegotiatedOffer: boolean;
  ProducerId: string;
  ProducerName: string;
  ProductDemoFile: string;
  ProductId: string;
  ProductType: number;
  RightsEnd: number;
  Tags: string;
  Currency: null | string;
  ViewsInfo: ViewsInfo;
}

interface ProductOption {
  FieldName: string;
  FlagValue: number;
}

enum SortBy {
  DATE_ASCENDING = 'DATE_ASCENDING',
  DATE_DESCENDING = 'DATE_DESCENDING',
  NAME_ASCENDING = 'NAME_ASCENDING',
  NAME_DESCENDING = 'NAME_DESCENDING',
  PRICE_ASCENDING = 'PRICE_ASCENDING',
  PRICE_DESCENDING = 'PRICE_DESCENDING',
  POPULARITY = 'POPULARITY',
}

interface FilterCategory {
  Name: string;
  Id: string;
  ParentCategoryId: string | null;
}

interface Category {
  Description: string;
  Id: string;
  Name: string;
  ParentCategoryId: string | null;
  Position: number;
  ProductType: number;
}

export interface AttributeValue {
  AttributeId: string;
  Id: string;
  Name: string;
  Position: number;
}

export interface Attribute {
  Id: string;
  Name: string;
  Position: number;
  ProductType: number;
  AttributeValues: AttributeValue[];
}

const DigitalProducts = () => {
  const [searchString, setSearchString] = useState<string>(
    sessionStorage.getItem('trackFilterSearchString')
      ? sessionStorage.getItem('trackFilterSearchString')
      : '',
  );
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [hasMoreProducers, setHasMoreProducers] = useState(true);
  const [productAttributes, setProductAttributes] = useState<Attribute[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const [offers, setOffers] = useState<Offer[]>([]);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState<boolean>(false);
  const [isSortMenuOpen, setIsSortMenuOpen] = useState<boolean>(false);
  const [productType, setProductType] = useState<number | string>(1);
  const [isWinwin, setIsWinwin] = useState<boolean>(
    sessionStorage.getItem('trackFilterIsWinwin') === 'yes' ? true : false,
  );
  const [isFavourite, setIsFavourite] = useState<boolean>(
    sessionStorage.getItem('trackFilterIsFavourite') === 'yes' ? true : false,
  );
  const [hasWaveFile, setHasWaveFile] = useState<boolean>(
    sessionStorage.getItem('trackFilterHasWaveFile') === 'yes' ? true : false,
  );
  const [producerName, setProducerName] = useState<string>(
    sessionStorage.getItem('trackFilterProducerName')
      ? sessionStorage.getItem('trackFilterProducerName')
      : '',
  );
  const [sortBy, setSortBy] = useState<SortBy | string>(
    sessionStorage.getItem('trackFilterSortBy')
      ? sessionStorage.getItem('trackFilterSortBy')
      : SortBy.DATE_DESCENDING,
  );
  const [categories, setCategories] = useState<Category[]>([]);
  const [minGrossValue, setMinGrossValue] = useState<
    number | string | undefined
  >(
    sessionStorage.getItem('trackFilterMinGrossValue')
      ? +sessionStorage.getItem('trackFilterMinGrossValue')
      : null,
  );
  const [maxGrossValue, setMaxGrossValue] = useState<
    number | string | undefined
  >(
    sessionStorage.getItem('trackFilterMaxGrossValue')
      ? +sessionStorage.getItem('trackFilterMaxGrossValue')
      : null,
  );
  const [primaryCategories, setPrimaryCategories] = useState<FilterCategory[]>(
    [],
  );
  const [primaryCategory, setPrimaryCategory] = useState<string>(
    sessionStorage.getItem('trackFilterPrimaryCategory')
      ? sessionStorage.getItem('trackFilterPrimaryCategory')
      : '',
  );
  const [vibe, setVibe] = useState<string>(
    sessionStorage.getItem('trackFilterVibe')
      ? sessionStorage.getItem('trackFilterVibe')
      : '',
  );
  const [tempo, setTempo] = useState<string>(
    sessionStorage.getItem('trackFilterTempo')
      ? sessionStorage.getItem('trackFilterTempo')
      : '',
  );
  const [tone, setTone] = useState<string>(
    sessionStorage.getItem('trackFilterTone')
      ? sessionStorage.getItem('trackFilterTone')
      : '',
  );
  const [secondaryCategories, setSecondaryCategories] = useState<
    FilterCategory[]
  >([]);
  const [secondaryCategory, setSecondaryCategory] = useState<string>(
    sessionStorage.getItem('trackFilterSecondaryCategory')
      ? sessionStorage.getItem('trackFilterSecondaryCategory')
      : '',
  );
  const [tertiaryCategories, setTertiaryCategories] = useState<
    FilterCategory[]
  >([]);
  const [tertiaryCategory, setTertiaryCategory] = useState<string>(
    sessionStorage.getItem('trackFilterTertiaryCategory')
      ? sessionStorage.getItem('trackFilterTertiaryCategory')
      : '',
  );
  const [roles, setRoles] = useState<Role[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const gridRef = useRef(null);

  const lastElementRef = useInfinityScroller(
    isLoading,
    hasMoreProducers,
    setPageNumber,
  );

  const mobileLastElementRef = useInfinityScroller(
    isLoading,
    hasMoreProducers,
    setPageNumber,
  );

  const mobileSearchBarRef = useRef<HTMLInputElement>(null);
  const mobileFilterMenuWrapperRef = useRef<HTMLDivElement | null>(null);

  const {setIsMenuOpen} = useMenuContext();
  const {isMobileFilterMenuOpen, setIsMobileFilterMenuOpen} =
    useFilterMenuContext();
  const {isMobileSearchBarOpen, setIsMobileSearchBarOpen} =
    useSearchBarContext();
  const {setIsBodyOverflowBlocked} = useIsBodyOverflowBlockedContext();
  const [userData, setUserData] = useContext(UserDataContext);
  const scrollRef = useRef<HTMLDivElement>(null);

  const {setPlaylistAndPlay, playlist, resetPlayer, closePlayer} = useAudio();

  const getProductAttributes = async () => {
    if (productType === '') return;
    try {
      const response = await api.get(`Product-Constants/8/attributes`);
      // console.log(response.data.Response);
      setProductAttributes(response.data.Response);
    } catch (error) {
      console.error(error);
    }
  };

  // function mergeUniqueProducts(array1: any[], array2: any[]) {
  //   // Łączymy tablice i tworzymy mapę, aby kluczem był `ProductId`
  //   console.log('arr1:', array1, 'arr2:', array2);
  //   const productMap = new Map();

  //   [...array1, ...array2].forEach((product) => {
  //     // Dodajemy do mapy tylko unikalne `ProductId`
  //     productMap.set(product.ProductId, product);
  //   });

  //   // Zwracamy tablicę unikalnych produktów
  //   return Array.from(productMap.values());
  // }

  const handleIsMobileFilterMenuOpenChange = (value: boolean) =>
    setIsMobileFilterMenuOpen(value);

  const handleIsMobileSearchBarOpenChange = (value: boolean) =>
    setIsMobileSearchBarOpen(value);

  const handleSearchStringChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
    sessionStorage.setItem('trackFilterSearchString', e.target.value);
    setSearchParams({
      SearchString: e.target.value,
      ProducerName: producerName,
      IsWinwin: isWinwin ? 'yes' : 'no',
      IsFavourite: isFavourite ? 'yes' : 'no',
      HasWaveFile: hasWaveFile ? 'yes' : 'no',
      MinGrossValue: minGrossValue ? minGrossValue?.toString() : '',
      MaxGrossValue: maxGrossValue ? maxGrossValue?.toString() : '',
      PrimaryCategory: primaryCategory,
      Vibe: vibe,
      Tempo: tempo,
      Tone: tone,
      SecondaryCategory: secondaryCategory,
    });
  };

  const handleMinGrossValueChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let userInput = e.target.value;
    userInput = userInput.replace(/[^0-9.,]/g, '');
    userInput = userInput.replace(',', '.');
    setMinGrossValue(userInput);
    sessionStorage.setItem('trackFilterMinGrossValue', userInput);
    setSearchParams({
      SearchString: searchString,
      ProducerName: producerName,
      IsWinwin: isWinwin ? 'yes' : 'no',
      IsFavourite: isFavourite ? 'yes' : 'no',
      HasWaveFile: hasWaveFile ? 'yes' : 'no',
      MinGrossValue: userInput ? userInput?.toString() : '',
      MaxGrossValue: maxGrossValue ? maxGrossValue?.toString() : '',
      PrimaryCategory: primaryCategory,
      Vibe: vibe,
      Tempo: tempo,
      Tone: tone,
      SecondaryCategory: secondaryCategory,
    });
  };

  const handleMaxGrossValueChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let userInput = e.target.value;
    userInput = userInput.replace(/[^0-9.,]/g, '');
    userInput = userInput.replace(',', '.');
    setMaxGrossValue(userInput);
    sessionStorage.setItem('trackFilterMaxGrossValue', userInput);
    setSearchParams({
      SearchString: searchString,
      ProducerName: producerName,
      IsWinwin: isWinwin ? 'yes' : 'no',
      IsFavourite: isFavourite ? 'yes' : 'no',
      HasWaveFile: hasWaveFile ? 'yes' : 'no',
      MinGrossValue: minGrossValue ? minGrossValue?.toString() : '',
      MaxGrossValue: userInput ? userInput?.toString() : '',
      PrimaryCategory: primaryCategory,
      Vibe: vibe,
      Tempo: tempo,
      Tone: tone,
      SecondaryCategory: secondaryCategory,
    });
  };

  const getAllOffers = async (
    searchString: string,
    sortBy: SortBy | string,
    productType: string | number,
    isWinwin: boolean,
    isFavourite: boolean,
    hasWaveFile: boolean,
    producerName: string,
    vibe: string,
    tempo: string,
    tone: string,
    minGrossValue: number | undefined | string,
    maxGrossValue: number | undefined | string,
    primaryCategory: string,
    secondaryCategory: string,
    tertiaryCategory: string,
    pageNumber: number,
  ) => {
    const attributeIds = [];
    if (vibe) attributeIds.push(vibe);
    if (tempo) attributeIds.push(tempo);
    if (tone) attributeIds.push(tone);

    try {
      setIsLoading(true);
      const params = {
        searchString,
        pageNumer: pageNumber,
        pageSize: 20,
        productType: 8,
        minGrossValue: minGrossValue ? +minGrossValue * 100 : undefined,
        maxGrossValue: maxGrossValue ? +maxGrossValue * 100 : undefined,
        isWinwin: isWinwin,
        // isWinwin:
        //   isWinwin === 'true' ? true : isWinwin === 'false' ? false : '',
        isFavourite,
        hasWaveFile,
        producerName,
        attributeIds,
        sortType:
          sortBy === SortBy.DATE_ASCENDING ||
          sortBy === SortBy.NAME_ASCENDING ||
          sortBy === SortBy.PRICE_ASCENDING
            ? 0
            : 1,
        sortBy:
          sortBy === SortBy.NAME_ASCENDING || sortBy === SortBy.NAME_DESCENDING
            ? 1
            : sortBy === SortBy.PRICE_ASCENDING ||
              sortBy === SortBy.PRICE_DESCENDING
            ? 0
            : sortBy === SortBy.POPULARITY
            ? 3
            : 2,
        categoryId: primaryCategory ? primaryCategory : undefined,
        secondCategoryId: secondaryCategory ? secondaryCategory : undefined,
        thirdCategoryId: tertiaryCategory ? tertiaryCategory : undefined,
      };

      const response = await api.get('Offer', {
        params,
        paramsSerializer: (params) => qs.stringify(params),
        headers: cookies.token && {
          Authorization: `Bearer ${cookies.token}`,
        },
      });
      const offersWithMusicProductOnly = response.data.Response.filter(
        (offer) => offer.ProductType === 8,
      );

      setHasMoreProducers(response.data.Response.length > 0);
      setOffers((prev) => [...prev, ...offersWithMusicProductOnly]);

      const arrForTrackPlayer = offersWithMusicProductOnly.map((item) => ({
        Song: item.ProductDemoFile,
        CoverFile: item.CoverFile,
        Name: item.Name,
        AttributeValues: item.AttributeValues,
        FirstCategoryName: item.Category.Name,
        SecondCategoryName:
          item.SecondCategoryId !== null ? item.SecondCategoryId.Name : '',
        ThirdCategoryName:
          item.ThirdCategoryId !== null ? item.ThirdCategoryId.Name : '',
        ProductId: item.ProductId,
      }));
      if (arrForTrackPlayer.length > 0) {
        // const arrForPlaylist = mergeUniqueProducts(playlist, arrForTrackPlayer);

        setPlaylistAndPlay(arrForTrackPlayer);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getRoles = async () => {
    try {
      const response = await api.get('Producer/Roles');
      setRoles(response.data.Response);
    } catch (error) {
      console.log(error);
    }
  };

  const mapCategoriesForFilter = () => {
    offers.map((offer) => {
      if (!offer.Category || !offer.Category.CategoryId) return;
      setPrimaryCategories((prev) =>
        [...prev.filter((el) => el.Id === offer.Category?.CategoryId)]
          .length === 0
          ? [
              ...prev,
              {
                Name:
                  categories.filter(
                    (el) => el.Id === offer.Category?.CategoryId,
                  )[0].Name || offer.Category?.Name,
                Id: offer.Category?.CategoryId,
                ParentCategoryId: null,
              },
            ]
          : [...prev],
      );
    });

    offers.map((offer) => {
      if (!offer.SecondCategoryId || !!offer.SecondCategoryId.CategoryId)
        return;
      setSecondaryCategories((prev) =>
        [...prev.filter((el) => el.Id === offer.SecondCategoryId?.CategoryId)]
          .length === 0
          ? [
              ...prev,
              {
                Name:
                  categories.filter(
                    (el) => el.Id === offer.SecondCategoryId?.CategoryId,
                  )[0].Name || offer.SecondCategoryId?.Name,
                Id: offer.SecondCategoryId?.CategoryId,
                ParentCategoryId: offer.Category?.CategoryId,
              },
            ]
          : [...prev],
      );
    });

    offers.map((offer) => {
      if (!offer.ThirdCategoryId || !offer.ThirdCategoryId.CategoryId) return;
      setTertiaryCategories((prev) =>
        [
          ...prev.filter(
            (el) =>
              el.Id === offer.ThirdCategoryId?.CategoryId ||
              offer.ThirdCategoryId?.CategoryId === null,
          ),
        ].length === 0
          ? [
              ...prev,
              {
                Name:
                  categories.filter(
                    (el) => el.Id === offer.ThirdCategoryId?.CategoryId,
                  )[0].Name || offer.ThirdCategoryId?.Name,
                Id: offer.ThirdCategoryId?.CategoryId,
                ParentCategoryId: offer.SecondCategoryId?.CategoryId,
              },
            ]
          : [...prev],
      );
    });
  };

  const getAllOffersDebounce = useCallback(
    _.debounce(
      (
        searchString,
        sortBy,
        productType,
        isWinwin,
        isFavourite,
        hasWaveFile,
        producerName,
        vibe,
        tempo,
        tone,
        minGrossValue,
        maxGrossValue,
        primaryCategory,
        secondaryCategory,
        tertiaryCategory,
        pageNumber,
      ) =>
        getAllOffers(
          searchString,
          sortBy,
          productType,
          isWinwin,
          isFavourite,
          hasWaveFile,
          producerName,
          vibe,
          tempo,
          tone,
          minGrossValue,
          maxGrossValue,
          primaryCategory,
          secondaryCategory,
          tertiaryCategory,
          pageNumber,
        ),
      500,
    ),
    [],
  );

  const getAllCategories = async () => {
    try {
      const response = await api.get(`product-constants/categories`);
      setCategories(response.data.Response);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteOffer = async (id: string) => {
    try {
      const response = await api.delete(`Offer/${id}`, {
        headers: {Authorization: `Bearer ${cookies.token}`},
      });
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleIsFilterMenuOpenChange = (value: boolean) =>
    setIsFilterMenuOpen(value);

  const handleIsSortMenuOpenChange = (value: boolean) =>
    setIsSortMenuOpen(value);

  const handleSortByChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    sessionStorage.setItem('trackFilterSortBy', e.target.value);
    setSortBy(e.target.value);
  };

  const handleIsWinwinChange = (value: boolean) => {
    setIsWinwin(value);
    if (value === true) {
      sessionStorage.setItem('trackFilterIsWinwin', 'yes');
    } else {
      sessionStorage.setItem('trackFilterIsWinwin', 'no');
    }
    setSearchParams({
      SearchString: searchString,
      ProducerName: producerName,
      IsWinwin: value ? 'yes' : 'no',
      IsFavourite: isFavourite ? 'yes' : 'no',
      HasWaveFile: hasWaveFile ? 'yes' : 'no',
      MinGrossValue: minGrossValue ? minGrossValue?.toString() : '',
      MaxGrossValue: maxGrossValue ? maxGrossValue?.toString() : '',
      PrimaryCategory: primaryCategory,
      Vibe: vibe,
      Tempo: tempo,
      Tone: tone,
      SecondaryCategory: secondaryCategory,
    });
  };

  const handleIsFavouriteChange = (value: boolean) => {
    setIsFavourite(value);
    if (value === true) {
      sessionStorage.setItem('trackFilterIsFavourite', 'yes');
    } else {
      sessionStorage.setItem('trackFilterIsFavourite', 'no');
    }
    setSearchParams({
      SearchString: searchString,
      ProducerName: producerName,
      IsWinwin: isWinwin ? 'yes' : 'no',
      IsFavourite: value ? 'yes' : 'no',
      HasWaveFile: hasWaveFile ? 'yes' : 'no',
      MinGrossValue: minGrossValue ? minGrossValue?.toString() : '',
      MaxGrossValue: maxGrossValue ? maxGrossValue?.toString() : '',
      PrimaryCategory: primaryCategory,
      Vibe: vibe,
      Tempo: tempo,
      Tone: tone,
      SecondaryCategory: secondaryCategory,
    });
  };

  const handleHasWaveFileChange = (value: boolean) => {
    setHasWaveFile(value);
    if (value === true) {
      sessionStorage.setItem('trackFilterHasWaveFile', 'yes');
    } else {
      sessionStorage.setItem('trackFilterHasWaveFile', 'no');
    }
    setSearchParams({
      SearchString: searchString,
      ProducerName: producerName,
      IsWinwin: isWinwin ? 'yes' : 'no',
      IsFavourite: isFavourite ? 'yes' : 'no',
      HasWaveFile: value ? 'yes' : 'no',
      MinGrossValue: minGrossValue ? minGrossValue?.toString() : '',
      MaxGrossValue: maxGrossValue ? maxGrossValue?.toString() : '',
      PrimaryCategory: primaryCategory,
      Vibe: vibe,
      Tempo: tempo,
      Tone: tone,
      SecondaryCategory: secondaryCategory,
    });
  };

  const handleProducerNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProducerName(e.target.value);
    sessionStorage.setItem('trackFilterProducerName', e.target.value);
    setSearchParams({
      SearchString: searchString,
      ProducerName: e.target.value,
      IsWinwin: isWinwin ? 'yes' : 'no',
      IsFavourite: isFavourite ? 'yes' : 'no',
      HasWaveFile: hasWaveFile ? 'yes' : 'no',
      MinGrossValue: minGrossValue ? minGrossValue?.toString() : '',
      MaxGrossValue: maxGrossValue ? maxGrossValue?.toString() : '',
      PrimaryCategory: primaryCategory,
      Vibe: vibe,
      Tempo: tempo,
      Tone: tone,
      SecondaryCategory: secondaryCategory,
    });
  };

  const handleProductTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setProductType(+e.target.value);

  const handlePrimaryCategoryChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setPrimaryCategory(e.target.value);
    sessionStorage.setItem('trackFilterPrimaryCategory', e.target.value);
    setSearchParams({
      SearchString: searchString,
      ProducerName: producerName,
      IsWinwin: isWinwin ? 'yes' : 'no',
      IsFavourite: isFavourite ? 'yes' : 'no',
      HasWaveFile: hasWaveFile ? 'yes' : 'no',
      MinGrossValue: minGrossValue ? minGrossValue?.toString() : '',
      MaxGrossValue: maxGrossValue ? maxGrossValue?.toString() : '',
      PrimaryCategory: e.target.value,
      Vibe: vibe,
      Tempo: tempo,
      Tone: tone,
      SecondaryCategory: secondaryCategory,
    });
  };

  const handleVibeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setVibe(e.target.value);
    sessionStorage.setItem('trackFilterVibe', e.target.value);
    setSearchParams({
      SearchString: searchString,
      ProducerName: producerName,
      IsWinwin: isWinwin ? 'yes' : 'no',
      IsFavourite: isFavourite ? 'yes' : 'no',
      HasWaveFile: hasWaveFile ? 'yes' : 'no',
      MinGrossValue: minGrossValue ? minGrossValue?.toString() : '',
      MaxGrossValue: maxGrossValue ? maxGrossValue?.toString() : '',
      PrimaryCategory: primaryCategory,
      Vibe: e.target.value,
      Tempo: tempo,
      Tone: tone,
      SecondaryCategory: secondaryCategory,
    });
  };

  const handleTempoChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTempo(e.target.value);
    sessionStorage.setItem('trackFilterTempo', e.target.value);
    setSearchParams({
      SearchString: searchString,
      ProducerName: producerName,
      IsWinwin: isWinwin ? 'yes' : 'no',
      IsFavourite: isFavourite ? 'yes' : 'no',
      HasWaveFile: hasWaveFile ? 'yes' : 'no',
      MinGrossValue: minGrossValue ? minGrossValue?.toString() : '',
      MaxGrossValue: maxGrossValue ? maxGrossValue?.toString() : '',
      PrimaryCategory: primaryCategory,
      Vibe: vibe,
      Tempo: e.target.value,
      Tone: tone,
      SecondaryCategory: secondaryCategory,
    });
  };

  const handleToneChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTone(e.target.value);
    sessionStorage.setItem('trackFilterTone', e.target.value);
    setSearchParams({
      SearchString: searchString,
      ProducerName: producerName,
      IsWinwin: isWinwin ? 'yes' : 'no',
      IsFavourite: isFavourite ? 'yes' : 'no',
      HasWaveFile: hasWaveFile ? 'yes' : 'no',
      MinGrossValue: minGrossValue ? minGrossValue?.toString() : '',
      MaxGrossValue: maxGrossValue ? maxGrossValue?.toString() : '',
      PrimaryCategory: primaryCategory,
      Vibe: vibe,
      Tempo: tempo,
      Tone: e.target.value,
      SecondaryCategory: secondaryCategory,
    });
  };

  const handleSecondaryCategoryChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSecondaryCategory(e.target.value);
    sessionStorage.setItem('trackFilterSecondaryCategory', e.target.value);
    setSearchParams({
      SearchString: searchString,
      ProducerName: producerName,
      IsWinwin: isWinwin ? 'yes' : 'no',
      IsFavourite: isFavourite ? 'yes' : 'no',
      HasWaveFile: hasWaveFile ? 'yes' : 'no',
      MinGrossValue: minGrossValue ? minGrossValue?.toString() : '',
      MaxGrossValue: maxGrossValue ? maxGrossValue?.toString() : '',
      PrimaryCategory: primaryCategory,
      Vibe: vibe,
      Tempo: tempo,
      Tone: tone,
      SecondaryCategory: e.target.value,
    });
  };

  const handleTertiaryCategoryChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setTertiaryCategory(e.target.value);
    sessionStorage.setItem('trackFilterTertiaryCategory', e.target.value);
  };

  const clearFilters = () => {
    setIsFavourite(false);
    sessionStorage.removeItem('trackFilterIsFavourite');
    setIsWinwin(false);
    sessionStorage.removeItem('trackFilterIsWinwin');
    setHasWaveFile(false);
    sessionStorage.removeItem('trackFilterHasWaveFile');
    setProducerName('');
    sessionStorage.removeItem('trackFilterProducerName');
    setMinGrossValue(null);
    sessionStorage.removeItem('trackFilterMinGrossValue');
    setMaxGrossValue(null);
    sessionStorage.removeItem('trackFilterMaxGrossValue');
    setPrimaryCategory('');
    sessionStorage.removeItem('trackFilterPrimaryCategory');
    setVibe('');
    sessionStorage.removeItem('trackFilterVibe');
    setTempo('');
    sessionStorage.removeItem('trackFilterTempo');
    setTone('');
    sessionStorage.removeItem('trackFilterTone');
    setSecondaryCategory('');
    sessionStorage.removeItem('trackFilterSecondaryCategory');
    setTertiaryCategory('');
    sessionStorage.removeItem('trackFilterTertiaryCategory');
    setSearchString('');
    sessionStorage.removeItem('trackFilterSearchString');
    setSearchParams({});
  };

  useEffect(() => {
    const urlSearchString = searchParams.get('SearchString');
    const urlProducerName = searchParams.get('ProducerName');
    const urlMinGrossValue = searchParams.get('MinGrossValue');
    const urlMaxGrossValue = searchParams.get('MaxGrossValue');
    const urlPrimaryCategory = searchParams.get('PrimaryCategory');
    const urlVibe = searchParams.get('Vibe');
    const urlTempo = searchParams.get('Tempo');
    const urlTone = searchParams.get('Tone');
    const urlSecondaryCategory = searchParams.get('SecondaryCategory');
    const urlIsWinwin = searchParams.get('IsWinwin');
    const urlIsFavourite = searchParams.get('IsFavourite');
    const urlHasWaveFile = searchParams.get('HasWaveFile');

    if (urlSearchString) {
      setSearchString(urlSearchString);
      sessionStorage.setItem('trackFilterSearchString', urlSearchString);
    }

    if (urlProducerName) {
      setProducerName(urlProducerName);
      sessionStorage.setItem('trackFilterProducerName', urlProducerName);
    }

    if (urlMinGrossValue) {
      setMinGrossValue(+urlMinGrossValue);
      sessionStorage.setItem('trackFilterMinGrossValue', urlMinGrossValue);
    }

    if (urlMaxGrossValue) {
      setMaxGrossValue(+urlMaxGrossValue);
      sessionStorage.setItem('trackFilterMaxGrossValue', urlMaxGrossValue);
    }

    if (urlPrimaryCategory) {
      setPrimaryCategory(urlPrimaryCategory);
      sessionStorage.setItem('trackFilterPrimaryCategory', urlPrimaryCategory);
    }

    if (urlVibe) {
      setVibe(urlVibe);
      sessionStorage.setItem('trackFilterVibe', urlVibe);
    }

    if (urlTempo) {
      setTempo(urlTempo);
      sessionStorage.setItem('trackFilterTempo', urlTempo);
    }

    if (urlTone) {
      setTone(urlTone);
      sessionStorage.setItem('trackFilterTone', urlTone);
    }

    if (urlSecondaryCategory) {
      setSecondaryCategory(urlSecondaryCategory);
      sessionStorage.setItem(
        'trackFilterSecondaryCategory',
        urlSecondaryCategory,
      );
    }

    if (urlIsWinwin) {
      const isWinwinValue = urlIsWinwin === 'yes';
      setIsWinwin(isWinwinValue);
      sessionStorage.setItem('trackFilterIsWinwin', urlIsWinwin);
    }

    if (urlIsFavourite) {
      const isFavouriteValue = urlIsFavourite === 'yes';
      setIsFavourite(isFavouriteValue);
      sessionStorage.setItem('trackFilterIsFavourite', urlIsFavourite);
    }

    if (urlHasWaveFile) {
      const hasWaveFileValue = urlHasWaveFile === 'yes';
      setHasWaveFile(hasWaveFileValue);
      sessionStorage.setItem('trackFilterHasWaveFile', urlHasWaveFile);
    }
    closePlayer();
    resetPlayer();
  }, [searchParams]);

  useEffect(() => {
    getAllOffersDebounce(
      searchString,
      sortBy,
      productType,
      isWinwin,
      isFavourite,
      hasWaveFile,
      producerName,
      vibe,
      tempo,
      tone,
      minGrossValue,
      maxGrossValue,
      primaryCategory,
      secondaryCategory,
      tertiaryCategory,
      pageNumber,
    );
  }, [
    searchString,
    sortBy,
    productType,
    isWinwin,
    isFavourite,
    hasWaveFile,
    producerName,
    vibe,
    tempo,
    tone,
    minGrossValue,
    maxGrossValue,
    primaryCategory,
    secondaryCategory,
    tertiaryCategory,
    pageNumber,
  ]);

  useEffect(() => {
    setOffers([]);
    setPageNumber(0);
  }, [
    searchString,
    sortBy,
    productType,
    isWinwin,
    isFavourite,
    hasWaveFile,
    producerName,
    vibe,
    tempo,
    tone,
    minGrossValue,
    maxGrossValue,
    primaryCategory,
    secondaryCategory,
    tertiaryCategory,
  ]);

  useEffect(() => {
    getAllCategories();
    getRoles();
    getProductAttributes();
  }, []);

  useEffect(() => {
    if (primaryCategory !== '') {
      sessionStorage.setItem('trackFilterPrimaryCategory', primaryCategory);
    }
    setSecondaryCategory('');
    setTertiaryCategory('');
    sessionStorage.setItem('trackFilterSecondaryCategory', '');
    sessionStorage.setItem('trackFilterTertiaryCategory', '');
  }, [primaryCategory]);

  useEffect(() => {
    if (secondaryCategory !== '') {
      sessionStorage.setItem('trackFilterSecondaryCategory', secondaryCategory);
      setTertiaryCategory('');
      sessionStorage.setItem('trackFilterTertiaryCategory', '');
    }
  }, [secondaryCategory]);

  useEffect(() => {
    if (tertiaryCategory !== '') {
      sessionStorage.setItem('trackFilterTertiaryCategory', tertiaryCategory);
    }
  }, [tertiaryCategory]);

  useEffect(() => {
    if (offers.length === 0 || categories.length === 0) return;
    mapCategoriesForFilter();
  }, [offers, categories]);

  useEffect(() => {
    const checkCloseButtonVisibility = () => {
      if (mobileFilterMenuWrapperRef.current) {
        if (isMobileFilterMenuOpen) {
          mobileFilterMenuWrapperRef.current.style.height = `${window.visualViewport.height}px`;
        } else {
          mobileFilterMenuWrapperRef.current.style.height = `${0}px`;
        }
      }
    };

    checkCloseButtonVisibility();

    window.addEventListener('resize', checkCloseButtonVisibility);

    return () => {
      window.removeEventListener('resize', checkCloseButtonVisibility);
    };
  }, [isMobileFilterMenuOpen]);

  useEffect(() => {
    const scrollToOfferId = sessionStorage.getItem('scrollToOffer');
    const el = document.getElementById(scrollToOfferId);
    if (el) {
      el.scrollIntoView();
      sessionStorage.removeItem('scrollToOffer');
    }
  }, [isLoading]);

  // const handleScroll = () => {
  //   if (!scrollRef.current || isLoading || !hasMoreProducers) return;

  //   const scrollElement = scrollRef.current;

  //   if (
  //     scrollElement.scrollTop + scrollElement.clientHeight !==
  //       scrollElement.scrollHeight ||
  //     isLoading
  //   ) {
  //     return;
  //   }
  //   getAllOffers(
  //     searchString,
  //     sortBy,
  //     productType,
  //     isWinwin,
  //     isFavourite,
  //     hasWaveFile,
  //     producerName,
  //     vibe,
  //     tempo,
  //     tone,
  //     minGrossValue,
  //     maxGrossValue,
  //     primaryCategory,
  //     secondaryCategory,
  //     tertiaryCategory,
  //     pageNumber,
  //   );
  // };

  // const handleMobileScroll = () => {
  //   if (
  //     window.innerHeight + document.documentElement.scrollTop !==
  //       document.documentElement.offsetHeight ||
  //     isLoading
  //   ) {
  //     return;
  //   }
  //   getAllOffers(
  //     searchString,
  //     sortBy,
  //     productType,
  //     isWinwin,
  //     isFavourite,
  //     hasWaveFile,
  //     producerName,
  //     vibe,
  //     tempo,
  //     tone,
  //     minGrossValue,
  //     maxGrossValue,
  //     primaryCategory,
  //     secondaryCategory,
  //     tertiaryCategory,
  //     pageNumber,
  //   );
  // };

  // useEffect(() => {
  //   const scrollElement = scrollRef.current;

  //   if (!scrollElement) return;

  //   scrollElement.addEventListener('scroll', handleScroll);
  //   return () => scrollElement.removeEventListener('scroll', handleScroll);
  // }, [isLoading, hasMoreProducers, pageNumber]);

  // useEffect(() => {
  //   window.addEventListener('scroll', handleMobileScroll);
  //   return () => window.removeEventListener('scroll', handleMobileScroll);
  // }, [isLoading, hasMoreProducers, pageNumber]);

  useEffect(() => {
    document.title = `MUGO Studio - Bity`;
    document
      .querySelector("meta[property='og:title']")
      ?.setAttribute('content', `MUGO Studio - Bity`);
    document
      .querySelector("meta[property='og:description']")
      ?.setAttribute(
        'content',
        'MUGO Studio to przestrzeń dla twórców, freelancerów, artystów i ludzi poszukujących usług oraz produktów oferowanych przez te osoby. To platforma stworzona dla usługodawców i usługobiorców, gwarantująca bezpieczeństwo transakcji oraz stwarzająca możliwość współpracy na innowacyjnych warunkach.',
      );
  }, []);

  return (
    <>
      <MainWrapper>
        <Main>
          {isLoading && <LoadingCircle />}
          <PageHeaderWrapper
            onMouseLeave={() => {
              handleIsFilterMenuOpenChange(false);
              handleIsSortMenuOpenChange(false);
            }}
          >
            <PageHeaderHeaderWrapper>
              <PageHeaderHeader>
                <p>Bity</p>
              </PageHeaderHeader>
            </PageHeaderHeaderWrapper>
            <PageHeaderContent>
              <FilterWrapper>
                <SortButtonSvg
                  value={isSortMenuOpen}
                  onClick={() => {
                    handleIsSortMenuOpenChange(!isSortMenuOpen);
                    handleIsFilterMenuOpenChange(false);
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="18.824"
                  height="13.204"
                  viewBox="0 0 18.824 13.204"
                >
                  <g
                    id="Group_1311"
                    data-name="Group 1311"
                    transform="translate(-2536.541 -534.989)"
                  >
                    <path
                      id="Path_1508"
                      data-name="Path 1508"
                      d="M2543.457,537.33l-2.582-2.1a1.076,1.076,0,0,0-1.357,0l-2.581,2.1a1.075,1.075,0,0,0,1.357,1.668l.9-.732v6.852a1.075,1.075,0,1,0,2.15,0v-6.735l.756.615a1.075,1.075,0,1,0,1.357-1.668Z"
                      fill="#797c93"
                    />
                    <path
                      id="Path_1509"
                      data-name="Path 1509"
                      d="M2548.448,545.852l2.582,2.1a1.074,1.074,0,0,0,1.357,0l2.581-2.1a1.075,1.075,0,0,0-1.357-1.668l-.9.732v-6.852a1.075,1.075,0,0,0-2.151,0V544.8l-.756-.615a1.075,1.075,0,0,0-1.357,1.668Z"
                      fill="#797c93"
                    />
                  </g>
                </SortButtonSvg>
                <FilterInputWrapper>
                  {/* <img src={searchIcon} alt="search" /> */}
                  <svg
                    id="Group_4104"
                    data-name="Group 4104"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14.146"
                    height="14"
                    viewBox="0 0 14.146 14"
                  >
                    <path
                      id="Path_1507"
                      data-name="Path 1507"
                      d="M2601.826,547.617l-3.519-3.207a5.82,5.82,0,0,0-.448-7.709h0a5.816,5.816,0,1,0-.7,8.813l3.6,3.28a.8.8,0,1,0,1.072-1.177Zm-11.3-3.586a4.554,4.554,0,1,1,3.219,1.333A4.559,4.559,0,0,1,2590.529,544.03Z"
                      transform="translate(-2587.94 -535.001)"
                      fill="#0091ff"
                    />
                  </svg>
                  <input
                    type="text"
                    placeholder="Wyszukaj..."
                    value={searchString}
                    onChange={handleSearchStringChange}
                  />
                </FilterInputWrapper>
                {countTruthyVariables(
                  searchString,
                  primaryCategory,
                  secondaryCategory,
                  tertiaryCategory,
                  isWinwin,
                  isFavourite,
                  hasWaveFile,
                  producerName,
                  vibe,
                  tempo,
                  tone,
                  minGrossValue,
                  maxGrossValue,
                ) > 0 && (
                  <CountFilterDisplay>
                    {countTruthyVariables(
                      searchString,
                      primaryCategory,
                      secondaryCategory,
                      tertiaryCategory,
                      isWinwin,
                      isFavourite,
                      hasWaveFile,
                      producerName,
                      vibe,
                      tempo,
                      tone,
                      minGrossValue,
                      maxGrossValue,
                    )}
                  </CountFilterDisplay>
                )}
                <FilterButtonSvg
                  value={isFilterMenuOpen}
                  onClick={() => {
                    handleIsSortMenuOpenChange(false);
                    handleIsFilterMenuOpenChange(!isFilterMenuOpen);
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.87"
                  height="15.047"
                  viewBox="0 0 20.87 15.047"
                >
                  <g
                    id="Group_1312"
                    data-name="Group 1312"
                    transform="translate(-2473.175 -536.818)"
                  >
                    <path
                      id="Path_1510"
                      data-name="Path 1510"
                      d="M2480.2,539.042h13a.85.85,0,0,0,0-1.7h-13a.85.85,0,0,0,0,1.7Z"
                      fill="#797c93"
                    />
                    <path
                      id="Path_1511"
                      data-name="Path 1511"
                      d="M2493.2,543.517h-13a.85.85,0,0,0,0,1.7h13a.85.85,0,0,0,0-1.7Z"
                      fill="#797c93"
                    />
                    <path
                      id="Path_1512"
                      data-name="Path 1512"
                      d="M2493.2,549.4h-13a.85.85,0,0,0,0,1.7h13a.85.85,0,0,0,0-1.7Z"
                      fill="#797c93"
                    />
                    <circle
                      id="Ellipse_1"
                      data-name="Ellipse 1"
                      cx="1.5"
                      cy="1.5"
                      r="1.5"
                      transform="translate(2473.175 548.864)"
                      fill="#797c93"
                    />
                    <circle
                      id="Ellipse_2"
                      data-name="Ellipse 2"
                      cx="1.5"
                      cy="1.5"
                      r="1.5"
                      transform="translate(2473.175 542.853)"
                      fill="#797c93"
                    />
                    <circle
                      id="Ellipse_3"
                      data-name="Ellipse 3"
                      cx="1.5"
                      cy="1.5"
                      r="1.5"
                      transform="translate(2473.175 536.818)"
                      fill="#797c93"
                    />
                  </g>
                </FilterButtonSvg>
                {countTruthyVariables(
                  searchString,
                  primaryCategory,
                  secondaryCategory,
                  tertiaryCategory,
                  isWinwin,
                  isFavourite,
                  hasWaveFile,
                  producerName,
                  vibe,
                  tempo,
                  tone,
                  minGrossValue,
                  maxGrossValue,
                ) > 0 && (
                  <ClearFiltersButton onClick={() => clearFilters()}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10.9"
                      height="12"
                      viewBox="0 0 10.9 12"
                    >
                      <g
                        id="Icon_feather-trash"
                        data-name="Icon feather-trash"
                        transform="translate(0.5 0.5)"
                      >
                        <path
                          id="Path_3663"
                          data-name="Path 3663"
                          d="M4.5,9h9.9"
                          transform="translate(-4.5 -6.8)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                        <path
                          id="Path_3664"
                          data-name="Path 3664"
                          d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                          transform="translate(-6.4 -3)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                      </g>
                    </svg>
                  </ClearFiltersButton>
                )}
              </FilterWrapper>
              {isSortMenuOpen && (
                <FilterMenuWrapper>
                  <SingleFilterWrapper>
                    <SingleFilterContentWrapper>
                      <SelectWrapper>
                        <p>Kolejność</p>
                        <select onChange={handleSortByChange} value={sortBy}>
                          <option hidden value={''}>
                            Sortowanie
                          </option>
                          <option value={SortBy.DATE_ASCENDING}>
                            Od najstarszego
                          </option>
                          <option value={SortBy.DATE_DESCENDING}>
                            Od najnowszego
                          </option>
                          <option value={SortBy.NAME_ASCENDING}>
                            Nazwa A-Z
                          </option>
                          <option value={SortBy.NAME_DESCENDING}>
                            Nazwa Z-A
                          </option>
                          <option value={SortBy.PRICE_ASCENDING}>
                            Cena od najmniejszej
                          </option>
                          <option value={SortBy.PRICE_DESCENDING}>
                            Cena od największej
                          </option>
                          <option value={SortBy.POPULARITY}>Popularność</option>
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#0091ff"
                            />
                          </g>
                        </svg>
                      </SelectWrapper>
                    </SingleFilterContentWrapper>
                  </SingleFilterWrapper>
                </FilterMenuWrapper>
              )}
              {isFilterMenuOpen && (
                <FilterMenuWrapper>
                  <SingleFilterWrapper>
                    <SingleFilterContentWrapper>
                      <SelectWrapper>
                        <p>Kategoria</p>
                        <select
                          value={primaryCategory}
                          onChange={handlePrimaryCategoryChange}
                        >
                          <option hidden value={''}>
                            Wybierz
                          </option>
                          <option value={''}>Wszystko</option>
                          {primaryCategories
                            .filter(
                              (category) => category.ParentCategoryId === null,
                            )
                            .map((category) => {
                              const {Name, Id} = category;

                              return (
                                <option value={Id} key={Id}>
                                  {Name.length > 25
                                    ? Name.slice(0, 25) + '...'
                                    : Name}
                                </option>
                              );
                            })}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#0091ff"
                            />
                          </g>
                        </svg>
                      </SelectWrapper>
                      <SelectWrapper>
                        <p>Podkategoria</p>
                        <select
                          value={secondaryCategory}
                          onChange={handleSecondaryCategoryChange}
                        >
                          <option hidden value={''}>
                            Wybierz
                          </option>
                          <option value={''}>Wszystko</option>

                          {secondaryCategories
                            .filter(
                              (category) =>
                                category.ParentCategoryId === primaryCategory,
                            )
                            .map((category) => {
                              const {Name, Id} = category;

                              return (
                                <option value={Id} key={Id}>
                                  {Name.length > 25
                                    ? Name.slice(0, 25) + '...'
                                    : Name}
                                </option>
                              );
                            })}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#0091ff"
                            />
                          </g>
                        </svg>
                      </SelectWrapper>
                      <SelectWrapper>
                        <p>Nastrój</p>
                        <select value={vibe} onChange={handleVibeChange}>
                          <option hidden value={''}>
                            Wybierz
                          </option>
                          <option value={''}>Wszystko</option>
                          {productAttributes.length > 0 &&
                            productAttributes
                              .filter(
                                (productAttribute) =>
                                  productAttribute.Id ===
                                  'f65268e6-c5f9-4f82-9203-68c673cbab08',
                              )[0]
                              .AttributeValues.map((attributeValue) => {
                                const {Id, Name} = attributeValue;

                                return (
                                  <option value={Id} key={Id}>
                                    {Name}
                                  </option>
                                );
                              })}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#0091ff"
                            />
                          </g>
                        </svg>
                      </SelectWrapper>
                      <SelectWrapper>
                        <p>Tempo</p>
                        <select value={tempo} onChange={handleTempoChange}>
                          <option hidden value={''}>
                            Wybierz
                          </option>
                          <option value={''}>Wyczyść</option>
                          {productAttributes.length > 0 &&
                            productAttributes
                              .filter(
                                (productAttribute) =>
                                  productAttribute.Id ===
                                  'f65268e6-c5f9-4f82-9203-68c673cbab06',
                              )[0]
                              .AttributeValues.map((attributeValue) => {
                                const {Id, Name} = attributeValue;

                                return (
                                  <option value={Id} key={Id}>
                                    {Name}
                                  </option>
                                );
                              })}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#0091ff"
                            />
                          </g>
                        </svg>
                      </SelectWrapper>
                      <SelectWrapper>
                        <p>Tonacja</p>
                        <select value={tone} onChange={handleToneChange}>
                          <option hidden value={''}>
                            Wybierz
                          </option>
                          <option value={''}>Wszystko</option>
                          {productAttributes.length > 0 &&
                            productAttributes
                              .filter(
                                (productAttribute) =>
                                  productAttribute.Id ===
                                  'f65268e6-c5f9-4f82-9203-68c673cbab07',
                              )[0]
                              .AttributeValues.map((attributeValue) => {
                                const {Id, Name} = attributeValue;

                                return (
                                  <option value={Id} key={Id}>
                                    {Name}
                                  </option>
                                );
                              })}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#0091ff"
                            />
                          </g>
                        </svg>
                      </SelectWrapper>
                    </SingleFilterContentWrapper>
                  </SingleFilterWrapper>

                  <SingleFilterWrapper>
                    <SingleFilterContentWrapper>
                      <FilterInput>
                        <p>Twórca</p>
                        <input
                          type="text"
                          placeholder="Znajdź..."
                          value={producerName}
                          onChange={handleProducerNameChange}
                        />
                        <svg
                          id="Group_4104"
                          data-name="Group 4104"
                          xmlns="http://www.w3.org/2000/svg"
                          width="13.136"
                          height="13"
                          viewBox="0 0 13.136 13"
                        >
                          <path
                            id="Path_1507"
                            data-name="Path 1507"
                            d="M2600.834,546.715l-3.268-2.978a5.4,5.4,0,0,0-.416-7.158h0a5.4,5.4,0,1,0-.652,8.183l3.342,3.046a.739.739,0,1,0,1-1.093Zm-10.49-3.33a4.229,4.229,0,1,1,2.989,1.238A4.233,4.233,0,0,1,2590.344,543.385Z"
                            transform="translate(-2587.94 -535.001)"
                            fill="#0091ff"
                          />
                        </svg>
                      </FilterInput>
                      <FilterInput>
                        <p>Cena od</p>
                        <input
                          type="text"
                          placeholder="0zł"
                          value={minGrossValue}
                          onChange={handleMinGrossValueChange}
                        />
                        <svg
                          id="Group_4104"
                          data-name="Group 4104"
                          xmlns="http://www.w3.org/2000/svg"
                          width="13.136"
                          height="13"
                          viewBox="0 0 13.136 13"
                        >
                          <path
                            id="Path_1507"
                            data-name="Path 1507"
                            d="M2600.834,546.715l-3.268-2.978a5.4,5.4,0,0,0-.416-7.158h0a5.4,5.4,0,1,0-.652,8.183l3.342,3.046a.739.739,0,1,0,1-1.093Zm-10.49-3.33a4.229,4.229,0,1,1,2.989,1.238A4.233,4.233,0,0,1,2590.344,543.385Z"
                            transform="translate(-2587.94 -535.001)"
                            fill="#0091ff"
                          />
                        </svg>
                      </FilterInput>
                      <FilterInput>
                        <p>Cena do</p>
                        <input
                          type="text"
                          placeholder="0zł"
                          value={maxGrossValue}
                          onChange={handleMaxGrossValueChange}
                        />
                        <svg
                          id="Group_4104"
                          data-name="Group 4104"
                          xmlns="http://www.w3.org/2000/svg"
                          width="13.136"
                          height="13"
                          viewBox="0 0 13.136 13"
                        >
                          <path
                            id="Path_1507"
                            data-name="Path 1507"
                            d="M2600.834,546.715l-3.268-2.978a5.4,5.4,0,0,0-.416-7.158h0a5.4,5.4,0,1,0-.652,8.183l3.342,3.046a.739.739,0,1,0,1-1.093Zm-10.49-3.33a4.229,4.229,0,1,1,2.989,1.238A4.233,4.233,0,0,1,2590.344,543.385Z"
                            transform="translate(-2587.94 -535.001)"
                            fill="#0091ff"
                          />
                        </svg>
                      </FilterInput>
                    </SingleFilterContentWrapper>
                  </SingleFilterWrapper>

                  <SingleFilterWrapper>
                    <SingleFilterContentWrapper>
                      <CheckboxWithTitleWrapper>
                        <p>Tylko Win-Win</p>
                        <CustomBlueCheckbox
                          isActive={isWinwin}
                          onClick={() => handleIsWinwinChange(!isWinwin)}
                        >
                          <div />
                        </CustomBlueCheckbox>
                      </CheckboxWithTitleWrapper>
                      <CheckboxWithTitleWrapper>
                        <p>Tylko obserwowane oferty</p>
                        <CustomBlueCheckbox
                          isActive={isFavourite}
                          onClick={() => handleIsFavouriteChange(!isFavourite)}
                        >
                          <div />
                        </CustomBlueCheckbox>
                      </CheckboxWithTitleWrapper>
                      <CheckboxWithTitleWrapper>
                        <p>Zawiera plik typu wave</p>
                        <CustomBlueCheckbox
                          isActive={hasWaveFile}
                          onClick={() => handleHasWaveFileChange(!hasWaveFile)}
                        >
                          <div />
                        </CustomBlueCheckbox>
                      </CheckboxWithTitleWrapper>
                    </SingleFilterContentWrapper>
                  </SingleFilterWrapper>
                </FilterMenuWrapper>
              )}
            </PageHeaderContent>
          </PageHeaderWrapper>
          <Grid ref={gridRef}>
            {offers.map((offer, index) => {
              return (
                <SingleDigitalProduct
                  desktopRef={lastElementRef}
                  roles={roles}
                  offer={offer}
                  key={offer.Id}
                  categories={categories}
                  index={index}
                  data-offer-id={offer.Id}
                />
              );
            })}
          </Grid>
        </Main>
      </MainWrapper>
      <MobileMainWrapper>
        <MenuMain
          isMenuOpen={isMobileFilterMenuOpen}
          ref={mobileFilterMenuWrapperRef}
        >
          <CloseIsLoginPageOpenWrapper>
            Filtrowanie
            <CloseIsLoginPageOpen
              onClick={() => {
                handleIsMobileFilterMenuOpenChange(false);
                setIsBodyOverflowBlocked(false);
                handleIsSortMenuOpenChange(false);
                handleIsFilterMenuOpenChange(false);
                mobileFilterMenuWrapperRef.current.style.transition =
                  'all 0.35s ease-out';
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16.625"
                height="16.625"
                viewBox="0 0 16.625 16.625"
              >
                <path
                  id="Path_3807"
                  data-name="Path 3807"
                  d="M27.313,24.987l-2.326,2.326L19,21.326l-5.987,5.987-2.326-2.326L16.674,19l-5.987-5.987,2.326-2.326L19,16.674l5.987-5.987,2.326,2.326L21.326,19Z"
                  transform="translate(-10.688 -10.688)"
                  fill="#07020f"
                />
              </svg>
            </CloseIsLoginPageOpen>
          </CloseIsLoginPageOpenWrapper>
          <MenuWrapper>
            {isSortMenuOpen && (
              <>
                <MobileSelectFilterWrapper value={sortBy}>
                  <select onChange={handleSortByChange} value={sortBy}>
                    <option hidden value={''}>
                      Sortowanie
                    </option>
                    <option value={SortBy.DATE_ASCENDING}>
                      Od najstarszego
                    </option>
                    <option value={SortBy.DATE_DESCENDING}>
                      Od najnowszego
                    </option>
                    <option value={SortBy.NAME_ASCENDING}>Nazwa A-Z</option>
                    <option value={SortBy.NAME_DESCENDING}>Nazwa Z-A</option>
                    <option value={SortBy.PRICE_ASCENDING}>
                      Cena od najmniejszej
                    </option>
                    <option value={SortBy.PRICE_DESCENDING}>
                      Cena od największej
                    </option>
                    <option value={SortBy.POPULARITY}>Popularność</option>
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.609"
                    height="13"
                    viewBox="0 0 7.609 13"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(-21.391 -14) rotate(180)"
                    >
                      <path
                        id="Arrow"
                        d="M6.048,12.735.185,6.941a.618.618,0,0,1,0-.882L6.048.265a.923.923,0,0,1,1.294,0,.9.9,0,0,1,0,1.278L2.327,6.5l5.015,4.956a.9.9,0,0,1,0,1.279.923.923,0,0,1-1.294,0"
                        transform="translate(-29 -27)"
                        fill="#3986ef"
                      />
                    </g>
                  </svg>
                </MobileSelectFilterWrapper>
              </>
            )}
            {isFilterMenuOpen && (
              <>
                <MobileSelectFilterWrapper value={primaryCategory}>
                  <select
                    value={primaryCategory}
                    onChange={handlePrimaryCategoryChange}
                  >
                    <option hidden value={''}>
                      Kategoria
                    </option>
                    <option value={''}>Wszystko</option>
                    {primaryCategories
                      .filter((category) => category.ParentCategoryId === null)
                      .map((category) => {
                        const {Name, Id} = category;

                        return (
                          <option value={Id} key={Id}>
                            {Name}
                          </option>
                        );
                      })}
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.609"
                    height="13"
                    viewBox="0 0 7.609 13"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(-21.391 -14) rotate(180)"
                    >
                      <path
                        id="Arrow"
                        d="M6.048,12.735.185,6.941a.618.618,0,0,1,0-.882L6.048.265a.923.923,0,0,1,1.294,0,.9.9,0,0,1,0,1.278L2.327,6.5l5.015,4.956a.9.9,0,0,1,0,1.279.923.923,0,0,1-1.294,0"
                        transform="translate(-29 -27)"
                        fill="#3986ef"
                      />
                    </g>
                  </svg>
                </MobileSelectFilterWrapper>
                <MobileSelectFilterWrapper value={secondaryCategory}>
                  <select
                    value={secondaryCategory}
                    onChange={handleSecondaryCategoryChange}
                  >
                    <option hidden value={''}>
                      Podkategoria
                    </option>
                    <option value={''}>Wszystko</option>
                    {secondaryCategories
                      .filter(
                        (category) =>
                          category.ParentCategoryId === primaryCategory,
                      )
                      .map((category) => {
                        const {Name, Id} = category;

                        return (
                          <option value={Id} key={Id}>
                            {Name}
                          </option>
                        );
                      })}
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.609"
                    height="13"
                    viewBox="0 0 7.609 13"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(-21.391 -14) rotate(180)"
                    >
                      <path
                        id="Arrow"
                        d="M6.048,12.735.185,6.941a.618.618,0,0,1,0-.882L6.048.265a.923.923,0,0,1,1.294,0,.9.9,0,0,1,0,1.278L2.327,6.5l5.015,4.956a.9.9,0,0,1,0,1.279.923.923,0,0,1-1.294,0"
                        transform="translate(-29 -27)"
                        fill="#3986ef"
                      />
                    </g>
                  </svg>
                </MobileSelectFilterWrapper>
                <MobileSelectFilterWrapper value={vibe}>
                  <select value={vibe} onChange={handleVibeChange}>
                    <option hidden value={''}>
                      Nastrój
                    </option>
                    <option value={''}>Wszystko</option>
                    {productAttributes.length > 0 &&
                      productAttributes
                        .filter(
                          (productAttribute) =>
                            productAttribute.Id ===
                            'f65268e6-c5f9-4f82-9203-68c673cbab08',
                        )[0]
                        .AttributeValues.map((attributeValue) => {
                          const {Id, Name} = attributeValue;

                          return (
                            <option value={Id} key={Id}>
                              {Name}
                            </option>
                          );
                        })}
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.609"
                    height="13"
                    viewBox="0 0 7.609 13"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(-21.391 -14) rotate(180)"
                    >
                      <path
                        id="Arrow"
                        d="M6.048,12.735.185,6.941a.618.618,0,0,1,0-.882L6.048.265a.923.923,0,0,1,1.294,0,.9.9,0,0,1,0,1.278L2.327,6.5l5.015,4.956a.9.9,0,0,1,0,1.279.923.923,0,0,1-1.294,0"
                        transform="translate(-29 -27)"
                        fill="#3986ef"
                      />
                    </g>
                  </svg>
                </MobileSelectFilterWrapper>
                <MobileSelectFilterWrapper value={tempo}>
                  <select value={tempo} onChange={handleTempoChange}>
                    <option hidden value={''}>
                      Tempo
                    </option>
                    <option value={''}>Wszystko</option>
                    {productAttributes.length > 0 &&
                      productAttributes
                        .filter(
                          (productAttribute) =>
                            productAttribute.Id ===
                            'f65268e6-c5f9-4f82-9203-68c673cbab06',
                        )[0]
                        .AttributeValues.map((attributeValue) => {
                          const {Id, Name} = attributeValue;

                          return (
                            <option value={Id} key={Id}>
                              {Name}
                            </option>
                          );
                        })}
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.609"
                    height="13"
                    viewBox="0 0 7.609 13"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(-21.391 -14) rotate(180)"
                    >
                      <path
                        id="Arrow"
                        d="M6.048,12.735.185,6.941a.618.618,0,0,1,0-.882L6.048.265a.923.923,0,0,1,1.294,0,.9.9,0,0,1,0,1.278L2.327,6.5l5.015,4.956a.9.9,0,0,1,0,1.279.923.923,0,0,1-1.294,0"
                        transform="translate(-29 -27)"
                        fill="#3986ef"
                      />
                    </g>
                  </svg>
                </MobileSelectFilterWrapper>
                <MobileSelectFilterWrapper value={tone}>
                  <select value={tone} onChange={handleToneChange}>
                    <option hidden value={''}>
                      Tonacja
                    </option>
                    <option value={''}>Wszystko</option>
                    {productAttributes.length > 0 &&
                      productAttributes
                        .filter(
                          (productAttribute) =>
                            productAttribute.Id ===
                            'f65268e6-c5f9-4f82-9203-68c673cbab07',
                        )[0]
                        .AttributeValues.map((attributeValue) => {
                          const {Id, Name} = attributeValue;

                          return (
                            <option value={Id} key={Id}>
                              {Name}
                            </option>
                          );
                        })}
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.609"
                    height="13"
                    viewBox="0 0 7.609 13"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(-21.391 -14) rotate(180)"
                    >
                      <path
                        id="Arrow"
                        d="M6.048,12.735.185,6.941a.618.618,0,0,1,0-.882L6.048.265a.923.923,0,0,1,1.294,0,.9.9,0,0,1,0,1.278L2.327,6.5l5.015,4.956a.9.9,0,0,1,0,1.279.923.923,0,0,1-1.294,0"
                        transform="translate(-29 -27)"
                        fill="#3986ef"
                      />
                    </g>
                  </svg>
                </MobileSelectFilterWrapper>
                <MobileInputFilterWrapper>
                  <p>Twórca</p>
                  <input
                    placeholder="Znajdź..."
                    type="text"
                    value={producerName}
                    onChange={handleProducerNameChange}
                  />
                </MobileInputFilterWrapper>
                <MobileInputFilterWrapper>
                  <p>Cena&nbsp;od</p>
                  <input
                    placeholder="0zł"
                    type="text"
                    value={minGrossValue}
                    onChange={handleMinGrossValueChange}
                  />
                </MobileInputFilterWrapper>
                <MobileInputFilterWrapper>
                  <p>Cena&nbsp;do</p>
                  <input
                    placeholder="0zł"
                    type="text"
                    value={maxGrossValue}
                    onChange={handleMaxGrossValueChange}
                  />
                </MobileInputFilterWrapper>
                <MobileCheckboxFilterWrapper>
                  <p>Tylko Win-Win</p>
                  <MobileCheckbox
                    isActive={isWinwin}
                    onClick={() => handleIsWinwinChange(!isWinwin)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22.167"
                      height="22.167"
                      viewBox="0 0 22.167 22.167"
                    >
                      <path
                        id="Icon_awesome-check-circle"
                        data-name="Icon awesome-check-circle"
                        d="M22.729,11.646A11.083,11.083,0,1,1,11.646.563,11.083,11.083,0,0,1,22.729,11.646ZM10.364,17.514l8.223-8.223a.715.715,0,0,0,0-1.011L17.576,7.269a.715.715,0,0,0-1.011,0L9.858,13.975,6.727,10.844a.715.715,0,0,0-1.011,0L4.7,11.855a.715.715,0,0,0,0,1.011l4.648,4.648a.715.715,0,0,0,1.011,0Z"
                        transform="translate(-0.563 -0.563)"
                        fill="#06f"
                      />
                    </svg>
                  </MobileCheckbox>
                </MobileCheckboxFilterWrapper>
                <MobileCheckboxFilterWrapper>
                  <p>Tylko obserwowane oferty</p>
                  <MobileCheckbox
                    isActive={isFavourite}
                    onClick={() => handleIsFavouriteChange(!isFavourite)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22.167"
                      height="22.167"
                      viewBox="0 0 22.167 22.167"
                    >
                      <path
                        id="Icon_awesome-check-circle"
                        data-name="Icon awesome-check-circle"
                        d="M22.729,11.646A11.083,11.083,0,1,1,11.646.563,11.083,11.083,0,0,1,22.729,11.646ZM10.364,17.514l8.223-8.223a.715.715,0,0,0,0-1.011L17.576,7.269a.715.715,0,0,0-1.011,0L9.858,13.975,6.727,10.844a.715.715,0,0,0-1.011,0L4.7,11.855a.715.715,0,0,0,0,1.011l4.648,4.648a.715.715,0,0,0,1.011,0Z"
                        transform="translate(-0.563 -0.563)"
                        fill="#06f"
                      />
                    </svg>
                  </MobileCheckbox>
                </MobileCheckboxFilterWrapper>
                <MobileCheckboxFilterWrapper>
                  <p>Zawiera plik typu wave</p>
                  <MobileCheckbox
                    isActive={hasWaveFile}
                    onClick={() => handleHasWaveFileChange(!hasWaveFile)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22.167"
                      height="22.167"
                      viewBox="0 0 22.167 22.167"
                    >
                      <path
                        id="Icon_awesome-check-circle"
                        data-name="Icon awesome-check-circle"
                        d="M22.729,11.646A11.083,11.083,0,1,1,11.646.563,11.083,11.083,0,0,1,22.729,11.646ZM10.364,17.514l8.223-8.223a.715.715,0,0,0,0-1.011L17.576,7.269a.715.715,0,0,0-1.011,0L9.858,13.975,6.727,10.844a.715.715,0,0,0-1.011,0L4.7,11.855a.715.715,0,0,0,0,1.011l4.648,4.648a.715.715,0,0,0,1.011,0Z"
                        transform="translate(-0.563 -0.563)"
                        fill="#06f"
                      />
                    </svg>
                  </MobileCheckbox>
                </MobileCheckboxFilterWrapper>
              </>
            )}
          </MenuWrapper>
        </MenuMain>
        <MobileMain>
          <NavButtonsWrapper>
            {countTruthyVariables(
              searchString,
              primaryCategory,
              secondaryCategory,
              tertiaryCategory,
              isWinwin,
              isFavourite,
              hasWaveFile,
              producerName,
              vibe,
              tempo,
              tone,
              minGrossValue,
              maxGrossValue,
            ) > 0 && (
              <CountFilterDisplay>
                {countTruthyVariables(
                  searchString,
                  primaryCategory,
                  secondaryCategory,
                  tertiaryCategory,
                  isWinwin,
                  isFavourite,
                  hasWaveFile,
                  producerName,
                  vibe,
                  tempo,
                  tone,
                  minGrossValue,
                  maxGrossValue,
                )}
              </CountFilterDisplay>
            )}
            <ArrowSvg
              onClick={() => {
                setIsBodyOverflowBlocked(true);
                setIsMobileSearchBarOpen(false);
                handleIsSortMenuOpenChange(true);
                handleIsFilterMenuOpenChange(false);
                handleIsMobileFilterMenuOpenChange(!isMobileFilterMenuOpen);
                if (!isMobileFilterMenuOpen) {
                  setTimeout(() => {
                    mobileFilterMenuWrapperRef.current.style.transition =
                      'none';
                  }, 350);
                }
              }}
              id="Group_1923"
              data-name="Group 1923"
              xmlns="http://www.w3.org/2000/svg"
              width="23.197"
              height="17.036"
              viewBox="0 0 23.197 17.036"
            >
              <path
                id="Path_1649"
                data-name="Path 1649"
                d="M258.494,352.337a1.071,1.071,0,0,0-1.437,0l-3.981,3.585a1.076,1.076,0,1,0,1.439,1.6l2.185-1.968v11.931a1.075,1.075,0,0,0,2.151,0V355.553l2.185,1.968a1.076,1.076,0,1,0,1.439-1.6Z"
                transform="translate(-252.719 -352.06)"
              />
              <path
                id="Path_1650"
                data-name="Path 1650"
                d="M269.854,363.478a1.073,1.073,0,0,0-1.518-.079l-2.187,1.97V353.436a1.075,1.075,0,1,0-2.149,0v11.931l-2.185-1.968a1.076,1.076,0,1,0-1.439,1.6l3.981,3.585a1.076,1.076,0,0,0,1.439,0L269.775,365A1.075,1.075,0,0,0,269.854,363.478Z"
                transform="translate(-246.934 -351.822)"
              />
            </ArrowSvg>
            <MobileSearchBarWrapper>
              <SearchSvg
                isActive={isMobileSearchBarOpen}
                onClick={() => {
                  setIsMenuOpen(false);
                  setTimeout(() => {
                    if (mobileSearchBarRef.current) {
                      mobileSearchBarRef.current.focus();
                    }
                  }, 0);

                  setIsMobileFilterMenuOpen(false);
                  handleIsMobileSearchBarOpenChange(!isMobileSearchBarOpen);
                }}
                id="Group_4104"
                data-name="Group 4104"
                xmlns="http://www.w3.org/2000/svg"
                width="14.146"
                height="14"
                viewBox="0 0 14.146 14"
              >
                <path
                  id="Path_1507"
                  data-name="Path 1507"
                  d="M2601.826,547.616l-3.519-3.207a5.82,5.82,0,0,0-.448-7.709h0a5.816,5.816,0,1,0-.7,8.813l3.6,3.28a.8.8,0,1,0,1.072-1.177Zm-11.3-3.586a4.554,4.554,0,1,1,3.219,1.333A4.558,4.558,0,0,1,2590.529,544.03Z"
                  transform="translate(-2587.939 -535.001)"
                  fill="#07020f"
                />
              </SearchSvg>
              <MobileSearchBar
                isActive={true}
                type="text"
                ref={mobileSearchBarRef}
                placeholder="Wyszukaj..."
                value={searchString}
                onChange={handleSearchStringChange}
              />
            </MobileSearchBarWrapper>

            <FilterSvg
              isActive={false}
              onClick={() => {
                setIsBodyOverflowBlocked(true);
                setIsMobileSearchBarOpen(false);
                handleIsSortMenuOpenChange(false);
                handleIsFilterMenuOpenChange(true);
                handleIsMobileFilterMenuOpenChange(!isMobileFilterMenuOpen);
                if (!isMobileFilterMenuOpen) {
                  setTimeout(() => {
                    mobileFilterMenuWrapperRef.current.style.transition =
                      'none';
                  }, 350);
                }
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="19.2"
              height="18.021"
              viewBox="0 0 19.2 18.021"
            >
              <g
                id="Group_1925"
                data-name="Group 1925"
                transform="translate(-155.861 -459.378)"
                opacity="0.86"
              >
                <path
                  id="Path_1651"
                  data-name="Path 1651"
                  d="M173.319,462.311H163.211a.955.955,0,0,1,0-1.909h10.107a.955.955,0,1,1,0,1.909Z"
                  transform="translate(0.787 0.126)"
                />
                <path
                  id="Path_1652"
                  data-name="Path 1652"
                  d="M173.319,468.486H163.211a.955.955,0,0,1,0-1.909h10.107a.955.955,0,1,1,0,1.909Z"
                  transform="translate(0.787 0.886)"
                />
                <path
                  id="Path_1653"
                  data-name="Path 1653"
                  d="M173.319,474.366H163.211a.955.955,0,0,1,0-1.909h10.107a.955.955,0,1,1,0,1.909Z"
                  transform="translate(0.787 1.609)"
                />
                <circle
                  id="Ellipse_11"
                  data-name="Ellipse 11"
                  cx="2.246"
                  cy="2.246"
                  r="2.246"
                  transform="translate(155.861 472.907)"
                />
                <circle
                  id="Ellipse_12"
                  data-name="Ellipse 12"
                  cx="2.246"
                  cy="2.246"
                  r="2.246"
                  transform="translate(155.861 466.156)"
                />
                <circle
                  id="Ellipse_13"
                  data-name="Ellipse 13"
                  cx="2.246"
                  cy="2.246"
                  r="2.246"
                  transform="translate(155.861 459.378)"
                />
              </g>
            </FilterSvg>
            {countTruthyVariables(
              searchString,
              primaryCategory,
              secondaryCategory,
              tertiaryCategory,
              isWinwin,
              isFavourite,
              hasWaveFile,
              producerName,
              vibe,
              tempo,
              tone,
              minGrossValue,
              maxGrossValue,
            ) > 0 && (
              <ClearFiltersButton onClick={() => clearFilters()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10.9"
                  height="12"
                  viewBox="0 0 10.9 12"
                >
                  <g
                    id="Icon_feather-trash"
                    data-name="Icon feather-trash"
                    transform="translate(0.5 0.5)"
                  >
                    <path
                      id="Path_3663"
                      data-name="Path 3663"
                      d="M4.5,9h9.9"
                      transform="translate(-4.5 -6.8)"
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                    <path
                      id="Path_3664"
                      data-name="Path 3664"
                      d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                      transform="translate(-6.4 -3)"
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                  </g>
                </svg>
              </ClearFiltersButton>
            )}
            {/* <SearchSvg
              isActive={isMobileSearchBarOpen}
              onClick={() => {
                setIsMenuOpen(false);
                setTimeout(() => {
                  if (mobileSearchBarRef.current) {
                    mobileSearchBarRef.current.focus();
                  }
                }, 0);

                setIsMobileFilterMenuOpen(false);
                handleIsMobileSearchBarOpenChange(!isMobileSearchBarOpen);
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="19.2"
              height="18.021"
              viewBox="0 0 19.2 18.021"
            >
              <path
                id="Path_1507"
                data-name="Path 1507"
                d="M2601.826,547.616l-3.519-3.207a5.82,5.82,0,0,0-.448-7.709h0a5.816,5.816,0,1,0-.7,8.813l3.6,3.28a.8.8,0,1,0,1.072-1.177Zm-11.3-3.586a4.554,4.554,0,1,1,3.219,1.333A4.558,4.558,0,0,1,2590.529,544.03Z"
                transform="translate(-2587.939 -535.001)"
                fill="#07020f"
              />
            </SearchSvg> */}
          </NavButtonsWrapper>
          {isLoading && <LoadingCircle />}
          {offers.map((offer, index) => {
            return (
              <SingleDigitalProduct
                mobileRef={mobileLastElementRef}
                roles={roles}
                offer={offer}
                key={offer.Id}
                categories={categories}
                index={index}
              />
            );
          })}
        </MobileMain>
      </MobileMainWrapper>
    </>
  );
};

export default DigitalProducts;
