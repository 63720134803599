import React, {createContext, useState, useEffect} from 'react';
import jwt_decode from 'jwt-decode';
import api from 'services/axiosConfig';
import {useCookies} from 'react-cookie';

interface DecodedToken {
  ProducerId: string;
  OwnedLabelId: string;
}

type Context = [Statistics, React.Dispatch<React.SetStateAction<Statistics>>];

export const StatisticsContext = createContext<Context>(null!);

interface Props {
  children: React.ReactNode;
}

interface Statistics {
  ActualOfertsCount: number;
  DistinguishedOffersCount: number;
  ActicePromotionsCount: number;
  NearestOfferEndDaysCount: number;
  NewMessagesCount: number;
  ReadMessagesCount: number;
  SentMessagesCount: number;
  AverageMessagesReactionTimeInMinutes: number;
  ActualEarnings: number;
  LastThirtyDaysEarnings: number;
  ActualBalance: number;
}

const StatisticsProvider: React.FC<Props> = ({children}) => {
  const [statistics, setStatistics] = useState<Statistics>({
    ActicePromotionsCount: 0,
    ActualBalance: 0,
    ActualEarnings: 0,
    ActualOfertsCount: 0,
    AverageMessagesReactionTimeInMinutes: 0,
    DistinguishedOffersCount: 0,
    LastThirtyDaysEarnings: 0,
    NearestOfferEndDaysCount: 0,
    NewMessagesCount: 0,
    ReadMessagesCount: 0,
    SentMessagesCount: 0,
  });
  const [cookies, setCookie] = useCookies(['token']);

  const decodedToken: {ProducerId: string; OwnedLabelId: string} =
    cookies.token && jwt_decode(cookies.token);

  const getStatistics = async () => {
    try {
      const response = await api.get(`Statistics`, {
        headers: {
          Authorization: `Bearer ${cookies.token}`,
        },
      });
      setStatistics(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!decodedToken) return;
    const intervalId = setInterval(async () => {
      await getStatistics();
    }, 30000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (!decodedToken) return;
    getStatistics()
  }, []);

  return (
    <StatisticsContext.Provider value={[statistics, setStatistics]}>
      {children}
    </StatisticsContext.Provider>
  );
};

export default StatisticsProvider;
