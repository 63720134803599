import React, {useEffect, useState, useContext} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {light} from '../../../constants/theme';

import RGL, {WidthProvider} from 'react-grid-layout';
import styled from 'styled-components';

import {Block, Producer, Skin} from 'pages/MyStudio/BusinessCard/interface';

import api from 'services/axiosConfig';
import {
  getSpecificRole,
  hexToRgb,
  renderBlockContent,
} from 'pages/MyStudio/BusinessCard';
import {UserThemeContext} from 'contexts/UserThemeContext';
import {ResolutionContext} from 'contexts/ResolutionContext';
import {
  AltPanelContent,
  AltPanelTextWrapper,
  FooterBigBlueBtn,
  FooterCircleAvatar,
  FooterSellerAccountWrapper,
  FooterSellerAvatarAndInfoWrapper,
  FooterSellerInfoWrapper,
  MainPanelContentWrapper,
  PanelContainer,
} from 'pages/MyStudio/BusinessCard/styles';
import {City} from '../SingleProducer';
import {useCookies} from 'react-cookie';
import {LoadingCircle} from 'utils/loadingCircle';
import VisitedProducerProvider, {
  VisitedProducerContext,
} from '../../../contexts/VisitedProducerContext';
import MobileOverviewComponent from '../../MyStudio/BusinessCard/MobileOverviewComponent';
import {ProducerDataContext} from 'contexts/ProducerDataContext';
import {SendMessageButton} from 'pages/Products/ProductDetail';
import {PageHeaderHeader} from '..';

import avatarImg from 'images/defaultAvatarStudio.jpg';

const rolesBasic = [
  {FieldName: 'Bitmaker', FlagValue: 0},
  {FieldName: 'Songwriter', FlagValue: 1},
];

const FooterWrapper = styled.div`
  height: 55px;
  min-height: 55px;
  background: #f8f8f8;
  width: 1020px;
  z-index: 1;
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
  padding-left: 26px;
  padding-right: 23px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(23, 22, 29, 0.21);
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.12);
  border-top: none;
`;

export const FooterButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const MainPanelWrapper = styled.div`
  width: 1020px;
  //height: 100%;
  height: 565px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* border: 1px solid ${({theme}) => theme.colors.whiteOp021}; */
  border: 1px solid rgba(23, 22, 29, 0.21);
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.12);
  border-top: none;
  border-bottom: none;

  @media (max-width: 1089px) {
    /* width: calc(100%); */
    display: none;
  }
`;

export const PageHeaderHeaderWrapper = styled.div`
  width: 1020px;
  height: 55px;
  border-radius: 24px 24px 0 0;
  background: #0091ff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const MainPanelWrapperMobile = styled.div`
  width: 1020px;
  height: 100%;
  display: none;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${({theme}) => theme.colors.whiteOp021};

  @media (max-width: 1089px) {
    /* width: calc(100%); */
    display: flex;
  }
`;

export const MainWrapper = styled.div`
  width: 100%;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 79px;
`;

export const Main = styled.div`
  pointer-events: all;
  width: 1020px;
  display: flex;
  height: 745px;
  flex-direction: column;
  align-items: center;
  //background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 10px;
  margin-bottom: 20px;

  & > p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.92);
    line-height: 24px;
    margin-bottom: 10px;
  }

  @media (max-width: 1089px) {
    width: 360px;
  }
`;

export const NewMainHeader = styled.div`
  width: 1020px;
  height: 55px;
  z-index: 2;
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
  border: 1px solid #c7c9cf;
  background: #0091ff;
  display: flex;
  align-items: center;
  justify-content: left;
  position: relative;
  transition: 0.15s;
`;

export const MainHeaderLeftPanelWrapper = styled.div`
  position: absolute;
  left: -1px;
  top: 54px;
  z-index: 3;
  width: 147px;
  height: 136px;
  transition: 0.15s;
  overflow: hidden;
`;

export const MainHeaderHiddenWrapper = styled.div`
  padding-top: 13px;
  position: absolute;
  top: -136px;
  left: 0;
  width: 147px;
  height: 136px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  background: #f1f1f2;
  border: 1px solid #707070;
  border-bottom-right-radius: 9px;
  border-top: none;
  overflow: hidden;
  border-left: none;
  transition: 0.25s;
`;

export const MainHeaderLeftPanelButton = styled.div<{
  isActive?: boolean;
}>`
  width: 120px;
  height: 28px;
  background: ${({isActive}) => (isActive ? '#00d10f' : '#f1f1f2')};
  border-radius: 15px;
  margin-bottom: 13px;
  font-size: 11px;
  text-align: center;
  line-height: 28px;
  letter-spacing: 0.1px;
  cursor: pointer;
  color: ${({isActive}) => (isActive ? '#f1f1f2' : '#3a87f0')};
  box-shadow: 1px 3px 4px 2px rgba(0, 0, 0, 0.12);
  &:hover {
    filter: brightness(1.1);
  }
  &:active {
    filter: brightness(0.9);
  }
`;

export const NewMainHeaderTitle = styled.div`
  height: 55px;
  width: 147px;
  border-right: 1px solid #c7c9cf;
  display: flex;
  align-items: center;
  justify-content: left;
  & > img {
    margin: 8px 11px 8px 10px;
    cursor: pointer;
    border-radius: 100%;
  }
  &:hover {
    filter: brightness(1.1);
  }
  &:active {
    filter: brightness(0.9);
  }
`;
export const NewMainHeaderNickWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  & > p {
    font-size: 15px;
    cursor: pointer;
    line-height: 17px;
    letter-spacing: 0.1px;
    color: #707070;
    opacity: 0.9;
  }
`;

export const NewMainHeaderProducerRole = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  //justify-content: center;
  & > p {
    padding-right: 5px;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: 0.3px;
    color: #232323;
    opacity: 0.7;
  }
  & > span {
    width: 3px;
    height: 3px;
    border-radius: 100%;
    opacity: 0.7;
    background: #232323;
  }
`;
export const NewMainHeaderProducerInfo = styled.div`
  padding-top: 8px;
  padding-left: 17px;
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  //justify-content: center;
`;
export const NewMainHeaderProducerCity = styled.div`
  & > p {
    font-size: 12px;
    line-height: 21px;
    letter-spacing: -0.25px;
    color: #232323;
    opacity: 0.7;
  }
`;

const GridStructur = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  padding-left: 1px;
  padding-right: 2px;
  grid-template-columns: repeat(18, 56px);
  grid-auto-rows: 56px;
`;
const GridOneItem = styled.div`
  z-index: -1;
  //border: 1px dashed #707070;
  opacity: 0.3;
  border-right: none;
  border-top: none;
`;

export interface Role {
  FieldName: string;
  FlagValue: number;
}

export interface ProducerCard {
  Id: string;
  ChosenSkinId: string;
  ChosenSkin: Skin;
  ProducerStreet: string;
  BackgroundColorHex: string;
  Blocks: Block[];
  DraftMatchPublish?: boolean;
  ProducerCountry: string;
  ProducerPostalCode: string;
  ProducerFirstName: string;
  ProducerLastName: string;
  ProducerName: string;
  ProducerProducerPhoto: string;
  ProducerRole: number;
  ProducerSecondaryRole: number;
  ProducerSettlementType: number;
}

const ProducerCard: React.FC = () => {
  const [producer, setProducer] = useState<ProducerCard>({
    BackgroundColorHex: '',
    Blocks: [],
    ChosenSkin: {
      Id: '',
      Name: '',
      PrimaryColorHex: '',
      SecondaryColorHex: '',
    },
    ChosenSkinId: '',
    Id: '',
    ProducerCountry: '',
    ProducerPostalCode: '',
    ProducerFirstName: '',
    ProducerStreet: '',
    ProducerLastName: '',
    ProducerName: '',
    ProducerProducerPhoto: '',
    ProducerRole: 0,
    ProducerSecondaryRole: 1,
    ProducerSettlementType: 2,
  });

  const [roles, setRoles] = useState<Role[]>(rolesBasic);
  const [isLoading, setIsLoading] = useState(true);
  const [isHeaderLeftPanelOpen, setIsHeaderLeftPanelOpen] =
    useState<boolean>(false);
  const [cities, setCities] = useState<City[]>([]);
  const [sellerPlan, setSellerPlan] = useState({ActiveFrom: '', Name: ''});
  const navigate = useNavigate();

  const ReactGridLayout = WidthProvider(RGL);

  const {id} = useParams();

  const [cookies, setCookie] = useCookies(['token']);
  const [theme, setTheme] = useContext(UserThemeContext);
  const {windowWidth} = useContext(ResolutionContext);
  const [visitedProducer, setVisitedProducer] = useContext(
    VisitedProducerContext,
  );

  const {decodedToken} = useContext(ProducerDataContext);
  // console.log('token', decodedToken.ProducerId, 'prucerId', id);

  const getProducer = async (producerId: string) => {
    try {
      const response = await api.get(`Producer/${producerId}/1`);
      setProducer(response.data.Response);
      // console.log('producersxdddd: ', response.data.Response);
      setVisitedProducer({
        Id: response.data.Response.ProducerId,
        CardType: response.data.Response.CardType,
      });
      // getCitiesFromPostalCode(response.data.Response.ProducerPostalCode);
      if (response.data.Response.Blocks.length === 0)
        return navigate('/producers/main');
    } catch (error) {
      console.error(error);
      navigate('/producers/main');
    } finally {
      setIsLoading(false);
    }
  };

  const getMaxHeight = (block: Block[]) => {
    if (block.length < 1) return 7;
    const arr = block.sort(
      (a, b) =>
        b.WebArea.StartY +
        b.WebArea.Height -
        (a.WebArea.StartY + a.WebArea.Height),
    );
    if (arr[0].WebArea.StartY + arr[0].WebArea.Height < 7) return 8;
    return arr[0].WebArea.StartY + arr[0].WebArea.Height;
  };

  function createArr(x: number, y: number) {
    const arr = [];
    for (let i = 0; i < x * y; i++) {
      arr.push(i);
    }
    return arr;
  }

  // const getSellerInfo = async () => {
  //   try {
  //     const response = await api.get(`Seller`, {
  //       headers: {Authorization: `bearer ${cookies.token}`},
  //     });

  //     setSellerPlan({
  //       ActiveFrom: response.data.Response.CurrentPlan.ActiveFrom,
  //       Name: response.data.Response.CurrentPlan.Plan.Name,
  //     });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const getRoles = async () => {
    try {
      const response = await api.get('Producer/Roles');
      setRoles(response.data.Response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // getSellerInfo();
    getProducer(id);
    getRoles();
  }, []);

  const newLayout = producer.Blocks.map((block) => ({
    w: block.WebArea.Width,
    h: block.WebArea.Height,
    i: block.Id,
    x: block.WebArea.StartX,
    y: block.WebArea.StartY,
    isBounded: undefined,
    isDraggable: undefined,
    isResizable: undefined,
    maxH: 9,
    maxW: 9,
    minH: 1,
    minW: 1,
    moved: false,
    resizeHandles: undefined,
    static: false,
  }));

  const newMobileLayout = producer.Blocks.map((block) => ({
    w: block.MobileArea.Width,
    h: block.MobileArea.Height,
    i: block.Id,
    x: block.MobileArea.StartX,
    y: block.MobileArea.StartY,
    isBounded: undefined,
    isDraggable: undefined,
    isResizable: undefined,
    maxH: 9,
    maxW: 3,
    minH: 1,
    minW: 1,
    moved: false,
    resizeHandles: undefined,
    static: false,
  }));

  // const getCitiesFromPostalCode = async (PostalCode: string) => {
  //   try {
  //     const params = {
  //       PostalCode,
  //     };

  //     const response = await api.get('Localizations', {params});
  //     setCities(response.data.Response[0].City);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  if (windowWidth <= light.breakpointsJs.mobile)
    return (
      <MobileOverviewComponent
        mobileGoToProducer={false}
        activePlan="ss"
        roles={roles}
        Blocks={[...producer.Blocks].reverse()}
        isEditing={false}
        overview={false}
        newLayout={newMobileLayout}
        mobileOverview={false}
        draftMatchPublish={false}
      />
    );

  return (
    <MainWrapper>
      <Main>
        {isLoading && <LoadingCircle />}
        <PageHeaderHeaderWrapper>
          <PageHeaderHeader>
            <div
              style={{
                position: 'absolute',
                left: '10px',
                cursor: 'pointer',

                padding: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
              }}
              // to={'/producers/main'}
              onClick={() => navigate(-1)}
            >
              <svg
                id="Arrow_Top_Bar_Accent"
                data-name="Arrow – Top Bar / Accent"
                xmlns="http://www.w3.org/2000/svg"
                width="8.195"
                height="14"
                viewBox="0 0 8.195 14"
              >
                <path
                  id="Arrow"
                  d="M6.513,13.715.2,7.475a.666.666,0,0,1,0-.95L6.513.285a.994.994,0,0,1,1.394,0,.967.967,0,0,1,0,1.377L2.506,7l5.4,5.337a.967.967,0,0,1,0,1.377.994.994,0,0,1-1.394,0"
                  transform="translate(0 0)"
                  fill="#fff"
                />
              </svg>
            </div>
            <p>Twórcy</p>
          </PageHeaderHeader>
        </PageHeaderHeaderWrapper>
        <MainPanelWrapper
          style={{
            // borderBottomLeftRadius: '22px',
            // borderBottomRightRadius: '22px',
            overflow: 'hidden',
          }}
        >
          <MainPanelContentWrapper isBasicPlan={false} overview={true}>
            <GridStructur>
              {createArr(getMaxHeight(producer.Blocks), 18).map(
                (item, index) => (
                  <GridOneItem
                    onClick={() => getMaxHeight(producer.Blocks)}
                    key={index}
                  />
                ),
              )}
              {/*<GridOneItem />*/}
            </GridStructur>
            <ReactGridLayout
              cols={18}
              isDraggable={false}
              isResizable={false}
              rowHeight={56}
              maxRows={200}
              margin={[0, 0]}
              containerPadding={[0, 0]}
              compactType={'vertical'}
              layout={newLayout}
              preventCollision={false}
            >
              {producer.Blocks.map((block, index) => {
                const {
                  BackgroundColorHex,
                  BlockOpacityIn0To100Ratio,
                  FontColorHex,
                  WebArea,
                  Id,

                  Type,
                } = block;
                const {StartX, StartY, Width, Height} = WebArea;
                const BackgroundColorRgb = hexToRgb(BackgroundColorHex);
                const FontColorRgb = hexToRgb(FontColorHex);

                return (
                  <PanelContainer
                    key={Id}
                    isEditing={false}
                    blockWidth={Width}
                    blockHeight={Height}
                    overview={true}
                  >
                    <AltPanelContent
                      backgroundAlpha={BlockOpacityIn0To100Ratio}
                      backgroundColor={BackgroundColorRgb}
                      blockWidth={Width}
                      overview={true}
                    >
                      <AltPanelTextWrapper
                        fontColor={FontColorRgb}
                        blockWidth={Width}
                      >
                        {renderBlockContent(
                          false,
                          'user',
                          roles,
                          block,
                          theme,
                          false,
                        )}
                      </AltPanelTextWrapper>
                    </AltPanelContent>
                  </PanelContainer>
                );
              })}
            </ReactGridLayout>
          </MainPanelContentWrapper>
        </MainPanelWrapper>
        <MainPanelWrapperMobile>
          <MainPanelContentWrapper isBasicPlan={false} overview={true}>
            <GridStructur>
              {createArr(getMaxHeight(producer.Blocks), 9).map(
                (item, index) => (
                  <GridOneItem
                    onClick={() => getMaxHeight(producer.Blocks)}
                    key={index}
                  />
                ),
              )}
              {/*<GridOneItem />*/}
              <FooterWrapper>
                <FooterSellerAvatarAndInfoWrapper>
                  <FooterCircleAvatar>
                    <img
                      src={
                        producer.ProducerProducerPhoto === ''
                          ? avatarImg
                          : producer.ProducerProducerPhoto
                      }
                      alt="avatar"
                    />
                  </FooterCircleAvatar>
                  <FooterSellerInfoWrapper>
                    {producer.ProducerName ? (
                      <p style={{fontWeight: 'bold'}}>
                        {`${producer.ProducerName}`}
                      </p>
                    ) : (
                      <p
                        style={{fontWeight: 'bold'}}
                      >{`${producer.ProducerFirstName} ${producer.ProducerLastName}`}</p>
                    )}
                    <p>{`${producer.ProducerStreet},${cities} ${producer.ProducerPostalCode} `}</p>{' '}
                    <p>{`Konto ${
                      producer.ProducerSettlementType === 0
                        ? 'Firmowe'
                        : producer.ProducerSettlementType === 2
                        ? '-'
                        : 'Prywatne'
                    }}`}</p>
                  </FooterSellerInfoWrapper>
                </FooterSellerAvatarAndInfoWrapper>
                {decodedToken?.ProducerId !== id && (
                  <FooterBigBlueBtn>Wyślij wiadomość</FooterBigBlueBtn>
                )}
                {/* <FooterBigBlueBtn>Wyślij wiadomość</FooterBigBlueBtn> */}
              </FooterWrapper>
            </GridStructur>
            <ReactGridLayout
              cols={1}
              isDraggable={false}
              isResizable={false}
              rowHeight={348}
              maxRows={20}
              margin={[0, 0]}
              containerPadding={[0, 0]}
              compactType={'vertical'}
              layout={newMobileLayout}
              preventCollision={false}
            >
              {producer.Blocks.map((block, index) => {
                const {
                  BackgroundColorHex,
                  BlockOpacityIn0To100Ratio,
                  FontColorHex,
                  Id,
                  Type,
                  MobileArea,
                } = block;
                const {StartX, StartY, Width, Height} = MobileArea;
                const BackgroundColorRgb = hexToRgb(BackgroundColorHex);
                const FontColorRgb = hexToRgb(FontColorHex);

                return (
                  <PanelContainer
                    key={Id}
                    isEditing={false}
                    blockWidth={Width}
                    blockHeight={Height}
                    overview={true}
                  >
                    <AltPanelContent
                      backgroundAlpha={BlockOpacityIn0To100Ratio}
                      backgroundColor={BackgroundColorRgb}
                      blockWidth={Width}
                      overview={true}
                    >
                      <AltPanelTextWrapper
                        fontColor={FontColorRgb}
                        blockWidth={Width}
                      >
                        {renderBlockContent(
                          false,
                          'user',
                          roles,
                          block,
                          theme,
                          true,
                        )}
                      </AltPanelTextWrapper>
                    </AltPanelContent>
                  </PanelContainer>
                );
              })}
            </ReactGridLayout>
          </MainPanelContentWrapper>
        </MainPanelWrapperMobile>
        <FooterWrapper>
          <FooterSellerAvatarAndInfoWrapper>
            <FooterCircleAvatar>
              <img
                src={
                  producer.ProducerProducerPhoto === ''
                    ? avatarImg
                    : producer.ProducerProducerPhoto
                }
                alt="avatar"
              />
            </FooterCircleAvatar>
            <FooterSellerInfoWrapper>
              <p style={{fontWeight: 'bold'}}>
                {producer.ProducerName
                  ? `${producer.ProducerName}`
                  : `${producer.ProducerFirstName} ${producer.ProducerLastName}`}
              </p>{' '}
              {/* <p>{`${producer.ProducerStreet},${cities} ${producer.ProducerPostalCode} `}</p>{' '} */}
              <p>{`Konto prywatne `}</p>
            </FooterSellerInfoWrapper>
          </FooterSellerAvatarAndInfoWrapper>
          <FooterButtonsWrapper>
            {id !== decodedToken?.ProducerId && (
              <FooterBigBlueBtn
                onClick={() => {
                  navigate(
                    `/products/main?ProducerName=${
                      producer.ProducerFirstName
                        ? `${producer.ProducerFirstName}${producer.ProducerLastName}`
                        : `${producer.ProducerName}`
                    }`,
                  );
                }}
              >
                Wszystkie oferty
              </FooterBigBlueBtn>
            )}
            {id !== decodedToken?.ProducerId && (
              <FooterBigBlueBtn
                onClick={() => {
                  navigate(
                    `/tracks/main?ProducerName=${
                      producer.ProducerFirstName
                        ? `${producer.ProducerFirstName}${producer.ProducerLastName}`
                        : `${producer.ProducerName}`
                    }`,
                  );
                }}
              >
                Wszystkie bity
              </FooterBigBlueBtn>
            )}
            {decodedToken?.ProducerId && id !== decodedToken?.ProducerId && (
              <Link
                style={{
                  width: '149px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  textDecoration: 'none',
                }}
                to={'/messages'}
                state={{
                  productIdFromOffer: '',
                  producerIdFromOffer: id,
                  productNameFromOffer: '',
                  producerNameFromOffer: producer.ProducerFirstName
                    ? `${producer.ProducerFirstName} ${producer.ProducerLastName}`
                    : producer.ProducerName,
                }}
              >
                <FooterBigBlueBtn>Wyślij wiadomość</FooterBigBlueBtn>
              </Link>
            )}
          </FooterButtonsWrapper>
        </FooterWrapper>
      </Main>
    </MainWrapper>
  );
};

export default ProducerCard;
