import React, {useEffect, useState, useCallback, useRef} from 'react';
import styled, {css} from 'styled-components';
import _ from 'lodash';
import {countTruthyVariables} from 'utils/countTruthyVariables';
import useInfinityScroller from 'hooks/useInfinityScroller';
import {useLocation, useSearchParams} from 'react-router-dom';

import api from 'services/axiosConfig';
import searchIcon from 'images/svg/search.svg';
import twoArrowsIcon from 'images/svg/two-arrows.svg';
import listIcon from 'images/svg/list.svg';
import arrowDown from 'images/svg/purple-select-arrow.svg';
import filterIcon from 'images/svg/filter-icon.svg';
import SingleProducer from './SingleProducer';
import {LoadingCircle} from 'utils/loadingCircle';
import {useMenuContext} from 'contexts/MenuContext';
import {useFilterMenuContext} from 'contexts/FilterMenuContext';
import {useSearchBarContext} from 'contexts/SearchBarContext';
import {
  MobileInput,
  MobileInputTitle,
  MobileInputWrapper,
  MobileSelect,
  MobileSelectWrapper,
  CustomSelectArrow,
  MobileCheckboxFilterWrapper,
  MobileCheckbox,
  MobileSearchBar,
  MobileSearchBarWrapper,
} from 'pages/Products/';
import {useIsBodyOverflowBlockedContext} from 'contexts/IsBodyOverflowBlockedContext';

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const MobileMainWrapper = styled.div`
  width: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background: #f8f8f8; */

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
  }
`;

export const NavButtonsWrapper = styled.div`
  width: 370px;
  /* height: 0px;
  position: fixed;
  top: 15px; */
  /* z-index: 2; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* padding-top: 10px; */

  @media (max-width: 400px) {
    width: 100%;
  }
`;

// export const MobileSearchBarWrapper = styled.div`
//   background: ${({theme}) => theme.colors.mobileSearchBarBg};
//   width: 258px;
//   border-radius: 14px;
//   height: 39px;
//   position: relative;
//   padding: 0 13px;
//   display: flex;
//   gap: 5px;
//   align-items: center;
//   justify-content: space-evenly;
// `;

// export const MobileSearchBar = styled.input<{isActive?: boolean}>`
//   display: ${({isActive}) => (isActive ? 'unset' : 'none')};
//   font-size: 14px;
//   line-height: 24px;
//   letter-spacing: 0.15px;
//   font-weight: 400;
//   color: ${({theme}) => theme.colors.mobileSearchBarTextColor};
//   /* background: #dce0e9; */
//   background: transparent;
//   width: auto;

//   border: none;
//   /* position: absolute;
//   right: 79px; */

//   /* top: -3px; */
//   position: relative;
//   outline: none;

//   &::placeholder {
//     color: ${({theme}) => theme.colors.mobileSearchBarTextColor};
//   }

//   @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
//     /* width: calc(100% - 120px);
//     right: 88px; */
//   }
// `;

export const FilterSvg = styled.svg<{isActive?: boolean}>`
  /* position: absolute;
  right: 56px; */

  & path {
    fill: ${({isActive, theme}) =>
      isActive ? '#3A87F0' : theme.colors.mobileSearchBarTextColor};
  }
  & circle {
    fill: ${({isActive, theme}) =>
      isActive ? '#3A87F0' : theme.colors.mobileSearchBarTextColor};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    right: 65px;
  }
`;
export const ArrowSvg = styled.svg<{isActive?: boolean}>`
  /* position: absolute;
  right: 56px; */

  & path {
    fill: ${({isActive, theme}) =>
      isActive ? '#3A87F0' : theme.colors.mobileSearchBarTextColor};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    right: 65px;
  }
`;

export const SearchSvg = styled.svg<{isActive?: boolean}>`
  /* position: absolute;
  left: 0px;
  margin-top: 1px; */

  & path {
    fill: ${({isActive, theme}) =>
      isActive ? '#3A87F0' : theme.colors.mobileSearchBarTextColor};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    right: 101px;
  }
`;

export const MobileMain = styled.div`
  width: 358px;
  min-height: calc(100vh - 105px);
  margin-bottom: 57px;
  /* min-height: 765px; */
  /* height: calc(100vh - 48px - 57px); */
  display: none;
  padding: 8px 0px;
  flex-direction: column;
  align-items: center;
  background: transparent;
  gap: 9px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
    width: 400px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: 100%;
    /* padding: unset; */
    padding: 8px;
    gap: 5px;
  }
`;

export const CheckboxWithTitleWrapper = styled.div`
  width: 259px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  & > p {
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.05px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }
`;

export const CustomBlueCheckbox = styled.div<{isActive: boolean}>`
  width: 19px;
  height: 19px;
  border-radius: 4px;
  /* border: 1px solid rgba(122, 122, 122, 0.3); */
  border: ${({isActive}) => (isActive ? 'none' : '1px solid #797C93')};
  background: transparent;
  cursor: pointer;

  & > div {
    clip-path: polygon(21% 42%, 41% 68%, 84% 27%, 90% 33%, 40% 84%, 14% 50%);
    display: none;
    background: white;
    height: 100%;
    width: 100%;
  }

  ${({isActive}) =>
    isActive &&
    css`
      background: #0091ff;

      & > div {
        display: flex;
      }
    `}
`;

export const CountFilterDisplay = styled.div`
  position: absolute;
  background: #89bfff;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  letter-spacing: 0.15px;
  font-size: 12px;
  color: #07020f;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 371px;
  top: 62px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    right: 26px;
    top: 78px;
  }
`;

export const MobileInputFilterWrapper = styled.div`
  width: 100%;
  border-radius: 16px;
  position: relative;
  background: ${({theme}) => theme.colors.bgGrey};
  height: 48px;
  gap: 5px;
  padding: 11px 25px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & > p {
    font-size: 17px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-weight: 500;
    letter-spacing: -0.0625px;
    margin: 0;
  }

  & > input {
    width: 100%;
    text-align: right;
    border: none;
    outline: none;
    background: transparent;
    font-size: 17px;
    color: #3a87f0;
    font-weight: 500;
    letter-spacing: -0.0625px;

    &::placeholder {
      color: rgba(7, 2, 15, 0.3);
    }
  }
`;

export const MobileSelectFilterWrapper = styled.div<{value?: any}>`
  width: 100%;
  border-radius: 16px;
  position: relative;
  background: ${({theme}) => theme.colors.bgGrey};
  height: 48px;
  gap: 5px;
  padding: 11px 25px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & > select {
    width: 100%;
    font-size: 17px;
    background: transparent;
    border: none;
    outline: none;
    color: ${({value}) => (value ? '#3a87f0' : '#352f40')};
    font-weight: 500;
    letter-spacing: -0.0625px;
    margin: 0;
    -webkit-appearance: none;

    & > option {
      color: #352f40;
    }
  }
`;

export const MenuMain = styled.div<{isMenuOpen: boolean; decodedToken?: any}>`
  width: 100%;
  background: ${({theme}) => theme.colors.bgWhite};
  border-top: ${({isMenuOpen}) => (isMenuOpen ? '4px solid #3A87F0' : 'none')};
  height: ${({isMenuOpen}) => (isMenuOpen ? '100vh' : '0px')};
  border-radius: 16px;
  position: fixed;
  overflow-y: auto;
  bottom: 0;
  transition: all 0.35s ease-out;
  z-index: 20;
  padding-bottom: ${({isMenuOpen}) => (isMenuOpen ? '42px' : '0px')};
`;

export const CloseIsLoginPageOpenWrapper = styled.div`
  width: 100%;
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(7, 2, 15, 0.3);
  position: relative;
  top: 0px;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: -0.05px;
  color: #352f40;
  margin-bottom: 20px;
`;

export const CloseIsLoginPageOpen = styled.div`
  position: absolute;
  height: 24px;
  width: 24px;
  font-size: 20px;
  color: black;
  font-weight: 900;
  /* top: 20px; */
  right: 23.5px;
`;

export const MenuWrapper = styled.div<{decodedToken?: any}>`
  width: 100%;
  padding: 0 32px;
  gap: 13px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  border-radius: 15px;
  /* background: rgba(255, 255, 255, 0.1); */
  /* padding: 10px; */
`;

export const Main = styled.div`
  width: 1020px;
  height: calc(100vh - 143px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  /* margin-bottom: 20px; */
  pointer-events: all;

  & > p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.92);
    line-height: 24px;
    margin-bottom: 10px;
  }

  @media (max-width: 1100px) {
    width: 720px;
  }

  @media (max-width: 800px) {
    width: 360px;
  }
`;

export const Grid = styled.div`
  width: 100%;
  background: transparent;
  height: 100%;
  //display: grid;
  border-radius: 9px;
  /* padding: 10px; */
  padding-top: 58px;
  padding-left: 14px;
  gap: 10px 11px;
  //grid-template-columns: repeat(3, 1fr);
  //grid-auto-rows: 116px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    margin: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3a87f0;
    border-radius: 25px;
    border: 4px solid transparent;
    background-clip: padding-box;
  }

  @media (max-width: 1200px) {
    height: calc(100vh - 116px);
  }

  @media (max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const FilterButtonSvg = styled.svg<{value: boolean}>`
  & path {
    fill: ${({value}) => value && '#0091FF'};
  }

  & circle {
    fill: ${({value}) => value && '#0091FF'};
  }
`;

export const SortButtonSvg = styled.svg<{value: boolean}>`
  & path {
    fill: ${({value}) => value && '#0091FF'};
  }
`;

export const FilterWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
  /* position: absolute; */

  & > svg {
    cursor: pointer;
  }
`;

export const FilterInputWrapper = styled.div`
  width: 170px;
  height: 28px;
  border-radius: 9px;
  background: transparent;
  border: ${({theme}) => `solid 1px ${theme.colors.headerBorderColor}`};
  /* position: absolute; */
  /* right: 36px; */
  right: 142px;
  top: -121px;
  display: flex;
  justify-content: space-between;
  /* padding: 9px 11px; */
  align-items: center;
  margin: 0;

  & > img {
    width: 11px;
    margin: 0 11px;
  }
  & > svg {
    width: 14.15px;
    margin-left: 10px;
    margin-right: 7px;
    & > g {
      & > path {
        fill: ${({theme}) => theme.colors.fontBlackOp100};
      }
    }
  }
  & > input {
    width: calc(100%);
    padding-right: 4px;
    height: 100%;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-size: 11px;
    font-weight: 400;
    background: transparent;
    letter-spacing: 0.15px;
    border: none;
    text-align: left;
    outline: none;

    ::placeholder {
      color: ${({theme}) => theme.colors.fontBlackOp100};
    }
  }
`;

export const FilterButton = styled.div<{isFilterMenuOpen: boolean}>`
  width: 28px;
  height: 22px;
  border-radius: 7px;
  margin-right: 1px;
  background: ${({isFilterMenuOpen, theme}) =>
    isFilterMenuOpen ? '#DCE0E9' : theme.colors.blueTheme};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & > img {
    height: 15px;
    width: 15px;
  }
`;

export const SingleFilterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 11px;
`;

export const SingleFilterHeaderWrapper = styled.div`
  height: 40px;
  width: 100%;
  border-bottom: 1px solid rgba(7, 2, 15, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  & > p {
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 0.15px;
    color: #07020f;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    border-bottom: none;

    & > p {
      font-size: 17px;
      color: #352f40;
      letter-spacing: -0.05px;
      line-height: 24px;
      font-weight: 400;
    }
  }
`;

export const SingleFilterContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
`;

export const FilterMenuWrapper = styled.div`
  width: calc(100% - 140px);
  /* max-height: calc(90%); */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* height: 40px; */
  /* margin-bottom: 20px; */
  margin: 0 auto 20px;
  /* gap: 5px; */

  @media (max-width: 1045px) {
    grid-template-columns: 1fr;
  }
`;

export const FilterInput = styled.div`
  position: relative;
  width: 259px;
  & > p {
    position: absolute;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-size: 11px;
    line-height: 24px;
    letter-spacing: 0.1px;
    font-weight: 400;
    left: 11.5px;
    top: 1px;
    cursor: default;
  }
  & > input {
    outline: none;
    background: transparent;
    border: ${({theme}) => `solid 1px ${theme.colors.fontBlackOp15}`};
    border-radius: 5px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    width: 100%;
    height: 22px;
    line-height: 20px;
    text-align: right;
    //font-size: 11px;
    font-size: 11px;
    letter-spacing: 0.1px;
    padding-right: 26.5px;
    opacity: 1 !important;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    text-align: -webkit-right;
    text-align-last: right;
    text-indent: 5px hanging;

    &:focus {
      border: 1px solid #0091ff;
    }
  }
  & > svg {
    display: block;
    position: absolute;
    top: 6px;
    right: 9px;
  }
  & > select:focus {
    /* background: #f2f7ff; */
  }
`;

export const SelectWrapper = styled.div`
  position: relative;
  width: 259px;
  & > p {
    position: absolute;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-size: 11px;
    line-height: 24px;
    letter-spacing: 0.1px;
    font-weight: 400;
    left: 11.5px;
    top: 1px;
    cursor: default;
  }
  & > select {
    cursor: pointer;
    outline: none;
    background: transparent;
    border: ${({theme}) => `solid 1px ${theme.colors.fontBlackOp15}`};
    border-radius: 5px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    width: 100%;
    height: 22px;
    line-height: 20px;
    text-align: right;
    //font-size: 11px;
    font-size: 11px;
    letter-spacing: 0.1px;
    padding-right: 26.5px;
    opacity: 1 !important;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    text-align: -webkit-right;
    text-align-last: right;
    text-indent: 5px hanging;

    & > option {
      background: ${({theme}) => theme.colors.headerContentBackground};

      color: ${({theme}) => theme.colors.fontBlackOp100};
      cursor: pointer;
    }

    & > option:checked {
      color: ${({theme}) => theme.colors.headerBlackFont};
      background-color: ${({theme}) => theme.colors.pageHeader};
      border-radius: 7px;
    }

    &:focus {
      border: 1px solid #0091ff;
    }
  }
  & > svg {
    display: block;
    position: absolute;
    top: 8px;
    right: 10px;
  }
  & > select:focus {
    /* background: #f2f7ff; */
  }
`;

export const PageHeaderWrapper = styled.div`
  width: 1020px;
  display: flex;
  /* height: 152px; */
  flex-direction: column;
  border: 1px solid ${({theme}) => theme.colors.headerBorderColor};
  align-items: center;
  background: ${({theme}) => theme.colors.bgWhite};
  border-radius: 24px 24px 21px 21px;
  position: absolute;
  top: 88px;
  z-index: 30;
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.12);

  & > p {
    font-size: 14px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    line-height: 24px;
    margin-bottom: 10px;
  }
`;

export const PageHeaderHeaderWrapper = styled.div`
  width: 100%;
  height: 55px;
  border-radius: 24px 24px 0 0;
  background: #0091ff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageHeaderHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 9px;

  & > p {
    color: ${({theme}) => theme.colors.headerBlackFont};
    font-size: 15px;
    letter-spacing: 0.625px;
  }
`;

export const PageHeaderContent = styled.div`
  width: 100%;
  /* height: calc(100% - 55px); */
  padding: 10px 0;
  display: grid;
  align-items: center;
`;

export const FilterButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ClearFiltersButton = styled.div`
  width: 19px;
  height: 19px;
  border-radius: 4px;
  background: #ff0300;
  position: absolute;
  right: 344px;
  top: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    right: 5px;
    top: unset;
  }
`;

export const ArrowWrapper = styled.div<{isActive?: boolean; length?: number}>`
  width: 84px;
  height: 21px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  transition: all 0.15s;
  border-radius: 7px;
  background: ${({isActive, length}) =>
    isActive || length > 0 ? 'rgba(137, 190, 255, 0.3)' : 'transparent'};

  & > svg {
    & path {
      fill: ${({theme}) => theme.colors.grayFontOp100};
    }
  }

  &:hover {
    background: rgba(137, 190, 255, 0.3);
  }

  & > p {
    position: absolute;
    background: #89bfff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 18px;
    width: 18px;
    right: 6px;
    letter-spacing: 0.15px;
    font-size: 14px;
    color: #07020f;
  }
`;

interface Block {
  BackgroundColorHex: string;
  BlockOpacityIn0To100Ratio: number;
  FontColorHex: string;
  Height: number;
  Id: string;
  IsRequiredOnCard: boolean;
  StartX: number;
  Type: number;
  Width: number;
}

interface ProducerCard {
  BackgroundColorHex: string;
  VerifiedRole: boolean;
  CardId: string;
  Country: string;
  PostalCode: string;
  ProducerFirstName: string;
  ProducerId: string;
  ProducerLastName: string;
  ProducerName: string;
  ProducerPhoto: string;
  AboutMe: string;
  Role: number;
  City: string;
  SecondaryRole: number;
  Street: string;
  BaseBlock: Block;
}

export interface Role {
  FieldName: string;
  FlagValue: number;
}

interface FilterCategory {
  Name: string | number;
  Id: number;
}

enum SortBy {
  DATE_ASCENDING = 'DATE_ASCENDING',
  DATE_DESCENDING = 'DATE_DESCENDING',
  NAME_ASCENDING = 'NAME_ASCENDING',
  NAME_DESCENDING = 'NAME_DESCENDING',
}

const Producers = () => {
  const [producers, setProducers] = useState<ProducerCard[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [roles, setRoles] = useState<Role[]>([]);
  const [onlyFollowedProducers, setOnlyFollowedProducers] = useState<boolean>(
    sessionStorage.getItem('producersFilterOnlyFollowedProducers') === 'yes'
      ? true
      : false,
  );
  const [onlyProducersWithWinWin, setOnlyProducersWithWinWin] =
    useState<boolean>(
      sessionStorage.getItem('producersFilterOnlyProducersWithWinWin') === 'yes'
        ? true
        : false,
    );
  const [
    onlyProducersWithExclusiveOffers,
    setOnlyProducersWithExclusiveOffers,
  ] = useState<boolean>(
    sessionStorage.getItem(
      'producersFilterOnlyProducersWithExclusiveOffers',
    ) === 'yes'
      ? true
      : false,
  );
  const [searchString, setSearchString] = useState<string>(
    sessionStorage.getItem('producersFilterSearchString')
      ? sessionStorage.getItem('producersFilterSearchString')
      : '',
  );
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState<boolean>(false);
  const [isSortMenuOpen, setIsSortMenuOpen] = useState<boolean>(false);
  const [primaryRolesToFilter, setPrimaryRolesToFilter] = useState<
    FilterCategory[]
  >([]);
  const [secondaryRolesToFilter, setSecondaryRolesToFilter] = useState<
    FilterCategory[]
  >([]);
  const [selectedPrimaryRole, setSelectedPrimaryRole] = useState<
    number | string
  >(
    sessionStorage.getItem('producersFilterPrimaryRole')
      ? +sessionStorage.getItem('producersFilterPrimaryRole')
      : '',
  );
  const [selectedSecondaryRole, setSelectedSecondaryRole] = useState<
    number | string
  >(
    sessionStorage.getItem('producersFilterSecondaryRole')
      ? +sessionStorage.getItem('producersFilterSecondaryRole')
      : '',
  );
  const [city, setCity] = useState<string>(
    sessionStorage.getItem('producersFilterCity')
      ? sessionStorage.getItem('producersFilterCity')
      : '',
  );
  const [sortBy, setSortBy] = useState<SortBy | string>(
    sessionStorage.getItem('producersFilterSortBy')
      ? sessionStorage.getItem('producersFilterSortBy')
      : SortBy.DATE_DESCENDING,
  );
  const [hasMoreProducers, setHasMoreProducers] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const lastElementRef = useInfinityScroller(
    isLoading,
    hasMoreProducers,
    setPageNumber,
  );

  const mobileLastElementRef = useInfinityScroller(
    isLoading,
    hasMoreProducers,
    setPageNumber,
  );

  const mobileSearchBarRef = useRef<HTMLInputElement>(null);
  const mobileFilterMenuWrapperRef = useRef<HTMLDivElement | null>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const {setIsBodyOverflowBlocked} = useIsBodyOverflowBlockedContext();

  const {setIsMenuOpen} = useMenuContext();
  const {isMobileFilterMenuOpen, setIsMobileFilterMenuOpen} =
    useFilterMenuContext();
  const {isMobileSearchBarOpen, setIsMobileSearchBarOpen} =
    useSearchBarContext();

  const handleIsMobileFilterMenuOpenChange = (value: boolean) =>
    setIsMobileFilterMenuOpen(value);

  const handleIsMobileSearchBarOpenChange = (value: boolean) =>
    setIsMobileSearchBarOpen(value);

  const handleIsFilterMenuOpenChange = (value: boolean) =>
    setIsFilterMenuOpen(value);

  const handleIsSortMenuOpenChange = (value: boolean) =>
    setIsSortMenuOpen(value);

  const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCity(e.target.value);
    sessionStorage.setItem('producersFilterCity', e.target.value);
    setSearchParams({
      OnlyFollowedProducers: onlyFollowedProducers ? 'yes' : 'no',
      OnlyProducersWithWinWin: onlyProducersWithWinWin ? 'yes' : 'no',
      OnlyProducersWithExclusiveOffers: onlyProducersWithExclusiveOffers
        ? 'yes'
        : 'no',
      SearchString: searchString,
      SelectedPrimaryRole: selectedPrimaryRole.toString(),
      SelectedSecondaryRole: selectedSecondaryRole.toString(),
      City: e.target.value,
    });
  };

  const handleSortByChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSortBy(e.target.value);
    sessionStorage.setItem('producersFilterSortBy', e.target.value);
  };

  const handleOnlyFollowedProducersChange = (value: boolean) => {
    setOnlyFollowedProducers(value);
    if (value === true) {
      sessionStorage.setItem('producersFilterOnlyFollowedProducers', 'yes');
    } else {
      sessionStorage.setItem('producersFilterOnlyFollowedProducers', 'no');
    }
    setSearchParams({
      OnlyFollowedProducers: value ? 'yes' : 'no',
      OnlyProducersWithWinWin: onlyProducersWithWinWin ? 'yes' : 'no',
      OnlyProducersWithExclusiveOffers: onlyProducersWithExclusiveOffers
        ? 'yes'
        : 'no',
      SearchString: searchString,
      SelectedPrimaryRole: selectedPrimaryRole.toString(),
      SelectedSecondaryRole: selectedSecondaryRole.toString(),
      City: city,
    });
  };

  const handleOnlyProducersWithWinWinChange = (value: boolean) => {
    setOnlyProducersWithWinWin(value);
    if (value === true) {
      sessionStorage.setItem('producersFilterOnlyProducersWithWinWin', 'yes');
    } else {
      sessionStorage.setItem('producersFilterOnlyProducersWithWinWin', 'no');
    }
    setSearchParams({
      OnlyFollowedProducers: onlyFollowedProducers ? 'yes' : 'no',
      OnlyProducersWithWinWin: value ? 'yes' : 'no',
      OnlyProducersWithExclusiveOffers: onlyProducersWithExclusiveOffers
        ? 'yes'
        : 'no',
      SearchString: searchString,
      SelectedPrimaryRole: selectedPrimaryRole.toString(),
      SelectedSecondaryRole: selectedSecondaryRole.toString(),
      City: city,
    });
  };

  const handleOnlyProducersWithExclusiveOffersChange = (value: boolean) => {
    setOnlyProducersWithExclusiveOffers(value);
    if (value === true) {
      sessionStorage.setItem(
        'producersFilterOnlyProducersWithExclusiveOffers',
        'yes',
      );
    } else {
      sessionStorage.setItem(
        'producersFilterOnlyProducersWithExclusiveOffers',
        'no',
      );
    }
    setSearchParams({
      OnlyFollowedProducers: onlyFollowedProducers ? 'yes' : 'no',
      OnlyProducersWithWinWin: onlyProducersWithWinWin ? 'yes' : 'no',
      OnlyProducersWithExclusiveOffers: value ? 'yes' : 'no',
      SearchString: searchString,
      SelectedPrimaryRole: selectedPrimaryRole.toString(),
      SelectedSecondaryRole: selectedSecondaryRole.toString(),
      City: city,
    });
  };

  const clearFilters = () => {
    setSearchString('');
    sessionStorage.removeItem('producersFilterSearchString');
    setOnlyProducersWithWinWin(false);
    sessionStorage.removeItem('producersFilterOnlyProducersWithWinWin');
    setOnlyFollowedProducers(false);
    sessionStorage.removeItem('producersFilterOnlyFollowedProducers');
    setOnlyProducersWithExclusiveOffers(false);
    sessionStorage.removeItem(
      'producersFilterOnlyProducersWithExclusiveOffers',
    );
    setSelectedPrimaryRole('');
    sessionStorage.removeItem('producersFilterPrimaryRole');
    setSelectedSecondaryRole('');
    sessionStorage.removeItem('producersFilterSecondaryRole');
    setCity('');
    sessionStorage.removeItem('producersFilterCity');
    setSearchParams({});
  };

  const getProducers = async (
    searchString: string,
    role: number | string,
    secondaryRole: number | string,
    city: string,
    onlyFollowedProducers: boolean,
    onlyProducersWithWinWin: boolean,
    onlyProducersWithExclusiveOffers: boolean,
    sortBy: SortBy | string,
    pageNumber: number,
  ) => {
    setIsLoading(true);
    try {
      setIsLoading(true);
      const params = {
        pageSize: 20,
        pageNumber: pageNumber,
        searchString: searchString ? searchString : undefined,
        role,
        secondaryRole,
        city,
        onlyFollowedProducers,
        onlyProducersWithWinWin,
        onlyProducersWithExclusiveOffers,
        sortType:
          sortBy === SortBy.DATE_ASCENDING || sortBy === SortBy.NAME_ASCENDING
            ? 0
            : 1,
        sortBy:
          sortBy === SortBy.NAME_ASCENDING || sortBy === SortBy.NAME_DESCENDING
            ? 1
            : 2,
      };

      const response = await api.get(`Producer/`, {params});
      setHasMoreProducers(response.data.Response.length > 0);
      setProducers((prev) => [...prev, ...response.data.Response]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getRoles = async () => {
    try {
      const response = await api.get('Producer/Roles');
      setRoles(response.data.Response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectedPrimaryRoleChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSelectedPrimaryRole(e.target.value);
    setSelectedSecondaryRole('');
    sessionStorage.setItem('producersFilterSecondaryRole', '');
    sessionStorage.setItem('producersFilterPrimaryRole', e.target.value);
    setSearchParams({
      OnlyFollowedProducers: onlyFollowedProducers ? 'yes' : 'no',
      OnlyProducersWithWinWin: onlyProducersWithWinWin ? 'yes' : 'no',
      OnlyProducersWithExclusiveOffers: onlyProducersWithExclusiveOffers
        ? 'yes'
        : 'no',
      SearchString: searchString,
      SelectedPrimaryRole: e.target.value,
      SelectedSecondaryRole: selectedSecondaryRole.toString(),
      City: city,
    });
  };

  const handleSelectedSecondaryRoleChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSelectedSecondaryRole(e.target.value);
    sessionStorage.setItem('producersFilterSecondaryRole', e.target.value);
    setSearchParams({
      OnlyFollowedProducers: onlyFollowedProducers ? 'yes' : 'no',
      OnlyProducersWithWinWin: onlyProducersWithWinWin ? 'yes' : 'no',
      OnlyProducersWithExclusiveOffers: onlyProducersWithExclusiveOffers
        ? 'yes'
        : 'no',
      SearchString: searchString,
      SelectedPrimaryRole: selectedPrimaryRole.toString(),
      SelectedSecondaryRole: e.target.value,
      City: city,
    });
  };

  const getProducersDebounce = useCallback(
    _.debounce(
      (
        searchString,
        selectedPrimaryRole,
        selectedSecondaryRole,
        city,
        onlyFollowedProducers,
        onlyProducersWithWinWin,
        onlyProducersWithExclusiveOffers,
        sortBy,
        pageNumber,
      ) =>
        getProducers(
          searchString,
          selectedPrimaryRole,
          selectedSecondaryRole,
          city,
          onlyFollowedProducers,
          onlyProducersWithWinWin,
          onlyProducersWithExclusiveOffers,
          sortBy,
          pageNumber,
        ),
      500,
    ),
    [],
  );

  const handleSearchStringChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
    sessionStorage.setItem('producersFilterSearchString', e.target.value);
    setSearchParams({
      OnlyFollowedProducers: onlyFollowedProducers ? 'yes' : 'no',
      OnlyProducersWithWinWin: onlyProducersWithWinWin ? 'yes' : 'no',
      OnlyProducersWithExclusiveOffers: onlyProducersWithExclusiveOffers
        ? 'yes'
        : 'no',
      SearchString: e.target.value,
      SelectedPrimaryRole: selectedPrimaryRole.toString(),
      SelectedSecondaryRole: selectedSecondaryRole.toString(),
      City: city,
    });
  };

  const mapCategoriesForFilter = () => {
    producers.map((producer) => {
      if (producer.Role === null || producer.Role === undefined) return;
      setPrimaryRolesToFilter((prev) =>
        [...prev.filter((el) => el.Id === producer.Role)].length === 0
          ? [
              ...prev,
              {
                Name:
                  roles.filter((el) => el.FlagValue === producer.Role)[0]
                    .FieldName || producer.Role,
                Id: producer.Role,
              },
            ]
          : [...prev],
      );
    });

    producers.map((producer) => {
      if (
        producer.SecondaryRole === null ||
        producer.SecondaryRole === undefined
      )
        return;
      setSecondaryRolesToFilter((prev) =>
        [...prev.filter((el) => el.Id === producer.SecondaryRole)].length === 0
          ? [
              ...prev,
              {
                Name:
                  roles.filter(
                    (el) => el.FlagValue === producer.SecondaryRole,
                  )[0].FieldName || producer.SecondaryRole,
                Id: producer.SecondaryRole,
              },
            ]
          : [...prev],
      );
    });
  };

  // const handleScroll = () => {
  //   if (!scrollRef.current || isLoading || !hasMoreProducers) return;

  //   const scrollElement = scrollRef.current;

  //   if (
  //     scrollElement.scrollTop + scrollElement.clientHeight !==
  //       scrollElement.scrollHeight ||
  //     isLoading
  //   ) {
  //     return;
  //   }
  //   getProducers(
  //     searchString,
  //     selectedPrimaryRole,
  //     selectedSecondaryRole,
  //     city,
  //     onlyFollowedProducers,
  //     onlyProducersWithWinWin,
  //     onlyProducersWithExclusiveOffers,
  //     sortBy,
  //     pageNumber,
  //   );
  // };

  // const handleMobileScroll = () => {
  //   if (
  //     window.innerHeight + document.documentElement.scrollTop !==
  //       document.documentElement.offsetHeight ||
  //     isLoading
  //   ) {
  //     return;
  //   }
  //   getProducers(
  //     searchString,
  //     selectedPrimaryRole,
  //     selectedSecondaryRole,
  //     city,
  //     onlyFollowedProducers,
  //     onlyProducersWithWinWin,
  //     onlyProducersWithExclusiveOffers,
  //     sortBy,
  //     pageNumber,
  //   );
  // };

  // useEffect(() => {
  //   const scrollElement = scrollRef.current;

  //   if (!scrollElement) return;

  //   scrollElement.addEventListener('scroll', handleScroll);
  //   return () => scrollElement.removeEventListener('scroll', handleScroll);
  // }, [isLoading, hasMoreProducers, pageNumber]);

  // useEffect(() => {
  //   window.addEventListener('scroll', handleMobileScroll);
  //   return () => window.removeEventListener('scroll', handleMobileScroll);
  // }, [isLoading, hasMoreProducers, pageNumber]);

  useEffect(() => {
    const urlOnlyFollowedProducers = searchParams.get('OnlyFollowedProducers');
    const urlOnlyProducersWithWinWin = searchParams.get(
      'OnlyProducersWithWinWin',
    );
    const urlOnlyProducersWithExclusiveOffers = searchParams.get(
      'OnlyProducersWithExclusiveOffers',
    );
    const urlSearchString = searchParams.get('SearchString');
    const urlSelectedPrimaryRole = searchParams.get('SelectedPrimaryRole');
    const urlSelectedSecondaryRole = searchParams.get('SelectedSecondaryRole');
    const urlCity = searchParams.get('City');

    if (urlOnlyFollowedProducers) {
      const onlyFollowedProducersValue = urlOnlyFollowedProducers === 'yes';
      setOnlyFollowedProducers(onlyFollowedProducersValue);
      sessionStorage.setItem(
        'producersFilterOnlyFollowedProducers',
        urlOnlyFollowedProducers,
      );
    }

    if (urlOnlyProducersWithWinWin) {
      const onlyProducersWithWinWinValue = urlOnlyProducersWithWinWin === 'yes';
      setOnlyProducersWithWinWin(onlyProducersWithWinWinValue);
      sessionStorage.setItem(
        'producersFilterOnlyProducersWithWinWin',
        urlOnlyProducersWithWinWin,
      );
    }

    if (urlOnlyProducersWithExclusiveOffers) {
      const onlyProducersWithExclusiveOffersValue =
        urlOnlyProducersWithExclusiveOffers === 'yes';
      setOnlyProducersWithExclusiveOffers(
        onlyProducersWithExclusiveOffersValue,
      );
      sessionStorage.setItem(
        'producersFilterOnlyProducersWithExclusiveOffers',
        urlOnlyProducersWithExclusiveOffers,
      );
    }

    if (urlSearchString) {
      setSearchString(urlSearchString);
      sessionStorage.setItem('producersFilterSearchString', urlSearchString);
    }

    if (urlSelectedPrimaryRole) {
      setSelectedPrimaryRole(
        urlSelectedPrimaryRole ? +urlSelectedPrimaryRole : '',
      );
      sessionStorage.setItem(
        'producersFilterPrimaryRole',
        urlSelectedPrimaryRole,
      );
    }

    if (urlSelectedSecondaryRole) {
      setSelectedSecondaryRole(
        urlSelectedSecondaryRole ? +urlSelectedSecondaryRole : '',
      );
      sessionStorage.setItem(
        'producersFilterSecondaryRole',
        urlSelectedSecondaryRole,
      );
    }

    if (urlCity) {
      setCity(urlCity);
      sessionStorage.setItem('producersFilterCity', urlCity);
    }
  }, [searchParams]);

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    if (producers.length === 0 || roles.length === 0) return;
    mapCategoriesForFilter();
  }, [producers, roles]);

  useEffect(() => {
    const checkCloseButtonVisibility = () => {
      if (mobileFilterMenuWrapperRef.current) {
        if (isMobileFilterMenuOpen) {
          mobileFilterMenuWrapperRef.current.style.height = `${window.visualViewport.height}px`;
        } else {
          mobileFilterMenuWrapperRef.current.style.height = `${0}px`;
        }
      }
    };

    checkCloseButtonVisibility();

    window.addEventListener('resize', checkCloseButtonVisibility);

    return () => {
      window.removeEventListener('resize', checkCloseButtonVisibility);
    };
  }, [isMobileFilterMenuOpen]);

  useEffect(() => {
    getProducersDebounce(
      searchString,
      selectedPrimaryRole,
      selectedSecondaryRole,
      city,
      onlyFollowedProducers,
      onlyProducersWithWinWin,
      onlyProducersWithExclusiveOffers,
      sortBy,
      pageNumber,
    );
  }, [
    searchString,
    selectedPrimaryRole,
    selectedSecondaryRole,
    city,
    onlyFollowedProducers,
    onlyProducersWithWinWin,
    onlyProducersWithExclusiveOffers,
    sortBy,
    pageNumber,
  ]);

  useEffect(() => {
    setProducers([]);
    setPageNumber(1);
  }, [
    searchString,
    selectedPrimaryRole,
    selectedSecondaryRole,
    city,
    onlyFollowedProducers,
    onlyProducersWithWinWin,
    onlyProducersWithExclusiveOffers,
    sortBy,
  ]);

  useEffect(() => {
    document.title = `MUGO Studio - Twórcy`;
    document
      .querySelector("meta[property='og:title']")
      ?.setAttribute('content', `MUGO Studio - Twórcy`);
    document
      .querySelector("meta[property='og:description']")
      ?.setAttribute(
        'content',
        'MUGO Studio to przestrzeń dla twórców, freelancerów, artystów i ludzi poszukujących usług oraz produktów oferowanych przez te osoby. To platforma stworzona dla usługodawców i usługobiorców, gwarantująca bezpieczeństwo transakcji oraz stwarzająca możliwość współpracy na innowacyjnych warunkach.',
      );
  }, []);

  return (
    <>
      <MainWrapper>
        <Main>
          {isLoading && <LoadingCircle />}
          <PageHeaderWrapper
            onMouseLeave={() => {
              handleIsFilterMenuOpenChange(false);
              handleIsSortMenuOpenChange(false);
            }}
          >
            <PageHeaderHeaderWrapper>
              <PageHeaderHeader>
                <p>Twórcy</p>
              </PageHeaderHeader>
            </PageHeaderHeaderWrapper>
            <PageHeaderContent>
              <FilterWrapper>
                <SortButtonSvg
                  value={isSortMenuOpen}
                  onClick={() => {
                    handleIsSortMenuOpenChange(!isSortMenuOpen);
                    handleIsFilterMenuOpenChange(false);
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="18.824"
                  height="13.204"
                  viewBox="0 0 18.824 13.204"
                >
                  <g
                    id="Group_1311"
                    data-name="Group 1311"
                    transform="translate(-2536.541 -534.989)"
                  >
                    <path
                      id="Path_1508"
                      data-name="Path 1508"
                      d="M2543.457,537.33l-2.582-2.1a1.076,1.076,0,0,0-1.357,0l-2.581,2.1a1.075,1.075,0,0,0,1.357,1.668l.9-.732v6.852a1.075,1.075,0,1,0,2.15,0v-6.735l.756.615a1.075,1.075,0,1,0,1.357-1.668Z"
                      fill="#797c93"
                    />
                    <path
                      id="Path_1509"
                      data-name="Path 1509"
                      d="M2548.448,545.852l2.582,2.1a1.074,1.074,0,0,0,1.357,0l2.581-2.1a1.075,1.075,0,0,0-1.357-1.668l-.9.732v-6.852a1.075,1.075,0,0,0-2.151,0V544.8l-.756-.615a1.075,1.075,0,0,0-1.357,1.668Z"
                      fill="#797c93"
                    />
                  </g>
                </SortButtonSvg>
                <FilterInputWrapper>
                  {/* <img src={searchIcon} alt="search" /> */}
                  <svg
                    id="Group_4104"
                    data-name="Group 4104"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14.146"
                    height="14"
                    viewBox="0 0 14.146 14"
                  >
                    <path
                      id="Path_1507"
                      data-name="Path 1507"
                      d="M2601.826,547.617l-3.519-3.207a5.82,5.82,0,0,0-.448-7.709h0a5.816,5.816,0,1,0-.7,8.813l3.6,3.28a.8.8,0,1,0,1.072-1.177Zm-11.3-3.586a4.554,4.554,0,1,1,3.219,1.333A4.559,4.559,0,0,1,2590.529,544.03Z"
                      transform="translate(-2587.94 -535.001)"
                      fill="#0091ff"
                    />
                  </svg>
                  <input
                    type="text"
                    placeholder="Wyszukaj..."
                    value={searchString}
                    onChange={handleSearchStringChange}
                  />
                </FilterInputWrapper>
                {countTruthyVariables(
                  searchString,
                  selectedPrimaryRole,
                  selectedSecondaryRole,
                  city,
                  onlyFollowedProducers,
                  onlyProducersWithWinWin,
                  onlyProducersWithExclusiveOffers,
                ) > 0 && (
                  <CountFilterDisplay>
                    {countTruthyVariables(
                      searchString,
                      selectedPrimaryRole,
                      selectedSecondaryRole,
                      city,
                      onlyFollowedProducers,
                      onlyProducersWithWinWin,
                      onlyProducersWithExclusiveOffers,
                    )}
                  </CountFilterDisplay>
                )}
                <FilterButtonSvg
                  value={isFilterMenuOpen}
                  onClick={() => {
                    handleIsSortMenuOpenChange(false);
                    handleIsFilterMenuOpenChange(!isFilterMenuOpen);
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.87"
                  height="15.047"
                  viewBox="0 0 20.87 15.047"
                >
                  <g
                    id="Group_1312"
                    data-name="Group 1312"
                    transform="translate(-2473.175 -536.818)"
                  >
                    <path
                      id="Path_1510"
                      data-name="Path 1510"
                      d="M2480.2,539.042h13a.85.85,0,0,0,0-1.7h-13a.85.85,0,0,0,0,1.7Z"
                      fill="#797c93"
                    />
                    <path
                      id="Path_1511"
                      data-name="Path 1511"
                      d="M2493.2,543.517h-13a.85.85,0,0,0,0,1.7h13a.85.85,0,0,0,0-1.7Z"
                      fill="#797c93"
                    />
                    <path
                      id="Path_1512"
                      data-name="Path 1512"
                      d="M2493.2,549.4h-13a.85.85,0,0,0,0,1.7h13a.85.85,0,0,0,0-1.7Z"
                      fill="#797c93"
                    />
                    <circle
                      id="Ellipse_1"
                      data-name="Ellipse 1"
                      cx="1.5"
                      cy="1.5"
                      r="1.5"
                      transform="translate(2473.175 548.864)"
                      fill="#797c93"
                    />
                    <circle
                      id="Ellipse_2"
                      data-name="Ellipse 2"
                      cx="1.5"
                      cy="1.5"
                      r="1.5"
                      transform="translate(2473.175 542.853)"
                      fill="#797c93"
                    />
                    <circle
                      id="Ellipse_3"
                      data-name="Ellipse 3"
                      cx="1.5"
                      cy="1.5"
                      r="1.5"
                      transform="translate(2473.175 536.818)"
                      fill="#797c93"
                    />
                  </g>
                </FilterButtonSvg>
                {countTruthyVariables(
                  searchString,
                  selectedPrimaryRole,
                  selectedSecondaryRole,
                  city,
                  onlyFollowedProducers,
                  onlyProducersWithWinWin,
                  onlyProducersWithExclusiveOffers,
                ) > 0 && (
                  <ClearFiltersButton onClick={() => clearFilters()}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10.9"
                      height="12"
                      viewBox="0 0 10.9 12"
                    >
                      <g
                        id="Icon_feather-trash"
                        data-name="Icon feather-trash"
                        transform="translate(0.5 0.5)"
                      >
                        <path
                          id="Path_3663"
                          data-name="Path 3663"
                          d="M4.5,9h9.9"
                          transform="translate(-4.5 -6.8)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                        <path
                          id="Path_3664"
                          data-name="Path 3664"
                          d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                          transform="translate(-6.4 -3)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                      </g>
                    </svg>
                  </ClearFiltersButton>
                )}
              </FilterWrapper>
              {isFilterMenuOpen && (
                <FilterMenuWrapper>
                  <SingleFilterWrapper>
                    <SingleFilterContentWrapper>
                      <SelectWrapper>
                        <p>Profesja</p>
                        <select
                          value={selectedPrimaryRole}
                          onChange={handleSelectedPrimaryRoleChange}
                        >
                          <option hidden value={''}>
                            Wybierz
                          </option>
                          <option value={''}>Wszystkie</option>
                          {primaryRolesToFilter
                            .filter((item) => item.Id % 100 === 0)
                            .map((role) => {
                              const {Id, Name} = role;

                              return (
                                <option key={Id} value={Id}>
                                  {Name}
                                </option>
                              );
                            })}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#0091ff"
                            />
                          </g>
                        </svg>
                      </SelectWrapper>
                      <SelectWrapper>
                        <p>Specjalizacja</p>
                        <select
                          value={selectedSecondaryRole}
                          onChange={handleSelectedSecondaryRoleChange}
                        >
                          <option hidden value={''}>
                            Wybierz
                          </option>
                          <option value={''}>Wszystkie</option>
                          {secondaryRolesToFilter
                            .concat(primaryRolesToFilter)
                            .filter(
                              (item) =>
                                item.Id >
                                  Number(
                                    selectedPrimaryRole && selectedPrimaryRole,
                                  ) &&
                                item.Id <
                                  Number(
                                    selectedPrimaryRole && selectedPrimaryRole,
                                  ) +
                                    100,
                            )
                            .map((role) => {
                              const {Id, Name} = role;

                              return (
                                <option key={Id} value={Id}>
                                  {Name}
                                </option>
                              );
                            })}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#0091ff"
                            />
                          </g>
                        </svg>
                      </SelectWrapper>
                    </SingleFilterContentWrapper>
                  </SingleFilterWrapper>

                  <SingleFilterWrapper>
                    <SingleFilterContentWrapper>
                      <FilterInput>
                        <p>Miejscowość</p>
                        <input
                          type="text"
                          placeholder="Znajdź..."
                          value={city}
                          onChange={handleCityChange}
                        />
                        <svg
                          id="Group_4104"
                          data-name="Group 4104"
                          xmlns="http://www.w3.org/2000/svg"
                          width="13.136"
                          height="13"
                          viewBox="0 0 13.136 13"
                        >
                          <path
                            id="Path_1507"
                            data-name="Path 1507"
                            d="M2600.834,546.715l-3.268-2.978a5.4,5.4,0,0,0-.416-7.158h0a5.4,5.4,0,1,0-.652,8.183l3.342,3.046a.739.739,0,1,0,1-1.093Zm-10.49-3.33a4.229,4.229,0,1,1,2.989,1.238A4.233,4.233,0,0,1,2590.344,543.385Z"
                            transform="translate(-2587.94 -535.001)"
                            fill="#0091ff"
                          />
                        </svg>
                      </FilterInput>
                    </SingleFilterContentWrapper>
                  </SingleFilterWrapper>

                  <SingleFilterWrapper>
                    <SingleFilterContentWrapper>
                      <CheckboxWithTitleWrapper>
                        <p>Tylko obserwowani Twórcy</p>
                        <CustomBlueCheckbox
                          isActive={onlyFollowedProducers}
                          onClick={() =>
                            handleOnlyFollowedProducersChange(
                              !onlyFollowedProducers,
                            )
                          }
                        >
                          <div />
                        </CustomBlueCheckbox>
                      </CheckboxWithTitleWrapper>
                    </SingleFilterContentWrapper>
                    <SingleFilterContentWrapper>
                      <CheckboxWithTitleWrapper>
                        <p>Tylko Twórcy oferujący WIN-WIN</p>
                        <CustomBlueCheckbox
                          isActive={onlyProducersWithWinWin}
                          onClick={() =>
                            handleOnlyProducersWithWinWinChange(
                              !onlyProducersWithWinWin,
                            )
                          }
                        >
                          <div />
                        </CustomBlueCheckbox>
                      </CheckboxWithTitleWrapper>
                    </SingleFilterContentWrapper>
                    <SingleFilterContentWrapper>
                      <CheckboxWithTitleWrapper style={{marginBottom: '0px'}}>
                        <p>Tylko Twórcy zamknięte oferty</p>
                        <CustomBlueCheckbox
                          isActive={onlyProducersWithExclusiveOffers}
                          onClick={() =>
                            handleOnlyProducersWithExclusiveOffersChange(
                              !onlyProducersWithExclusiveOffers,
                            )
                          }
                        >
                          <div />
                        </CustomBlueCheckbox>
                      </CheckboxWithTitleWrapper>
                    </SingleFilterContentWrapper>
                  </SingleFilterWrapper>
                </FilterMenuWrapper>
              )}
              {isSortMenuOpen && (
                <FilterMenuWrapper>
                  <SingleFilterWrapper>
                    <SingleFilterContentWrapper>
                      <SelectWrapper>
                        <p>Kolejność</p>
                        <select onChange={handleSortByChange} value={sortBy}>
                          <option hidden value={''}>
                            Sortowanie
                          </option>
                          {/* <option value={SortBy.DATE_ASCENDING}>
                          Date - From oldest
                        </option>
                        <option value={SortBy.DATE_DESCENDING}>
                          Date - From newest
                        </option> */}
                          <option value={SortBy.DATE_ASCENDING}>
                            Od najstarszego
                          </option>
                          <option value={SortBy.DATE_DESCENDING}>
                            Od najnowszego
                          </option>
                          <option value={SortBy.NAME_ASCENDING}>
                            Nazwa A-Z
                          </option>
                          <option value={SortBy.NAME_DESCENDING}>
                            Nazwa Z-A
                          </option>
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#0091ff"
                            />
                          </g>
                        </svg>
                      </SelectWrapper>
                    </SingleFilterContentWrapper>
                  </SingleFilterWrapper>
                </FilterMenuWrapper>
              )}
            </PageHeaderContent>
          </PageHeaderWrapper>
          <Grid ref={scrollRef}>
            {producers.map((producer) => {
              return (
                <SingleProducer
                  desktopRef={lastElementRef}
                  producer={producer}
                  roles={roles}
                  key={producer.CardId}
                />
              );
            })}
          </Grid>
        </Main>
      </MainWrapper>
      <MobileMainWrapper>
        <MenuMain
          isMenuOpen={isMobileFilterMenuOpen}
          ref={mobileFilterMenuWrapperRef}
        >
          <CloseIsLoginPageOpenWrapper>
            Filtrowanie
            <CloseIsLoginPageOpen
              onClick={() => {
                handleIsMobileFilterMenuOpenChange(false);
                setIsBodyOverflowBlocked(false);
                handleIsSortMenuOpenChange(false);
                handleIsFilterMenuOpenChange(false);
                mobileFilterMenuWrapperRef.current.style.transition =
                  'all 0.35s ease-out';
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16.625"
                height="16.625"
                viewBox="0 0 16.625 16.625"
              >
                <path
                  id="Path_3807"
                  data-name="Path 3807"
                  d="M27.313,24.987l-2.326,2.326L19,21.326l-5.987,5.987-2.326-2.326L16.674,19l-5.987-5.987,2.326-2.326L19,16.674l5.987-5.987,2.326,2.326L21.326,19Z"
                  transform="translate(-10.688 -10.688)"
                  fill="#07020f"
                />
              </svg>
            </CloseIsLoginPageOpen>
          </CloseIsLoginPageOpenWrapper>
          <MenuWrapper>
            {isSortMenuOpen && (
              <>
                <MobileSelectFilterWrapper value={sortBy}>
                  <select onChange={handleSortByChange} value={sortBy}>
                    <option hidden value={''}>
                      Sortowanie
                    </option>
                    {/* <option value={SortBy.DATE_ASCENDING}>
                          Date - From oldest
                        </option>
                        <option value={SortBy.DATE_DESCENDING}>
                          Date - From newest
                        </option> */}
                    <option value={SortBy.NAME_ASCENDING}>Nazwa A-Z</option>
                    <option value={SortBy.NAME_DESCENDING}>Nazwa Z-A</option>
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.609"
                    height="13"
                    viewBox="0 0 7.609 13"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(-21.391 -14) rotate(180)"
                    >
                      <path
                        id="Arrow"
                        d="M6.048,12.735.185,6.941a.618.618,0,0,1,0-.882L6.048.265a.923.923,0,0,1,1.294,0,.9.9,0,0,1,0,1.278L2.327,6.5l5.015,4.956a.9.9,0,0,1,0,1.279.923.923,0,0,1-1.294,0"
                        transform="translate(-29 -27)"
                        fill="#3986ef"
                      />
                    </g>
                  </svg>
                </MobileSelectFilterWrapper>
              </>
            )}
            {isFilterMenuOpen && (
              <>
                <MobileSelectFilterWrapper value={selectedPrimaryRole}>
                  <select
                    value={selectedPrimaryRole}
                    onChange={handleSelectedPrimaryRoleChange}
                  >
                    <option hidden value={''}>
                      Profesja
                    </option>
                    <option value={''}>Wszystkie</option>
                    {primaryRolesToFilter
                      .filter((item) => item.Id % 100 === 0)
                      .map((role) => {
                        const {Id, Name} = role;

                        return (
                          <option key={Id} value={Id}>
                            {Name}
                          </option>
                        );
                      })}
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.609"
                    height="13"
                    viewBox="0 0 7.609 13"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(-21.391 -14) rotate(180)"
                    >
                      <path
                        id="Arrow"
                        d="M6.048,12.735.185,6.941a.618.618,0,0,1,0-.882L6.048.265a.923.923,0,0,1,1.294,0,.9.9,0,0,1,0,1.278L2.327,6.5l5.015,4.956a.9.9,0,0,1,0,1.279.923.923,0,0,1-1.294,0"
                        transform="translate(-29 -27)"
                        fill="#3986ef"
                      />
                    </g>
                  </svg>
                </MobileSelectFilterWrapper>
                <MobileSelectFilterWrapper value={selectedSecondaryRole}>
                  <select
                    value={selectedSecondaryRole}
                    onChange={handleSelectedSecondaryRoleChange}
                  >
                    <option hidden value={''}>
                      Specjalizacja
                    </option>
                    <option value={''}>Wszystkie</option>
                    {secondaryRolesToFilter
                      .concat(primaryRolesToFilter)
                      .filter(
                        (item) =>
                          item.Id >
                            Number(
                              selectedPrimaryRole && selectedPrimaryRole,
                            ) &&
                          item.Id <
                            Number(selectedPrimaryRole && selectedPrimaryRole) +
                              100,
                      )
                      .map((role) => {
                        const {Id, Name} = role;

                        return (
                          <option key={Id} value={Id}>
                            {Name}
                          </option>
                        );
                      })}
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.609"
                    height="13"
                    viewBox="0 0 7.609 13"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(-21.391 -14) rotate(180)"
                    >
                      <path
                        id="Arrow"
                        d="M6.048,12.735.185,6.941a.618.618,0,0,1,0-.882L6.048.265a.923.923,0,0,1,1.294,0,.9.9,0,0,1,0,1.278L2.327,6.5l5.015,4.956a.9.9,0,0,1,0,1.279.923.923,0,0,1-1.294,0"
                        transform="translate(-29 -27)"
                        fill="#3986ef"
                      />
                    </g>
                  </svg>
                </MobileSelectFilterWrapper>

                <MobileInputFilterWrapper>
                  <p>Miejscowość</p>
                  <input
                    type="text"
                    placeholder="Znajdź..."
                    value={city}
                    onChange={handleCityChange}
                  />
                </MobileInputFilterWrapper>
                <MobileCheckboxFilterWrapper>
                  <p>Tylko obserwowani Twórcy</p>
                  <MobileCheckbox
                    isActive={onlyFollowedProducers}
                    onClick={() =>
                      handleOnlyFollowedProducersChange(!onlyFollowedProducers)
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22.167"
                      height="22.167"
                      viewBox="0 0 22.167 22.167"
                    >
                      <path
                        id="Icon_awesome-check-circle"
                        data-name="Icon awesome-check-circle"
                        d="M22.729,11.646A11.083,11.083,0,1,1,11.646.563,11.083,11.083,0,0,1,22.729,11.646ZM10.364,17.514l8.223-8.223a.715.715,0,0,0,0-1.011L17.576,7.269a.715.715,0,0,0-1.011,0L9.858,13.975,6.727,10.844a.715.715,0,0,0-1.011,0L4.7,11.855a.715.715,0,0,0,0,1.011l4.648,4.648a.715.715,0,0,0,1.011,0Z"
                        transform="translate(-0.563 -0.563)"
                        fill="#06f"
                      />
                    </svg>
                  </MobileCheckbox>
                </MobileCheckboxFilterWrapper>
                <MobileCheckboxFilterWrapper>
                  <p>Tylko Twórcy oferujący WIN-WIN</p>
                  <MobileCheckbox
                    isActive={onlyProducersWithWinWin}
                    onClick={() =>
                      handleOnlyProducersWithWinWinChange(
                        !onlyProducersWithWinWin,
                      )
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22.167"
                      height="22.167"
                      viewBox="0 0 22.167 22.167"
                    >
                      <path
                        id="Icon_awesome-check-circle"
                        data-name="Icon awesome-check-circle"
                        d="M22.729,11.646A11.083,11.083,0,1,1,11.646.563,11.083,11.083,0,0,1,22.729,11.646ZM10.364,17.514l8.223-8.223a.715.715,0,0,0,0-1.011L17.576,7.269a.715.715,0,0,0-1.011,0L9.858,13.975,6.727,10.844a.715.715,0,0,0-1.011,0L4.7,11.855a.715.715,0,0,0,0,1.011l4.648,4.648a.715.715,0,0,0,1.011,0Z"
                        transform="translate(-0.563 -0.563)"
                        fill="#06f"
                      />
                    </svg>
                  </MobileCheckbox>
                </MobileCheckboxFilterWrapper>
                <MobileCheckboxFilterWrapper>
                  <p>Tylko Twórcy zamknięte oferty</p>
                  <MobileCheckbox
                    isActive={onlyProducersWithExclusiveOffers}
                    onClick={() =>
                      handleOnlyProducersWithExclusiveOffersChange(
                        !onlyProducersWithExclusiveOffers,
                      )
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22.167"
                      height="22.167"
                      viewBox="0 0 22.167 22.167"
                    >
                      <path
                        id="Icon_awesome-check-circle"
                        data-name="Icon awesome-check-circle"
                        d="M22.729,11.646A11.083,11.083,0,1,1,11.646.563,11.083,11.083,0,0,1,22.729,11.646ZM10.364,17.514l8.223-8.223a.715.715,0,0,0,0-1.011L17.576,7.269a.715.715,0,0,0-1.011,0L9.858,13.975,6.727,10.844a.715.715,0,0,0-1.011,0L4.7,11.855a.715.715,0,0,0,0,1.011l4.648,4.648a.715.715,0,0,0,1.011,0Z"
                        transform="translate(-0.563 -0.563)"
                        fill="#06f"
                      />
                    </svg>
                  </MobileCheckbox>
                </MobileCheckboxFilterWrapper>
              </>
            )}
          </MenuWrapper>
        </MenuMain>
        <MobileMain>
          <NavButtonsWrapper>
            {countTruthyVariables(
              searchString,
              selectedPrimaryRole,
              selectedSecondaryRole,
              city,
              onlyFollowedProducers,
              onlyProducersWithWinWin,
              onlyProducersWithExclusiveOffers,
            ) > 0 && (
              <CountFilterDisplay>
                {countTruthyVariables(
                  searchString,
                  selectedPrimaryRole,
                  selectedSecondaryRole,
                  city,
                  onlyFollowedProducers,
                  onlyProducersWithWinWin,
                  onlyProducersWithExclusiveOffers,
                )}
              </CountFilterDisplay>
            )}
            <ArrowSvg
              onClick={() => {
                setIsBodyOverflowBlocked(true);
                setIsMobileSearchBarOpen(false);
                handleIsSortMenuOpenChange(true);
                handleIsFilterMenuOpenChange(false);
                handleIsMobileFilterMenuOpenChange(!isMobileFilterMenuOpen);
                if (!isMobileFilterMenuOpen) {
                  setTimeout(() => {
                    mobileFilterMenuWrapperRef.current.style.transition =
                      'none';
                  }, 350);
                }
              }}
              id="Group_1923"
              data-name="Group 1923"
              xmlns="http://www.w3.org/2000/svg"
              width="23.197"
              height="17.036"
              viewBox="0 0 23.197 17.036"
            >
              <path
                id="Path_1649"
                data-name="Path 1649"
                d="M258.494,352.337a1.071,1.071,0,0,0-1.437,0l-3.981,3.585a1.076,1.076,0,1,0,1.439,1.6l2.185-1.968v11.931a1.075,1.075,0,0,0,2.151,0V355.553l2.185,1.968a1.076,1.076,0,1,0,1.439-1.6Z"
                transform="translate(-252.719 -352.06)"
              />
              <path
                id="Path_1650"
                data-name="Path 1650"
                d="M269.854,363.478a1.073,1.073,0,0,0-1.518-.079l-2.187,1.97V353.436a1.075,1.075,0,1,0-2.149,0v11.931l-2.185-1.968a1.076,1.076,0,1,0-1.439,1.6l3.981,3.585a1.076,1.076,0,0,0,1.439,0L269.775,365A1.075,1.075,0,0,0,269.854,363.478Z"
                transform="translate(-246.934 -351.822)"
              />
            </ArrowSvg>
            <MobileSearchBarWrapper>
              <SearchSvg
                isActive={isMobileSearchBarOpen}
                onClick={() => {
                  setIsMenuOpen(false);
                  setTimeout(() => {
                    if (mobileSearchBarRef.current) {
                      mobileSearchBarRef.current.focus();
                    }
                  }, 0);

                  setIsMobileFilterMenuOpen(false);
                  handleIsMobileSearchBarOpenChange(!isMobileSearchBarOpen);
                }}
                id="Group_4104"
                data-name="Group 4104"
                xmlns="http://www.w3.org/2000/svg"
                width="14.146"
                height="14"
                viewBox="0 0 14.146 14"
              >
                <path
                  id="Path_1507"
                  data-name="Path 1507"
                  d="M2601.826,547.616l-3.519-3.207a5.82,5.82,0,0,0-.448-7.709h0a5.816,5.816,0,1,0-.7,8.813l3.6,3.28a.8.8,0,1,0,1.072-1.177Zm-11.3-3.586a4.554,4.554,0,1,1,3.219,1.333A4.558,4.558,0,0,1,2590.529,544.03Z"
                  transform="translate(-2587.939 -535.001)"
                  fill="#07020f"
                />
              </SearchSvg>
              <MobileSearchBar
                isActive={true}
                type="text"
                ref={mobileSearchBarRef}
                placeholder="Wyszukaj..."
                value={searchString}
                onChange={handleSearchStringChange}
              />
            </MobileSearchBarWrapper>

            <FilterSvg
              isActive={false}
              onClick={() => {
                setIsBodyOverflowBlocked(true);
                setIsMobileSearchBarOpen(false);
                handleIsSortMenuOpenChange(false);
                handleIsFilterMenuOpenChange(true);
                handleIsMobileFilterMenuOpenChange(!isMobileFilterMenuOpen);
                if (!isMobileFilterMenuOpen) {
                  setTimeout(() => {
                    mobileFilterMenuWrapperRef.current.style.transition =
                      'none';
                  }, 350);
                }
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="19.2"
              height="18.021"
              viewBox="0 0 19.2 18.021"
            >
              <g
                id="Group_1925"
                data-name="Group 1925"
                transform="translate(-155.861 -459.378)"
                opacity="0.86"
              >
                <path
                  id="Path_1651"
                  data-name="Path 1651"
                  d="M173.319,462.311H163.211a.955.955,0,0,1,0-1.909h10.107a.955.955,0,1,1,0,1.909Z"
                  transform="translate(0.787 0.126)"
                />
                <path
                  id="Path_1652"
                  data-name="Path 1652"
                  d="M173.319,468.486H163.211a.955.955,0,0,1,0-1.909h10.107a.955.955,0,1,1,0,1.909Z"
                  transform="translate(0.787 0.886)"
                />
                <path
                  id="Path_1653"
                  data-name="Path 1653"
                  d="M173.319,474.366H163.211a.955.955,0,0,1,0-1.909h10.107a.955.955,0,1,1,0,1.909Z"
                  transform="translate(0.787 1.609)"
                />
                <circle
                  id="Ellipse_11"
                  data-name="Ellipse 11"
                  cx="2.246"
                  cy="2.246"
                  r="2.246"
                  transform="translate(155.861 472.907)"
                />
                <circle
                  id="Ellipse_12"
                  data-name="Ellipse 12"
                  cx="2.246"
                  cy="2.246"
                  r="2.246"
                  transform="translate(155.861 466.156)"
                />
                <circle
                  id="Ellipse_13"
                  data-name="Ellipse 13"
                  cx="2.246"
                  cy="2.246"
                  r="2.246"
                  transform="translate(155.861 459.378)"
                />
              </g>
            </FilterSvg>
            {countTruthyVariables(
              searchString,
              selectedPrimaryRole,
              selectedSecondaryRole,
              city,
              onlyFollowedProducers,
              onlyProducersWithWinWin,
              onlyProducersWithExclusiveOffers,
            ) > 0 && (
              <ClearFiltersButton onClick={() => clearFilters()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10.9"
                  height="12"
                  viewBox="0 0 10.9 12"
                >
                  <g
                    id="Icon_feather-trash"
                    data-name="Icon feather-trash"
                    transform="translate(0.5 0.5)"
                  >
                    <path
                      id="Path_3663"
                      data-name="Path 3663"
                      d="M4.5,9h9.9"
                      transform="translate(-4.5 -6.8)"
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                    <path
                      id="Path_3664"
                      data-name="Path 3664"
                      d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                      transform="translate(-6.4 -3)"
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                  </g>
                </svg>
              </ClearFiltersButton>
            )}
          </NavButtonsWrapper>
          {isLoading && <LoadingCircle />}
          {producers.map((producer) => {
            return (
              <SingleProducer
                mobileRef={mobileLastElementRef}
                producer={producer}
                roles={roles}
                key={producer.CardId}
              />
            );
          })}
        </MobileMain>
      </MobileMainWrapper>
    </>
  );
};

export default Producers;
