import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import styled from 'styled-components';
import {useCookies} from 'react-cookie';
import _ from 'lodash';
import qs from 'qs';
import {Navigate, useNavigate} from 'react-router-dom';
import {getProductTypePolishName} from 'utils/getProductTypePolishName';
import useInfinityScroller from 'hooks/useInfinityScroller';

import {ProductTypeEnum} from 'constants/conditionEnums';
import searchIcon from 'images/svg/search.svg';
import filterIcon from 'images/svg/filter-icon.svg';
import twoArrowsIcon from 'images/svg/two-arrows.svg';
import listIcon from 'images/svg/list.svg';
import approveIcon from 'images/svg/green-approve.svg';
import EditCondition from './EditCondition';
import arrowLeft from 'images/svg/transparent-left-arrow.svg';
import arrowRight from 'images/svg/transparent-arrow-right.svg';
import {ProducerDataContext} from 'contexts/ProducerDataContext';
import api from 'services/axiosConfig';
import winWinIcon from 'images/svg/new-win-win-icon.svg';
import conditionIcon from 'images/svg/condition-icon.svg';
import {UserDataContext} from 'contexts/UserDataContext';
import {LoadingCircle} from 'utils/loadingCircle';
import useAlert from 'hooks/useAlert';
import PopupButton from 'components/PopUp/PopupButton';
import {ConditionSvg, WinWinSvg} from 'components/SvgComponents';
import {countTruthyVariables} from 'utils/countTruthyVariables';
import {
  MenuMain,
  CloseIsLoginPageOpenWrapper,
  CloseIsLoginPageOpen,
  MenuWrapper,
  MobileSelectFilterWrapper,
  MobileInputFilterWrapper,
  MobileCheckboxFilterWrapper,
  MobileCheckbox,
} from 'pages/Products';
import {useMenuContext} from 'contexts/MenuContext';
import {useFilterMenuContext} from 'contexts/FilterMenuContext';
import {useSearchBarContext} from 'contexts/SearchBarContext';
import {useIsBodyOverflowBlockedContext} from 'contexts/IsBodyOverflowBlockedContext';

import {
  Grid,
  CheckboxWithTitleWrapper,
  CustomBlueCheckbox,
  CountFilterDisplay,
  FilterButtonSvg,
  SortButtonSvg,
  FilterWrapper,
  FilterInputWrapper,
  SingleFilterWrapper,
  FilterMenuWrapper,
  FilterInput,
  SelectWrapper,
  PageHeaderContent,
  PageHeaderWrapper,
  SingleFilterContentWrapper,
} from 'pages/Products/index';

import {
  ConditionsContent,
  ConditionsContentHeader,
  ConditionsHeader,
  ConditionsHeaderButton,
  ConditionsMain,
  ConditionsWrapper,
  Main,
  MainWrapper,
  MobileArea,
  MobileConditionsWrapper,
  MobileRowSpan,
  MobileSliderWrapper,
  DeleteButton,
  ContentWrapper,
  AddButtonWrapper,
  FilterButton,
} from './styles';

export const MobileFilterWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  & > svg {
    & path {
      fill: ${({theme}) => theme.colors.mobileFilterMenuSvgColor};
    }

    & circle {
      fill: ${({theme}) => theme.colors.mobileFilterMenuSvgColor};
    }
  }
`;

export const MobileSearchInputWrapper = styled.div`
  background: ${({theme}) => theme.colors.headerContentBackground};
  width: 258px;
  height: 38.8px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 16.5px;

  & > input {
    width: 196px;
    outline: none;
    border: none;
    background: transparent;
    color: ${({theme}) => theme.colors.mobileFilterMenuFontColor};
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.15px;

    &::placeholder {
      color: ${({theme}) => theme.colors.mobileFilterMenuFontColor};
    }
  }

  & > svg {
    & path {
      fill: ${({theme}) => theme.colors.mobileFilterMenuSvgColor};
    }
  }
`;

export const MobileAddButtonWrapper = styled.div`
  width: 100%;
  height: 104px;
  border-radius: 16px;
  border: 1px solid ${({theme}) => theme.colors.mobileAddNewProductBorderColor};
  display: flex;
  align-items: center;

  & > p {
    color: ${({theme}) => theme.colors.mobileAddNewProductFontColor};
    font-weight: 400;
    font-size: 17px;
    letter-spacing: -0.05px;
  }

  & > div {
    width: 130px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const MobileAddButton = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 25px;
  background: ${({theme}) => theme.colors.blueTheme};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: fixed;
  bottom: 72px;
  right: 14px;
  z-index: 3;

  & > svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    filter: brightness(0.8);
  }
`;

export const DeleteTagButton = styled.div`
  position: absolute;
  top: 10px;
  right: 14px;

  & > button {
    width: 18px;
    border: none;
    height: 18px;
    border-radius: 4px;
    background: #ff7e7d !important;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.15s;

    &:hover {
      filter: brightness(1.1);
    }

    &:active {
      filter: brightness(0.9);
    }
  }
`;

export const MobileMainWrapper = styled.div`
  width: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: center;
  /* background: #f8f8f8; */

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
  }
`;

export const MobileMain = styled.div`
  width: 396px;
  min-height: calc(100vh - 105px);
  margin-bottom: 57px;
  /* min-height: 765px; */
  /* height: calc(100vh - 48px - 57px); */
  display: none;
  padding: 8px 0px;
  flex-direction: column;
  align-items: center;
  background: transparent;
  gap: 9px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: 100%;
    /* padding: unset; */
    padding: 8px;
    gap: 5px;
  }
`;

export const ClearFiltersButton = styled.div`
  width: 19px;
  height: 19px;
  border-radius: 4px;
  background: #ff0300;
  position: absolute;
  right: 344px;
  top: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    right: 5px;
    top: unset;
  }
`;

export const PrimaryButton = styled.button`
  border-radius: 9px;
  border: 1px solid rgba(112, 112, 112, 0.3);
  background: transparent;
  font-size: 11px;
  color: #352f40;
  cursor: pointer;
  transition: all 0.15s;
  width: 119px;
  height: 38px;
  letter-spacing: 0.625px;

  &:hover {
    filter: brightness(1.2);
  }
`;

export const WinWinInfo = styled.div`
  height: 20px;
  width: 82px;
  border: 1px solid #fb9614;
  display: flex;
  gap: 8.7px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;

  & > p {
    color: #fb9614;
    font-size: 9px;
    font-weight: 500;
  }

  & > img {
    width: 12.35px;
    height: 8px;
  }
`;

export const MobileSingleConditionWrapper = styled.div<{
  IsDefault: boolean;
  ref: any;
}>`
  width: 100%;
  height: 116px;
  border-radius: 16px;
  /* border: ${({IsDefault}) => (IsDefault ? '1px solid #707070' : 'none')}; */
  border: 1px solid rgba(112, 112, 112, 0.3);
  /* background: ${({IsDefault}) =>
    IsDefault ? 'rgba(239, 240, 242, 0.3)' : '#F8F8F8'}; */
  background: ${({theme}) => theme.colors.mobileOfferListingBackground};
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  & > svg {
    margin-left: 32px;
    margin-right: 24.5px;

    & path {
      fill: ${({theme}) => theme.colors.fontBlackOp100};
    }
  }

  & > div {
    position: relative;
    height: 100%;
    display: flex;
    width: 284px;
    flex-direction: column;

    & > p {
      font-size: 11px;
      margin-top: 11px;
      color: ${({theme}) => theme.colors.fontBlackOp50};
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.05px;
    }

    & > h5 {
      font-size: 18px;
      color: ${({theme}) => theme.colors.fontBlackOp100};
      width: 252px;
      height: 42px;
      letter-spacing: -0.15px;
      font-weight: bold;
      line-height: 21px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    & > svg {
      position: absolute;
      top: 12.5px;
      right: 13px;
    }

    & > button {
      width: 12px;
      height: 13px;
      background: transparent;
      border: none;
      position: absolute;
      cursor: pointer;
      bottom: 17px;
      right: 18.8px;
    }
  }
`;

export const IconWithDataWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 32.5px;
  padding-left: 30px;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    gap: 32.5px;
  }

  /* @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    gap: 18.5px;

    & > img {
      align-self: flex-start;
      margin-top: 11px;
    }
  } */
`;

export const MobileDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* gap: 5px; */

  & > p {
    font-size: 13px;
    color: rgba(53, 47, 64, 0.5);
    letter-spacing: 0.25px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.05px;
  }

  & > h5 {
    font-size: 18px;
    color: #07020f;
    letter-spacing: -0.15px;
    font-weight: bold;
    line-height: 21px;
    margin-bottom: 6px;
  }

  /* @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    & > h5 {
      font-size: 18px;
      color: rgba(7, 2, 15, 1);
      letter-spacing: 0.1px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 3px;
      min-height: 48px;
    }

    & > svg {
      position: absolute;
      top: 88px;
      right: 21px;
    }
  } */
`;

export const SingleConditionWrapper = styled.div<{
  IsDefault: boolean;
  ref: any;
}>`
  width: 100%;
  min-height: 87px;
  border-radius: 16px;
  position: relative;
  border: ${({IsDefault}) =>
    IsDefault ? ' 2px solid black' : '1px solid rgba(112, 112, 112, 0.3)'};
  background: ${({theme}) => theme.colors.headerContentBackground};
  display: grid;
  align-items: center;
  padding: 9px 10px 8px 27px;
  grid-template-columns: 57.5px 565px 320px;
  transition: all 0.15s;
  cursor: pointer;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.12);

  &:hover {
    border: 3px solid #3986ef;
  }

  &:active {
    border: 3px solid #3986ef;
    filter: brightness(0.95);
  }
`;

export const SingleSection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4px;

  & > h4 {
    color: white;
    font-size: 15px;
    font-weight: 400;
  }

  & > p {
    font-size: 11px;
    color: ${({theme}) => theme.colors.grayFontOp100};
    letter-spacing: 0.1px;
  }

  & > h5 {
    font-size: 13px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    letter-spacing: -0.15px;
    font-weight: 400;
  }

  & > img {
    width: 24.5px;
    height: 28px;
  }

  & > div {
    & > img {
    }
  }

  &:nth-child(2) {
    align-items: unset;
  }

  &:nth-child(3) {
    width: 320px;
  }

  &:first-child {
    align-items: unset;
  }

  /* &:last-child {
    gap: 8px;
    margin-left: 26px;
    justify-content: space-between;
    align-items: flex-end;
  } */
`;

export const DescriptionWrapper = styled.div`
  background: transparent;
  border: 1px solid ${({theme}) => theme.colors.fontBlackOp50};
  padding: 9px 18px;
  width: 320px;
  height: 100%;
  border-radius: 9px;

  & > p {
    font-size: 9px;
    line-height: 13px;
    font-size: 400;
    color: ${({theme}) => theme.colors.grayFontOp100};
  }
`;

export const MobileDescriptionWrapper = styled.div`
  background: transparent;
  border: 1px solid rgba(112, 112, 112, 0.3);
  padding: 9px 18px;
  width: 100%;
  height: 67px;
  border-radius: 9px;

  & > p {
    font-size: 9px;
    line-height: 13px;
    font-size: 400;
    color: #352f40;
  }
`;

export const StatusWithStyledStatus = styled.div`
  display: flex;
  gap: 3px;

  & > p {
    color: #07020f;
    font-size: 9px;
    font-weight: 400;
    letter-spacing: 0.25px;
  }
`;

export const StyledStatusWrapper = styled.h2<{AssignedProductsCount?: number}>`
  width: 220px;
  display: flex;
  flex-direction: column;
  gap: 7px;

  & > h1 {
    letter-spacing: 0.1px;
    font-size: 11px;
    font-weight: 500;
    color: ${({AssignedProductsCount}) =>
      AssignedProductsCount > 0 ? '#0091ff' : '#00d10f'};
  }
`;

export const StyledStatusLine = styled.div<{AssignedProductsCount?: number}>`
  width: 220px;
  border-bottom: 3px solid ${({theme}) => theme.colors.statusDefaultLine};
  position: relative;
  border-radius: 25px;

  & > div {
    position: absolute;
    left: 0px;
    width: 220px;
    border-radius: 25px;
    height: 3px;
    background: ${({AssignedProductsCount}) =>
      AssignedProductsCount > 0 ? '#0091ff' : '#00d10f'};
  }
`;

export const StyledStatus = styled.h2<{AssignedProductsCount: number}>`
  font-size: 11px;
  font-weight: 500;
  width: 112px;
  height: 24px;
  letter-spacing: -0.15px;
  inline-size: 112px;
  margin-left: 36px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: black;
  background: ${({AssignedProductsCount}) =>
    AssignedProductsCount > 0 ? '#0091ff' : '#ff0300'};
  overflow-wrap: break-word;
  text-align: center;

  /* @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    position: absolute;
    left: 21px;
    top: 81px;
  } */
`;

export const PageHeaderHeaderWrapper = styled.div`
  width: 100%;
  height: 55px;
  border-radius: 24px 24px 0 0;
  background: ${({theme}) => theme.colors.pageHeader};
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
`;

export const PageHeaderHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 9px;

  & > p {
    color: ${({theme}) => theme.colors.headerBlackFont};
    font-size: 15px;
    letter-spacing: 0.625px;
  }
`;

export const FilterButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ArrowWrapper = styled.div<{isActive?: boolean}>`
  width: 46px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.15s;
  border-radius: 7px;
  background: ${({isActive}) =>
    isActive ? 'rgba(137, 190, 255, 0.3)' : 'transparent'};

  & > svg {
    & path {
      fill: ${({theme}) => theme.colors.grayFontOp100};
    }
  }

  &:hover {
    background: rgba(137, 190, 255, 0.3);
  }
`;

export const AddButton = styled.button`
  width: 120px;
  height: 100%;
  position: absolute;
  right: 0px;
  border: none;
  border-left: 1px solid white;
  background: transparent;
  border-radius: 0 24px 0 0;
  cursor: pointer;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.625px;
  color: ${({theme}) => theme.colors.grayFontOp100};
  transition: all 0.15s;

  &:hover {
    background: rgba(248, 248, 248, 0.15);
  }
`;

interface FieldOfUse {
  fieldId: string;
  name: string;
  canPriceBeNegotiated: boolean;
  grossValue: number;
  inIncomeParticipation: number;
  end: number | string;
  type: number;
}

interface Condition {
  AllowWinWinSettlement: boolean;
  AnotherLimits: string;
  CanPriceBeNegotiated: true;
  Currency: number;
  Description: string;
  FieldsOfUse: FieldOfUse[];
  GrossValue: number;
  Id: string;
  Invoice: true;
  LimitType: number;
  LimitValue: number;
  Name: string;
  PaymentDeadline: number;
  PaymentDeadlineValue: number;
  Producer: null;
  ProducerId: string;
  ProductType: number;
  RightsEnd: number;
  Scope: number;
  TargetGroup: number;
  Tax: number;
  Type: number;
  IsDefault: boolean;
  AssignedProductsCount: number;
  WinWinDigital: number;
  WinWinPhysical: number;
  WinWinYoutube: number;
}

enum SortBy {
  DATE_ASCENDING = 'DATE_ASCENDING',
  DATE_DESCENDING = 'DATE_DESCENDING',
  NAME_ASCENDING = 'NAME_ASCENDING',
  NAME_DESCENDING = 'NAME_DESCENDING',
}

export interface ConditionOption {
  FieldName: string;
  FlagValue: number;
}

const ConditionsComponent: React.FC = () => {
  const [cookies, setCookie] = useCookies(['token']);

  const {decodedToken} = useContext(ProducerDataContext);

  if (!decodedToken) {
    return <Navigate to="/identity/login" />;
  }
  if (decodedToken.ProducerId === '00000000-0000-0000-0000-000000000000') {
    return <Navigate to="/producer" />;
  }

  const [userData, setUserData] = useContext(UserDataContext);

  if (userData.FirstName === null) {
    window.location.href = '/account/settings';
  }
  const [productType, setProductType] = useState<number | string>(
    sessionStorage.getItem('conditionFilterProductType')
      ? +sessionStorage.getItem('conditionFilterProductType')
      : '',
  );
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [hasMoreProducers, setHasMoreProducers] = useState(true);
  const [isWinwin, setIsWinwin] = useState<boolean>(
    sessionStorage.getItem('conditionFilterIsWinwin') === 'yes' ? true : false,
  );
  const [hasAssignedProductsFilter, setHasAssignedProductsFilter] =
    useState<boolean>(
      sessionStorage.getItem('conditionFilterHasAssignedProductsFilter') ===
        'yes'
        ? true
        : false,
    );
  const [searchString, setSearchString] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEditing, setEditing] = useState<boolean>(false);
  const [isDeleting, setDeleting] = useState<boolean>(false);
  const [conditionsList, setConditionsList] = useState<Condition[]>([]);
  const [conditionId, setConditionId] = useState<string | undefined>(undefined);
  const [selectedCondition, setSelectedCondition] = useState<number>(0);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState<boolean>(false);
  const [isSortMenuOpen, setIsSortMenuOpen] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<SortBy | string>(SortBy.NAME_ASCENDING);
  // const [productTypes, setProductTypes] = useState<ConditionOption[]>([]);
  const [productTypeFilter, setProductTypeFilter] = useState<number | string>(
    '',
  );

  const lastElementRef = useInfinityScroller(
    isLoading,
    hasMoreProducers,
    setPageNumber,
  );

  const mobileLastElementRef = useInfinityScroller(
    isLoading,
    hasMoreProducers,
    setPageNumber,
  );

  const {isMobileSearchBarOpen, setIsMobileSearchBarOpen} =
    useSearchBarContext();
  const {setIsBodyOverflowBlocked} = useIsBodyOverflowBlockedContext();
  const scrollRef = useRef<HTMLDivElement>(null);
  const mobileFilterMenuWrapperRef = useRef<HTMLDivElement | null>(null);
  const {isMobileFilterMenuOpen, setIsMobileFilterMenuOpen} =
    useFilterMenuContext();
  const navigate = useNavigate();
  const {Alert} = useAlert();

  const handleIsMobileFilterMenuOpenChange = (value: boolean) =>
    setIsMobileFilterMenuOpen(value);

  const handleIsDeletingChange = (value: boolean) => setDeleting(value);

  const handleSearchStringChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchString(e.target.value);

  const handleProductTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === '') {
      setProductType('');
      sessionStorage.setItem('conditionFilterProductType', e.target.value);
    } else {
      setProductType(+e.target.value);
      sessionStorage.setItem('conditionFilterProductType', e.target.value);
    }
  };

  const handleIsWinwinChange = (value: boolean) => {
    setIsWinwin(value);
    if (value === true) {
      sessionStorage.setItem('conditionFilterIsWinwin', 'yes');
    } else {
      sessionStorage.setItem('conditionFilterIsWinwin', 'no');
    }
  };

  const handleHasAssignedProductsFilterChange = (value: boolean) => {
    setHasAssignedProductsFilter(value);
    if (value === true) {
      sessionStorage.setItem('conditionFilterHasAssignedProductsFilter', 'yes');
    } else {
      sessionStorage.setItem('conditionFilterHasAssignedProductsFilter', 'no');
    }
  };

  // const getProductTypes = async () => {
  //   try {
  //     const response = await api.get(`products/productTypeEnum`);
  //     setProductTypes(response.data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const clearFilters = () => {
    setProductType('');
    sessionStorage.removeItem('conditionFilterProductType');
    setIsWinwin(false);
    sessionStorage.removeItem('conditionFilterIsWinwin');
    setHasAssignedProductsFilter(false);
    sessionStorage.removeItem('conditionFilterHasAssignedProductsFilter');
    setSearchString('');
  };

  const getConditionsList = async (
    searchString: string,
    hasAssignedProductsFilter: boolean,
    sortBy: SortBy | string,
    isWinwin: boolean,
    productType: string | number,
    pageNumber: number,
  ) => {
    try {
      setIsLoading(true);
      const response = await api.get(`Condition/getAll`, {
        headers: {Authorization: `bearer ${cookies.token}`},
        params: {
          pageNumber: pageNumber,
          searchString,
          pageSize: 20,
          productType: productType ? productType : undefined,
          allowWinWinFilter: isWinwin ? 1 : 0,
          SortBy:
            sortBy === SortBy.NAME_ASCENDING ||
            sortBy === SortBy.NAME_DESCENDING
              ? 1
              : undefined,
          SortType:
            sortBy === SortBy.DATE_ASCENDING || sortBy === SortBy.NAME_ASCENDING
              ? 0
              : 1,
          hasAssignedProductsFilter: hasAssignedProductsFilter ? 1 : 0,
        },
      });
      setHasMoreProducers(response.data.length > 0);
      setConditionsList((prev) => [...prev, ...response.data]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getConditionsListDebounce = useCallback(
    _.debounce(
      (
        searchString,
        hasAssignedProductsFilter,
        sortBy,
        isWinwin,
        productType,
        pageNumber,
      ) =>
        getConditionsList(
          searchString,
          hasAssignedProductsFilter,
          sortBy,
          isWinwin,
          productType,
          pageNumber,
        ),
      500,
    ),
    [],
  );

  const handleProductTypeFilterChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => setProductTypeFilter(e.target.value);

  const handleIsFilterMenuOpenChange = (value: boolean) =>
    setIsFilterMenuOpen(value);

  const handleIsSortMenuOpenChange = (value: boolean) =>
    setIsSortMenuOpen(value);

  const handleSortByChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setSortBy(e.target.value);

  const deleteCondition = async (e: React.MouseEvent, id: string) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const response = await api.delete(`Condition/Delete/${id}`, {
        headers: {Authorization: `Bearer ${cookies.token}`},
      });
      getConditionsList(
        searchString,
        hasAssignedProductsFilter,
        sortBy,
        isWinwin,
        productType,
        pageNumber,
      );
      Alert('Warunek został usunięty.', 'Komunikat');
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getConditionsListDebounce(
      searchString,
      hasAssignedProductsFilter,
      sortBy,
      isWinwin,
      productType,
      pageNumber,
    );
  }, [
    searchString,
    hasAssignedProductsFilter,
    sortBy,
    isWinwin,
    productType,
    pageNumber,
  ]);

  useEffect(() => {
    setPageNumber(1);
    setConditionsList([]);
  }, [searchString, hasAssignedProductsFilter, sortBy, isWinwin, productType]);

  const nextCondition = () => {
    if (selectedCondition === conditionsList.length - 1) {
      return setSelectedCondition(0);
    }
    return setSelectedCondition((prev) => prev + 1);
  };

  const prevCondition = () => {
    if (selectedCondition === 0) {
      return setSelectedCondition(conditionsList.length - 1);
    }
    return setSelectedCondition((prev) => prev - 1);
  };

  useEffect(() => {
    document.title = `MUGO Studio - Warunki`;
    document
      .querySelector("meta[property='og:title']")
      ?.setAttribute('content', `MUGO Studio - Warunki`);
    document
      .querySelector("meta[property='og:description']")
      ?.setAttribute(
        'content',
        'MUGO Studio to przestrzeń dla twórców, freelancerów, artystów i ludzi poszukujących usług oraz produktów oferowanych przez te osoby. To platforma stworzona dla usługodawców i usługobiorców, gwarantująca bezpieczeństwo transakcji oraz stwarzająca możliwość współpracy na innowacyjnych warunkach.',
      );
  }, []);

  return (
    <>
      <MainWrapper>
        <Main>
          {isLoading && <LoadingCircle />}
          <FilterWrapper>
            <FilterInputWrapper>
              {/* <img src={searchIcon} alt="search" /> */}
              <svg
                id="Group_4104"
                data-name="Group 4104"
                xmlns="http://www.w3.org/2000/svg"
                width="14.146"
                height="14"
                viewBox="0 0 14.146 14"
              >
                <path
                  id="Path_1507"
                  data-name="Path 1507"
                  d="M2601.826,547.617l-3.519-3.207a5.82,5.82,0,0,0-.448-7.709h0a5.816,5.816,0,1,0-.7,8.813l3.6,3.28a.8.8,0,1,0,1.072-1.177Zm-11.3-3.586a4.554,4.554,0,1,1,3.219,1.333A4.559,4.559,0,0,1,2590.529,544.03Z"
                  transform="translate(-2587.94 -535.001)"
                  fill="#0091ff"
                />
              </svg>

              <input
                type="text"
                placeholder="Wyszukaj..."
                value={searchString}
                onChange={handleSearchStringChange}
              />
              {/* <FilterButton
              isFilterMenuOpen={isFilterMenuOpen}
              onClick={() => {
                handleIsFilterMenuOpenChange(!isFilterMenuOpen);
              }}
            >
              <img src={filterIcon} />
            </FilterButton> */}
            </FilterInputWrapper>
          </FilterWrapper>
          {/* {isSortMenuOpen && (
          <FilterMenuWrapper>
            <SelectWrapper>
              <select onChange={handleSortByChange} value={sortBy}>
                <option hidden value={''}>
                  Sortowanie
                </option>
                <option value={SortBy.DATE_ASCENDING}>
                  Date - From oldest
                </option> */}
          {/* <option value={SortBy.DATE_DESCENDING}>
                  Date - From newest
                </option> */}
          {/* <option value={SortBy.NAME_ASCENDING}>Name A-Z</option>
                <option value={SortBy.NAME_DESCENDING}>Name Z-A</option>
              </select>
            </SelectWrapper>
          </FilterMenuWrapper>
        )} */}
          {/* {isFilterMenuOpen && (
          <FilterMenuWrapper>
            <SelectWrapper>
              <select
                value={productTypeFilter}
                onChange={handleProductTypeFilterChange}
              >
                <option hidden value={''}>
                  Typ produktu
                </option>
                <option value={''}>Wszystkie</option>
                {productTypes.map((productType) => {
                  const {FieldName, FlagValue} = productType;

                  return (
                    <option value={FlagValue} key={FlagValue}>
                      {FieldName}
                    </option>
                  );
                })}
              </select>
            </SelectWrapper>
            <SelectWrapper>
              <select>
                <option hidden value={''}>
                  Ograniczenia
                </option>
                <option value={''}>Wszystkie</option>
                <option value={'true'}>Kanały</option>
                <option value={'false'}>Dostępny</option>
              </select>
            </SelectWrapper>
            <SelectWrapper>
              <select>
                <option hidden value={''}>
                  Przychody
                </option>
                <option value={''}>Wszystko</option>
                <option value={'true'}>Z potencjalnych przychodów</option>
                <option value={'false'}>Cykliczne</option>
              </select>
            </SelectWrapper>
            <SelectWrapper>
              <select
                value={hasAssignedProductsFilter}
                onChange={handleHasAssignedProductsFilterChange}
              >
                <option hidden value={''}>
                  Aktywne produkty
                </option>
                <option value={''}>Wszystkie</option>
                <option value={'active'}>Aktywne</option>
                <option value={'inactive'}>Nieaktywne</option>
              </select>
            </SelectWrapper>
          </FilterMenuWrapper>
        )} */}
          <PageHeaderWrapper
            onMouseLeave={() => {
              handleIsFilterMenuOpenChange(false);
              handleIsSortMenuOpenChange(false);
            }}
          >
            <PageHeaderHeaderWrapper>
              <PageHeaderHeader>
                <p>Warunki</p>
              </PageHeaderHeader>
              <AddButton onClick={() => navigate('/conditions/add')}>
                Dodaj
              </AddButton>
            </PageHeaderHeaderWrapper>
            <PageHeaderContent>
              <FilterWrapper>
                <SortButtonSvg
                  value={isSortMenuOpen}
                  onClick={() => {
                    handleIsSortMenuOpenChange(!isSortMenuOpen);
                    handleIsFilterMenuOpenChange(false);
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="18.824"
                  height="13.204"
                  viewBox="0 0 18.824 13.204"
                >
                  <g
                    id="Group_1311"
                    data-name="Group 1311"
                    transform="translate(-2536.541 -534.989)"
                  >
                    <path
                      id="Path_1508"
                      data-name="Path 1508"
                      d="M2543.457,537.33l-2.582-2.1a1.076,1.076,0,0,0-1.357,0l-2.581,2.1a1.075,1.075,0,0,0,1.357,1.668l.9-.732v6.852a1.075,1.075,0,1,0,2.15,0v-6.735l.756.615a1.075,1.075,0,1,0,1.357-1.668Z"
                      fill="#797c93"
                    />
                    <path
                      id="Path_1509"
                      data-name="Path 1509"
                      d="M2548.448,545.852l2.582,2.1a1.074,1.074,0,0,0,1.357,0l2.581-2.1a1.075,1.075,0,0,0-1.357-1.668l-.9.732v-6.852a1.075,1.075,0,0,0-2.151,0V544.8l-.756-.615a1.075,1.075,0,0,0-1.357,1.668Z"
                      fill="#797c93"
                    />
                  </g>
                </SortButtonSvg>
                <FilterInputWrapper>
                  {/* <img src={searchIcon} alt="search" /> */}
                  <svg
                    id="Group_4104"
                    data-name="Group 4104"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14.146"
                    height="14"
                    viewBox="0 0 14.146 14"
                  >
                    <path
                      id="Path_1507"
                      data-name="Path 1507"
                      d="M2601.826,547.617l-3.519-3.207a5.82,5.82,0,0,0-.448-7.709h0a5.816,5.816,0,1,0-.7,8.813l3.6,3.28a.8.8,0,1,0,1.072-1.177Zm-11.3-3.586a4.554,4.554,0,1,1,3.219,1.333A4.559,4.559,0,0,1,2590.529,544.03Z"
                      transform="translate(-2587.94 -535.001)"
                      fill="#0091ff"
                    />
                  </svg>
                  <input
                    type="text"
                    placeholder="Wyszukaj..."
                    value={searchString}
                    onChange={handleSearchStringChange}
                  />
                </FilterInputWrapper>
                {countTruthyVariables(
                  searchString,
                  productType,
                  isWinwin,
                  hasAssignedProductsFilter,
                ) > 0 && (
                  <CountFilterDisplay>
                    {countTruthyVariables(
                      searchString,
                      productType,
                      isWinwin,
                      hasAssignedProductsFilter,
                    )}
                  </CountFilterDisplay>
                )}
                <FilterButtonSvg
                  value={isFilterMenuOpen}
                  onClick={() => {
                    handleIsSortMenuOpenChange(false);
                    handleIsFilterMenuOpenChange(!isFilterMenuOpen);
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.87"
                  height="15.047"
                  viewBox="0 0 20.87 15.047"
                >
                  <g
                    id="Group_1312"
                    data-name="Group 1312"
                    transform="translate(-2473.175 -536.818)"
                  >
                    <path
                      id="Path_1510"
                      data-name="Path 1510"
                      d="M2480.2,539.042h13a.85.85,0,0,0,0-1.7h-13a.85.85,0,0,0,0,1.7Z"
                      fill="#797c93"
                    />
                    <path
                      id="Path_1511"
                      data-name="Path 1511"
                      d="M2493.2,543.517h-13a.85.85,0,0,0,0,1.7h13a.85.85,0,0,0,0-1.7Z"
                      fill="#797c93"
                    />
                    <path
                      id="Path_1512"
                      data-name="Path 1512"
                      d="M2493.2,549.4h-13a.85.85,0,0,0,0,1.7h13a.85.85,0,0,0,0-1.7Z"
                      fill="#797c93"
                    />
                    <circle
                      id="Ellipse_1"
                      data-name="Ellipse 1"
                      cx="1.5"
                      cy="1.5"
                      r="1.5"
                      transform="translate(2473.175 548.864)"
                      fill="#797c93"
                    />
                    <circle
                      id="Ellipse_2"
                      data-name="Ellipse 2"
                      cx="1.5"
                      cy="1.5"
                      r="1.5"
                      transform="translate(2473.175 542.853)"
                      fill="#797c93"
                    />
                    <circle
                      id="Ellipse_3"
                      data-name="Ellipse 3"
                      cx="1.5"
                      cy="1.5"
                      r="1.5"
                      transform="translate(2473.175 536.818)"
                      fill="#797c93"
                    />
                  </g>
                </FilterButtonSvg>
                {countTruthyVariables(
                  searchString,
                  productType,
                  isWinwin,
                  hasAssignedProductsFilter,
                ) > 0 && (
                  <ClearFiltersButton onClick={() => clearFilters()}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10.9"
                      height="12"
                      viewBox="0 0 10.9 12"
                    >
                      <g
                        id="Icon_feather-trash"
                        data-name="Icon feather-trash"
                        transform="translate(0.5 0.5)"
                      >
                        <path
                          id="Path_3663"
                          data-name="Path 3663"
                          d="M4.5,9h9.9"
                          transform="translate(-4.5 -6.8)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                        <path
                          id="Path_3664"
                          data-name="Path 3664"
                          d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                          transform="translate(-6.4 -3)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                      </g>
                    </svg>
                  </ClearFiltersButton>
                )}
              </FilterWrapper>
              {isSortMenuOpen && (
                <FilterMenuWrapper>
                  <SingleFilterWrapper>
                    <SingleFilterContentWrapper>
                      <SelectWrapper>
                        <p>Kolejność</p>
                        <select onChange={handleSortByChange} value={sortBy}>
                          <option hidden value={''}>
                            Wybierz
                          </option>
                          <option value={SortBy.DATE_ASCENDING}>
                            Od najstarszego
                          </option>
                          <option value={SortBy.DATE_DESCENDING}>
                            Od najnowszego
                          </option>
                          <option value={SortBy.NAME_ASCENDING}>
                            Nazwa A-Z
                          </option>
                          <option value={SortBy.NAME_DESCENDING}>
                            Nazwa Z-A
                          </option>
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#0091ff"
                            />
                          </g>
                        </svg>
                      </SelectWrapper>
                    </SingleFilterContentWrapper>
                  </SingleFilterWrapper>
                </FilterMenuWrapper>
              )}
              {isFilterMenuOpen && (
                <FilterMenuWrapper>
                  <SingleFilterWrapper>
                    <SingleFilterContentWrapper>
                      <SelectWrapper>
                        <p>Typ produktu</p>
                        <select
                          value={productType}
                          onChange={handleProductTypeChange}
                        >
                          <option hidden value={''}>
                            Wybierz
                          </option>
                          <option value={''}>Wszystko</option>
                          {ProductTypeEnum.map((productType) => {
                            const {FieldName, FlagValue} = productType;

                            return (
                              <option value={FlagValue} key={FlagValue}>
                                {getProductTypePolishName(FlagValue)}
                              </option>
                            );
                          })}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#0091ff"
                            />
                          </g>
                        </svg>
                      </SelectWrapper>
                    </SingleFilterContentWrapper>
                  </SingleFilterWrapper>

                  <SingleFilterWrapper>
                    <SingleFilterContentWrapper>
                      <SelectWrapper>
                        <p>Status</p>
                        <select>
                          <option hidden value={''}>
                            Wybierz
                          </option>
                          <option value={''}>Wszystko</option>
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#0091ff"
                            />
                          </g>
                        </svg>
                      </SelectWrapper>
                    </SingleFilterContentWrapper>
                  </SingleFilterWrapper>

                  <SingleFilterWrapper>
                    <SingleFilterContentWrapper>
                      <CheckboxWithTitleWrapper>
                        <p>Tylko Win-Win</p>
                        <CustomBlueCheckbox
                          isActive={isWinwin}
                          onClick={() => handleIsWinwinChange(!isWinwin)}
                        >
                          <div />
                        </CustomBlueCheckbox>
                      </CheckboxWithTitleWrapper>
                      <CheckboxWithTitleWrapper>
                        <p>Tylko z aktywnymi ofertami</p>
                        <CustomBlueCheckbox
                          isActive={hasAssignedProductsFilter}
                          onClick={() =>
                            handleHasAssignedProductsFilterChange(
                              !hasAssignedProductsFilter,
                            )
                          }
                        >
                          <div />
                        </CustomBlueCheckbox>
                      </CheckboxWithTitleWrapper>
                    </SingleFilterContentWrapper>
                  </SingleFilterWrapper>
                </FilterMenuWrapper>
              )}
            </PageHeaderContent>
          </PageHeaderWrapper>
          <Grid style={{paddingTop: '32px'}}>
            {conditionsList &&
              conditionsList
                .sort((a, b) => {
                  if (a.IsDefault && !b.IsDefault) {
                    return -1;
                  } else if (!a.IsDefault && b.IsDefault) {
                    return 1;
                  } else {
                    return 0;
                  }
                })
                .map((condition) => {
                  const {
                    Name,
                    Id,
                    AssignedProductsCount,
                    Description,
                    IsDefault,
                    ProductType,
                    WinWinDigital,
                    WinWinPhysical,
                    WinWinYoutube,
                  } = condition;

                  return (
                    <a
                      href={`/conditions/condition/${Id}`}
                      style={{textDecoration: 'none'}}
                      key={Id}
                    >
                      <SingleConditionWrapper
                        ref={lastElementRef}
                        IsDefault={IsDefault}
                        // onClick={() => navigate(`/conditions/condition/${Id}`)}
                      >
                        <SingleSection>
                          <img src={conditionIcon} />
                        </SingleSection>
                        <SingleSection>
                          <p>{getProductTypePolishName(ProductType)}</p>
                          <h5>{Name}</h5>
                          {WinWinDigital || WinWinPhysical || WinWinYoutube ? (
                            <div>
                              <img src={winWinIcon} alt="winwin" />
                            </div>
                          ) : (
                            <div
                            // style={{height: "28px"}}
                            />
                          )}
                        </SingleSection>
                        <SingleSection>
                          <StyledStatusWrapper
                            AssignedProductsCount={AssignedProductsCount}
                          >
                            <h1>
                              {/* (${AssignedProductsCount}) */}
                              {AssignedProductsCount === 0
                                ? 'Dostępny'
                                : `W użyciu`}
                            </h1>
                            <StyledStatusLine
                              AssignedProductsCount={AssignedProductsCount}
                            >
                              <div />
                            </StyledStatusLine>
                          </StyledStatusWrapper>
                          {/* <StyledStatus
                        AssignedProductsCount={AssignedProductsCount}
                      >
                        {AssignedProductsCount === 0
                          ? 'Nieaktywny'
                          : `Aktywny (${AssignedProductsCount})`}
                      </StyledStatus> */}
                        </SingleSection>
                        {!IsDefault && AssignedProductsCount === 0 && (
                          <DeleteTagButton>
                            <PopupButton
                              buttons={['Anuluj', 'Usuń']}
                              title={'Uwaga'}
                              text={` Czy na pewno chcesz usunąć warunek ${Name}?
                     `}
                              onClick={(e) => deleteCondition(e, Id)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10.9"
                                height="12"
                                viewBox="0 0 10.9 12"
                              >
                                <g
                                  id="Icon_feather-trash"
                                  data-name="Icon feather-trash"
                                  transform="translate(0.5 0.5)"
                                >
                                  <path
                                    id="Path_3663"
                                    data-name="Path 3663"
                                    d="M4.5,9h9.9"
                                    transform="translate(-4.5 -6.8)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1"
                                  />
                                  <path
                                    id="Path_3664"
                                    data-name="Path 3664"
                                    d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                                    transform="translate(-6.4 -3)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1"
                                  />
                                </g>
                              </svg>
                            </PopupButton>
                          </DeleteTagButton>
                        )}
                      </SingleConditionWrapper>
                    </a>
                  );
                })}
          </Grid>
        </Main>
      </MainWrapper>
      <MobileMainWrapper>
        <MenuMain
          isMenuOpen={isMobileFilterMenuOpen}
          ref={mobileFilterMenuWrapperRef}
        >
          <CloseIsLoginPageOpenWrapper>
            Filtrowanie
            <CloseIsLoginPageOpen
              onClick={() => {
                handleIsMobileFilterMenuOpenChange(false);
                setIsBodyOverflowBlocked(false);
                handleIsSortMenuOpenChange(false);
                handleIsFilterMenuOpenChange(false);
                mobileFilterMenuWrapperRef.current.style.transition =
                  'all 0.35s ease-out';
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16.625"
                height="16.625"
                viewBox="0 0 16.625 16.625"
              >
                <path
                  id="Path_3807"
                  data-name="Path 3807"
                  d="M27.313,24.987l-2.326,2.326L19,21.326l-5.987,5.987-2.326-2.326L16.674,19l-5.987-5.987,2.326-2.326L19,16.674l5.987-5.987,2.326,2.326L21.326,19Z"
                  transform="translate(-10.688 -10.688)"
                  fill="#07020f"
                />
              </svg>
            </CloseIsLoginPageOpen>
          </CloseIsLoginPageOpenWrapper>
          <MenuWrapper>
            {isSortMenuOpen && (
              <>
                <MobileSelectFilterWrapper value={sortBy}>
                  <select onChange={handleSortByChange} value={sortBy}>
                    <option hidden value={''}>
                      Wybierz
                    </option>
                    <option value={SortBy.DATE_ASCENDING}>
                      Od najstarszego
                    </option>
                    <option value={SortBy.DATE_DESCENDING}>
                      Od najnowszego
                    </option>
                    <option value={SortBy.NAME_ASCENDING}>Nazwa A-Z</option>
                    <option value={SortBy.NAME_DESCENDING}>Nazwa Z-A</option>
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.609"
                    height="13"
                    viewBox="0 0 7.609 13"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(-21.391 -14) rotate(180)"
                    >
                      <path
                        id="Arrow"
                        d="M6.048,12.735.185,6.941a.618.618,0,0,1,0-.882L6.048.265a.923.923,0,0,1,1.294,0,.9.9,0,0,1,0,1.278L2.327,6.5l5.015,4.956a.9.9,0,0,1,0,1.279.923.923,0,0,1-1.294,0"
                        transform="translate(-29 -27)"
                        fill="#3986ef"
                      />
                    </g>
                  </svg>
                </MobileSelectFilterWrapper>
              </>
            )}
            {isFilterMenuOpen && (
              <>
                <MobileSelectFilterWrapper value={productType}>
                  <select
                    value={productType}
                    onChange={handleProductTypeChange}
                  >
                    <option hidden value={''}>
                      Typ produktu
                    </option>
                    <option value={''}>Wszystko</option>
                    {ProductTypeEnum.map((productType) => {
                      const {FieldName, FlagValue} = productType;

                      return (
                        <option value={FlagValue} key={FlagValue}>
                          {getProductTypePolishName(FlagValue)}
                        </option>
                      );
                    })}
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.609"
                    height="13"
                    viewBox="0 0 7.609 13"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(-21.391 -14) rotate(180)"
                    >
                      <path
                        id="Arrow"
                        d="M6.048,12.735.185,6.941a.618.618,0,0,1,0-.882L6.048.265a.923.923,0,0,1,1.294,0,.9.9,0,0,1,0,1.278L2.327,6.5l5.015,4.956a.9.9,0,0,1,0,1.279.923.923,0,0,1-1.294,0"
                        transform="translate(-29 -27)"
                        fill="#3986ef"
                      />
                    </g>
                  </svg>
                </MobileSelectFilterWrapper>
                <MobileSelectFilterWrapper value={false}>
                  <select>
                    <option hidden value={''}>
                      Status
                    </option>
                    <option value={''}>Wszystko</option>
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.609"
                    height="13"
                    viewBox="0 0 7.609 13"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(-21.391 -14) rotate(180)"
                    >
                      <path
                        id="Arrow"
                        d="M6.048,12.735.185,6.941a.618.618,0,0,1,0-.882L6.048.265a.923.923,0,0,1,1.294,0,.9.9,0,0,1,0,1.278L2.327,6.5l5.015,4.956a.9.9,0,0,1,0,1.279.923.923,0,0,1-1.294,0"
                        transform="translate(-29 -27)"
                        fill="#3986ef"
                      />
                    </g>
                  </svg>
                </MobileSelectFilterWrapper>
                <MobileCheckboxFilterWrapper>
                  <p>Tylko Win-Win</p>
                  <MobileCheckbox
                    isActive={isWinwin}
                    onClick={() => handleIsWinwinChange(!isWinwin)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22.167"
                      height="22.167"
                      viewBox="0 0 22.167 22.167"
                    >
                      <path
                        id="Icon_awesome-check-circle"
                        data-name="Icon awesome-check-circle"
                        d="M22.729,11.646A11.083,11.083,0,1,1,11.646.563,11.083,11.083,0,0,1,22.729,11.646ZM10.364,17.514l8.223-8.223a.715.715,0,0,0,0-1.011L17.576,7.269a.715.715,0,0,0-1.011,0L9.858,13.975,6.727,10.844a.715.715,0,0,0-1.011,0L4.7,11.855a.715.715,0,0,0,0,1.011l4.648,4.648a.715.715,0,0,0,1.011,0Z"
                        transform="translate(-0.563 -0.563)"
                        fill="#06f"
                      />
                    </svg>
                  </MobileCheckbox>
                </MobileCheckboxFilterWrapper>
                <MobileCheckboxFilterWrapper>
                  <p>Tylko z aktywnymi ofertami</p>
                  <MobileCheckbox
                    isActive={hasAssignedProductsFilter}
                    onClick={() =>
                      handleHasAssignedProductsFilterChange(
                        !hasAssignedProductsFilter,
                      )
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22.167"
                      height="22.167"
                      viewBox="0 0 22.167 22.167"
                    >
                      <path
                        id="Icon_awesome-check-circle"
                        data-name="Icon awesome-check-circle"
                        d="M22.729,11.646A11.083,11.083,0,1,1,11.646.563,11.083,11.083,0,0,1,22.729,11.646ZM10.364,17.514l8.223-8.223a.715.715,0,0,0,0-1.011L17.576,7.269a.715.715,0,0,0-1.011,0L9.858,13.975,6.727,10.844a.715.715,0,0,0-1.011,0L4.7,11.855a.715.715,0,0,0,0,1.011l4.648,4.648a.715.715,0,0,0,1.011,0Z"
                        transform="translate(-0.563 -0.563)"
                        fill="#06f"
                      />
                    </svg>
                  </MobileCheckbox>
                </MobileCheckboxFilterWrapper>
              </>
            )}
          </MenuWrapper>
        </MenuMain>
        <MobileMain>
          {isLoading && <LoadingCircle />}
          <MobileFilterWrapper>
            {countTruthyVariables(
              searchString,
              productType,
              isWinwin,
              hasAssignedProductsFilter,
            ) > 0 && (
              <CountFilterDisplay style={{right: '18px', top: '10px'}}>
                {countTruthyVariables(
                  searchString,
                  productType,
                  isWinwin,
                  hasAssignedProductsFilter,
                )}
              </CountFilterDisplay>
            )}
            <svg
              onClick={() => {
                setIsBodyOverflowBlocked(true);
                setIsMobileSearchBarOpen(false);
                handleIsSortMenuOpenChange(true);
                handleIsFilterMenuOpenChange(false);
                handleIsMobileFilterMenuOpenChange(!isMobileFilterMenuOpen);
                if (!isMobileFilterMenuOpen) {
                  setTimeout(() => {
                    mobileFilterMenuWrapperRef.current.style.transition =
                      'none';
                  }, 350);
                }
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="23.197"
              height="17.036"
              viewBox="0 0 23.197 17.036"
            >
              <g
                id="Group_1924"
                data-name="Group 1924"
                transform="translate(-255.98 -356.674)"
                opacity="0.86"
              >
                <g
                  id="Group_1923"
                  data-name="Group 1923"
                  transform="translate(255.98 356.674)"
                >
                  <path
                    id="Path_1649"
                    data-name="Path 1649"
                    d="M258.494,352.337a1.071,1.071,0,0,0-1.437,0l-3.981,3.585a1.076,1.076,0,1,0,1.439,1.6l2.185-1.968v11.931a1.075,1.075,0,0,0,2.151,0V355.553l2.185,1.968a1.076,1.076,0,1,0,1.439-1.6Z"
                    transform="translate(-252.719 -352.06)"
                  />
                  <path
                    id="Path_1650"
                    data-name="Path 1650"
                    d="M269.854,363.478a1.073,1.073,0,0,0-1.518-.079l-2.187,1.97V353.436a1.075,1.075,0,1,0-2.149,0v11.931l-2.185-1.968a1.076,1.076,0,1,0-1.439,1.6l3.981,3.585a1.076,1.076,0,0,0,1.439,0L269.775,365A1.075,1.075,0,0,0,269.854,363.478Z"
                    transform="translate(-246.934 -351.822)"
                  />
                </g>
              </g>
            </svg>
            <MobileSearchInputWrapper>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15.157"
                height="15"
                viewBox="0 0 15.157 15"
              >
                <g id="Group_1310" data-name="Group 1310" opacity="0.86">
                  <path
                    id="Path_1507"
                    data-name="Path 1507"
                    d="M2602.818,548.518l-3.771-3.436a6.235,6.235,0,0,0-.48-8.26h0a6.231,6.231,0,1,0-.752,9.442l3.856,3.514a.853.853,0,1,0,1.149-1.261Zm-12.1-3.843a4.879,4.879,0,1,1,3.449,1.429A4.884,4.884,0,0,1,2590.714,544.675Z"
                    transform="translate(-2587.94 -535.001)"
                  />
                </g>
              </svg>
              <input
                type="text"
                placeholder="Wyszukaj..."
                value={searchString}
                onChange={handleSearchStringChange}
              />
            </MobileSearchInputWrapper>
            <svg
              onClick={() => {
                setIsBodyOverflowBlocked(true);
                setIsMobileSearchBarOpen(false);
                handleIsSortMenuOpenChange(false);
                handleIsFilterMenuOpenChange(true);
                handleIsMobileFilterMenuOpenChange(!isMobileFilterMenuOpen);
                if (!isMobileFilterMenuOpen) {
                  setTimeout(() => {
                    mobileFilterMenuWrapperRef.current.style.transition =
                      'none';
                  }, 350);
                }
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="19.2"
              height="18.021"
              viewBox="0 0 19.2 18.021"
            >
              <g
                id="Group_1925"
                data-name="Group 1925"
                transform="translate(-155.861 -459.378)"
                opacity="0.86"
              >
                <path
                  id="Path_1651"
                  data-name="Path 1651"
                  d="M173.319,462.311H163.211a.955.955,0,0,1,0-1.909h10.107a.955.955,0,1,1,0,1.909Z"
                  transform="translate(0.787 0.126)"
                />
                <path
                  id="Path_1652"
                  data-name="Path 1652"
                  d="M173.319,468.486H163.211a.955.955,0,0,1,0-1.909h10.107a.955.955,0,1,1,0,1.909Z"
                  transform="translate(0.787 0.886)"
                />
                <path
                  id="Path_1653"
                  data-name="Path 1653"
                  d="M173.319,474.366H163.211a.955.955,0,0,1,0-1.909h10.107a.955.955,0,1,1,0,1.909Z"
                  transform="translate(0.787 1.609)"
                />
                <circle
                  id="Ellipse_11"
                  data-name="Ellipse 11"
                  cx="2.246"
                  cy="2.246"
                  r="2.246"
                  transform="translate(155.861 472.907)"
                />
                <circle
                  id="Ellipse_12"
                  data-name="Ellipse 12"
                  cx="2.246"
                  cy="2.246"
                  r="2.246"
                  transform="translate(155.861 466.156)"
                />
                <circle
                  id="Ellipse_13"
                  data-name="Ellipse 13"
                  cx="2.246"
                  cy="2.246"
                  r="2.246"
                  transform="translate(155.861 459.378)"
                />
              </g>
            </svg>
            {countTruthyVariables(
              searchString,
              productType,
              isWinwin,
              hasAssignedProductsFilter,
            ) > 0 && (
              <ClearFiltersButton onClick={() => clearFilters()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10.9"
                  height="12"
                  viewBox="0 0 10.9 12"
                >
                  <g
                    id="Icon_feather-trash"
                    data-name="Icon feather-trash"
                    transform="translate(0.5 0.5)"
                  >
                    <path
                      id="Path_3663"
                      data-name="Path 3663"
                      d="M4.5,9h9.9"
                      transform="translate(-4.5 -6.8)"
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                    <path
                      id="Path_3664"
                      data-name="Path 3664"
                      d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                      transform="translate(-6.4 -3)"
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                  </g>
                </svg>
              </ClearFiltersButton>
            )}
          </MobileFilterWrapper>
          <MobileAddButtonWrapper onClick={() => navigate('/conditions/add')}>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33.371"
                height="33.066"
                viewBox="0 0 33.371 33.066"
              >
                <path
                  id="Path_1660"
                  data-name="Path 1660"
                  d="M203.6,115.33l-13.791.3.241-13.676a1.5,1.5,0,1,0-2.99,0l.24,13.676-13.79-.3a1.5,1.5,0,1,0,0,2.99l13.79-.3-.24,13.675a1.5,1.5,0,1,0,2.99,0l-.241-13.675,13.791.3a1.5,1.5,0,1,0,0-2.99Z"
                  transform="translate(-171.869 -100.292)"
                  fill="#0091ff"
                />
              </svg>
            </div>
            <p>Dodaj nowy warunek</p>
          </MobileAddButtonWrapper>
          {/* <MobileAddButton onClick={() => navigate('/conditions/add')}>
            <svg
              width="64px"
              height="64px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              stroke="#ffffff"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {' '}
                <path
                  d="M4 12H20M12 4V20"
                  stroke="#ffffff"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>{' '}
              </g>
            </svg>
          </MobileAddButton> */}
          {conditionsList &&
            conditionsList
              .sort((a, b) => {
                if (a.IsDefault && !b.IsDefault) {
                  return -1;
                } else if (!a.IsDefault && b.IsDefault) {
                  return 1;
                } else {
                  return 0;
                }
              })
              .map((condition) => {
                const {
                  Name,
                  Id,
                  AssignedProductsCount,
                  Description,
                  IsDefault,
                  ProductType,
                  WinWinDigital,
                  WinWinPhysical,
                  WinWinYoutube,
                } = condition;

                return (
                  <>
                    <MobileSingleConditionWrapper
                      key={Id}
                      ref={mobileLastElementRef}
                      IsDefault={IsDefault}
                      onClick={() => navigate(`/conditions/condition/${Id}`)}
                    >
                      <ConditionSvg />
                      <div>
                        {WinWinDigital || WinWinPhysical || WinWinYoutube ? (
                          <WinWinSvg />
                        ) : null}

                        <p>{getProductTypePolishName(ProductType)}</p>
                        <h5>{Name}</h5>
                        <StyledStatusWrapper
                          AssignedProductsCount={AssignedProductsCount}
                        >
                          <h1>
                            {/* (${AssignedProductsCount}) */}
                            {AssignedProductsCount === 0
                              ? 'Dostępny'
                              : `W użyciu`}
                          </h1>
                          <StyledStatusLine
                            AssignedProductsCount={AssignedProductsCount}
                          >
                            <div />
                          </StyledStatusLine>
                        </StyledStatusWrapper>
                        {!IsDefault && AssignedProductsCount === 0 && (
                          <button>
                            <PopupButton
                              buttons={['Anuluj', 'Usuń']}
                              title={'Uwaga'}
                              text={` Czy na pewno chcesz usunąć warunek ${Name}?
                     `}
                              onClick={(e) => deleteCondition(e, Id)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13.2"
                                height="14.5"
                                viewBox="0 0 13.2 14.5"
                              >
                                <g
                                  id="Icon_feather-trash"
                                  data-name="Icon feather-trash"
                                  transform="translate(0.75 0.75)"
                                >
                                  <path
                                    id="Path_3663"
                                    data-name="Path 3663"
                                    d="M4.5,9H16.2"
                                    transform="translate(-4.5 -6.4)"
                                    fill="none"
                                    stroke="#ff0300"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                  />
                                  <path
                                    id="Path_3664"
                                    data-name="Path 3664"
                                    d="M16.6,5.6v9.1A1.3,1.3,0,0,1,15.3,16H8.8a1.3,1.3,0,0,1-1.3-1.3V5.6m1.95,0V4.3A1.3,1.3,0,0,1,10.75,3h2.6a1.3,1.3,0,0,1,1.3,1.3V5.6"
                                    transform="translate(-6.2 -3)"
                                    fill="none"
                                    stroke="#ff0300"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                  />
                                </g>
                              </svg>
                            </PopupButton>
                          </button>
                        )}
                      </div>
                    </MobileSingleConditionWrapper>
                  </>
                );
              })}
        </MobileMain>
      </MobileMainWrapper>
    </>
  );
};

export default ConditionsComponent;
