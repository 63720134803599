import React, {useContext} from 'react';
import styled from 'styled-components';
import useAlert from '../../hooks/useAlert';
import {ResolutionContext} from '../../contexts/ResolutionContext';

const MainWrapper = styled.div`
  z-index: 99;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    background-color: rgba(112, 112, 112, 0.3);
  }
`;

export const PopupHeader = styled.div`
  background: ${({theme}) => theme.colors.blueTheme};
  width: 100%;
  height: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  & > h4 {
    font-size: 16px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-weight: normal;
    margin-bottom: 10px;
  }
  //@media (max-width: 1024px) {
  //  display: none;
  //}
`;

export const PopUpContentWrapper = styled.div`
  width: 292px;
  min-height: 148px;
  justify-content: space-between;
  //height: 161px;
  //border: 1px solid rgba(112, 112, 112, 0.3);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  background-color: ${({theme}) => theme.colors.bgWhite};
  display: flex;
  flex-direction: column;
  position: relative;
  //justify-content: center;
  align-items: center;
  & > p {
    //height: 62px;
    margin-top: 10px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    width: 80%;
    text-align: center;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: -0.25px;
    overflow: hidden;
    white-space: pre-line;
  }
  & > pre {
    margin-top: 10px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    width: 100%;
    text-align: center;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.25px;
    overflow: hidden;
  }
  //@media (max-width: 1024px) {
  //  width: 80%;
  //  & > p {
  //    margin-top: 38px;
  //    margin-bottom: 19px;
  //    font-size: 13px;
  //    letter-spacing: -0.025px;
  //  }
  //}
  //
  //@media (max-width: 400px) {
  //  width: 80%;
  //}
  //@media (max-width: 340px) {
  //  width: 80%;
  //}
`;

export const SvgCloseButtonWrapper = styled.div`
  position: absolute;
  right: 14px;
  top: 3px;
  cursor: pointer;
  & > svg {
    & path {
      fill: ${({theme}) => theme.colors.bgWhite};
    }
  }
`;

export const WrapperForButton = styled.div`
  @media (max-width: 1024px) {
    width: 100%;
    border-top: 1px solid rgba(7, 2, 15, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const PopUpButton = styled.button<{isBlue?: boolean}>`
  background-color: ${({isBlue}) => (isBlue ? `#0091FF` : '#0091FF')};
  color: white;
  font-weight: 500;
  cursor: pointer;
  //border: ${({isBlue}) => (isBlue ? `#7270F2 solid 1px` : 'grey solid 1px')};
  border: none;
  border-radius: 7px;
  text-align: center;
  padding: 5px 0px;
  font-size: 11px;
  line-height: 24px;
  width: 124px;
  height: 32px;
  margin: 12px;
  transition: all 0.15s;
  &:hover {
    filter: brightness(1.1);
  }
  &:active {
    filter: brightness(0.9);
  }
  //@media (max-width: 1024px) {
  //  margin: 10px;
  //  height: 44px;
  //  width: 80%;
  //  font-size: 15px;
  //  border-radius: 16px;
  //}
  //@media (max-width: 400px) {
  //  width: 80%;
  //}
  //@media (max-width: 340px) {
  //  width: 80%;
  //}
`;

type ListProps = {
  children: React.ReactChild[];
  show: boolean;
  onHide: () => void;
};

export const AlertPopup = () => {
  const {button, text, reload, title, closeAlert} = useAlert();
  const popupClose = () => {
    if (reload) {
      return new Promise((resolve) => {
        window.location.reload();
      });
    } else {
      return new Promise((resolve) => {
        closeAlert();
        resolve(true);
      });
    }
  };

  if (text && title) {
    return (
      <MainWrapper>
        <PopUpContentWrapper>
          <SvgCloseButtonWrapper onClick={popupClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="9"
              viewBox="0 0 9 9"
            >
              <path
                id="Path_4069"
                data-name="Path 4069"
                d="M32.273,8.038,28.6,4.515l3.633-3.508a.608.608,0,0,0,.043-.824.608.608,0,0,0-.823.043L27.941,3.86,24.417.182A.6.6,0,0,0,23.6.144a.6.6,0,0,0,.038.819l3.678,3.523L23.681,7.993a.555.555,0,1,0,.781.781L27.97,5.141l3.523,3.678a.554.554,0,1,0,.781-.781"
                transform="translate(-23.455 0)"
                fill="#fff"
              />
            </svg>
          </SvgCloseButtonWrapper>
          <PopupHeader>{/*<h4>{title}</h4>*/}</PopupHeader>
          <p>{text}</p>
          <PopUpButton onClick={popupClose} isBlue={true}>
            {button === 'Zamknij' ? 'OK' : button}
          </PopUpButton>
        </PopUpContentWrapper>
      </MainWrapper>
    );
  } else {
    return <></>;
  }
};

export const PopUpComponent = ({children, show, onHide}: ListProps) => {
  return show ? <MainWrapper>{children}</MainWrapper> : <></>;
};
