import React from 'react';
import {
  AtricleWrapper,
  ArticleImgWrapper,
  ArticleCategory,
  ArticleTitle,
  ArticleLead,
  ArticleTextWrapper,
} from './styles';

import digitalCover from 'images/post_mugo.png';
import {useNavigate} from 'react-router-dom';

interface Props {
  PhotoPath: string;
  Category: {CategoryId: string; Name: string};
  Title: string;
  Lead: string;
  UrlPath: string;
  Id: string;
}

const SingleAtricle: React.FC<Props> = ({
  PhotoPath,
  Category,
  Title,
  Lead,
  UrlPath,
  Id,
}) => {
  const navigate = useNavigate();
  const navigateToDetails = (UrlPath: string, Category: string) => {
    navigate(`/czytelnia/${Category.replace(/ /g, '-')}/${UrlPath}`);
  };
  return (
    <a
      href={`/czytelnia/${Category.Name.replace(/ /g, '-')}/${UrlPath}`}
      style={{textDecoration: 'none'}}
    >
      <AtricleWrapper
      // onClick={() => navigateToDetails(UrlPath, Category.Name)}
      >
        <ArticleImgWrapper>
          <img src={PhotoPath === '' ? digitalCover : PhotoPath} alt="" />
        </ArticleImgWrapper>
        <ArticleTextWrapper>
          {' '}
          <ArticleCategory>{Category.Name}</ArticleCategory>
          <ArticleTitle>{Title}</ArticleTitle>
          <ArticleLead>{Lead}</ArticleLead>
        </ArticleTextWrapper>
      </AtricleWrapper>
    </a>
  );
};

export default SingleAtricle;
